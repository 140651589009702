import React from 'react';
import { connect } from 'react-redux';

import { alertActions, profileActions, userActions } from '../../_actions';

import { NavigationBar } from '../navigation';
import { ProcessIndicator } from '../processIndicator';
import { UserDataEdit } from '../userDataEdit';
import { VerifyPassword } from '../verifypassword';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { changepw: false };
    this.onEditSubmit = this.onEditSubmit.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onPasswordChangeClick = this.onPasswordChangeClick.bind(this);
    this.onPasswordVerifyCancel = this.onPasswordVerifyCancel.bind(this);
    this.onPasswordVerifyInput = this.onPasswordVerifyInput.bind(this);
    this.onPasswordVerifySubmit = this.onPasswordVerifySubmit.bind(this);
    this.passwordChangeCancel = this.passwordChangeCancel.bind(this);
  }

  componentDidMount() {
    const { dispatch, auth } = this.props;
    dispatch(alertActions.clear());
    if (auth) {
      dispatch(profileActions.get(auth));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(profileActions.clear());
    dispatch(alertActions.clear());
  }

  render() {
    const { alert, authentication, auth, profile, dispatch } = this.props;
    if (!auth) return null;
    if (!auth.permissions) return null;
    const headerText = "Profile";

    let editForm = null;
    let showIndicator = false;
    let verifyPwOptions = new verifyPWOptions();

    if (!showIndicator) showIndicator = authentication.submitted ? authentication.submitted : false;

    if (profile) {
      if (!showIndicator) {
        if (profile.processing) showIndicator = profile.processing;
      }

      if (profile.user) {
        const validity = profile.validity ? profile.validity : null;
        const allowpwchange = authentication.proceed ? authentication.proceed : false;
        editForm = <UserDataEdit user={profile.user}
                            submitHandler={this.onEditSubmit}
                            changeHandler={this.onChangeHandler}
                            genPWHandler={null}
                            cancelHandler={null}
                            pwresetHandler={this.onPasswordChangeClick}
                            allowPWChange={allowpwchange}
                            pwChangeCancelHandler={this.passwordChangeCancel}
                            validity={validity}
                            disable={showIndicator} />;
      }

      if (authentication.verify) {
        const pwvCancelHandler = this.onPasswordVerifyCancel;
        const pwvChangeHandler = this.onPasswordVerifyInput;
        const pwvSubmitHandler = this.onPasswordVerifySubmit;
        verifyPwOptions.show = true;
        verifyPwOptions.handlers.proceed = pwvSubmitHandler;
        verifyPwOptions.handlers.change = pwvChangeHandler;
        verifyPwOptions.handlers.cancel = pwvCancelHandler;
        verifyPwOptions.pwval = authentication.pwinput? authentication.pwinput : '';
        verifyPwOptions.disable = showIndicator;
        verifyPwOptions.errorMsg = alert.message ? alert.message : null;
      }
    }

    return (
      <div>
        <NavigationBar header={headerText} />
        <ProcessIndicator show={showIndicator} />
        <VerifyPassword options={verifyPwOptions} />
        {alert.message &&
          <div className={`alert ${alert.type}`}>{alert.message}</div>
        }
        {editForm}
      </div>
    );
  }

  onEditSubmit(e) {
    e.preventDefault();
    const { dispatch, profile } = this.props;
    dispatch(alertActions.clear());
    dispatch(profileActions.update(profile));
  }

  onChangeHandler(e) {
    let { name, value } = e.target;
    const { dispatch, profile } = this.props;
    const { user } = profile;
    let oldval = user[name];
    let newuser = {
      ...user,
      [name]: value
    };
    let newprofile = {
      ...profile,
      user: newuser
    };
    dispatch(alertActions.clear());
    dispatch(profileActions.input(newprofile));
  }

  onPasswordChangeClick() {
    const { dispatch, authentication } = this.props;
    dispatch(alertActions.clear());
    dispatch(userActions.verifypw(authentication));
  }

  onPasswordVerifyCancel() {
    const { dispatch, authentication } = this.props;
    dispatch(alertActions.clear());
    dispatch(userActions.cancelverifypw(authentication));
  }

  onPasswordVerifyInput(e) {
    const { value } = e.target;
    const { dispatch, authentication } = this.props;
    dispatch(alertActions.clear());
    let newauthentication = {
      ...authentication,
      pwinput: value
    };
    dispatch(userActions.inputverifypw(newauthentication));
  }

  onPasswordVerifySubmit(e) {
    const { dispatch, authentication } = this.props;
    dispatch(alertActions.clear());
    if (!authentication.pwinput) {
      dispatch(alertActions.error('You need to enter your password'));
      return;
    }
    dispatch(userActions.checkverifypw(authentication));
  }

  passwordChangeCancel() {
    const { dispatch, profile, authentication } = this.props;
    dispatch(alertActions.clear());
    dispatch(userActions.cancelverifypw(authentication));
    const { user } = profile;
    const { password, verifypassword, ...nopw } = user;
    let newprofile = {
      ...profile,
      user: nopw
    };
    dispatch(profileActions.input(newprofile));
  }
};

function mapStateToProps(state) {
  const alert = state.alert
  const authentication = state.authentication;
  const auth = authentication.user;
  const profile = state.profile;
  return { alert, authentication, auth, profile };
}

const connectedProfile = connect(mapStateToProps)(Profile);
export { connectedProfile as Profile };


function verifyPWOptions() {
  return {
      show: false
    , handlers: {
          proceed: null
        , change: null
        , cancel: null
      }
    , pwval: ''
    , disable: false
    , errorMsg: null
  };
}
