import React from 'react';
import { WciModal } from '../../wcimodal';

export const SiteList = props => {
  let marshaCodes = null;
  if (props.marsha) {
    marshaCodes = <div><span>Site Codes Entered At Registration: </span><span>{props.marsha}</span></div>;
  }

  return (
    <WciModal show={props.show} className="baseModal">
      <div className="siteListModal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.title}</h5>
            <button type="button"
                    className="close"
                    onClick={props.cancel}>&times;</button>
          </div>
          <div className="modal-body">
            {props.alert.message &&
              <div className={`alert ${props.alert.type}`}>{props.alert.message}</div>
            }
            {marshaCodes}
            <div id="assignSiteSearchForm">
              {props.search}
            </div>
            <div className="row justify-content-end mr-1">{props.pagination}</div>
            {props.table}
          </div>
          <div className="modal-footer">
            <button type="button"
                    className="btn btn-secondary"
                    onClick={props.cancel}>Cancel</button>
          </div>
        </div>
      </div>
    </WciModal>
  );
};
