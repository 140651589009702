import { adminPermissionConstants } from '../_constants';
import { alertActions } from './';
import { history, textsorter } from '../_helpers';
import { adminService } from '../_services';

export const adminPermissionActions = {
    input
  , search
  , pageChange
  , rowCount
  , add
  , edit
  , update
  , delete: _delete
  , sort
};

function input(permission) {
  return dispatch => {
    dispatch(request(permission));
  };
  function request(obj) { return { type: adminPermissionConstants.PERMISSION_INPUT, obj }; }
}

function search(admin) {
  return dispatch => {
    const { permission } = admin;
    const { refresh, edititem, ...norefresh } = permission;
    const reqpermission = {
      ...norefresh,
      processing: true
    };
    dispatch(request(reqpermission));

    if (permission.searchValue && permission.searchValue.length > 0) {
      var params = [{'like': {'col': 'name', 'val': `%${permission.searchValue}%`}}];
      adminService.search('permission', params)
        .then(
          items => {
            if (items.length === 0) {
              dispatch(alertActions.success('No Data Found'));
              dispatch(success(norefresh));
            }
            else {
              dispatch(alertActions.success('Found ' + items.length + ' permissions.'));
              textsorter(items, 'name', 'asc');
              const nextpermission = {
                ...norefresh,
                  items: items
                , sort: { direction: 'asc', field: 'name' }
              };
              dispatch(success(nextpermission));
            }
          },
          error => {
            dispatch(failure(norefresh));
            dispatch(alertActions.error(error));
          }
        );
    }
    else {
      adminService.list('permission')
        .then(
          items => {
            dispatch(success(items));
            if (items.length === 0) {
              dispatch(alertActions.success('No Data Found'));
              dispatch(success(norefresh));
            }
            else {
              dispatch(alertActions.success('Found ' + items.length + ' permissions.'));
              textsorter(items, 'name', 'asc');
              const listpermission = {
                ...norefresh,
                  items: items
                , sort: { direction: 'asc', field: 'name' }
              };
              dispatch(success(listpermission));
            }
          },
          error => {
            dispatch(failure(norefresh));
            dispatch(alertActions.error(error));
          }
        );
    }
  };

  function request(obj) { return { type: adminPermissionConstants.PERMISSION_SEARCH_REQUEST, obj }; }
  function success(obj) { return { type: adminPermissionConstants.PERMISSION_SEARCH_SUCCESS, obj }; }
  function failure(obj) { return { type: adminPermissionConstants.PERMISSION_SEARCH_FAILURE, obj }; }
}

function pageChange(admin, nextPage) {
  return dispatch => {
    const { permission } = admin;
    const reqpermission = {
      ...permission,
      dataPage: nextPage
    };
    dispatch(request(reqpermission));
  };
  function request(obj) { return { type: adminPermissionConstants.PERMISSION_PAGE_CHANGE, obj }; }
}

function rowCount(admin, count) {
  return dispatch => {
    const { permission } = admin;
    const reqpermission = {
      ...permission,
      tableRows: parseInt(count)
    };
    dispatch(request(reqpermission));
  };
  function request(obj) { return { type: adminPermissionConstants.PERMISSION_PAGE_ROWS, obj }; }
}

function add(admin, dataobj) {
  return dispatch => {
    const { permission } = admin;
    const reqpermission = {
      ...permission,
      processing: true
    };
    dispatch(request(reqpermission));

    var validity = validateData(dataobj);
    const valpermission = {
      ...reqpermission,
      validity: validity
    };
    dispatch(validate(valpermission));

    if (validity.valid) {
      adminService.add('permission', dataobj)
        .then(
          result => {
            const nextpermission = {
              ...permission,
              refresh: true
            };
            dispatch(success(nextpermission));
            dispatch(alertActions.success('Permission add successful'));
          },
          error => {
            dispatch(failure(permission));
            dispatch(alertActions.error(error));
          }
        );
    }
    else {
      let msg = 'Form Validation Failed';
      if (validity.msg.length > 0) {
        msg = validity.msg;
      }
      dispatch(alertActions.error(msg));
      const { processing, ...notprocessing } = valpermission;
      dispatch(failure(notprocessing));
    }
  };

  function request(obj) { return { type: adminPermissionConstants.PERMISSION_ADD_REQUEST, obj }; }
  function validate(obj) { return { type: adminPermissionConstants.PERMISSION_ADD_VALIDITY, obj }; }
  function success(obj) { return { type: adminPermissionConstants.PERMISSION_ADD_SUCCESS, obj }; }
  function failure(obj) { return { type: adminPermissionConstants.PERMISSION_ADD_FAILURE, obj }; }
}

function edit(admin, item) {
  return dispatch => {
    const { permission } = admin;
    const reqpermission = {
      ...permission,
      processing: true
    };
    dispatch(request(reqpermission));

    adminService.get('permission', item.permission_id)
      .then(
        item => {
          const nextpermission = {
            ...permission,
            edititem: item
          };
          dispatch(success(nextpermission));
          history.push('/admin/permissions/edit');
        },
        error => {
          dispatch(failure(permission));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(obj) { return { type: adminPermissionConstants.PERMISSION_GET_REQUEST, obj }; }
  function success(obj) { return { type: adminPermissionConstants.PERMISSION_GET_SUCCESS, obj }; }
  function failure(obj) { return { type: adminPermissionConstants.PERMISSION_GET_FAILURE, obj }; }
}

function update(admin, data) {
  return dispatch => {
    const { permission } = admin;
    const reqpermission = {
      ...permission,
      processing: true
    };
    dispatch(request(reqpermission));

    var validity = validateData(data);
    const valpermission = {
      ...reqpermission,
      validity: validity
    };
    dispatch(validate(valpermission));

    if (validity.valid) {
      const { edititem } = permission;
      const { created_user_name, modified_user_name, ...nonames } = edititem;
      const newitem = {
        ...nonames,
          name: data.name
        , description: data.description
      };

      adminService.update('permission', newitem)
        .then(
          result => {
            if (result.status === 'success') {
              const nextpermission = {
                ...permission,
                refresh: true
              };
              dispatch(success(nextpermission));
              dispatch(alertActions.success('Permission update was successful'));
            }
          },
          error => {
            dispatch(failure(permission));
            dispatch(alertActions.error(error));
          }
        );
    }
  };

  function request(obj) { return { type: adminPermissionConstants.PERMISSION_UPDATE_REQUEST, obj }; }
  function validate(obj) { return { type: adminPermissionConstants.PERMISSION_UPDATE_VALIDITY, obj }; }
  function success(obj) { return { type: adminPermissionConstants.PERMISSION_UPDATE_SUCCESS, obj }; }
  function failure(obj) { return { type: adminPermissionConstants.PERMISSION_UPDATE_FAILURE, obj }; }
}

function _delete(admin, item) {
  return dispatch => {
    const { permission } = admin;
    const reqpermission = {
      ...permission,
      processing: true
    };
    dispatch(request(reqpermission));

    adminService.delete('permission', item.permission_id)
      .then(
        result => {
          const nextpermission = {
            ...permission,
            refresh: true
          };
          dispatch(success(nextpermission));
        },
        error => {
          dispatch(failure(permission));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: adminPermissionConstants.PERMISSION_DELETE_REQUEST, obj }; }
  function success(obj) { return { type: adminPermissionConstants.PERMISSION_DELETE_SUCCESS, obj }; }
  function failure(obj) { return { type: adminPermissionConstants.PERMISSION_DELETE_FAILURE, obj }; }
}

function sort(admin, field) {
  return dispatch => {
    const { permission } = admin;
    const reqpermission = {
      ...permission,
      processing: true
    };
    dispatch(request(reqpermission));

    if (permission.items.length === 1) {
      dispatch(success(permission));
      return;
    }

    const { items, sort } = permission;
    let direction = 'asc';
    let list = [];
    for (let i = 0, j = items.length; i < j; i++) {
      list.push(items[i]);
    }

    if (field === sort.field && sort.direction === 'asc') direction = 'desc';
    textsorter(list, field, direction);
    const nextsort = { field: field, direction: direction };
    const nextpermission = {
      ...permission,
        items: list
      , sort: nextsort
    };
    dispatch(success(nextpermission));
  };
  function request(obj) { return { type: adminPermissionConstants.PERMISSION_SORT_REQUEST, obj }; }
  function success(obj) { return { type: adminPermissionConstants.PERMISSION_SORT_SUCCESS, obj }; }
}

function validateData(params) {
  let validity = {
      valid: true
    , name: true
    , description: true
    , msg: ''
  };
  let valid = true;

  if (!params.name) {
    valid = false;
    validity.name = false;
  }
  if (!params.description) {
    valid = false;
    validity.description = false;
  }
  if (params.description && params.description.length > 45) {
    valid = false;
    validity.description = false;
    validity.msg = 'Description is too long. Only 45 characters are allowed.';
  }
  validity.valid = valid;
  return validity;
}
