export const config = {
    images: {
        on: 'greenLight.svg'
      , off: 'blueLight.svg'
      , down: 'redLight.svg'
      , chkin: 'guestIn.svg'
      , chkout: 'guestOut.svg'
      , unstable: 'redblue.svg'
    }
  , stbCommTime: {
        hotel: 120
      , wci: 15
    }
  , networkGraphIntervals: {
        day: 'netgraph.png'
      , week: 'netgraphweek.png'
      , month: 'netgraphmonth.png'
    }
  , numericColumns: {
        sites: ['box_on', 'box_down', 'box_tot']
      , message: ['priority', 'type']
      , msgdetail: ['room', 'viewed']
      , events: ['active', 'recurrence']
    }
  , messages: {
        type: ['', 'Public', 'Private']
      , priority: ['Emergency', 'Important', 'Standard']
    }
  , events: {
        active: ['No', 'Yes']
      , recurrence: ['No', 'Yes']
  }
  , itemCountOptions: [10, 20, 25]
  , sites: {
      searchParams: {
          site_id: ''
        , their_id: ''
        , name: ''
        , site_ip_address: ''
      }
    }
  , locations: {
        guestroom: [
          {
            id: "loc-none",
            data:"",
            label: {en: "Single TV", cf: "Single TV"}
          },
          {
            id: "loc-lr",
            data: "lr",
            label: {en: "Living Room", cf: "Salon"}
          },
          {
            id: "loc-bar",
            data: "bar",
            allowMultiple: true,
            label: {en: "Bar", cf: "Bar"}
          },
          {
            id: "loc-br",
            data: "br",
            allowMultiple: true,
            label: {en: "Bedroom", cf: "Chambre"}
          },
          {
            id: "loc-dn",
            data: "dn",
            label: {en: "Dining Room", cf: "Salle à Manger"}
          },
          {
            id: "loc-cab",
            data: "cabana",
            label: {en: "Cabana", cf: "Cabane"}
          },
          {
            id: "loc-bath",
            data: "bath",
            label: {en: "Bath", cf: "La Salle de Bain"}
          }
        ]
      , publicspace: [
          {
            id: "loc-bar",
            data:"bar",
            label: {en: "Bar", cf: "Bar"}
          },
          {
            id: "loc-res",
            data:"restaurant",
            label: {en: "Restaurant", cf: "Restaurant"}
          },
          {
            id: "loc-pol",
            data:"pool",
            label: {en: "Pool", cf: "Piscine"}
          },
          {
            id: "loc-conv",
            data:"convention",
            label: {en: "Convention", cf: "Convention"}
          },
          {
            id: "loc-ovhd",
            data:"overhead",
            label: {en: "Overhead", cf: "Aérien"}
          },
          {
            id: "loc-lby",
            data:"lobby",
            label: {en: "Lobby", cf: "Hall"}
          },
          {
            id: "loc-bkf",
            data:"breakfast",
            label: {en: "Breakfast", cf: "Déjeuner"}
          },
          {
            id: "loc-fit",
            data:"fitness",
            label: {en: "Fitness", cf: "la Santé"}
          },
          {
            id: "loc-meet",
            data:"meeting",
            label: {en: "Meeting", cf: "la Entrevue"}
          },
          {
            id: "loc-thet",
            data:"theater",
            label: {en: "Theater", cf: "Théâtre"}
          },
          {
            id: "loc-ldry",
            data:"laundry",
            label: {en: "Laundry", cf: "Blanchisserie"}
          },
          {
            id: "loc-loug",
            data:"lounge",
            label: {en: "Lounge", cf: "Salon"}
          },
          {
            id: "loc-conc",
            data:"concierge",
            label: {en: "Concierge", cf: "Concierge"}
          },
          {
            id: "loc-lib",
            data:"library",
            label: {en: "Library", cf: "Bibliothèque"}
          },
          {
            id: "loc-bal",
            data:"ballroom",
            label: {en: "Ballroom", cf: "Salle de Bal"}
          },
          {
            id: "loc-bkr",
            data:"breakroom",
            label: {en: "Breakroom", cf: "Salle de Pause"}
          },
          {
            id: "loc-caf",
            data:"cafeteria",
            label: {en: "Cafeteria", cf: "la Cafétéria"}
          },
          {
            id: "loc-ao",
            data:"adminOffice",
            label: {en: "Admin Office", cf: "Bureau d'administration"}
          },
          {
            id: "loc-eo",
            data:"engiOffice",
            label: {en: "Engineer Office", cf: "Bureau d'Ingénieur"}
          },
          {
            id: "loc-sp",
            data:"spare",
            label: {en: "Spare STB", cf: "Rechange STB"}
          }
        ]
    }
  , userAddParams: {
        user_name:''
      , email:''
      , first_name:''
      , last_name:''
      , phone:''
      , password:''
      , ldap_user:false
      , new:1
    }
  , stbParams: {
      message_urgent_turn_tv_on: {
          stb_level: true,
          value_type: 'string',
          allowed_values: ['true', 'false']
      }
  }
  , STBStateCodeText: {
    "100": "Online/Off",
    "200": "Online/On",
    "500": "Offline",
    "501": "Disconnected",
    "999": "Unknown"
  }
  , scriptButtons: {
    "C01538": [
      {text: "Turn All On", script: "stbPower" , extra: "on"},
      {text: "Go Fullscreen", script: "stbToTv", extra: null},
      {text: "Tune To Channel", script: "changeChannel", extra: "logical"},
      {text: "Turn All Off", script: "stbPower", extra: "off"}
    ],
    "X3003": [
      {text: "Turn All On", script: "stbPower" , extra: "on"},
      {text: "Go Fullscreen", script: "stbToTv", extra: null},
      {text: "Tune To Channel", script: "changeChannel", extra: "logical"},
      {text: "Turn All Off", script: "stbPower", extra: "off"}
    ]
  }
};
