import React from 'react';

export const FileContentTypes = props => (
  <div className="targetzone">
    <div>Select Type of Content:</div>
    <div className="ml-2">
      <input type="radio" id="rdofct0" name="Events Calendar" value="0" onChange={(e) => { props.changeHandler(e); }} checked={props.focus === 0 ? true : false} disabled={props.disable} /><label htmlFor="rdofct0" className="rdoLabel">&nbsp;Events Calendar</label>
    </div>
  </div>
);
