import React from 'react';
import { connect } from 'react-redux';

import { alertActions, adminRoleActions } from '../../../_actions';
import { permissionCheck, prepTableInfo, history, isObjectEmpty, confirmOptions } from '../../../_helpers';

import { NavigationBar } from '../../navigation';
import { SimpleSearch } from '../../search';
import { DataTable } from '../../dataTable';
import { ConfirmModal } from '../../confirm';

class RoleAddPermission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        searchInput: ''
    };

    this.onCancel = this.onCancel.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onkeydown = this.onkeydown.bind(this);
    this.onDeleteProceed = this.onDeleteProceed.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
  }

  componentDidMount() {
    const { auth } = this.props;
    if (!auth) {
      history.push('/admin/roles');
    }
  }

  render() {
    const { auth, alert, admin } = this.props;
    if (!auth) return null;
    const allowEdit = permissionCheck(auth.permissions, 'admin.role.edit');
    const { role } = admin;
    const item = role.items[role.editidx];
    const btndisable = role.searching || role.updating;
    let btnText = 'Cancel';
    let datatable = null;

    if (allowEdit && role.permissionsAvailable && role.permissionsAvailable.length > 0) {
      var tableInfoObj = prepTableInfo(role.permissionsAvailable);
      var tableDisplayParams = {
          hideFields: ['permission_id', 'created', 'modified', 'selected']
        , allowOptions: {
            edit: { allowed: false, handler: null },
            delete: { allowed: false, handler: null },
            select: { allowed: true, handler: this.onSelect }
          }
      };
      datatable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} />;
    }

    let confirmOpts = new confirmOptions();
    if (role.noPermissionSelection) {
      confirmOpts.show = true;
      confirmOpts.title = 'Confirm Delete';
      confirmOpts.question = 'Are you sure you want to delete all the permissions?';
      confirmOpts.handlers.proceed = this.onDeleteProceed;
      confirmOpts.handlers.cancel = this.onDeleteCancel;
      confirmOpts.proceed.title = 'Delete';
      confirmOpts.proceed.className = 'btn-danger';
    }

    return(
      <div>
        <NavigationBar header="Admin - Roles - Permissions" />
        <div className="jumbotron">
          <div className="container">
            <div className="row justify-content-md-center">
              <h5>Change Role Permissions</h5>
            </div>
            <div className="row">
              <label htmlFor="role_id" className="col-sm-2 col-form-label text-right">ID:</label>
              <input type="text" id="role_id"
                className="col-sm-1 form-control-plaintext"
                readOnly
                value={item.role_id} />
              <label htmlFor="name" className="col-sm-2 col-form-label text-right">Name:</label>
              <input type="text" id="name"
                    className="col-sm-4 form-control-plaintext"
                    name="name"
                    readOnly
                    value={item.name} />
            </div>
            <div className="row">
              <label htmlFor="description" className="col-sm-2 col-form-label text-right">Description:</label>
              <input type="text" id="description"
                    className="col-sm-6 form-control-plaintext"
                    name="description"
                    readOnly
                    value={item.description} />
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-md-center">
              <h6>Look For Permissions</h6>
            </div>
            <SimpleSearch submitHandler={this.onSearch}
                          changeHandler={this.onChange}
                          searchValue={this.state.search}
                          searching={role.searching}
                          searchDisable={btndisable}
                          keydownHandler={this.onkeydown} />
          </div>
          <div className="container">
            {datatable}
          </div>
          <div className="container">
            <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={btndisable}>
              <span>Submit</span>
              {role.updating &&
                <div className="d-inline fa-2x left-margin-10"><i className="fas fa-spinner fa-pulse"></i></div>
              }
            </button>
            <button type="button" className="btn btn-secondary left-margin-10" disabled={btndisable} onClick={this.onCancel}>{btnText}</button>
          </div>
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
        </div>
        <ConfirmModal options={confirmOpts} />
      </div>
    );
  }

  onChange(evt) {
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    const { value } = evt.target;
    this.setState({ searchInput: value });
  }

  onSearch() {
    const { alert, admin, search, clearAlert } = this.props;
    const { searchInput } = this.state;
    if (!isObjectEmpty(alert)) clearAlert();
    search(admin, searchInput);
  }

  onCancel() {
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    history.push('/admin/roles/edit');
  }

  onSelect(evt) {
    var idx = parseInt(evt.currentTarget.attributes['data-index'].value);
    const { alert, admin, select, clearAlert } = this.props;
    const { role } = admin;
    var obj = null;
    if (idx >= 0) {
      obj = role.permissionsAvailable[idx];
    }
    if (!obj) {
      dispatch(alertActions.error('selected role was not found'));
      return;
    }
    const { checked } = evt.target;
    select(admin, obj, checked);
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onSubmit(evt) {
    evt.preventDefault();
    const { alert, admin, update, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    update(admin);
  }

  onkeydown(evt) {
      if (evt.keyCode === 13) {
          evt.preventDefault();
          evt.stopPropagation();
          this.onSearch();
      }
  }

  onDeleteProceed() {
    const { alert, admin, update, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    const { role } = admin;
    const proceedrole = {
      ...role,
      deleteAll: true
    }
    const proceedadmin = {
      ...admin,
      role: proceedrole
    }
    update(proceedadmin);
  }
  onDeleteCancel() {
    const { admin, cancel } = this.props;
    cancel(admin);
  }
};

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const admin = state.administration
  return { alert, auth, admin };
}

const mapDispatchToProps = (dispatch) => ({
    clearAlert() { dispatch(alertActions.clear()); }
  , search(admin, value) { dispatch(adminRoleActions.permissionSearch(admin, value)); }
  , select(admin, permission, add) { dispatch(adminRoleActions.permissionSelect(admin, permission, add)); }
  , update(admin) { dispatch(adminRoleActions.permissionUpdate(admin)); }
  , cancel(admin) { dispatch(adminRoleActions.cancel(admin)); }
});

const connectedRoleAddPermission = connect(mapStateToProps, mapDispatchToProps)(RoleAddPermission);
export { connectedRoleAddPermission as RoleAddPermission };
