import { siteConstants } from '../_constants';
import { alertActions, statsActions, msgActions, eventActions, streamingActions, paramActions } from './';
import { history, config, textsorter, setStbStatus, setCheckInState, stbReservationState, stbPowerState, listFilter, permissionCheck, duplicates, serialLabels, setStateCodes, stbStateCode } from '../_helpers';
import { siteService } from '../_services';

export const siteActions = {
    clear
  , get
  , setupCharts
  , searchByStatus
  , searchInput
  , searchClear
  , search
  , pageChange
  , tableRowCount
  , sort
  , deactivate
  , reboot
  , roomchange
  , rcCancel
  , rcpublic
  , rcinput
  , rclocation
  , rcLocationNum
  , rcSave
  , callScript
};

function clear() {
  return dispatch => {
    const newsite = {};
    dispatch(clear(newsite));
    dispatch(statsActions.clear());
    dispatch(msgActions.clear());
    dispatch(eventActions.clear());
    dispatch(streamingActions.clear());
    dispatch(paramActions.clear());
  };
  function clear(obj) { return { type: siteConstants.SITE_CLEAR, obj }; }
}

function get(monitor, siteobj, route, auth = null) {
  return dispatch => {
    const { site } = monitor;
    const reqsite = {
      ...site,
        processing: true
      , stb: {stats: false}
    };
    const { dataPage, search, sort, edit, stbs, users, ...cleared } = reqsite;
    dispatch(request(cleared));
    dispatch(statsActions.clear());
    dispatch(msgActions.clear());
    dispatch(eventActions.clear());
    dispatch(streamingActions.clear());
    dispatch(paramActions.clear());

    siteService.get(siteobj)
      .then(
        items => {
          textsorter(items, 'room_number', 'asc');

          const { processing, ...notprocessing } = cleared;
          const nextsite = {
            ...notprocessing,
              edit: siteobj
            , stbs: items
            , dups: null
            , invalid: null
            , sort: { direction: 'asc', field: 'room_number' }
          }
          const labeldups = duplicates(items, 'room_number');
          const nolabel = serialLabels(items);
          //if (labeldups.length > 2) {
          //  textsorter(labeldups, 'room_number', 'asc');
          //}
          if (labeldups.length > 0) nextsite.dups = labeldups;
          if (nolabel.length > 0) nextsite.invalid = nolabel;
          dispatch(success(nextsite));
          if (!route) return;
          if (auth && permissionCheck(auth.permissions, 'portal.site')) {
            history.push('/portal/site');
          }
          else {
            // We now have roles which do not allow site page to be seen, so
            // find the first tab to where the user has permission to route.
            if (!auth) {
              history.push('/');
              return;
            }
            if (auth && permissionCheck(auth.permissions, 'portal.stats')) {
              history.push('/portal/stats');
            }
            else if (auth && permissionCheck(auth.permissions, 'portal.message')) {
              history.push('/portal/messaging');
            }
            else if (auth && permissionCheck(auth.permissions, 'portal.events')) {
              history.push('/portal/events');
            }
            else if (auth && permissionCheck(auth.permissions, 'portal.upload')) {
              history.push('/portal/uploader');
            }
            else if (auth && permissionCheck(auth.permissions, 'portal.resv')) {
              history.push('/portal/resv');
            }
            else if (auth && permissionCheck(auth.permissions, 'portal.housekeeping')) {
              history.push('/portal/housekeeping');
            }
            /*else if (auth && permissionCheck(auth.permissions, 'portal.roomservice')) {
              history.push('/portal/roomservice');
            }*/ //01.25.2021 Commenting room service stuff. TODO: Uncomment it later
            else if (auth && permissionCheck(auth.permissions, 'portal.documentation')) {
              history.push('/portal/documentation');
            }
            else {
              history.push('/');
            }
          }
        },
        error => {
          dispatch(failure(site));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: siteConstants.SITE_GET_REQUEST, obj }; }
  function success(obj) { return { type: siteConstants.SITE_GET_SUCCESS, obj }; }
  function failure(obj) { return { type: siteConstants.SITE_GET_FAILURE, obj }; }
}

function setupCharts(monitor, wci) {
  let compareTo = wci ? config.stbCommTime.wci : config.stbCommTime.hotel;
  return dispatch => {
    const { site } = monitor;
    const { stb } = site;
    const newstb = {
      ...stb,
        stats: true
      , onStats: null
      , inStats: null
    };
    setStbStatus(site.stbs, compareTo, function(ste) {
      newstb.onStats = ste;
      const newsite = {
        ...site,
        stb: newstb
      };
      dispatch(stbOnStats(newsite));
    });
    setCheckInState(site.stbs, function(chk) {
      newstb.inStats = chk;
      const newsite = {
        ...site,
        stb: newstb
      };
      dispatch(stbChkInStats(newsite));
    });
    setStateCodes(site.stbs, stateCodes => {
      newstb.stateCodes = stateCodes;
      const newsite = {
        ...site,
        stb: newstb
      };
    });
  };
  function stbOnStats(obj) { return { type: siteConstants.SITE_STB_ONSTATE, obj }; }
  function stbChkInStats(obj) { return { type: siteConstants.SITE_STB_CHECKINSTATE, obj }; }
}

function searchByStatus(monitor, searchType, wci) {
  return dispatch => {
    const { site } = monitor;
    const reqsite = {
      ...site,
      processing: true
    };
    dispatch(request(reqsite));

    const found = stbFilter(searchType, site.stbs);
    if (found && found.length > 0) {
      const search = { found: found };
      const nextsite = {
        ...site,
        search: search
      };
      dispatch(success(nextsite));
    }
    else {
      dispatch(failure(site));
      dispatch(alertActions.info('No matches found'));
    }
  };
  function request(obj) { return { type: siteConstants.SITE_CHART_SEARCH_REQUEST, obj }; }
  function success(obj) { return { type: siteConstants.SITE_CHART_SEARCH_SUCCESS, obj }; }
  function failure(obj) { return { type: siteConstants.SITE_CHART_SEARCH_FAILURE, obj }; }
}

function searchInput(monitor, value) {
  return dispatch => {
    const { site } = monitor;
    const { search } = site;
    const nextsearch = {
      ...search,
      text: value
    };
    const nextsite = {
      ...site,
      search: nextsearch
    };
    dispatch(request(nextsite));
  };
  function request(obj) { return { type: siteConstants.SITE_SEARCH_INPUT, obj }; }
}

function searchClear(monitor) {
  return dispatch => {
    const { site } = monitor;
    const { search, ...nosearch } = site;
    dispatch(clear(nosearch));
  };
  function clear(obj) { return { type: siteConstants.SITE_SEARCH_CLEAR, obj }; }
}

function search(monitor) {
  return dispatch => {
    const { site } = monitor;
    const { search } = site;
    const reqsearch = {
      ...search,
      looking: true
    };
    const reqsite = {
      ...site,
        processing: true
      , search: reqsearch
    };
    dispatch(request(reqsite));

    let searches = ['room_number', 'serial_number', 'ip_address'];
    let searchVals = [search.text];
    let searchresult = listFilter(site.stbs, searches, searchVals);
    if (searchresult.length > 0) {
      const newsearch = {
        ...search,
        found: searchresult
      };
      const nextsite = {
        ...site,
        search: newsearch
      };
      dispatch(alertActions.info('Search complete found ' + searchresult.length + ' rooms.'));
      dispatch(success(nextsite));
    }
    else {
      dispatch(alertActions.info('No rooms found matching the criteria.'));
      dispatch(failure(site));
    }
  };

  function request(obj) { return { type: siteConstants.SITE_SEARCH_REQUEST, obj }; }
  function success(obj) { return { type: siteConstants.SITE_SEARCH_SUCCESS, obj }; }
  function failure(obj) { return { type: siteConstants.SITE_SEARCH_FAILURE, obj }; }
}

function pageChange(monitor, page) {
// Pagination through the stb table.
  return dispatch => {
    const { site } = monitor;
    const newsite = {
      ...site,
      dataPage: page
    };
    dispatch(request(newsite));
  };
  function request(obj) { return { type: siteConstants.SITE_PAGE_CHANGE, obj }; }
}

function tableRowCount(monitor, rows) {
  return dispatch => {
    const { site } = monitor;
    const reqsite = {
      ...site,
      tableRows: rows
    };
    dispatch(request(reqsite));
  };
  function request(obj) { return { type: siteConstants.SITE_TABLE_ROWS, obj }; }
}

function sort(monitor, field) {
  return dispatch => {
    const { site } = monitor;
    const reqsite = {
      ...site,
      processing: true
    };
    dispatch(request(reqsite));

    if (site.stbs.length === 1) {
      dispatch(success(site));
      return;
    }

    let direction = 'asc';
    const { sort, search } = site;
    if (sort) {
      if (field === sort.field) {
        if (sort.direction === 'asc') direction = 'desc';
      }
    }
    textsorter(site.stbs, field, direction);
    if (search && search.found) {
      textsorter(search.found, field, direction);
    }
    const nextsite = {
      ...site,
      sort: { direction: direction, field: field }
    };

    dispatch(success(nextsite));
  };
  function request(obj) { return { type: siteConstants.SITE_SORT_REQUEST, obj }; }
  function success(obj) { return { type: siteConstants.SITE_SORT_SUCCESS, obj }; }
}

function deactivate(monitor, stb) {
  return dispatch => {
    const { site } = monitor;
    const reqsite = {
      ...site,
      processing: true
    };
    dispatch(request(reqsite));

    siteService.stbDeactivate(stb)
      .then(
        result => {
          dispatch(alertActions.success('STB removed from list'));
          siteService.get(site.edit)
            .then(
              items => {
                const nextsite = {
                  ...site,
                  stbs: items
                }
                dispatch(success(nextsite));
              },
              error => {
                dispatch(failure(site));
                dispatch(alertActions.error(error));
              }
            );
        },
        error => {
          dispatch(failure(site));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: siteConstants.SITE_STB_DEACTIVATE_REQUEST, obj }; }
  function success(obj) { return { type: siteConstants.SITE_STB_DEACTIVATE_SUCCESS, obj }; }
  function failure(obj) { return { type: siteConstants.SITE_STB_DEACTIVATE_FAILURE, obj }; }
}

function reboot(monitor, stb) {
  return dispatch => {
    const { site } = monitor;
    const reqsite = {
      ...site,
      processing: true
    };
    dispatch(request(reqsite));

    siteService.stbReboot(stb)
      .then(
        result => {
          get(monitor, site, false);
          dispatch(success(site));
          dispatch(alertActions.info(result));
        },
        error => {
          dispatch(failure(site));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: siteConstants.SITE_STB_REBOOT_REQUEST, obj }; }
  function success(obj) { return { type: siteConstants.SITE_STB_REBOOT_SUCCESS, obj }; }
  function failure(obj) { return { type: siteConstants.SITE_STB_REBOOT_FAILURE, obj }; }
}

function roomchange(monitor, stbObj) {
  return dispatch => {
    const { site } = monitor;
    const { stb } = site;
    const roomChgObj = { stb: stbObj };
    const newstb = {
      ...stb,
      rmChgObj: roomChgObj
    };
    const newsite = {
      ...site,
      stb: newstb
    };
    dispatch(request(newsite));
  };
  function request(obj) { return { type: siteConstants.SITE_ROOM_NUMBER_CHANGE_REQUEST, obj }; }
}

function rcCancel(monitor) {
  return dispatch => {
    const { site } = monitor;
    const { stb } = site;
    const { rmChgObj, ...noroomchg } = stb;
    const newsite = {
      ...site,
      stb: noroomchg
    };
    dispatch(request(newsite));
  };
  function request(obj) { return { type: siteConstants.SITE_ROOM_NUMBER_CHANGE_CANCEL, obj }; }
}

function rcpublic(monitor, ischecked) {
  return dispatch => {
    const { site } = monitor;
    const { stb } = site;
    const { rmChgObj } = stb;

    const { rmnum, locationidx, locnum, ...cleareddata } = rmChgObj;
    cleareddata.isPublic = ischecked;

    const newstb = {
      ...stb,
      rmChgObj: cleareddata
    };
    const newsite = {
      ...site,
      stb: newstb
    };
    dispatch(request(newsite));
  };
  function request(obj) { return { type: siteConstants.SITE_ROOM_NUMBER_CHANGE_PUBLIC, obj }; }
}

function rcinput(monitor, rmnum) {
  return dispatch => {
    const { site } = monitor;
    const { stb } = site;
    const { rmChgObj } = stb;

    const nextrmChgObj = {
      ...rmChgObj,
      rmnum: rmnum
    };

    const newstb = {
      ...stb,
      rmChgObj: nextrmChgObj
    };
    const newsite = {
      ...site,
      stb: newstb
    };
    dispatch(request(newsite));
  };
  function request(obj) { return { type: siteConstants.SITE_ROOM_NUMBER_CHANGE_INPUT, obj }; }
}

function rclocation(monitor, isPublic, idx) {
  return dispatch => {
    const { site } = monitor;
    const { stb } = site;
    const { rmChgObj } = stb;
    if (!isPublic) {
      const guestroomloc = config.locations.guestroom[idx];
    }
    const { newroomlocnum, ...nolocnum } = rmChgObj;
    const newRmChgObj = {
      ...nolocnum,
        locationidx: idx
      , isPublic: isPublic
    }
    const newstb = {
      ...stb,
      rmChgObj: newRmChgObj
    };
    const newsite = {
      ...site,
      stb: newstb
    };
    dispatch(request(newsite));
  };
  function request(obj) { return { type: siteConstants.SITE_ROOM_NUMBER_CHANGE_LOCATION, obj }; }
}

function rcLocationNum(monitor, value) {
  return dispatch => {
    const { site } = monitor;
    const { stb } = site;
    const { rmChgObj } = stb;
    const newrmChgObj = {
      ...rmChgObj,
      locnum: value
    };
    const newstb = {
      ...stb,
      rmChgObj: newrmChgObj
    };
    const newsite = {
      ...site,
      stb: newstb
    };
    dispatch(request(newsite));
  };
  function request(obj) { return { type: siteConstants.SITE_ROOM_NUMBER_CHANGE_LOCATIONNUM, obj }; }
}

function rcSave(monitor, roomname) {
  return dispatch => {
    const { site } = monitor;
    const { stb } = site;
    const { rmChgObj } = stb;
    const newrmChgObj = {
      ...rmChgObj,
      saving: true
    };
    const newstb = {
      ...stb,
      rmChgObj: newrmChgObj
    };
    const newsite = {
      ...site,
      stb: newstb
    };
    dispatch(request(newsite));


    let savedata = {
        site_id: site.edit.site_id
      , serial_number: rmChgObj.stb.serial_number
      , old_room: rmChgObj.stb.room_number
      , new_room: roomname
    };
    let sd = JSON.stringify(savedata);

    siteService.stbRoomChange(sd)
      .then(
        result => {
          const { rmChgObj, ...noroomchg } = stb;
          const nextsite = {
            ...site,
            stb: noroomchg
          };
          siteService.get(site.edit)
            .then(
              items => {
                nextsite.stbs = items;
                dispatch(success(nextsite));
              },
              error => {
                dispatch(getsitefailure(nextsite));
                dispatch(alertActions.error(error));
              }
            );
        },
        error => {
          dispatch(failure(site));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: siteConstants.SITE_ROOM_NUMBER_CHANGE_SAVE_REQUEST, obj }; }
  function success(obj) { return { type: siteConstants.SITE_ROOM_NUMBER_CHANGE_SAVE_SUCCESS, obj }; }
  function failure(obj) { return { type: siteConstants.SITE_ROOM_NUMBER_CHANGE_SAVE_FAILURE, obj }; }
}

function callScript(auth, monitor, scriptInfo) {
    return dispatch => {
        if (!auth || !permissionCheck(auth.permissions, 'portal.site.scripts')) {
            dispatch(alertActions.error('You do not have permission to perform this action'));
            return;
        }
        // permission exist continue
        const { site } = monitor;
        const { edit } = site;
        const reqsite = {
          ...site,
          processing: true
        };
        dispatch(request(reqsite));
        
        const callscript = {
          name: scriptInfo.script,
          param: scriptInfo.extra
        };
        const data = {
          site_id: edit.site_id,
          script: callscript
        };
        const ds = JSON.stringify(data);
        siteService.callScript(ds).then(
          result => {
            dispatch(success(site));
            if (result.status === 'OK')
              dispatch(alertActions.info('Script Run Successful'));
          },
          error => {
            dispatch(failure(site));
            dispatch(alertActions.error(error));
          }
        );
    }

    function request(obj) { return { type: siteConstants.SITE_SCRIPT_EXECUTE_REQUEST, obj }; }
    function success(obj) { return { type: siteConstants.SITE_SCRIPT_EXECUTE_SUCCESS, obj }; }
    function failure(obj) { return { type: siteConstants.SITE_SCRIPT_EXECUTE_FAILURE, obj }; }
}


/* PRIVATE METHODS */
function stbFilter(search, stbs, wci) {
  switch(search) {
    case 'on':
    case 'off':
    case 'down':
      return stbPowerState(search, stbs, wci);
    case 'in':
    case 'out':
    case 'unknown':
      return stbReservationState(stbs, search);
    case '100':
    case '200':
    case '500':
    case '501':
      return stbStateCode(search, stbs);
  }
}
