import React from 'react';

import { permissionCheck, history } from '../../../_helpers';

export const RolePermissions = props => {
  const { auth, admin } = props;
  if (!auth) {
    history.push('/admin/roles');
    return null;
  }
  const role = admin.role;
  const permissions = role.permissions;
  let allowEdit = permissionCheck(auth.permissions, 'admin.role.edit');
  let addbtnstyle = {display: 'none'};
  let addBtn = null;
  if (allowEdit) {
    addBtn = <button type="button" className="btn btn-success" onClick={props.addHandler} disabled={role.updating}>Change</button>
  }
  if (props.showAdd) {
    addbtnstyle = null;
  }

  //<button type="button" className="btn btn-info left-margin-10" onClick={props.permissionShowHandler} data-toggle="collapse" data-target="#permissionList" aria-expanded="false" aria-controls="permissionList">
  return (
    <div className="col">
      <div className="row justify-content-md-center">
        <h5 style={{paddingTop: "8px"}}>Current Permissions ({permissions.length})</h5>
        <button type="button" className="btn btn-info left-margin-10" onClick={props.permissionShowHandler}>
          Show Permissions
        </button>
      </div>
      <div className="row justify-content-md-center" id="permissionList" style={addbtnstyle}>
        {addBtn}
      </div>
    </div>
  );
};
