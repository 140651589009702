import { textTokenConstants } from "../_constants/monitor.site.texttokens.constants";
import { alertActions } from "./alert.actions";
import { textTokenServices } from "../_services/texttokens.services";



export const textTokensActions = {
   get,
   save
}

function get(monitor){
   return dispatch => {
      const {site} = monitor;
      function request(obj){return {type: textTokenConstants.TEXT_TOKEN_GET_REQUEST, payload: obj}}
      function success(obj){return {type: textTokenConstants.TEXT_TOKEN_GET_SUCCESS, payload: obj}}
      function failure(obj){return {type: textTokenConstants.TEXT_TOKEN_GET_FAILURE, payload: obj}}
      dispatch(request())

      const d = { site_id: site.edit.site_id };
      const ds = JSON.stringify(d);
      textTokenServices.gettexttokens(ds).then(
         tokenPayload => {
            dispatch(success(tokenPayload));
         },
         error => {
             dispatch(failure());
             if(error.message){
               dispatch(alertActions.error(error.message));
             }
             else{
               dispatch(alertActions.error(error));
             }
         });
   }
}

function save(site, textTokens){
   return dispatch => {
      function request(obj){return {type: textTokenConstants.TEXT_TOKEN_SAVE_REQUEST, payload: obj}}
      function success(obj){return {type: textTokenConstants.TEXT_TOKEN_SAVE_SUCCESS, payload: obj}}
      function failure(obj){return {type: textTokenConstants.TEXT_TOKEN_SAVE_FAILURE, payload: obj}}
      dispatch(request())

      const d = { 'site_id': site, 'list':textTokens };
      const ds = JSON.stringify(d);

      textTokenServices.savetexttokens(ds).then(
         payload => {
            dispatch(success(payload));
            dispatch(alertActions.success("Save Successful"));
         },
         error => {
             dispatch(failure());
             if(error.message){
               dispatch(alertActions.error(error.message));
             }
             else {
                dispatch(alertActions.error(error));
             }
         });
   }
}