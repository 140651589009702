import { adminRoleConstants } from '../_constants';
import { alertActions } from './';
import { history, numericsorter, listFilter, textsorter } from '../_helpers';
import { adminService } from '../_services';

export const adminRoleActions = {
    unauthorized
  , search
  , add
  , delete: _delete
  , edit
  , update
  , permissionSearch
  , permissionSelect
  , permissionUpdate
  , sort
  , cancel
};

function unauthorized() {
  return dispatch => {
    dispatch(alertActions.error('Access has not been granted for that page.'));
    history.push('/');
  };
}

function search(admin, value) {
  return dispatch => {
    const { role } = admin;
    const { refresh, ...norefresh } = role;
    const reqrole = {
      ...norefresh,
      searching: true
    };
    dispatch(request(reqrole));

    if (value.length > 0) {
      var params = [{'like': {'col': 'name', 'val': `%${value}%`}}];
      adminService.search('role', params)
        .then(
          items => {
            const nextrole = {
              items: items
            };
            dispatch(success(nextrole));
            if (items.length === 0) {
              dispatch(alertActions.success('No Data Found'));
            }
          },
          error => {
            dispatch(failure({}));
            dispatch(alertActions.error(error));
          }
        );
    }
    else {
      adminService.list('role')
        .then(
          items => {
            const nextrole = {
              items: items
            };
            dispatch(success(nextrole));
            if (items.length === 0) {
              dispatch(alertActions.success('No Data Found'));
            }
            else {
                // look for permission count of zero
                const rolelist = [...items];
                numericsorter(rolelist, 'permission_count', 'asc');
                const nopermissionlist = listFilter(rolelist, ['permission_count'], ['0'], true); // this filter needs direct compararison
                //dispatch(alertActions.info(`found ${nopermissionlist.length} roles without permissions`));
                for(let i = 0, j = nopermissionlist.length; i < j; i++) {
                    const itm = nopermissionlist[i];
                    if (itm.name.indexOf('admin') >= 0) continue;
                    else {
                        const roleId = itm.role_id;
                        const payload = {'role_id': parseInt(roleId), 'permission_ids': [18,20,23,24,45]};
                        adminService.command('role', 'assignpermissions', payload)
                        .then(
                            result => {
                                dispatch(alertActions.success('Role permission(s) changed'));
                            },
                            error => {
                                dispatch(alertActions.error(error));
                            }
                        );
                    }
                }
            }
          },
          error => {
            dispatch(failure({}));
            dispatch(alertActions.error(error));
          }
        );
    }
  };
  function request(obj) { return { type: adminRoleConstants.ROLE_SEARCH_REQUEST, obj }; }
  function success(obj) { return { type: adminRoleConstants.ROLE_SEARCH_SUCCESS, obj }; }
  function failure(obj) { return { type: adminRoleConstants.ROLE_SEARCH_FAILURE, obj }; }
}

function add(admin, params) {
  return dispatch => {
    const { role } = admin;
    const reqrole = {
      ...role,
      adding: true
    };
    dispatch(request(reqrole));

    const validity = validateData(params);
    if (!validity.valid) {
      const invalidrole = {
        ...role,
        validity: validity
      };
      dispatch(failure(invalidrole));
      return;
    }

    adminService.add('role', params)
      .then(
        result => {
          const nextrole = {
            ...role,
            refresh: true
          };
          dispatch(success(nextrole));
          dispatch(alertActions.success('Role add successful'));
        },
        error => {
          dispatch(failure(role));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: adminRoleConstants.ROLE_ADD_REQUEST, obj }; }
  function success(obj) { return { type: adminRoleConstants.ROLE_ADD_SUCCESS, obj }; }
  function failure(obj) { return { type: adminRoleConstants.ROLE_ADD_FAILURE, obj }; }
}

function _delete(admin, idx) {
  return dispatch => {
    const { role } = admin;
    const reqrole = {
      ...role,
      deleting: true
    };
    dispatch(request(reqrole));

    if (idx >= 0) {
      const item = role.items[idx];
      adminService.delete('role', item.role_id)
        .then(
          result => {
            const nextrole = {
              ...role,
              refresh: true
            };
            dispatch(success(nextrole));
            dispatch(alertActions.success('Successfully removed role'));
          },
          error => {
            dispatch(failure(role));
            dispatch(alertActions.error(error));
          }
        );
    }
    else {
      dispatch(failure(role));
      dispatch(alertActions.error('Could not find the item in the list'));
    }
  };
  function request(obj) { return { type: adminRoleConstants.ROLE_DELETE_REQUEST, obj }; }
  function success(obj) { return { type: adminRoleConstants.ROLE_DELETE_SUCCESS, obj }; }
  function failure(obj) { return { type: adminRoleConstants.ROLE_DELETE_FAILURE, obj }; }
}

function edit(admin, idx) {
  return dispatch => {
    const { role } = admin;
    if (isNaN(idx)) {
      dispatch(failure(role));
      dispatch(alertActions.error('Index value is not a valid index'));
    }
    else {
      if (idx >= role.items.length) {
        dispatch(failure(role));
        dispatch(alertActions.error('Index value is above the list count'));
      }
      else {
        // everything is fine get permissions and users for role
        const item = role.items[idx];
        let permissionItems = [];
        let userItems = [];
        adminService.getextend('role', item.role_id, 'permissions')
          .then(
            items => {
                permissionItems = items;
                const nextrole = {
                    ...role,
                    editidx: idx,
                    permissions: items,
                    users: userItems
                };
                dispatch(request(nextrole));
            },
            error => {
              const failrole = {
                ...role,
                editidx: idx
              };
              dispatch(failure(failrole));
              dispatch(alertActions.error(error));
            }
          );
        adminService.getextend('role', item.role_id, 'users')
            .then(
                items => {
                    userItems = items;
                    const roleusers = {
                        ...role,
                        editidx: idx,
                        users: items,
                        permissions: permissionItems
                    };
                    dispatch(request(roleusers));
                },
                error => {
                    const failrole = {
                        ...role,
                        editidx: idx
                    };
                    dispatch(failure(failrole));
                    dispatch(alertActions.error(error));
                }
            );
        history.push('/admin/roles/edit');
      }
    }
  };
  function request(obj) { return { type: adminRoleConstants.ROLE_EDIT_REQUEST, obj }; }
  function failure(obj) { return { type: adminRoleConstants.ROLE_EDIT_FAILURE, obj }; }
}

function update(admin, params) {
  return dispatch => {
    const { role } = admin;
    const reqrole = {
      ...role,
      updating: true
    };
    dispatch(request(reqrole));

    const validity = validateData(params);
    if (!validity.valid) {
      const invalidrole = {
        ...role,
        validity: validity
      };
      dispatch(failure(invalidrole));
      return;
    }

    const item = role.items[role.editidx];
    const { created_user_name, modified_user_name, ...dbitem } = item;
    const saveitem = {
      ...dbitem,
        name: params.name
      , description: params.description
    };
    adminService.update('role', saveitem)
      .then(
        result => {
          const newitem = {
            ...saveitem,
              created_user_name: created_user_name
            , modified_user_name: modified_user_name
          };
          let newlist = role.items;
          newlist[role.editidx] = newitem;
          const nextrole = {
            ...role,
            items: newlist
          }
          dispatch(success(nextrole));
          dispatch(alertActions.success('Role update was successful'));
        },
        error => {
          dispatch(failure(role));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: adminRoleConstants.ROLE_UPDATE_REQUEST, obj }; }
  function success(obj) { return { type: adminRoleConstants.ROLE_UPDATE_SUCCESS, obj }; }
  function failure(obj) { return { type: adminRoleConstants.ROLE_UPDATE_FAILURE, obj }; }
}

function permissionSearch(admin, search) {
  return dispatch => {
    const { role } = admin;
    const reqrole = {
      ...role,
      searching: true
    };
    dispatch(request(reqrole));

    // store existing permissions for comparison later
    var existingPermissions = role.permissions;
    var aEPIds = [];
    for (var i = 0, j = existingPermissions.length; i < j; i++) {
      aEPIds.push(parseInt(existingPermissions[i].permission_id));
    }

    var params = [{'like': {'col': 'name', 'val': `%${search}%`}}];
    adminService.search('permission', params)
      .then(
        items => {
          if (items.length === 0) {
            dispatch(success(role));
            dispatch(alertActions.success('No Data Found'));
          }
          else {
            // We have permissions from the search and we have permissions assigned to the role,
            // so we will mark existing permissions in the list.
            if (aEPIds.length > 0) {
              for (var i = 0, j = items.length; i < j; i++) {
                if(aEPIds.indexOf(parseInt(items[i].permission_id)) >= 0) {
                  items[i].selected = true;
                }
              }
            }
            const nextrole = {
              ...role,
                permissionsAvailable: items
              , permissionSelection: aEPIds
            };
            dispatch(success(nextrole));
          }
        },
        error => {
          dispatch(failure(role));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: adminRoleConstants.ROLE_PERMISSION_SEARCH_REQUEST, obj }; }
  function success(obj) { return { type: adminRoleConstants.ROLE_PERMISSION_SEARCH_SUCCESS, obj }; }
  function failure(obj) { return { type: adminRoleConstants.ROLE_PERMISSION_SEARCH_FAILURE, obj }; }
}

function permissionSelect(admin, permission, add) {
  return dispatch => {
    const { role } = admin;
    let selection = role.permissionSelection;
    const id = permission.permission_id;
    if (add) {
      if (selection.length === 0) selection.push(parseInt(id));
      else if (selection.indexOf(parseInt(id)) < 0) selection.push(parseInt(id));
    }
    else {
      var idx = selection.indexOf(parseInt(id));
      selection.splice(idx, 1);
    }
    const nextrole = {
      ...role,
      permissionSelection: selection
    };
    dispatch(request(nextrole));
  };
  function request(obj) { return { type: adminRoleConstants.ROLE_PERMISSION_ADD_SELECT, obj }; }
}

function permissionUpdate(admin) {
  return dispatch => {
    const { role } = admin;
    const reqrole = {
      ...role,
      updating: true
    };
    dispatch(request(reqrole));

    // Format the data before calling the command method
    const item = role.items[role.editidx];
    const { permissionSelection, permissionsAvailable, noPermissionSelection, deleteAll, ...noselect } = role;
    if (!deleteAll && (!permissionSelection || permissionSelection.length === 0)) {
      const noselectionrole = {
        ...role,
        noPermissionSelection: true
      };
      dispatch(request(noselectionrole));
      return;
    }
    const payload = {'role_id': parseInt(item.role_id), 'permission_ids': permissionSelection};
    adminService.command('role', 'assignpermissions', payload)
    .then(
      result => {
        adminService.getextend('role', item.role_id, 'permissions')
          .then(
            items => {
              const nextrole = {
                ...noselect,
                permissions: items
              };
              dispatch(success(nextrole));
            },
            error => {
              dispatch(failure(noselect));
              dispatch(alertActions.error(error));
            }
          );
        dispatch(alertActions.success('Role permission(s) changed'));
      },
      error => {
        dispatch(failure(role));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(obj) { return { type: adminRoleConstants.ROLE_PERMISSION_UPDATE_REQUEST, obj }; }
  function success(obj) { return { type: adminRoleConstants.ROLE_PERMISSION_UPDATE_SUCCESS, obj }; }
  function failure(obj) { return { type: adminRoleConstants.ROLE_PERMISSION_UPDATE_FAILURE, obj }; }
}

function sort(admin, field) {
    return dispatch => {
        const { role } = admin;
        const { sort } = role;
        let direction = 'asc';
        if (sort) {
            if (field === sort.field) {
                if (sort.direction === 'asc') direction = 'desc';
            }
        }
        switch(field) {
            case 'name':
                textsorter(role.items, field, direction);
                break;
            case 'user_count':
            case 'permission_count':
                numericsorter(role.items, field, direction);
                break;
        }
        let nextrole = {
            ...role,
            sort: {
                field: field,
                direction: direction
            }
        };

        dispatch(request(nextrole));
    };
    function request(obj) { return { type: adminRoleConstants.ROLE_SORT_REQUEST, obj }; }
}

function validateData(params) {
  let validity = {
      valid: true
    , name: true
    , description: true
  };
  let valid = true;

  if (!params.name) {
    valid = false;
    validity.name = false;
  }
  if (!params.description) {
    valid = false;
    validity.description = false;
  }
  validity.valid = valid;
  return validity;
}

function cancel(admin) {
  return dispatch => {
    const { role } = admin;
    const { noPermissionSelection, ...cancelrole } = role;
    dispatch(request(cancelrole));
  };
  function request(obj) { return { type: adminRoleConstants.ROLE_UPDATE_CANCEL, obj }; }
}