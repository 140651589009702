import { housekeepingConstants } from '../_constants';

export function housekeeping(state = {}, action) {
  switch(action.type) {
   // case housekeepingConstants.HOUSEKEEPING_ADD_REQUEST:
  //  case housekeepingConstants.HOUSEKEEPING_ADD_SUCCESS:
   // case housekeepingConstants.HOUSEKEEPING_ADD_FAILURE:
    case housekeepingConstants.HOUSEKEEPING_GET_REQUEST:
    case housekeepingConstants.HOUSEKEEPING_GET_SUCCESS:
    case housekeepingConstants.HOUSEKEEPING_GET_FAILURE:
    case housekeepingConstants.HOUSEKEEPING_SORT_REQUEST:
    case housekeepingConstants.HOUSEKEEPING_SORT_SUCCESS:
    case housekeepingConstants.HOUSEKEEPING_SEARCH_INPUT:
    case housekeepingConstants.HOUSEKEEPING_SEARCH_REQUEST:
    case housekeepingConstants.HOUSEKEEPING_SEARCH_SUCCESS:
    case housekeepingConstants.HOUSEKEEPING_SEARCH_CLEAR:
  //  case housekeepingConstants.HOUSEKEEPING_DELETE_REQUEST:
  //  case housekeepingConstants.HOUSEKEEPING_DELETE_SUCCESS:
   // case housekeepingConstants.HOUSEKEEPING_DELETE_FAILURE:
  //  case housekeepingConstants.HOUSEKEEPING_EDIT_SELECT:
   // case housekeepingConstants.HOUSEKEEPING_EDIT_REQUEST:
   // case housekeepingConstants.HOUSEKEEPING_EDIT_SUCCESS:
  //  case housekeepingConstants.HOUSEKEEPING_EDIT_FAILURE:
    case housekeepingConstants.HOUSEKEEPING_PAGE_CHANGE:
      return{
        ...state,
        housekeeping: action.obj
      };
    default:
      return state;
  }
}
