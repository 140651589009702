import React from 'react';
import { connect } from 'react-redux';

import { alertActions, adminUserActions } from '../../../_actions';
import { permissionCheck, prepTableInfo, calcTotalPages, history, config, confirmOptions } from '../../../_helpers';

import { DataTable } from '../../dataTable';
import { NavigationBar } from '../../navigation';
import { ComplexSiteSearch } from '../../search';
import { Pagination } from '../../pagination';
import { ProcessIndicator } from '../../processIndicator';
import { ConfirmModal } from '../../confirm';

import { UserData } from '../userData';

import { SiteList } from './assignSiteList';

class UserSites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        showAddSiteList: false
      , siteSearchParams: config.sites.searchParams
      , siteTablePage: 1
      , siteTableRowCount: 5
      , marshaCodes: null
      , confirmExit: false
    };

    this.deleteSite = this.deleteSite.bind(this);
    this.addSite = this.addSite.bind(this);
    this.onSearchInput = this.onSearchInput.bind(this);
    this.onSearchClear = this.onSearchClear.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.onSiteTablePage = this.onSiteTablePage.bind(this);
    this.onSiteSelect = this.onSiteSelect.bind(this);
    this.onSiteAddCancel = this.onSiteAddCancel.bind(this);
    this.onExitConfirm = this.onExitConfirm.bind(this);
    this.onConfirmCancel = this.onConfirmCancel.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
  }

  componentDidMount() {
    const { auth, admin, list, reg } = this.props;
    if (!auth) {
      history.push('/admin/users');
      return null;
    }
    const allowEdit = permissionCheck(auth.permissions, 'admin.user.edit');
    if (!allowEdit) {
      alertActions.error('You do not have permission to access that page.');
      history.push('/');
      return null;
    }
    list(admin);
    if (reg.userid && parseInt(admin.user.edit.user_id) === parseInt(reg.userid)) {
      if (reg.selected && reg.selected.item && reg.selected.item.marsha.length > 0) {
        this.setState({ marshaCodes: reg.selected.item.marsha });
      }
    }
  }

  render() {
    const { auth, admin, alert } = this.props;
    if (!auth) return null;
    const allowEdit = permissionCheck(auth.permissions, 'admin.user.edit');
    if (!allowEdit) return null;
    if (!admin.user) return null;
    const { user } = admin;
    if (!user.edit) return null;
    const { edit } = user;

    let editSitesTable = null;
    let siteListModal = null;

    const processIndShow = user.processing ? user.processing : false;
    if (edit.sites && edit.sites.length > 0) {
      let editSitesInfoObj = prepTableInfo(edit.sites);
      let editSitesDisplayParams = {
          hideFields: ['user_id', 'created', 'created_id', 'created_user_name']
        , allowOptions: {
            edit: { allowed: false, handler: null },
            delete: { allowed: true, handler: this.deleteSite },
            select: { allowed: false, handler: null }
          }
      };
      editSitesTable = <DataTable data={editSitesInfoObj.data} header={editSitesInfoObj.header} displayParams={editSitesDisplayParams} />;
    }

    if (this.state.showAddSiteList) {
      if (user.sites && user.sites.length > 0) {
        const siteparams = {searchParams: this.state.siteSearchParams, searching: processIndShow};
        const siteSearch = <ComplexSiteSearch submitHandler={this.onSearchSubmit} changeHandler={this.onSearchInput} clearHandler={this.onSearchClear} site={siteparams} searchDisable={processIndShow} />;
        const sitelist = user.siteSearchResult ? user.siteSearchResult : null;

        let resultTable = null;
        let pagination = null;
        if (sitelist) {
          let totalpages = calcTotalPages(sitelist.length, this.state.siteTableRowCount);

          const pageParams = {
              rowCount: this.state.siteTableRowCount
            , dataPage: this.state.siteTablePage
            , pages: totalpages
            , handler: this.onSiteTablePage
          };
          let resultTableInfoObj = prepTableInfo(sitelist);
          const resultTableDisplayParams = {
              hideFields: ['box_on', 'box_down', 'box_tot', 'update_time']
            , allowOptions: {
                edit: { allowed: true, handler: this.onSiteSelect },
                delete: { allowed: false, handler: null },
                select: { allowed: false, handler: null }
              }
            , pagination: {
                  rowCount: pageParams.rowCount
                , dataPage: pageParams.dataPage
              }
          };
          resultTable = <DataTable data={resultTableInfoObj.data} header={resultTableInfoObj.header} displayParams={resultTableDisplayParams} />;
          pagination = <Pagination data={resultTableInfoObj.data} pageParams={pageParams} />;
        }
        siteListModal = <SiteList show={true} title="Add Sites" cancel={this.onSiteAddCancel} search={siteSearch} table={resultTable} alert={alert} pagination={pagination} marsha={this.state.marshaCodes} />;
      }
    }

//    let marshaCodes = null;
//    if (this.state.marshaCodes) {
//      marshaCodes = <div><span>Marsha Codes Entered At Registration: </span><span>{this.state.marshaCodes}</span></div>;
//    }

    let cModal = null;
    if (this.state.confirmExit) {
      let confirmOpts = confirmOptions();
      confirmOpts.show = true;
      confirmOpts.title = 'Confirm Exit';
      confirmOpts.question = 'Have you assigned all the marsha codes?';
      confirmOpts.handlers.proceed = this.onExitConfirm;
      confirmOpts.handlers.cancel = this.onConfirmCancel;
      confirmOpts.proceed.title = 'Assignment Confirmation';
      confirmOpts.proceed.className = 'btn-primary';
      cModal = <ConfirmModal options={confirmOpts} />;
    }

//    {marshaCodes}
    return(
      <div>
        {siteListModal}
        {cModal}
        <NavigationBar header="Admin - Users - Assign Site" />
        <UserData item={edit} more={false} />
        <ProcessIndicator show={processIndShow} />
        <div className="container">
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
          <div>
            <button type="button"
                            className="btn btn-success "
                            disabled={processIndShow}
                            onClick={this.addSite}>
                <span>+ Add</span>
            </button>
          </div>
          {editSitesTable}
          <button type="button" className="btn btn-secondary" disabled={processIndShow} onClick={this.onCancelClick}>Close</button>
        </div>
      </div>
    );
  }

  deleteSite(e) {
    e.stopPropagation();
    const { admin, clearAlert, remove } = this.props;
    clearAlert();
    let idx = parseInt(e.currentTarget.attributes['data-index'].value);
    if (isNaN(idx)) {
      dispatch(alertActions.error('Error getting the selected site to delete.'));
      return;
    }
    remove(admin, idx);
  }

  addSite() {
    this.setState({ showAddSiteList: true });
  }

  onSearchInput(e) {
    const { name, value } = e.target;
    const { siteSearchParams } = this.state;
    this.props.clearAlert();
    const newparams = {
      ...siteSearchParams,
      [name]: value
    };
    this.setState({ siteSearchParams: newparams });
  }
  onSearchClear() {
    const { admin, clear, clearAlert } = this.props;
    clearAlert();
    clear(admin);
    this.setState({ siteSearchParams: config.sites.searchParams });
  }
  onSearchSubmit(e) {
    e.preventDefault();
    const { siteSearchParams } = this.state;
    const { admin, search } = this.props;
    search(admin, siteSearchParams);
  }

  onSiteTablePage(e) {
    this.props.clearAlert();
    let page = parseInt(e.currentTarget.attributes['data-pagenumber'].value);
    this.setState({ siteTablePage: page });
  }

  onSiteSelect(e) {
    const { admin, reg, submit, clearAlert } = this.props;
    clearAlert();
    let idx = e.currentTarget.attributes['data-index'].value;
    if (isNaN(idx)) {
      dispatch(alertActions.error('Error getting the selected site.'));
      return;
    }
    submit(admin, parseInt(idx), reg);
  }

  onSiteAddCancel() {
    this.props.clearAlert();
    this.setState({ showAddSiteList: false });
  }

  onCancelClick() {
    if (this.state.marshaCodes) {
      this.setState({ confirmExit: true });
      return;
    }
    const { admin, cancel} = this.props;
    cancel(admin);
  }

  onExitConfirm() {
    const { admin, cancel} = this.props;
    cancel(admin);
  }

  onConfirmCancel() {
    this.setState({ confirmExit: false });
  }
};

function mapStateToProps(state) {
  const alert = state.alert;
  const auth = state.authentication.user;
  const admin = state.administration;
  const reg = state.registration;
  return { alert, auth, admin, reg };
}

const mapDispatchToProps = (dispatch) => ({
    clearAlert() { dispatch(alertActions.clear()); }
  , list(admin) { dispatch(adminUserActions.getAvailableSites(admin)); }
  , cancel(admin) { dispatch(alertActions.clear()); dispatch(adminUserActions.cancelAssignSites(admin)); }
  , search(admin, params) { dispatch(adminUserActions.siteAddSearch(admin, params)); }
  , clear(admin) { dispatch(adminUserActions.siteAddSearchClear(admin)); }
  , submit(admin, siteidx, reg) { dispatch(adminUserActions.siteAddSelect(admin, siteidx, reg)); }
  , remove(admin, siteidx) { dispatch(adminUserActions.deleteSite(admin, siteidx)); }
});

const connectedUserSites = connect(mapStateToProps, mapDispatchToProps)(UserSites);
export { connectedUserSites as UserSites };
