import { userConstants } from '../_constants';

const initialState = {};

export function authentication(state = initialState, action) {
  switch(action.type) {
    case userConstants.INIT_REQUEST:
      return state;
    case userConstants.INIT_SUCCESS:
      return { user: action.user };
    case userConstants.INIT_FAILURE:
      return {}
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};
    case userConstants.PASSWORDRESET_REQUEST:
      return {
        resetting: true,
        user: action.user
      };
    case userConstants.PASSWORDRESET_SUCCESS:
      return {
        user: action.user
      };
    case userConstants.PASSWORDRESET_FAILURE:
        return {};
    case userConstants.VERIFY_PASSWORD_DISPLAY:
    case userConstants.VERIFY_PASSWORD_CANCEL:
    case userConstants.VERIFY_PASSWORD_INPUT:
    case userConstants.VERIFY_PASSWORD_REQUEST:
    case userConstants.VERIFY_PASSWORD_SUCCESS:
    case userConstants.VERIFY_PASSWORD_FAILURE:
    case userConstants.SEND_USERNAME_REQUEST:
    case userConstants.SEND_USERNAME_SUCCESS:
    case userConstants.SEND_USERNAME_FAILURE:
    case userConstants.RESET_PASSWORD_REQUEST:
    case userConstants.RESET_PASSWORD_SUCCESS:
    case userConstants.RESET_PASSWORD_FAILURE:
        return action.obj;
    default:
        return state;
  }
}
