import React from 'react';

import Charts from '../../charts';

import { createChartArray } from '../../../_helpers';

export const AppUsage = props => {
  const { apps } = props;
  let pieData = createChartArray(apps, 'app', 'hours');
  let ch = {
      type: 'pie'
    , data: pieData
    , options:{
          "title": "App Usage - Hours"
        , "pieHole": 0.3
        , "is3D": false
        , "pieSliceText": "value"
        , height:'300px'
        , width:'400px'
      }
  };
  return (
    <div className="statchart">
      <Charts data={ch} />
    </div>
  );
};
