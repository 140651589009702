import React from 'react';
import { WciModal } from '../../wcimodal';
import { StatTable } from './stattable';
import { standardizeValues } from '../../../_helpers';

export const RoomStat = props => {
  const { close, room, data, ddtype, header, tname, site, dte } = props;
  let tables = null;
  let show = false;
  if (data) {
    show = true;
    tables = [];
    tables = data.map((stb, idx) => {
      const d = standardizeValues(stb[ddtype], null, false, 3);
      return <StatTable key={'stbtbl' + idx} data={d} header={header} site={site} dte={dte} tname={stb.room + '-' + tname} toggleHandler={null} show={show} cssclass='' sort={true} title={true} />;
    });
  }
  return (
    <WciModal show={show} className="baseModal">
      <div className="roomStatModal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Room {room} {tname} Stats</h5>
            <button type="button" className="close" onClick={props.close}>&times;</button>
          </div>
          <div className="modal-body">
            {tables}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={props.close}>Close</button>
          </div>
        </div>
      </div>
    </WciModal>
  );
};
