import React from 'react';
import { connect } from 'react-redux';

import { alertActions, adminUserActions } from '../../../_actions';
import { permissionCheck, history, generateTemporaryPassword } from '../../../_helpers';

import { NavigationBar } from '../../navigation';

class UserResetPw extends React.Component {
  constructor(props) {
    super(props);
    this.state = { temppw: '' };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onGenPw = this.onGenPw.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    const {alert, auth, admin } = this.props;
    if (!auth) {
      history.push('/admin/users');
      return null;
    }
    const allowEdit = permissionCheck(auth.permissions, 'admin.user.edit');
    if (!allowEdit) {
      alertActions.error('You do not have permission to access that page.');
      history.push('/');
      return null;
    }
    if (!admin.user) {
      history.push('/admin/users');
      return null;
    }
    const user = admin.user;
    if (!user.edit) {
      history.push('/admin/users');
      return null;
    }
  }

  render() {
    const {alert, auth, admin} = this.props;
    if (!auth) {
      return null;
    }
    const allowEdit = permissionCheck(auth.permissions, 'admin.user.edit');
    if (!allowEdit) {
      return null;
    }
    if (!admin.user) {
      return null;
    }
    const { user } = admin;
    if (!user.edit) {
      return null;
    }
    const { edit, validity } = user;
    const processing = user.processing ? user.processing : false;

    return(
      <div>
        <NavigationBar header="Admin - Users - Reset Password" />
        <div className="jumbotron">
          <div className="container">
            {alert.message &&
              <div className={`alert ${alert.type}`}>{alert.message}</div>
            }
            <form onSubmit={this.onSubmit}>
              <div className="form-group row">
                <label htmlFor="user_id" className="col-sm-1 col-form-label text-right">ID:</label>
                <input type="text"
                  id="user_id"
                  className="col-sm-1 form-control-plaintext"
                  readOnly
                  value={edit.user_id} />
                <label htmlFor="user_name" className="col-sm-2 col-form-label text-right">User Name:</label>
                <input type="text"
                  id="user_name"
                  readOnly
                  className="col-sm-2 form-control-plaintext"
                  value={edit.user_name} />
                <label htmlFor="fullname" className="col-sm-2 col-form-label text-right">Name:</label>
                <input type="text"
                  id="fullname"
                  readOnly
                  className="col-sm-2 form-control-plaintext"
                  value={edit.first_name + ' ' + edit.last_name} />
              </div>
              <div className="form-group row">
                  <input type="text"
                    name="password"
                    className={'col-sm-4 col-sm-offset-2 form-control' + (validity ? user.validity.password ? '' : ' is-invalid' : '')}
                    placeholder="Reset Password"
                    onChange={this.onChange}
                    value={this.state.temppw} />
                  <button type="button" onClick={this.onGenPw} className="btn btn-secondary" disabled={processing}>Generate Temp. Password</button>
                  {validity && !user.validity.valid && !edit.password &&
                    <div className='invalid-feedback'>Password is required</div>
                  }
                  {validity && !user.validity.valid && edit.password && edit.password.length < 8 &&
                    <div className='invalid-feedback'>Password length needs to be at least 8 characters</div>
                  }
              </div>
              <div className="form-group row">
                <button type="submit" className="btn btn-primary" disabled={processing}>
                  <span>Submit</span>
                  {processing &&
                    <div className="d-inline fa-2x left-margin-10"><i className="fas fa-spinner fa-pulse"></i></div>
                  }
                </button>
                <button type="button" className="btn btn-secondary left-margin-10" disabled={processing} onClick={this.onCancel}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  onGenPw() {
    const newpw = generateTemporaryPassword();
    this.setState({ temppw: newpw });
  }

  onChange(e) {
    const { value } = e.target;
    this.setState({ temppw: value });
  }

  onCancel() {
    this.props.clear();
    history.push('/admin/users/edit');
  }

  onSubmit(e) {
    e.preventDefault();
    const { temppw } = this.state;
    const { admin, save } = this.props;
    const { user } = admin;
    const { edit } = user;
    const newedit = {
      ...edit,
        password: temppw
      , newpw: 1
    };
    const newuser = {
      ...user,
      edit: newedit
    };
    const newadmin = {
      ...admin,
      user: newuser
    };
    save(newadmin, temppw);
  }
};

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const admin = state.administration
  return { alert, auth, admin };
}


const mapDispatchToProps = (dispatch) => ({
    clear() { dispatch(alertActions.clear()); }
  , save(admin) { dispatch(adminUserActions.update(admin)); }
});

const connectedUserResetPw = connect(mapStateToProps, mapDispatchToProps)(UserResetPw);
export { connectedUserResetPw as UserResetPw };
