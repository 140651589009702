const $ = require('jquery');
const apiurls = {
      dev:  'https://wciwvdev:89/api/' //'https://wciwvdev:88/api/'
    , test: 'https://wciwvdev:89/api/'
    , prod: 'https://hubapi.worldvue.com/api/'
};

module.exports = {
    isObjectEmpty
  , getComponentFromPath
  , getApiUrl
  , hasHtmlCode
  , convertHtmlCode
};

function isObjectEmpty(obj) {
  for(var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

function getComponentFromPath(path) {
  switch(path) {
    case '/':
      return 'HomePage';
    case '/login':
      return 'LoginPage';
    case '/register':
      return 'RegisterPage';
    case '/setpassword':
      return 'SetPasswordPage';
    case '/contact':
      return 'ContactPage';
    case '/portal/sites':
      return 'Sites';
    case '/portal/site':
      return 'Site';
    case '/portal/stats':
      return 'Stats';
    case '/portal/network':
      return 'NetworkStats';
    case '/portal/resv':
      return 'ReservationStats';
    case '/portal/messaging':
      return 'Messaging';
    case '/portal/events':
      return 'Events';
    case '/portal/events/add':
      return 'EventsAdd';
    case '/portal/events/edit':
      return 'EventsEdit';
    case '/portal/uploader':
      return 'UploaderMain';
    case '/portal/housekeeping':
      return 'Housekeeping';
    /*case '/portal/roomservice':
      return 'RoomServiceMain';
    case '/portal/roomservice/createMenu':
        return 'CreateMenu';
    case '/portal/roomservice/viewMenu':
        return 'ViewMenu';
    case '/portal/roomservice/editMenu':
        return 'DiningMenuEdit';*/ // 01.25.2021 Commenting room service stuff. TODO: Uncomment it later
    case '/portal/documentation':
        return 'DocumentationMain';
    case '/admin/users':
      return 'UserAdmin';
    case '/admin/users/add':
      return 'UserAdminAdd';
    case '/admin/users/edit':
      return 'UserAdminEdit';
    case '/admin/users/reset/password':
      return 'UserResetPw';
    case '/admin/users/assign/role':
      return 'UserRoles';
    case '/admin/users/assign/site':
      return 'UserSites';
    case '/admin/roles':
      return 'RoleAdmin';
    case '/admin/roles/add':
      return 'RoleAdd';
    case '/admin/roles/edit':
      return 'RoleEdit';
    case '/admin/roles/addpermission':
      return 'RoleAddPermission';
    case '/admin/permissions':
      return 'PermissionAdmin';
    case '/admin/permissions/add':
      return 'PermissionAdd';
    case '/admin/permissions/edit':
      return 'PermissionEdit';
    case '/admin/sites':
      return 'SiteAdmin';
    case '/admin/register/list':
      return 'RegisteredList';
    case '/admin/register/edit':
      return 'RegisteredEdit';
    case '/user/profile':
      return 'Profile';
    case '/portal/site/users':
      return 'SiteUsers';
    case '/portal/stbparams':
      return 'StbParameters';
    default:
      return 'Not entered';
  }
}

console.log('Startup Mode: ' + process.env.NODE_ENV);
function getApiUrl() {
  if (process.env.NODE_ENV === 'production') return apiurls.prod;
  else if (process.env.NODE_ENV === 'test') return apiurls.test;
  else return apiurls.dev;
}

function hasHtmlCode(strval) {
    if (!strval) return false;
    let amppos = null;
    let hashpos = null;
    let semicolonpos = null;
    amppos = strval.indexOf('&');
    if (isNaN(amppos) || amppos < 0) return false;
    hashpos = strval.indexOf('#');
    if (isNaN(hashpos) || hashpos < 0) return false;
    semicolonpos = strval.indexOf(';')
    if (isNaN(semicolonpos) || semicolonpos < 0) return false;
    if (amppos >= 0 && hashpos >= 0 && semicolonpos >= 0) return true;
    return false;
}
function convertHtmlCode(strval) {
    return $('<div/>').html(strval).text();
}