import React from 'react';
import { connect } from 'react-redux';
import { WciModal } from '../../wcimodal';

import { msgActions } from '../../../_actions';
import { confirmOptions } from '../../../_helpers';

import { GroupList } from './groupList';
import { ConfirmModal } from '../../confirm';
import { ProcessIndicator } from '../../processIndicator';

const GroupManager = props => {
  const { alert, monitor } = props;
  const { msg } = monitor;
  const { group } = msg;
  const { manage } = group;

  let actions = null;
  let cModal = null;
  let selection = manage.selectedIdx ? manage.selectedIdx : null;
  let processIndShow = false;
  if (group.deleting) processIndShow = true;

  if (manage.edit) {
    if (!processIndShow) {
      actions = (<div><div>Actions</div><div onClick={() => { props.delete(monitor); }} className="grpMgrAction" disabled={processIndShow}><u>Delete</u></div></div>);
    }
    else {
      actions = (<div><div>Actions</div><div className="grpMgrAction" disabled={processIndShow}><u>Delete</u></div></div>);
    }
  }
  if (manage.deleting) {
    let confirmOpts = confirmOptions();
    const deleteProceedHandler = () => { props.confirm(monitor); }
    const deleteCancelHandler = () => { props.confirmCancel(monitor); }
    confirmOpts.show = true;
    confirmOpts.title = 'Confirm Delete';
    confirmOpts.question = 'Are you sure you want to delete: ' + group.list[manage.selectedIdx].name;
    confirmOpts.handlers.proceed = deleteProceedHandler;
    confirmOpts.handlers.cancel = deleteCancelHandler;
    confirmOpts.proceed.title = 'Delete';
    confirmOpts.proceed.className = 'btn-danger';
    cModal = <ConfirmModal options={confirmOpts} />;
  }

  return (
    <WciModal show={true} className="baseModal">
      <div className="createGroupModal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Group Manager</h5>
            <button type="button"
                    className="close"
                    onClick={() => { props.cancel(monitor); }}
                    disabled={processIndShow}>&times;</button>
          </div>
          <div className="modal-body">
            <ProcessIndicator show={processIndShow} />
            {alert.message &&
              <div className={`alert ${alert.type}`}>{alert.message}</div>
            }
            <div className="grpMgrSection">
              <div>Group List</div>
              <GroupList list={group.list} changeHandler={(e) => { props.select(monitor, e); }} selected={selection} disable={processIndShow} />
            </div>
            {actions}
          </div>
          <div className="modal-footer">
            <button type="button"
                    className="btn btn-secondary"
                    onClick={() => { props.cancel(monitor); }} disabled={processIndShow}>Cancel</button>
          </div>
        </div>
        {cModal}
      </div>
    </WciModal>
  );
};

function mapStateToProps(state) {
  const alert = state.alert;
  const monitor = state.monitor;
  return { alert, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    cancel(monitor) { dispatch(msgActions.groupManageCancel(monitor)); }
  , select(monitor, e) { dispatch(msgActions.groupManageSelect(monitor, e)); }
  , delete(monitor) { dispatch(msgActions.groupManageDelete(monitor)); }
  , confirm(monitor) { dispatch(msgActions.groupManageDeleteConfirm(monitor)); }
  , confirmCancel(monitor) { dispatch(msgActions.groupManageDeleteConfirmCancel(monitor)); }
});

const connectedGroupManager = connect(mapStateToProps, mapDispatchToProps)(GroupManager);
export { connectedGroupManager as GroupManager };
