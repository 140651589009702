module.exports = {
    convertSeconds
  , standardizeValues
};

function convertSeconds(seconds, label, setPrecision) {
  const mins = makeMins(seconds);
  if (mins < 60 && label) {
    return setSignificant(mins, setPrecision) + ' mins.';
  }
  const hrs = makeHrs(mins);
  if (label) {
    return setSignificant(hrs, setPrecision) + ' hrs.';
  }
  return setSignificant(hrs, setPrecision);
}

function standardizeValues(arrayWValues, goto, label, setPrecision) {
  let newarr = {...arrayWValues};
  let arrentries = Object.entries(newarr);
  for(let i = 0, j = arrentries.length; i < j; i++) {
    let entry = arrentries[i];
    const entrykey = entry[0];
    let entryval = entry[1];
    switch(goto) {
      case 'mins':
        entryval = makeMins(entryval);
        break;
      default:
        entryval = convertSeconds(entryval, label, setPrecision);
        break;
    }
    newarr[entrykey] = entryval;
  }
  return newarr;
}

function makeMins(seconds) {
  if (!seconds || seconds === 0) return 0;
  return seconds / 60;
}
function makeHrs(mins) {
  if (!mins || mins === 0) return 0;
  return mins / 60;
}
function setSignificant(value, setPrecision) {
  if (value === 0) return value;
  if (!setPrecision) return value;
  if (isNaN(setPrecision)) return value;
  if (setPrecision === 0) return Math.ceil(value);
  return value.toPrecision(setPrecision);;
}
