export const adminSiteConstants = {
    SITE_INPUT: 'SITE_INPUT'
  , SITE_CLEAR: 'SITE_CLEAR'
  , SITE_SEARCH_REQUEST: 'SITE_SEARCH_REQUEST'
  , SITE_SEARCH_SUCCESS: 'SITE_SEARCH_SUCCESS'
  , SITE_SEARCH_FAILURE: 'SITE_SEARCH_FAILURE'
  , SITE_USER_REQUEST: 'SITE_USER_REQUEST'
  , SITE_USER_SUCCESS: 'SITE_USER_SUCCESS'
  , SITE_USER_FAILURE: 'SITE_USER_FAILURE'
};
