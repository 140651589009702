import { adminUserConstants } from '../_constants';
import { alertActions, registrationActions } from './';
import { history, userParamValidator, listFilter } from '../_helpers';
import { adminService, siteService } from '../_services';

export const adminUserActions = {
    get
  , search
  , remove
  , add
  , input
  , update
  , getAvailableSites
  , cancelAssignSites
  , siteAddSearch
  , siteAddSearchClear
  , siteAddSelect
  , deleteSite
  , getAvailableRoles
  , cancelAvailableRoles
  , addRole
  , deleteRole
  , editCancel
  , rolePage
  , roleSearch
  , clearEdit
};

function get(admin, id) {
  return dispatch => {
    dispatch(alertActions.clear());
    const { user } = admin;
    const requser = {
      ...user,
      processing: true
    };
    dispatch(request(requser));

    adminService.get('user', id)
      .then(
        item => {
          const getuser = {
            ...user,
            edit: item
          };
          dispatch(success(getuser));
          adminService.getextend('user', id, 'roles')
            .then(
              items => {
                getuser.edit.roles = items;
                dispatch(rolesuccess(getuser));
              },
              error => {
                dispatch(failure(getuser));
                dispatch(alertActions.error(error));
              }
            );
          adminService.getextend('user', id, 'site')
            .then(
              items => {
                getuser.edit.sites = items;
                dispatch(sitesuccess(getuser));
              },
              error => {
                dispatch(failure(getuser));
                dispatch(alertActions.error(error));
              }
            );
          history.push('/admin/users/edit');
        },
        error => {
          dispatch(failure(user));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(obj) { return { type: adminUserConstants.USER_GET_REQUEST, obj }; }
  function success(obj) { return { type: adminUserConstants.USER_GET_SUCCESS, obj }; }
  function failure(obj) { return { type: adminUserConstants.USER_GET_FAILURE, obj }; }
  function rolesuccess(obj) { return { type: adminUserConstants.USER_ROLES_SUCCESS, obj }; }
  function sitesuccess(obj) { return { type: adminUserConstants.USER_SITE_SUCCESS, obj }; }
}

function search(admin, searchVals, searchInput) {
  return dispatch => {
    dispatch(alertActions.clear());
    const { user } = admin;
    const requser = {
      ...user,
        searching: true
      , processing: true
    };
    dispatch(request(requser));

    if (searchInput) {
      var searchParams = [];
      var keys = Object.keys(searchVals);
      for(var k in keys) {
        var key = searchVals[keys[k]];
        if (key.length > 0) {
          searchParams.push({'like': {'col': `${keys[k]}`, 'val': `%${key}%`}});
        }
      }
      var criteria = JSON.stringify(searchParams);
      adminService.search('user', searchParams)
        .then(
          items => {
            // detect if an object or array is returned
            if (items instanceof Object) {
              const searchuser = {
                ...user,
                list: items
              };
              dispatch(success(searchuser));
              if (items.length === 0) {
                dispatch(alertActions.success('No matches found for the search.'));
              }
            }
            else {
              // if not it is probably an error message
              dispatch(failure(user));
              dispatch(alertActions.error(items));
            }
          },
          error => {
            dispatch(failure(user));
            dispatch(alertActions.error(error));
          }
        );
    }
    else {
      adminService.list('user')
        .then(
          items => {
            const listuser = {
              ...user,
              list: items
            };
            dispatch(success(listuser));
          },
          error => {
            dispatch(failure(user));
            dispatch(alertActions.error(error));
          }
        );
    }
  }

  function request(obj) { return { type: adminUserConstants.USER_SEARCH_REQUEST, obj }; }
  function success(obj) { return { type: adminUserConstants.USER_SEARCH_SUCCESS, obj }; }
  function failure(obj) { return { type: adminUserConstants.USER_SEARCH_FAILURE, obj }; }
}

function remove(admin, idx) {
  return dispatch => {
    const { user } = admin;
    const requser = {
      ...user,
      processing: true
    };
    dispatch(request(requser));

    const { list } = user;
    const item = list[idx];

    adminService.delete('user', item.user_id)
      .then(
        result => {
          let newlist = [];
          for(let i = 0, j = list.length; i < j; i++) {
            if (i !== idx) {
              newlist.push(list[i]);
            }
          }
          const successuser = {
            ...user,
            list: newlist
          };
          dispatch(success(successuser));
          dispatch(alertActions.success('User successfully removed from the system'));
        },
        error => {
          dispatch(failure(user));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(obj) { return { type: adminUserConstants.USER_DELETE_REQUEST, obj }; }
  function success(obj) { return { type: adminUserConstants.USER_DELETE_SUCCESS, obj }; }
  function failure(obj) { return { type: adminUserConstants.USER_DELETE_FAILURE, obj }; }
}

function add(admin, params) {
  return dispatch => {
    dispatch(alertActions.clear());
    const { user } = admin;
    const requser = {
      ...user,
      processing: true
    };
    dispatch(request(requser));

    var validity = userParamValidator(params, true);
    if (validity.valid) {
      var usernameSearch = [{'like': {'col':'user_name', 'val':`${params.user_name}`}}];
      adminService.search('user', usernameSearch)
        .then(
          items => {
            if (items.length > 0) {
              let msg = 'User Name already exists';
              validity.user_name = false;
              validity.valid = false;
              const failusername = {
                ...user,
                validity: validity
              };
              dispatch(failure(failusername));
              dispatch(alertActions.error(msg));
            }
            else {
              params.password_verify = params.password;
              adminService.add('user', params)
                .then(
                  result => {
                    const { list, ...nolist } = user;
                    const successUser = {
                      ...nolist,
                      added: true
                    };
                    dispatch(success(successUser));
//                    history.push('/admin/users');
                    dispatch(alertActions.success('New user successfully added.'))
                  },
                  error => {
                    dispatch(failure(user));
                    dispatch(alertActions.error(error));
                  }
                );
            }
          },
          error => {
            let msg = 'Error verifying user name';
            dispatch(failure(user));
            dispatch(alertActions.error(msg));
          }
        );
    }
    else {
      const failuser = {
        ...user,
        validity: validity
      };
      dispatch(failure(failuser));
      dispatch(alertActions.error('Form Validation Failed'));
    }
  };

  function request(obj) { return { type: adminUserConstants.USER_ADD_REQUEST, obj }; }
  function success(obj) { return { type: adminUserConstants.USER_ADD_SUCCESS, obj }; }
  function failure(obj) { return { type: adminUserConstants.USER_ADD_FAILURE, obj }; }
}

function input(admin, e) {
  return dispatch => {
    let { name, value } = e.target;
    const { user } = admin;
    const { edit } = user;
    const reqedit = {
      ...edit,
      [name]: value
    };
    const requser = {
      ...user,
      edit: reqedit
    };
    dispatch(request(requser));
  };
  function request(obj) { return { type: adminUserConstants.USER_INPUT, obj }; }
}

function update(admin) {
  return dispatch => {
    const { user } = admin;
    const { edit } = user;
    const  requser = { ...user, processing: true };
    dispatch(request(requser));

    const validatePw = (edit.password);

    var validity = userParamValidator(edit, validatePw);
    if (validity.valid) {
      const keys = Object.keys(edit);
      const oldedit = { ...edit };
      let newedit = {};
      for(let k in keys) {
        const key = keys[k];
        switch(key) {
          case 'created_user_name':
          case 'modified_user_name':
          case 'roles':
          case 'sites':
            break;
          case 'newpw':
            newedit = {
              ...newedit,
              new: 1
            };
            break;
          default:
            newedit[key] = oldedit[key];
            break;
        }
      }
      adminService.update('user', newedit)
        .then(
          result => {
            const { password, newpw, ...resultedit } = edit;
            const successuser = {
              ...user,
              edit: resultedit
            };
            dispatch(success(successuser));
            let msg = 'Update was successful.';
            if (validatePw) msg = 'Password reset was successful';
            dispatch(alertActions.success(msg));
            if (validatePw) history.push('/admin/users/edit');
          },
          error => {
            dispatch(failure(user));
            dispatch(alertActions.error(error));
          }
        );
    }
    else {
      const { password, newpw, ...restedit } = edit;
      const failuser = {
        ...user,
          edit: restedit
        , validity: validity
      };
      dispatch(failure(failuser));
      let msg = '';
      if (validity.password) {
        msg = 'User data is not valid the password cannot be changed until the data is fixed.';
      }
      else {
        msg = 'Password is not valid.';
      }
      dispatch(alertActions.error(msg));
    }
  };

  function request(obj) { return { type: adminUserConstants.USER_UPDATE_REQUEST, obj }; }
  function success(obj) { return { type: adminUserConstants.USER_UPDATE_SUCCESS, obj }; }
  function failure(obj) { return { type: adminUserConstants.USER_UPDATE_FAILURE, obj }; }
}

function getAvailableSites(admin) {
  return dispatch => {
    const { user } = admin;
    const requser = {
      ...user,
      processing: true
    };
    dispatch(request(requser));

    siteService.list()
      .then(
        items => {
          if (items.length > 0) {
            const successuser = {
              ...user,
              sites: items
            };
            dispatch(success(successuser));
          }
          else {
            dispatch(success(user));
            if (!user.edit.sites || user.edit.sites.length === 0) {
              // If there is an invalid character in the name field of the data, the JSON conversion will fail.
              // This will keep those failures from breaking the site.
              dispatch(alertActions.info('No sites found.'));
            }
          }
        },
        error => {
          dispatch(failure(user));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(obj) { return { type: adminUserConstants.USER_GET_AVAILABLESITES_REQUEST, obj }; }
  function success(obj) { return { type: adminUserConstants.USER_GET_AVAILABLESITES_SUCCESS, obj }; }
  function failure(obj) { return { type: adminUserConstants.USER_GET_AVAILABLESITES_FAILURE, obj }; }
}

function cancelAssignSites(admin) {
  return dispatch => {
    const { user } = admin;
    const { sites, siteSearchResult, ...nosites } = user;
    dispatch(registrationActions.clear());
    dispatch(request(nosites));
    history.push('/admin/users/edit');
  };
  function request(obj) { return { type: adminUserConstants.USER_ADDSITE_CANCEL, obj }; }
}

function siteAddSearch(admin, searchparams) {
  return dispatch => {
    const { user } = admin;
    const { sites } = user;
    const { siteSearchResult, ...noresults } = user;
    const requser = {
      ...noresults,
      processing: true
    };
    dispatch(request(requser));

    let searches = [];
    let searchVals = [];
    const searchKeys = Object.keys(searchparams);
    for (let a = 0, b = searchKeys.length; a < b; a++) {
      let val = searchparams[searchKeys[a]];
      if (val.length > 0) {
        searches.push(searchKeys[a]);
        searchVals.push(val);
      }
    }
    if (searches.length === 0) {
      dispatch(alertActions.error('Enter criteria before searching'));
      dispatch(failure(noresults));
      return;
    }
    const searchresult = listFilter(user.sites, searches, searchVals);
    if (searchresult.length > 0) {
      if (searchresult.length > 10) {
        dispatch(alertActions.error('Found ' + searchresult.length + ' sites. You should narrow the search.'));
      }
      else {
        dispatch(alertActions.info('Search complete found ' + searchresult.length + ' sites.'));
      }
      const successuser = {
        ...user,
        siteSearchResult: searchresult
      };
      dispatch(success(successuser));
    }
    else {
      dispatch(alertActions.info('No sites found matching the criteria.'));
      dispatch(failure(noresults));
    }
  };

  function request(obj) { return { type: adminUserConstants.USER_SITESEARCH_REQUEST, obj }; }
  function success(obj) { return { type: adminUserConstants.USER_SITESEARCH_SUCCESS, obj }; }
  function failure(obj) { return { type: adminUserConstants.USER_SITESEARCH_FAILURE, obj }; }
}

function siteAddSearchClear(admin) {
  return dispatch => {
    const { user } = admin;
    const { siteSearchResult, ...nosearch } = user;
    dispatch(request(nosearch));
  };
  function request(obj) { return { type: adminUserConstants.USER_SITESEARCH_CLEAR, obj }; }
}

function siteAddSelect(admin, siteidx, reg) {
  return dispatch => {
    const { user } = admin;
    const requser = {
      ...user,
      processing: true
    };
    dispatch(request(requser));

    const { siteSearchResult, edit } = user;
    const { sites } = edit;
    const selectedSite = siteSearchResult[siteidx];
    if (!selectedSite) {
      dispatch(failure(user));
      dispatch(alertActions.error('Could not find the selected site!'));
      return;
    }

    let payload = {user_id: edit.user_id, site_id: []};
    for (let i = 0, j = sites.length; i < j; i++) {
      payload.site_id.push(sites[i].site_id);
    }
    payload.site_id.push(selectedSite.site_id);
    adminService.command('user', 'assignsite', payload)
      .then(
        result => {
          dispatch(alertActions.info('Successfully added site ' + selectedSite.site_id));
          adminService.getextend('user', edit.user_id, 'site')
            .then(
              items => {
                const { siteSearchResult, ...noresult } = user;
                const successedit = {
                  ...edit,
                  sites: items
                };
                const successuser = {
                  ...noresult,
                  edit: successedit
                };
                dispatch(sitesuccess(successuser));
              }
            );
        },
        error => {
          dispatch(failure(user));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(obj) { return { type: adminUserConstants.USER_SITESUBMIT_REQUEST, obj }; }
  function success(obj) { return { type: adminUserConstants.USER_SITESUBMIT_SUCCESS, obj }; }
  function failure(obj) { return { type: adminUserConstants.USER_SITESUBMIT_FAILURE, obj }; }
  function sitesuccess(obj) { return { type: adminUserConstants.USER_SITE_SUCCESS, obj }; }
}

function deleteSite(admin, siteidx) {
  return dispatch => {
    const { user } = admin;
    const { edit } = user;
    const { sites } = edit;
    const requser = {
      ...user,
      processing: true
    };
    dispatch(request(requser));

    let removesite = sites[siteidx];
    if (!removesite) {
      dispatch(failure(user));
      dispatch(alertActions.error('Could not find the site for delete!'));
      return;
    }

    let payload = {user_id: edit.user_id, site_id: []};
    for (let i = 0, j = sites.length; i < j; i++) {
      if (i !== siteidx) {
        payload.site_id.push(sites[i].site_id);
      }
    }
    adminService.command('user', 'assignsite', payload)
      .then(
        result => {
          dispatch(alertActions.info('Successfully removed site ' + removesite.site_id));
          adminService.getextend('user', edit.user_id, 'site')
            .then(
              items => {
                const successedit = {
                  ...edit,
                  sites: items
                };
                const successuser = {
                  ...user,
                  edit: successedit
                };
                dispatch(sitesuccess(successuser));
              }
            );
        },
        error => {
          dispatch(failure(user));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: adminUserConstants.USER_DELETESITE_REQUEST, obj }; }
  function success(obj) { return { type: adminUserConstants.USER_DELETESITE_SUCCESS, obj }; }
  function failure(obj) { return { type: adminUserConstants.USER_DELETESITE_FAILURE, obj }; }
  function sitesuccess(obj) { return { type: adminUserConstants.USER_SITE_SUCCESS, obj }; }
}

function getAvailableRoles(admin) {
  return dispatch => {
    const { user } = admin;
    const { edit } = user;
    const requser = {
      ...user,
      processing: true
    };
    dispatch(request(requser));

    let currRoles = edit.roles ? edit.roles : null;
    adminService.list('role')
      .then(
        items => {
          let ar = [];
          if (items.length === 0) {
            dispatch(alertActions.success('No available roles found.'));
            dispatch(success(user));
            return;
          }
          else {
            ar = listAvailableRoles(items, currRoles);
          }
          if (ar.length === 0) {
            dispatch(alertActions.success('No available roles found.'));
            dispatch(success(user));
            return;
          }
          const successuser = {
            ...user,
            roles: items
          };
          dispatch(success(successuser));
        },
        error => {
          dispatch(failure(user));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(obj) { return { type: adminUserConstants.USER_AVAILABLEROLES_REQUEST, obj }; }
  function success(obj) { return { type: adminUserConstants.USER_AVAILABLEROLES_SUCCESS, obj }; }
  function failure(obj) { return { type: adminUserConstants.USER_AVAILABLEROLES_FAILURE, obj }; }
}

function cancelAvailableRoles(admin) {
  return dispatch => {
    const { user } = admin;
    const { roles, ...cancelled } = user;
    dispatch(request(cancelled));
    history.push('/admin/users/edit');
  };
  function request(obj) { return { type: adminUserConstants.USER_AVAILABLEROLES_CANCEL, obj }; }
}

function addRole(admin, roleidx) {
  return dispatch => {
    const { user } = admin;
    const requser = {
      ...user,
      processing: true
    };
    dispatch(request(requser));

    const { edit, roles } = user;
    const roledata = user.found ? user.found : user.roles;
    const role = roledata[roleidx];

    let payload = { 'user_id': parseInt(edit.user_id), 'role_id': parseInt(role.role_id)};
    adminService.command('user', 'assignrole', payload)
      .then(
        result => {
          adminService.getextend('user', parseInt(edit.user_id), 'roles')
            .then(
              items => {
                const successedit = {
                  ...edit,
                  roles: items
                };
                const successuser = {
                  ...user,
                  edit: successedit
                };
//                const successadmin = {
//                  user: successuser
//                };
                dispatch(success(successuser));
                dispatch(alertActions.success('Role ' + role.name + ' assigned to user.'));
                //getAvailableRoles(successadmin);
              }
            );
        },
        error => {
          dispatch(failure(user));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(obj) { return { type: adminUserConstants.USER_ADDROLE_REQUEST, obj }; }
  function success(obj) { return { type: adminUserConstants.USER_ADDROLE_SUCCESS, obj }; }
  function failure(obj) { return { type: adminUserConstants.USER_ADDROLE_FAILURE, obj }; }
}

function deleteRole(admin, roleidx) {
  return dispatch => {
    const { user } = admin;
    const requser = {
      ...user,
      processing: true
    };
    dispatch(request(requser));

    const { edit } = user;
    const { roles } = edit;
    const role = roles[roleidx];

    let payload = { user_id: parseInt(edit.user_id), role_id: parseInt(role.role_id)};
    adminService.command('user', 'deleterole', payload)
      .then(
        result => {
          let remainingRoles = [];
          for(let i = 0, j = edit.roles.length; i < j; i++) {
            if (i !== roleidx) {
              remainingRoles.push(edit.roles[i]);
            }
          }
          const { roles, ...noroles } = edit;
          const successedit = {
            ...noroles,
            roles: remainingRoles
          };
          const successuser = {
            ...user,
            edit: successedit
          };
          dispatch(success(successuser));
        },
        error => {
          dispatch(failure(user));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(obj) { return { type: adminUserConstants.USER_DELETEROLE_REQUEST, obj }; }
  function success(obj) { return { type: adminUserConstants.USER_DELETEROLE_SUCCESS, obj }; }
  function failure(obj) { return { type: adminUserConstants.USER_DELETEROLE_FAILURE, obj }; }
}

function editCancel(admin) {
  return dispatch => {
    const { user } = admin;
    const { edit, ...noedit } = user;
    dispatch(request(noedit));
    history.push('/admin/users');
  };
  function request(obj) { return { type: adminUserConstants.USER_EDIT_CANCEL, obj }; }
}

function rolePage(admin, evt) {
  return dispatch => {
    const { user } = admin;
    let page = parseInt(evt.currentTarget.attributes['data-pagenumber'].value);
    const newuser = {
      ...user,
      rolepage: page
    };

    dispatch(request(newuser));
  };
  function request(obj) { return { type: adminUserConstants.USER_AVAILABLEROLES_PAGE, obj }; }
}

function roleSearch(admin, searchInput) {
    return dispatch => {
        const { user } = admin;
        const { roles } = user;
        const requser = {
            ...user,
            roleSearch: true
        };
        dispatch(request(requser));

        if(searchInput.length === 0) {
            if (user.found) {
                const { found, ...nofound} = user;
                dispatch(success(nofound));
                dispatch(alertActions.info('Search Cleared'));
                return;
            }
            dispatch(failure(user));
            dispatch(alertActions.error('Type something to search for.'));
        }
        else {
            const sresult = listFilter(roles, ['name', 'description'], [searchInput]);
            if (sresult.length > 0) {
                const newuser = {
                    ...user,
                    found: sresult
                };
                dispatch(success(newuser));
            }
            else {
                dispatch(success(user));
                dispatch(alertActions.info('No matches found'));
            }
        }
    };
    function request(obj) { return { type: adminUserConstants.USER_ROLESEARCH_REQUEST, obj }; }
    function success(obj) { return { type: adminUserConstants.USER_ROLESEARCH_SUCCESS, obj }; }
    function failure(obj) { return { type: adminUserConstants.USER_ROLESEARCH_FAILURE, obj }; }
}

function clearEdit(admin) {
  return dispatch => {
    const { user } = admin;
    const { edit, ...noedit } = user;
    dispatch(request(noedit));
  };
  function request(obj) { return { type: adminUserConstants.USER_EDIT_CANCEL, obj }; }
}




/* PRIVATE METHODS */
function listAvailableRoles(items, currRoles) {
  if (!currRoles) return items;
  let availableRoles = [];
  for (let a = 0, b = items.length; a < b; a++) {
    let ar = items[a];
    let found = false;
    for(let c = 0, d = currRoles.length; c < d; c++) {
      if(parseInt(ar.role_id) === parseInt(currRoles[c].role_id)) {
        found = true;
        break;
      }
    }
    if (!found) {
      availableRoles.push(ar);
    }
  }
  return availableRoles;
}
