import React from 'react';
import { connect } from 'react-redux';

import { alertActions, adminSiteActions } from '../../../_actions';
import { prepTableInfo } from '../../../_helpers';

import { NavigationBar } from '../../navigation';
import { SimpleSearchWClear } from '../../search';
import { DataTable } from '../../dataTable';

var alerttimer = null;

const SiteAdmin = props => {
  const {alert, auth, admin, dispatch} = props;
  if (!auth) return null;
  const { site } = admin;

  const searchValue = site.searchValue ? site.searchValue : '';
  const searching = site.processing ? site.processing : false;
  const submitHandler = onSearchClick.bind(null, dispatch, admin);
  const changeHandler = onSearchInput.bind(null, dispatch, admin);
  const clearHandler = onSearchClear.bind(null, dispatch, admin);
  const clearAlertHandler = clearAlert.bind(null, dispatch);
  const keydownHandler = onkeydownHandler.bind(null, dispatch, admin);

  let dataTable = null;

  if (alert.message) {
    alerttimer = setTimeout(clearAlertHandler, 5000);
  }

  if (site.list) {
    const editHandler = onRowClick.bind(null, dispatch, admin);
    var tableInfoObj = prepTableInfo(site.list);
    var tableDisplayParams = {
        hideFields: ['site_id', 'created_id']
      , allowOptions: {
          edit: { allowed: true, handler: editHandler },
          delete: { allowed: false, handler: null },
          select: { allowed: false, handler: null }
        }
    };
    dataTable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} />;
  }

  return (
    <div>
      <NavigationBar header="Admin - Sites" />
      <div className="container">
        <SimpleSearchWClear submitHandler={submitHandler}
                            changeHandler={changeHandler}
                            clearHandler={clearHandler}
                            searchValue={searchValue}
                            searching={searching}
                            searchDisable={searching}
                            keydownHandler={keydownHandler}
                            displayText="Site Id" />
        {alert.message &&
          <div className={`alert ${alert.type}`}>{alert.message}</div>
        }
        {dataTable}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const admin = state.administration
  return { alert, auth, admin };
}

const connectedSiteAdmin = connect(mapStateToProps)(SiteAdmin);
export { connectedSiteAdmin as SiteAdmin };

function onSearchInput(dispatch, admin, e) {
  dispatch(alertActions.clear());
  clearTimeout(alerttimer);
  const { value } = e.target;
  dispatch(adminSiteActions.searchInput(admin, value));
}

function onSearchClear(dispatch, admin) {
  dispatch(alertActions.clear());
  clearTimeout(alerttimer);
  dispatch(adminSiteActions.searchClear(admin));
}

function onSearchClick(dispatch, admin) {
  dispatch(alertActions.clear());
  clearTimeout(alerttimer);
  const { site } = admin;
  if (!site.searchValue) {
    dispatch(alertActions.error('Need a site id to search for.'));
    return;
  }
  if (site.searchValue.length < 2) {
    dispatch(alertActions.error('Please provide just a little more information.'));
    return;
  }
  dispatch(adminSiteActions.search(admin));
}

function clearAlert(dispatch) {
  dispatch(alertActions.clear());
  clearTimeout(alerttimer);
  alerttimer = null;
}

function onRowClick(dispatch, admin, e) {
  dispatch(alertActions.clear());
  clearTimeout(alerttimer);
  var idx = parseInt(e.currentTarget.attributes['data-index'].value);
  var obj = null;
  const { site } = admin;
  if (idx >= 0) {
    obj = site.list[idx];
  }
  if (!obj) {
    dispatch(alertActions.error('Item data not found'));
    return;
  }
  dispatch(adminSiteActions.gotoUser(admin, obj));
}

function onkeydownHandler(dispatch, admin, evt) {
    if (evt.keyCode === 13) {
        evt.preventDefault();
        evt.stopPropagation();
        onSearchClick(dispatch, admin);
    }
}
