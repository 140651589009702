import { textTokenConstants } from "../_constants/monitor.site.texttokens.constants";

export function texttokens(state={}, action){
   switch(action.type){
      case textTokenConstants.TEXT_TOKEN_GET_REQUEST:
      case textTokenConstants.TEXT_TOKEN_SAVE_REQUEST:
         return {
            ...state,
            texttokens:{
               ...state.texttokens,
               isRequesting: true
            }
         }
      case textTokenConstants.TEXT_TOKEN_GET_SUCCESS:
         return {
            ...state,
            texttokens:{
               ...action.payload,
               isRequesting: false
            }
         };
      case textTokenConstants.TEXT_TOKEN_GET_FAILURE:
         return {
            ...state,
            texttokens:{
               list: [],
               isRequesting: false
            }
         };
      case textTokenConstants.TEXT_TOKEN_SAVE_SUCCESS:
         return {
            ...state,
            texttokens:{
            ...state.texttokens,
            isRequesting: false
            }
         }
      case textTokenConstants.TEXT_TOKEN_SAVE_FAILURE:
         return {
            ...state,
            texttokens:{
            ...state.texttokens,
            isRequesting: false
            }
         }
      case textTokenConstants.TEXT_TOKEN_ADD_TOKEN:
         return {
            ...state,
            texttokens:{
               list: [...state.texttokens.list, action.payload]
            }
         }
      case textTokenConstants.TEXT_TOKEN_CANCEL_TOKEN:
         let filteredList = state.texttokens.list.filter((obj) => obj.mode !== 'new')
         return {
            ...state,
            texttokens:{
               list: filteredList
            }
         }
      case textTokenConstants.TEXT_TOKEN_SAVE_TOKEN:
         let newlist = [...state.texttokens.list]
         for(let item of newlist){
            if(item.token === 'newtoken'){
               item.label = action.payload
               item.token = toCamelCase(item.label)
               item.value = ""
               item.mode = ""
            }
         }
         return {
            ...state,
            texttokens:{
               list: newlist
            }
         }
      case textTokenConstants.TEXT_TOKEN_DELETE_TOKEN:
            return {
               ...state,
               texttokens:{
                  list: state.texttokens.list.filter((obj) => obj.token !== action.payload),
               }
            }
      case textTokenConstants.TEXT_TOKEN_UPDATE_TOKEN:
         let newList = [...state.texttokens.list];
         for (let item of newList) {
            if (item.token === action.payload.token) {
               item.value = action.payload.value;
            }
         }
         return {
            ...state,
            texttokens:{
               list: newList
            }
         }
   }
}

function toCamelCase(input) {
   return input.replace(/\s+(\w)/g, (match, char) => char.toUpperCase());
 }
