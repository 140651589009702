export const siteConstants = {
    SITE_CLEAR: 'SITE_CLEAR'

  , SITE_GET_REQUEST: 'SITE_GET_REQUEST'
  , SITE_GET_SUCCESS: 'SITE_GET_SUCCESS'
  , SITE_GET_FAILURE: 'SITE_GET_FAILURE'

  , SITE_STB_ONSTATE: 'SITE_STB_ONSTATE'
  , SITE_STB_CHECKINSTATE: 'SITE_STB_CHECKINSTATE'
  , SITE_STB_SETSTATECODES: 'SITE_STB_SETSTATECODES'

  , SITE_CHART_SEARCH_REQUEST: 'SITE_CHART_SEARCH_REQUEST'
  , SITE_CHART_SEARCH_SUCCESS: 'SITE_CHART_SEARCH_SUCCESS'
  , SITE_CHART_SEARCH_FAILURE: 'SITE_CHART_SEARCH_FAILURE'

  , SITE_SEARCH_INPUT: 'SITE_SEARCH_INPUT'
  , SITE_SEARCH_CLEAR: 'SITE_SEARCH_CLEAR'
  , SITE_SEARCH_REQUEST: 'SITE_SEARCH_REQUEST'
  , SITE_SEARCH_SUCCESS: 'SITE_SEARCH_SUCCESS'
  , SITE_SEARCH_FAILURE: 'SITE_SEARCH_FAILURE'

  , SITE_PAGE_CHANGE: 'SITE_PAGE_CHANGE'
  , SITE_TABLE_ROWS: 'SITE_TABLE_ROWS'

  , SITE_SORT_REQUEST: 'SITE_SORT_REQUEST'
  , SITE_SORT_SUCCESS: 'SITE_SORT_SUCCESS'

  , SITE_STB_DEACTIVATE_REQUEST: 'SITE_STB_DEACTIVATE_REQUEST'
  , SITE_STB_DEACTIVATE_SUCCESS: 'SITE_STB_DEACTIVATE_SUCCESS'
  , SITE_STB_DEACTIVATE_FAILURE: 'SITE_STB_DEACTIVATE_FAILURE'

  , SITE_STB_REBOOT_REQUEST: 'SITE_STB_REBOOT_REQUEST'
  , SITE_STB_REBOOT_SUCCESS: 'SITE_STB_REBOOT_SUCCESS'
  , SITE_STB_REBOOT_FAILURE: 'SITE_STB_REBOOT_FAILURE'

  , SITE_ROOM_NUMBER_CHANGE_REQUEST: 'SITE_ROOM_NUMBER_CHANGE_REQUEST'
  , SITE_ROOM_NUMBER_CHANGE_CANCEL: 'SITE_ROOM_NUMBER_CHANGE_CANCEL'
  , SITE_ROOM_NUMBER_CHANGE_PUBLIC: 'SITE_ROOM_NUMBER_CHANGE_PUBLIC'
  , SITE_ROOM_NUMBER_CHANGE_INPUT: 'SITE_ROOM_NUMBER_CHANGE_INPUT'
  , SITE_ROOM_NUMBER_CHANGE_LOCATION: 'SITE_ROOM_NUMBER_CHANGE_LOCATION'
  , SITE_ROOM_NUMBER_CHANGE_LOCATIONNUM: 'SITE_ROOM_NUMBER_CHANGE_LOCATIONNUM'
  , SITE_ROOM_NUMBER_CHANGE_SAVE_REQUEST: 'SITE_ROOM_NUMBER_CHANGE_SAVE_REQUEST'
  , SITE_ROOM_NUMBER_CHANGE_SAVE_SUCCESS: 'SITE_ROOM_NUMBER_CHANGE_SAVE_SUCCESS'
  , SITE_ROOM_NUMBER_CHANGE_SAVE_FAILURE: 'SITE_ROOM_NUMBER_CHANGE_SAVE_FAILURE'

  , SITE_SCRIPT_EXECUTE_REQUEST: 'SITE_SCRIPT_EXECUTE_REQUEST'
  , SITE_SCRIPT_EXECUTE_SUCCESS: 'SITE_SCRIPT_EXECUTE_SUCCESS'
  , SITE_SCRIPT_EXECUTE_FAILURE: 'SITE_SCRIPT_EXECUTE_FAILURE'
};
