import React from 'react';
import { connect } from 'react-redux';

import { msgActions } from '../../../_actions';

import { CreateGroup } from './createGroup';

const SelectedRooms = props => {
  const { auth, monitor } = props;
  if (!auth) return null;
  const { site, msg } = monitor;
  if (!site.stbs) return null;

  let sortIcon = null;
  let tableData = null;
  let disabled = true;
  let groupCreate = false;
  let groupName = '';
  let btnText = 'Create Group'
  let popupTitle = 'Create';
  if (msg) {
    groupCreate = msg.createGroup;
    const { group } = msg;
    if (group) {
      groupName = group.name ? group.name : groupName;
      if (group.selectedIdx >= 0) {
        groupName = msg.group.list[msg.group.selectedIdx].name;
        btnText = 'Change Group';
        popupTitle = 'Change';
      }
    }

    if (msg.rooms) {
      if (!props.disable) {
        disabled = false;
        tableData = msg.rooms.map(function(room, idx) {
          return (
            <tr key={'srm' + idx} data-row-index={idx} onClick={() => { props.select(monitor, idx); }} className="trselectable">
              <td className="tdf-lg-c" style={{width:"132px"}}>{room.room_number}</td>
            </tr>);
        });
      }
      else {
        tableData = msg.rooms.map(function(room, idx) {
          return (
            <tr key={'srm' + idx} data-row-index={idx} className="trselectable">
              <td className="tdf-lg-c" style={{width:"132px"}}>{room.room_number}</td>
            </tr>);
        });
      }
    }

    if (msg.sort && msg.sort.selected && msg.sort.selected.direction) {
      if (msg.sort.selected.direction === 'asc') {
        sortIcon = <i className="up ml-1"></i>;
      }
      else {
        sortIcon = <i className="down ml-1"></i>;
      }
    }
  }

  return (
    <div>
      <CreateGroup show={groupCreate} cancelhandler={() => { props.hideCreateGroup(monitor); }} createhandler={() => { props.submitNewGroup(monitor); }} changehandler={(e) => { props.inputGroupName(monitor, e); }} groupName={groupName} title={popupTitle} />
      <div className={props.cssClass}>
        <table id="roomlist" className="roomlist">
          <thead>
            <tr>
              <th className="thfc thsort" style={{width:"132px"}} onClick={() => { props.sort(monitor); }} data-toggle="tooltip" data-placement="top" title="Sort">Room{sortIcon}</th>
            </tr>
          </thead>
          <tbody>
            {tableData}
          </tbody>
        </table>
      </div>
      <div style={{textAlign: "center"}}>
        <button type="button" onClick={() => { props.showCreateGroup(monitor); }} className="btn btn-primary" style={{marginTop:"3px"}} disabled={disabled}>{btnText}</button>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const auth = state.authentication.user;
  const monitor = state.monitor;
  return { auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    sort(monitor) { dispatch(msgActions.selectedSort(monitor)); }
  , select(monitor, idx) { dispatch(msgActions.deselectRoom(monitor, idx)); }
  , showCreateGroup(monitor) { dispatch(msgActions.createGroupDisplay(monitor)); }
  , hideCreateGroup(monitor) { dispatch(msgActions.createGroupCancel(monitor)); }
  , inputGroupName(monitor, e) { dispatch(msgActions.createGroupInput(monitor, e)); }
  , submitNewGroup(monitor) { dispatch(msgActions.createGroupSubmit(monitor)); }
});

const connectedSelectedRooms = connect(mapStateToProps, mapDispatchToProps)(SelectedRooms);
export { connectedSelectedRooms as SelectedRooms };
