import React from 'react';
import { connect } from 'react-redux';

import { alertActions, eventActions } from '../../../_actions';
import { history, isObjectEmpty, formatYYYYMMDD } from '../../../_helpers';

import { NavigationBar } from '../../navigation';

import { SiteData } from '../siteData';

import { EventEditNewForm } from './views/newedit';
import { IterationView } from './views/iteration';
import { DailyIteration } from './views/daily';
import { WeeklyIteration } from './views/weekly';
import { MonthlyIteration } from './views/monthly';

class EventsEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        inputs: null
      , originals: null
      , dirty: false
      , showrecurrence: false
      , showview: null
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.onSubmitNew = this.onSubmitNew.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.modifyRecurrence = this.modifyRecurrence.bind(this);
    this.modalClose = this.modalClose.bind(this);
    this.iterationSelected = this.iterationSelected.bind(this);
    this.endsSelected = this.endsSelected.bind(this);
  }

  componentDidMount() {
    const { auth, monitor } = this.props;
    if (!auth) {
      history.push('/portal/events');
      return;
    }
    if (monitor) {
      const { events } = monitor;
      const { edit } = events;
      if (edit) {
        this.setState({ inputs: edit, originals: edit });
      }
      else {
        history.push('/portal/events');
        return;
      }
    }
  }

  componentDidUpdate() {
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) window.setTimeout(clearAlert, 3000);;
  }

  render() {
    const { auth, monitor, alert } = this.props;
    if (!auth) return null;
    const { site, events } = monitor;

    const { inputs, showrecurrence, showview } = this.state;
    const headerText = 'Monitor - Site: ' + site.edit.site_id + ' - Edit Event';

    let processing = events.processing ? events.processing : false;
    let validity = events.validity ? events.validity : null;
    let dtmin = formatYYYYMMDD(new Date());
    
    let recurrenceModal = null;
    if (showrecurrence) {
      switch(showview) {
        case 'daily':
          recurrenceModal = <DailyIteration onclose={this.modalClose} onselect={this.endsSelected} inputs={inputs} validity={validity} onchange={this.onInputChange} dtmin={dtmin} />;
          break;
        case 'weekly':
          recurrenceModal = <WeeklyIteration onclose={this.modalClose} onselect={this.endsSelected} inputs={inputs} validity={validity} onchange={this.onInputChange} dtmin={dtmin} />;
          break;
        case 'monthly':
          recurrenceModal = <MonthlyIteration onclose={this.modalClose} onselect={this.endsSelected} inputs={inputs} validity={validity} onchange={this.onInputChange} dtmin={dtmin} />;
          break;
        default:
          recurrenceModal = <IterationView onclose={this.modalClose} onselect={this.iterationSelected} inputs={inputs} />;
          break;
      }
    }

    return(
      <div id="eventsEdit">
        <NavigationBar header={headerText} />
        <div className="container">
          <SiteData site={site.edit} more={false} showSiteIp={false} siteList={null} showList={false} clickHandler={null} displayHandler={null} closeHandler={null} />
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
          <EventEditNewForm processing={processing} changeHandler={this.onInputChange} checkHandler={this.onCheckChange} validity={validity} inputs={inputs} dtmin={dtmin} onSubmit={this.onSubmitNew} onCancel={this.onCancel} modifyRecurrence={this.modifyRecurrence} />
        </div>
        {recurrenceModal}
      </div>
    );
}

  onInputChange(evt) {
    const { name, value } = evt.target;
    const { alert, clearAlert } = this.props;
    const { inputs, originals } = this.state;
    const origval = originals[name];
    let dirty = false;
    if (origval !== value) dirty = true;
    const newinputs = {
      ...inputs,
      [name]: value
    };
    this.setState({ inputs: newinputs, dirty: dirty });
    if (!isObjectEmpty(alert)) clearAlert();
  }
  onCheckChange(evt) {
    const { name, checked } = evt.currentTarget;
    const { alert, clearAlert } = this.props;
    const { inputs, originals } = this.state;
    const origval = originals[name];
    const evtval = checked ? 1 : 0;
    let dirty = false;
    let showRecurrence = false;
    let newinputs = null;
    if (parseInt(origval) !== evtval) dirty = true;
    switch(name) {
      case 'all_day':
        if (evtval === 1) {
          newinputs = {
            ...inputs,
            [name]: evtval.toString(),
            end_date: null,
            end_time: null,
            end: null
          }
        }
        else {
          newinputs = {
            ...inputs,
            [name]: evtval.toString(),
            end_date: originals['end_date'],
            end_time: originals['end_time'],
            end: originals['end']
          }
        }
        break;
      case 'recurrence':
        if (evtval === 1) showRecurrence = true;
      default:
        newinputs = {
          ...inputs,
          [name]: evtval.toString()
        };
        break;
      }
    this.setState({ inputs: newinputs, dirty: dirty, showrecurrence: showRecurrence });
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onCancel() {
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    history.push('/portal/events');
  }

  modifyRecurrence() {
    this.setState({ showrecurrence: true, showview: null });
  }
  modalClose() {
    this.setState({ showrecurrence: false, showview: null });
  }

  iterationSelected(evt) {
    const { name } = evt.target;
    const { inputs, originals } = this.state;
    const origval = originals['iteration'];
    let dirty = false;
    if (origval !== name) dirty = true;
    const newinputs = {
      ...inputs,
      iteration: name
    };
    this.setState({ inputs: newinputs, dirty: dirty, showview: name });
  }

  endsSelected(evt) {
    const { name, value } = evt.currentTarget;
    const { alert, clearAlert } = this.props;
    const { inputs, originals, dirty } = this.state;
    const origval = originals[name];
    let isdirty = false;
    if (!dirty) {
      if (parseInt(origval) !== value) isdirty = true;
    }
    const newinputs = {
      ...inputs,
        [name]: value
      , "ends_date": null
      , "ends_count": null
    };
    this.setState({ inputs: newinputs, dirty: dirty });
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onSubmitNew(evt) {
    evt.preventDefault();
    const { auth, monitor, save, alert, clearAlert, sendError } = this.props;
    if (!this.state.dirty) {
      sendError('Nothing Changed!');
      return;
    }
    if (!isObjectEmpty(alert)) clearAlert();
    const { inputs } = this.state;
    save(monitor, inputs, auth);
  }
};

function mapStateToProps(state) {
  const alert = state.alert;
  const auth = state.authentication.user;
  const monitor = state.monitor;
  return { alert, auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    clearAlert() { dispatch(alertActions.clear()); }
  , sendError(msg) { dispatch(alertActions.error(msg)); }
  , save(monitor, values, auth) { dispatch(eventActions.save(monitor, values, auth)); }
});


const connectedEventsEdit = connect(mapStateToProps, mapDispatchToProps)(EventsEdit);
export { connectedEventsEdit as EventsEdit };
