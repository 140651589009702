import { sites } from './monitor.sites.reducer';
import { site } from './monitor.site.reducer';
import { stats } from './monitor.stats.reducer';
import { msg } from './monitor.messaging.reducer';
import { events } from './monitor.events.reducer';
import { file } from './monitor.file.reducer';
import { chan } from './monitor.streaming.reducer';
import { housekeeping } from './monitor.housekeeping.reducer';
//import { roomservice } from './monitor.roomservice.reducer';
//import { diningmenu } from './monitor.diningmenu.reducer';
import { documentation } from './monitor.documentation.reducer';
import { appparam } from './monitor.site.param.reducer';
import { texttokens } from './monitor.site.texttokens.reducer';

const initState = {
    sites: {}
  , site: {}
  , stats: {}
  , msg: {}
  , events: {}
  , file: {}
  , chan: {}
  ,housekeeping:{}
 // ,roomservice:{}
 // ,diningmenu:{}
  ,documentation:{}
  , appparam: {}
  , texttokens: {
    isRequesting: false,
    list: []
  }
}

export function monitor(state = initState, action) {
  if (!action) return state;
  var actionSection = action.type.split('_');
  switch(actionSection[0]) {
    case 'SITES':
      return sites(state, action);
    case 'SITE':
      return site(state, action);
    case 'STATS':
      return stats(state, action);
    case 'MSG':
      return msg(state, action);
    case 'EVENTS':
      return events(state, action);
    case 'FILE':
      return file(state, action);
    case 'CHAN':
      return chan(state, action);
    case 'HOUSEKEEPING':
       return housekeeping(state, action);
  /*  case 'ROOMSERVICE':
        return roomservice(state, action);
    case 'DININGMENU':
      return diningmenu(state, action);*/ // 01.25.2021 Commenting room service stuff. TODO: Uncomment it later
    case 'DOCUMENTATION':
      return documentation(state, action);
    case 'APPPARAM':
      return appparam(state, action);
    case 'TEXTTOKEN':
      return texttokens(state, action);
    default:
      return state;
  }
}
