import { fileConstants } from '../_constants';

export function file(state = {}, action) {
  switch(action.type) {
    case fileConstants.FILE_UPLOAD_REQUEST:
    case fileConstants.FILE_UPLOAD_SUCCESS:
    case fileConstants.FILE_UPLOAD_FAILURE:
      return{
        ...state,
        file: action.obj
      };
    default:
      return state;
  }
}
