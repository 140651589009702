export const navConfig = {
    pageLinks: {
      admin: [
          {name:'user', title:'Users', path:'/admin/users'}
        , {name:'role', title:'Roles', path:'/admin/roles'}
        , {name:'permission', title:'Permissions', path:'/admin/permissions'}
        , {name:'site', title:'Sites', path:'/admin/sites'}
        , {name:'registered', title:'Registrations', path:'/admin/register/list'}
        , {name:'group', title:'Groups', path:'/admin/groups'}
      ],
      portal: [
          {name:'sites', title:'Sites', path:'/portal/sites'}
        , {name:'site', title:'Site', path:'/portal/site'}
        , {name:'stats', title:'Stats', path:'/portal/stats'}
        , {name:'resv', title:'Reservation', path:'/portal/resv'}
        , {name:'network', title:'Network', path:'/portal/network'}
        , {name:'message', title:'Message', path:'/portal/messaging'}
        , {name:'events', title:'Events', path:'/portal/events'}
        , {name:'upload', title:'Uploader', path:'/portal/uploader'}
        , {name:'streaming', title:'Streaming', path:'/portal/streaming'}
        , {name:'housekeeping', title:'Housekeeping', path:'/portal/housekeeping'}
       // , {name:'roomservice', title:'RoomService', path:'/portal/roomservice'} // 01.25.2021 Commenting room service stuff. TODO: Uncomment it later
        , {name:'documentation', title:'Documentation', path: '/portal/documentation'}
        , {name:'site.users', title:'Users', path:'/portal/site/users'}
        , {name:'stbparams', title:'Stb Parameters', path:'/portal/stbparams'}
        , {name:'texttokens', title:'Text Tokens', path:'/portal/texttokens'}
      ],
      common: [
          {name:'home', title:'Home', path:'/'}
        , {name:'profile', title:'Profile', path:'/user/profile'}
        , {name:'contact', title:'Contact Us', path:'/contact'}
        , {name:'signout', title:'Sign Out', path:'/login'}
      ]
    }
  , accessLinks: [
        {name: 'admin', title: 'Administration'}
      , {name: 'portal', title: 'Monitor'}
      , {name: 'common', title: 'Auxiliary'}
    ]
};
