import React from 'react';
import { connect } from 'react-redux';

import { alertActions, msgActions } from '../../../_actions';

import { GroupList } from './groupList';

const MessageGroups = props => {
  const { auth, monitor } = props;
  if (!auth) return null;
  const { site, msg } = monitor;
  if (!site.stbs) return null;
  if (!msg) return null;

  let grpmgrbtn = null;
  let view = <div className="msgGroupNone">None</div>;

  if (msg.group && msg.group.list && msg.group.list.length > 0) {
    grpmgrbtn = <div><button type="button" onClick={() => { props.manage(monitor); }} className="btn btn-primary" style={{marginTop:"3px"}} disabled={props.disable}>Manage Groups</button></div>;
    view = <GroupList list={msg.group.list} changeHandler={(e) => { props.select(monitor, e); }} selected={msg.group.selectedIdx} disable={props.disable} />
  }

  return (
    <div className={props.cssClass}>
      <div>
        <span>Existing Groups</span>
      </div>
      {view}
      {grpmgrbtn}
    </div>
  );
};

function mapStateToProps(state) {
  const auth = state.authentication.user;
  const monitor = state.monitor;
  return { auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    select(monitor, e) { dispatch(msgActions.groupSelect(monitor, e)); }
  , manage(monitor) { dispatch(msgActions.managegroups(monitor)); }
});

const connectedMessageGroups = connect(mapStateToProps, mapDispatchToProps)(MessageGroups);
export { connectedMessageGroups as MessageGroups };
