import React from 'react';
import { connect } from 'react-redux';
import { WciModal } from '../../wcimodal';

const CreateGroup = props => {
  const { alert } = props;
  return (
    <WciModal show={props.show} className="baseModal">
      <div className="createGroupModal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.title} Group</h5>
            <button type="button"
                    className="close"
                    onClick={props.cancelhandler}>&times;</button>
          </div>
          <div className="modal-body">
            <div>Group Name</div>
            <input type="text" onChange={(e) => { props.changehandler(e); }} value={props.groupName} />
            {alert.message &&
              <div className={`alert ${alert.type}`}>{alert.message}</div>
            }
          </div>
          <div className="modal-footer">
            <button type="button"
                    className="btn btn-secondary"
                    onClick={props.cancelhandler}>Cancel</button>
            <button type="button"
                    className={'btn btn-primary'}
                    onClick={props.createhandler}>{props.title}</button>
          </div>
        </div>
      </div>
    </WciModal>
  );
};

function mapStateToProps(state) {
  const alert = state.alert
  return { alert };
}

const connectedCreateGroup = connect(mapStateToProps)(CreateGroup);
export { connectedCreateGroup as CreateGroup };
