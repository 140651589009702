import { registrationConstants, adminUserConstants } from '../_constants';
import { alertActions } from './';
import { history, config, userParamValidator, isUserNameDuplicate, isEmailDuplicate } from '../_helpers';
import { userService, adminService } from '../_services';


export const registrationActions = {
    returnToLogin
  , register
  , list
  , select
  , input
  , cancel
  , save
  , clear
  , remove
};

function returnToLogin() {
  return dispatch => {
    dispatch(request());
    history.push('/login');
  };
  function request() { return { type: registrationConstants.REGISTER_RETURN }; }
}

function register(user) {
    return dispatch => {
        dispatch(request());

        let vun = false;
        let ve = false;

        isUserNameDuplicate(user.user_name)
        .then(() => { 
            vun = true;
            if (vun && ve) {
                const ds = JSON.stringify(user);
                doRegister(ds)
                .then(() => {
                    dispatch(success());
                    dispatch(alertActions.success('Registration successful. You will be notified when your account is set up.'));
                })
                .catch(e => {
                    dispatch(failure());
                    dispatch(alertActions.error(e));
                });
            }
        })
        .catch(e => { 
            dispatch(failure());
            dispatch(alertActions.error(e));
        });

        isEmailDuplicate(user.email)
        .then(() => { 
            ve = true;
            if (vun && ve) {
                const ds = JSON.stringify(user);
                doRegister(ds)
                .then(() => {
                    dispatch(success());
                    dispatch(alertActions.success('Registration successful. You will be notified when your account is set up.'));
                })
                .catch(e => {
                    dispatch(failure());
                    dispatch(alertActions.error(e));
                });
            }
        })
        .catch(e => {
            dispatch(failure());
            dispatch(alertActions.error(e));
        });
/*
        const d  = {username: user.user_name};
        const ds = JSON.stringify(d);
        userService.verifyUserName(ds)
            .then(
                result => {
                  if(!isNaN(result)) {
                        let msg = 'User Name already exists';
                        dispatch(failure());
                        dispatch(alertActions.error(msg));
                    }
                    else {
                        // This is just in case something changes in the function. 
                        // As of 4/2022 when no match is found a 409 error is returned.
                        const ds = JSON.stringify(user);
                        userService.register(ds)
                            .then(
                                () => {
                                    dispatch(success());
                                    dispatch(alertActions.success('Registration successful. You will be notified when your account is set up.'));
                                },
                                error => {
                                    if (error.indexOf('user_name') > 0) {
                                        dispatch(alertActions.error('User name ' + user.user_name + ' is already taken.'));
                                    }
                                    else {
                                        dispatch(alertActions.error(error));
                                    }
                                    dispatch(failure(user));
                                }
                            );
                    }
                },
                error => {
                    // When no match is found a 409 error is returned.
                    const ds = JSON.stringify(user);
                    userService.register(ds)
                        .then(
                            () => {
                                dispatch(success());
                                dispatch(alertActions.success('Registration successful. You will be notified when your account is set up.'));
                            },
                            error => {
                                if (error.indexOf('user_name') > 0) {
                                    dispatch(alertActions.error('User name ' + user.user_name + ' is already taken.'));
                                }
                                else {
                                    dispatch(alertActions.error(error));
                                }
                                dispatch(failure(user));
                            }
                        );
                }
            );
*/
    };

    function request() { return { type: registrationConstants.REGISTER_REQUEST }; }
    function success() { return { type: registrationConstants.REGISTER_SUCCESS }; }
    function failure() { return { type: registrationConstants.REGISTER_FAILURE }; }
}

function list() {
  return dispatch => {
    dispatch(request({processing: true}));

    adminService.list('registration')
      .then(
        items => {
          if (items.length === 0) {
            dispatch(alertActions.success('No registrations found'));
            dispatch(success({list: []}));
            return;
          }
          dispatch(success({list: items}));
        },
        error => {
          dispatch(failure({list: []}));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(obj) { return { type: registrationConstants.REGISTER_LIST_REQUEST, obj }; }
  function success(obj) { return { type: registrationConstants.REGISTER_LIST_SUCCESS, obj }; }
  function failure(obj) { return { type: registrationConstants.REGISTER_LIST_FAILURE, obj }; }
}

function select(reg, idx) {
  return dispatch => {
    dispatch(alertActions.clear());

    let regitem = reg.list[idx];
    if (!regitem) {
      dispatch(alertActions.error('Did not find the selected registration'));
      dispatch(failure(reg));
    }

    let params = config.userAddParams;
    params.user_name = regitem.user_name;
    params.first_name = regitem.first_name;
    params.last_name = regitem.last_name;
    params.phone = regitem.phone;
    params.email = regitem.email;

    const regedit = {
        item: regitem
      , edit: params
      , idx: idx
    };
    const newreg = {
      ...reg,
      selected: regedit
    };
    history.push('/admin/register/edit');
    dispatch(success(newreg));
  };

  function success(obj) { return { type: registrationConstants.REGISTER_SELECT_SUCCESS, obj }; }
  function failure(obj) { return { type: registrationConstants.REGISTER_SELECT_FAILURE, obj }; }
}

function input(reg, change) {
  return dispatch => {
    dispatch(alertActions.clear());
    const { selected } = reg;
    const { edit } = selected;
    const { name, value } = change;
    const newedit = {
      ...edit,
      [name]: value
    };
    const newselected = {
      ...selected,
      edit: newedit
    };
    const newreg = {
      ...reg,
      selected: newselected
    };
    dispatch(success(newreg));
  };
  function success(obj) { return { type: registrationConstants.REGISTER_EDIT_INPUT, obj }; }
}

function cancel(reg) {
  return dispatch => {
    dispatch(alertActions.clear());
    const { selected, ...listonly } = reg;
    dispatch(success(listonly));
    history.push('/admin/register/list');
  };
  function success(obj) { return { type: registrationConstants.REGISTER_EDIT_CANCEL, obj }; }
}

function save(reg) {
  return dispatch => {
    dispatch(alertActions.clear());
    const { selected, list } = reg;
    const { edit, item } = selected;
    const reqreg = {
      ...reg,
      processing: true
    };
    dispatch(request(reqreg));

    const validity = userParamValidator(edit, true);
    if (validity.valid) {
//    code from adminUserActions.add();
      var usernameSearch = [{'like': {'col':'user_name', 'val':`${edit.user_name}`}}];
      adminService.search('user', usernameSearch)
        .then(
          items => {
            if (items.length > 0) {
              let msg = 'User Name already exists';
              validity.user_name = false;
              validity.valid = false;
              const usernamefail = {
                ...selected,
                validity: validity
              };
              const searchfailreg = {
                ...reg,
                selected: usernamefail
              };
              dispatch(failure(searchfailreg));
              dispatch(alertActions.error(msg));
            }
            else {
              edit.password_verify = edit.password;
              adminService.add('user', edit)
                .then(
                  result => {
                    const addedId = parseInt(result[0]);
                    adminService.delete('registration', item.register_id)
                      .then(
                        count => {
                          const { edit, idx, ...itemonly } = selected;
                          const addreg = {
                              userid: addedId
                            , selected: itemonly
                          };
                          dispatch(success(addreg));
                          dispatch(alertActions.success('Registered user added to site.'));
                          history.push('/admin/users');
                        },
                        error => {
                          dispatch(failure(addreg));
                          dispatch(alertActions.error(error));
                        }
                      );
                  },
                  error => {
                    dispatch(failure(reg));
                    dispatch(alertActions.error(error));
                  }
                );
            }
          },
          error => {
            let msg = 'Error verifying user name';
            dispatch(failure(reg));
            dispatch(alertActions.error(msg));
          }
        );
    }
    else {
      const failselected = {
        ...selected,
        validity: validity
      };
      const failreg = {
        ...reg,
        selected: failselected
      };
      dispatch(failure(failreg));
      dispatch(alertActions.error('Form Validation Failed'));
    }
  };
  function request(obj) { return { type: registrationConstants.REGISTER_EDIT_SAVE_REQUEST, obj }; }
  function success(obj) { return { type: registrationConstants.REGISTER_EDIT_SAVE_SUCCESS, obj }; }
  function failure(obj) { return { type: registrationConstants.REGISTER_EDIT_SAVE_FAILURE, obj }; }
}

function clear() {
  return dispatch => {
    dispatch(request());
  };
  function request() { return { type: registrationConstants.REGISTER_CLEAR }; }
}

function remove(reg, idx) {
  return dispatch => {
    let reqreg = {
      ...reg,
      processing: true
    };
    dispatch(request(reqreg));

    const { list, ...nolist } = reg;
    const item = list[idx];
    adminService.delete('registration', item.register_id)
      .then(
        count => {
            dispatch(success(nolist));
            dispatch(alertActions.success('Registration removed.'));
        },
        error => {
          dispatch(failure(reg));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: registrationConstants.REGISTER_REMOVE_REQUEST, obj }; }
  function success(obj) { return { type: registrationConstants.REGISTER_REMOVE_SUCCESS, obj }; }
  function failure(obj) { return { type: registrationConstants.REGISTER_REMOVE_FAILURE, obj }; }
}


// PRIVATE METHODS
function doRegister(d) {
  return new Promise((resolve, reject) => {
    userService.register(d)
    .then(
        () => { resolve(); },
        error => { reject(error); }
    );
});
};
