module.exports = {
    stbUp
  , stbOn
  , stbOff
  , checkedIn
  , setCheckInState
  , setStbStatus
  , stbDownText
  , setStateCodes
};

function stbUp(stb, compareto) {
  if (stb.status_code >=500) return false;
  return true;
}

function stbOn(stb) {
  if(stb.status_code==200)
    return true;
}

function stbOff(stb){
  if(stb.status_code==100)
    return true
}

function stbDownText(stb){
  if(stb.status_code==500)
    return 'Not Reporting'
  if(stb.status_code==501)
    return 'Network Disconnected'
  if(stb.status_code==999)
    return 'Unknown'
}
function checkedIn(stb) {
  if (stb.check_in_flag === 'N') return false;
  if (stb.check_in_flag === 'Y') return true;
  return null;
}
function setCheckInState(stbs, cb) {
  let chkState = {in:0, out:0, unknown:0, total:0};
  for (let i = 0, j = stbs.length; i < j; i++) {
    ++chkState.total;
    let _in = checkedIn(stbs[i]);
    if (_in) ++chkState.in;
    if (_in === null) ++chkState.unknown;
    if (_in === false) ++chkState.out;
  }
  cb(chkState);
}
function setStbStatus(stbs, compareto, cb) {
  let ste = {on: 0, down: 0, total: 0};
  for (let i = 0, j = stbs.length; i < j; i++) {
    ++ste.total;
    let itm = stbs[i];
    let up = stbUp(itm, compareto);
    let on = stbOn(itm);
    if (up && on) ++ste.on;
    if (!up) ++ste.down;
  }
  cb(ste);
}

function setStateCodes(stbs, cb) {
  let ss = {total: 0};
  for (let i = 0, j = stbs.length; i < j; i++) {
    const keys = Object.keys(ss);
    const stbSC = stbs[i].status_code;
    let val = null;
    if (keys.includes(stbSC)) {
      val = ss[stbSC];
      val++;
    }
    ss[stbSC] = val ? val : 1;
    ++ss.total;
  }
  cb(ss);
}