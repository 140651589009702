import React from 'react';
import { connect } from 'react-redux';

import { paramActions } from '../../../_actions';
import { prepTableInfo, confirmOptions } from '../../../_helpers';

import { DataTable } from '../../dataTable';
import { ConfirmModal } from '../../confirm';

const AppLevelTable = props => {
    const { monitor, auth } = props;
    const { appparam } = monitor;

    let tableInfoObj = null;
    let dataTable = null;
    let cModal = null;
  
    if (appparam.list && appparam.list.length > 0) {
        tableInfoObj = prepTableInfo(appparam.list);
        if (tableInfoObj) {
            const rowClickHandler = (evt) => { props.select(monitor, evt); };
            const deactivateHandler = (evt) => { props.deactivate(monitor, evt); };

            const tableDisplayParams = {
                name: 'appparams'
              , hideFields: ['stb_level', 'stb_count', 'created', 'created_id', 'modified', 'modified_id']
              , allowOptions: {
                    edit: { allowed: true, handler: rowClickHandler }
                  , delete: { allowed: true, handler: deactivateHandler }
                  , select: { allowed: false, handler: null }
                }
            };
            dataTable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} />;    
        }
    }

    if (appparam.deactivate) {
        let confirmOpts = confirmOptions();
        const deactivateProceedHandler = () => { props.deactivateConfirm(monitor, auth); };
        const deactivateCancelHandler = () => { props.deactivateCancel(monitor); };
        confirmOpts.show = true;
        confirmOpts.title = 'Confirm Deactivate';
        confirmOpts.question = 'Are you sure you want to deactivate the parameter.';
        confirmOpts.handlers.proceed = deactivateProceedHandler;
        confirmOpts.handlers.cancel = deactivateCancelHandler;
        confirmOpts.proceed.title = 'Deactivate Parameter';
        confirmOpts.proceed.className = 'btn-danger';
        cModal = <ConfirmModal options={confirmOpts} />;
    }
        
    return(
        <div id="appleveltable">{dataTable}{cModal}</div>
    );
};

function mapStateToProps(state) {
    const monitor = state.monitor;
    const auth = state.authentication.user;
    return { monitor, auth };
}

const mapDispatchToProps = (dispatch) => ({
      select(monitor, evt) { dispatch(paramActions.setParam(monitor, evt)); }
    , deactivate(monitor, evt) { evt.stopPropagation(); dispatch(paramActions.appLevelDeactivateParam(monitor, evt)); }
    , deactivateCancel(monitor) { dispatch(paramActions.appLevelDeactivateCancel(monitor)); }
    , deactivateConfirm(monitor, auth) { dispatch(paramActions.appLevelDeactiveConfirm(monitor, auth)); }
  });

const connectedAppLevelTable = connect(mapStateToProps, mapDispatchToProps)(AppLevelTable);
export { connectedAppLevelTable as AppLevelTable };
