import React from 'react';
import { connect } from 'react-redux';

import { msgActions } from '../../../_actions';

const Rooms = props => {
  const { auth, monitor, dispatch } = props;
  if (!auth) return null;
  const { site, msg } = monitor;
  if (!site.stbs) return null;
  if (!msg) return null;
  if (!msg.list) return null;

  let direction = msg.sort ? msg.sort.selector.direction : null;
  let sortIcon = null;
  let filterIcon = null;
  if (direction) {
    if (direction === 'asc') {
      sortIcon = <i className="up ml-1"></i>;
    }
    else {
      sortIcon = <i className="down ml-1"></i>;
    }
  }

  if (msg.filter && msg.filter.length > 0) {
    filterIcon = <span><i className="fas fa-filter ml-1"></i></span>;
  }
  let tableItems = (msg.filter && msg.filter.length > 0) ? msg.filter : msg.list;
  let tableData = null;
  if (!props.disable) {
    tableData = tableItems.map(function(item, idx) {
      if (msg && msg.rooms) {
        let rooms = msg.rooms;
        for (let i = 0, j = rooms.length; i < j; i++) {
          if (item.room_number === rooms[i].room_number) {
            return (
              <tr key={'rl' + idx} className="roomlistSelected">
                <td style={{width:"140px"}}>{item.room_number}</td>
                <td style={{width:"100px", textAlign:"center"}}>{item.check_in_flag}</td>
              </tr>);
          }
        }
      }
      return (
        <tr key={'rl' + idx} onClick={() => { props.select(monitor, idx); }} className="trselectable">
          <td style={{width:"140px"}}>{item.room_number}</td>
          <td style={{width:"100px", textAlign:"center"}}>{item.check_in_flag}</td>
        </tr>);
    });
  }
  else {
    tableData = tableItems.map(function(item, idx) {
      return (
        <tr key={'rl' + idx} className="trselectable">
          <td style={{width:"140px"}}>{item.room_number}</td>
          <td style={{width:"100px", textAlign:"center"}}>{item.check_in_flag}</td>
        </tr>);
    });
  }


  return (
    <div className={props.cssClass}>
      <table id="roomlist" className="roomlist">
        <thead>
          <tr>
            <th className="thf thsort" style={{width:"140px"}} onClick={() => { props.sort(monitor); }} data-toggle="tooltip" data-placement="top" title="Sort">Room{sortIcon}</th>
            <th className="thf thsort" style={{width:"100px", textAlign:"center"}} onClick={() => { props.filter(monitor); }} data-toggle="tooltip" data-placement="top" title="Filter for Checked In">Checked In{filterIcon}</th>
          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </table>
    </div>
  );
};

function mapStateToProps(state) {
  const auth = state.authentication.user;
  const monitor = state.monitor;
  return { auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    sort(monitor) { dispatch(msgActions.selectorSort(monitor)); }
  , filter(monitor) { dispatch(msgActions.filter(monitor)); }
  , select(monitor, idx) { dispatch(msgActions.selectRoom(monitor, idx)); }
});

const connectedRooms = connect(mapStateToProps, mapDispatchToProps)(Rooms);
export { connectedRooms as Rooms };
