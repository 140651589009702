import React from 'react';

import { config } from '../../../_helpers';

export const ScriptCommandButtons = props => {
    const { siteId, handler } = props;
    if (siteId !== 'C01538' && siteId !== 'X3003') return null;
    const btns = config.scriptButtons[siteId];
    if (!btns) return null;

    let disp = btns.map((btn, idx) => {
        return <button key={`scriptcmdbtn${idx}`} type="button" onClick={handler} data-scriptidx={idx} className="btn btn-primary ml-3" disabled={props.processing}>{btn.text}</button>
    });

    return (
        <div className='mb-3'>{disp}</div>
    );
};
