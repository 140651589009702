import React from "react";

import { WciModal } from '../../../wcimodal';
import { EndsView } from './ends';

export const WeeklyIteration = props => {
    const inputs = props.inputs;
    return(
        <WciModal show={true} className="baseModal">
            <div className="eventsIterationModal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Weekly Options</h5>                    
                        <button type="button"
                            className="close"
                            onClick={props.onclose}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" 
                                    type="radio" 
                                    id="iterationDay0" 
                                    name="iteration_day" 
                                    value="0" 
                                    onClick={props.onselect}
                                    checked={inputs && inputs.iteration_day === '0' ? true : false} />
                            <label className="form-check-label" htmlFor="iterationDay0">Sunday</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" 
                                    type="radio" 
                                    id="iterationDay1" 
                                    name="iteration_day" 
                                    value="1" 
                                    onClick={props.onselect}
                                    checked={inputs && inputs.iteration_day === '1' ? true : false} />
                            <label className="form-check-label" htmlFor="iterationDay1">Monday</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" 
                                    type="radio" 
                                    id="iterationDay2" 
                                    name="iteration_day" 
                                    value="2" 
                                    onClick={props.onselect}
                                    checked={inputs && inputs.iteration_day === '2' ? true : false} />
                            <label className="form-check-label" htmlFor="iterationDay2">Tuesday</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" 
                                    type="radio" 
                                    id="iterationDay3" 
                                    name="iteration_day" 
                                    value="3" 
                                    onClick={props.onselect}
                                    checked={inputs && inputs.iteration_day === '3' ? true : false} />
                            <label className="form-check-label" htmlFor="iterationDay3">Wednesday</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" 
                                    type="radio" 
                                    id="iterationDay4" 
                                    name="iteration_day" 
                                    value="4" 
                                    onClick={props.onselect}
                                    checked={inputs && inputs.iteration_day === '4' ? true : false} />
                            <label className="form-check-label" htmlFor="iterationDay4">Thursday</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" 
                                    type="radio" 
                                    id="iterationDay5" 
                                    name="iteration_day" 
                                    value="5" 
                                    onClick={props.onselect}
                                    checked={inputs && inputs.iteration_day === '5' ? true : false} />
                            <label className="form-check-label" htmlFor="iterationDay5">Friday</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" 
                                    type="radio" 
                                    id="iterationDay6" 
                                    name="iteration_day" 
                                    value="6" 
                                    onClick={props.onselect}
                                    checked={inputs && inputs.iteration_day === '6' ? true : false} />
                            <label className="form-check-label" htmlFor="iterationDay6">Saturday</label>
                        </div>
                        <EndsView {...props} />
                    </div>
                    <div className="modal-footer">
                        <button type="button"
                                className="btn btn-primary"
                                onClick={props.onclose}>Done</button>
                    </div>
                </div>
            </div>
        </WciModal>
    );
};