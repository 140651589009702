import { config, getApiUrl } from '../_helpers';

export const statsService = {
    general
  , resv
  , network
};

function general(id, from, to) {
  var url = getApiUrl() + 'site/stats/general/' + id + '/' + from + '/' + to;

  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response.json().then(data => data));
        }
        else if (response.status === 404) {
          reject('There was an error looking for the site stats.');
        }
        else {
          reject(response.statusText);
        }
      })
      .catch(response => {
        reject(response.message);
      });
  });
}

function resv(id, on) {
  var url = getApiUrl() + 'site/stats/resv/' + id + '/' + on;

  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response.json().then(data => data));
        }
        else if(response.status === 404) {
          reject('There was an error looking for the reservation stats.');
        }
        else { reject(response.statusText); }
      })
      .catch(response => { reject(response.message); });
  });
}

function network(id, interval) {
  var duration = config.networkGraphIntervals[interval];
  var url = getApiUrl() + 'site/stats/network/' + id + '/' + duration;

  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'include'
    })
    .then(response => {
      if (response.status === 200) {
        resolve(response.blob().then(data => data));
      }
      else if (response.status === 404) {
        reject('There was an error looking for the site stats.');
      }
      else {
        reject(response.statusText);
      }
    })
      .catch(response => {
        reject(response.message);
      });
  });
}
