import React from 'react';
import { connect } from 'react-redux';

import { alertActions, siteUserActions, siteActions } from '../../../_actions';
import { history, permissionCheck, prepTableInfo, calcTotalPages, confirmOptions, isObjectEmpty } from '../../../_helpers';

import { NavigationBar } from '../../navigation';
import { ProcessIndicator } from '../../processIndicator';
import { Pagination } from '../../pagination';
import { DataTable } from '../../dataTable';
import { ConfirmModal } from '../../confirm';

import { SiteTabs } from '../siteTabs';
import { SiteData } from '../siteData';

class SiteUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
              authorized: false
            , allowDelete: false
            , showSiteIp: false
            , showSiteList: false
            , tableRows: 10
            , showConfirm: false
            , deleting: null
            , deleteIndex: null
        };

        this.onSiteSelect = this.onSiteSelect.bind(this);
        this.onSiteDisplay = this.onSiteDisplay.bind(this);
        this.onSiteDisplayClose = this.onSiteDisplayClose.bind(this);
        this.onPageClick = this.onPageClick.bind(this);
        this.onPaginationCountChange = this.onPaginationCountChange.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.onConfirmCancel = this.onConfirmCancel.bind(this);
        this.onSortClick = this.onSortClick.bind(this);
    }

    componentDidMount() {
        const { auth, unauthorized, monitor, get } = this.props;
        const { authorized } = this.state;
        const { site } = monitor;
        if(auth && !authorized) {
            if (permissionCheck(auth.permissions, 'portal.site.users')) {
                const allowDel = permissionCheck(auth.permissions, 'portal.site.users.delete');
                const showSiteIp = permissionCheck(auth.permissions, 'portal.sites.link'); // Permission to determine if site link displayed
                this.setState({ authorized: true, allowDelete: allowDel, showSiteIp: showSiteIp });
                if(site.edit && !site.users) {
                    get(monitor);
                }
            }
            else {
                unauthorized();
                history.push('/portal/site');
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { auth, unauthorized, monitor, alert, clearAlert } = this.props;
        const { authorized } = this.state;
        const { site } = monitor;
        if (auth && !authorized) {
            if (permissionCheck(auth.permissions, 'portal.site.users')) {
                const allowDel = permissionCheck(auth.permissions, 'portal.site.users.delete');
                const showSiteIp = permissionCheck(auth.permissions, 'portal.sites.link'); // Permission to determine if site link displayed
                this.setState({ authorized: true, allowDelete: allowDel, showSiteIp: showSiteIp });
                if(site.edit && !site.users) {
                    get(monitor);
                }
            }
            else {
                unauthorized();
                history.push('/portal/site');
            }
        }
        if (!isObjectEmpty(alert)) window.setTimeout(clearAlert, 5000);
    }

    render() {
        const { auth, monitor, alert } = this.props;
        const { showSiteIp, showSiteList, tableRows, allowDelete, showConfirm, deleting } = this.state;
        if (!auth) return null;
        const { sites, site } = monitor;
        const { users } = site;

        let processIndShow = users && users.processing ? users.processing : false;
        let headerText = 'Monitor - Site - Users';
        if (site.edit) {
            headerText = 'Monitor - Site: ' + site.edit.site_id + ' - Users';
        }

        const list = (users && users.list && users.list.length > 0) ? users.list : null;
        let pagination = null;
        let datatable = null;
        let cmodal = null;
        if (list) {
            const rowCount = users.tableRows ? users.tableRows : tableRows;
            const currPage = users.page ? users.page : 1;
            const totalpages = calcTotalPages(list.length, rowCount);
            const tableInfoObj = prepTableInfo(list);

            let pageParams = {
                  rowCount: rowCount
                , dataPage: currPage
                , pages: totalpages
                , handler: this.onPageClick
                , selectcount: true
                , numselectHandler: this.onPaginationCountChange
                , hasBtn: true
            };
            const tableDisplayParams = {
                name: 'users'
              , hideFields: ['user_id', 'site_id', 'created_first_name', 'created_id', 'created_last_name']
              , allowOptions: {
                    edit: { allowed: false, handler: null }
                  , delete: { allowed: allowDelete, handler: this.onDeleteClick }
                  , select: { allowed: false, handler: null }
                }
              , pagination: {
                    rowCount: pageParams.rowCount
                  , dataPage: pageParams.dataPage
                }
/*              , sorting: {
                    columns: ['first_name', 'last_name', 'created', 'last_login']
                  , handler: this.onSortClick
                  , activeSort: (events.sort) ? events.sort.field : null
                  , direction: (events.sort) ? events.sort.direction : null
                }*/
              , columnHiding: {
                    xs: ['created']
                  , sm: ['modified']
                  , md: ['created']
                }
            };
            datatable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} />;
            pagination = <Pagination data={tableInfoObj.data} pageParams={pageParams} />;
        }

        if (showConfirm) {
            let confirmOpts = confirmOptions();
            confirmOpts.show = true;
            confirmOpts.title = 'Confirm Delete';
            confirmOpts.question = 'Are you sure you want to delete: ' + deleting.first_name + ' ' + deleting.last_name;
            confirmOpts.handlers.proceed = this.onConfirmDelete;
            confirmOpts.handlers.cancel = this.onConfirmCancel;
            confirmOpts.proceed.title = 'Delete';
            confirmOpts.proceed.className = 'btn-danger';
            cmodal = <ConfirmModal options={confirmOpts} />;
        }

        return(
            <div id="siteUsers">
                <NavigationBar header={headerText} />
                <div className="container-fluid">
                    <SiteTabs active="users" />
                    <SiteData site={site.edit} more={false} showSiteIp={showSiteIp} siteList={sites.list} showList={showSiteList} clickHandler={this.onSiteSelect} displayHandler={this.onSiteDisplay} closeHandler={this.onSiteDisplayClose} />
                    <ProcessIndicator show={processIndShow} />
                    {alert.message &&
                        <div className={`alert ${alert.type}`}>{alert.message}</div>
                    }
                </div>
                <div className="row">
                    <div className="col-sm paginationWBtn2 row justify-content-end mr-1">{pagination}</div>
                </div>
                <div className='ml-3 mr-3 mt-3'>{datatable}</div>
                {cmodal}
            </div>
        );
    }

    onSiteSelect(evt) {
        let siteIdx = evt.currentTarget.attributes['data-idx'].value;
        this.setState({ showSiteList: false });
        const { monitor, getSite, alert, clearAlert } = this.props;
        if (!isObjectEmpty(alert)) clearAlert();
        let selectedsite = monitor.sites.list[siteIdx];
        getSite(monitor, selectedsite);
    }

    onSiteDisplay() {
        this.setState({ showSiteList: true });
        const { alert, clearAlert } = this.props;
        if (!isObjectEmpty(alert)) clearAlert();
    }

    onSiteDisplayClose() {
        this.setState({ showSiteList: false });
        const { alert, clearAlert } = this.props;
        if (!isObjectEmpty(alert)) clearAlert();    
    }

    onPageClick(evt) {
        const pagenum = parseInt(evt.currentTarget.attributes['data-pagenumber'].value);
        const { monitor, page, alert, clearAlert } = this.props;
        page(monitor, pagenum);
        if (!isObjectEmpty(alert)) clearAlert();
    }

    onPaginationCountChange(evt) {
        const { value } = evt.target;
        this.setState({ tableRows: value });
    }

    onDeleteClick(evt) {
        evt.stopPropagation();
        var idx = parseInt(evt.currentTarget.attributes['data-index'].value);
        const { monitor, sendErr, alert, clearAlert } = this.props;
        const { site } = monitor;
        const { users } = site;
        const list = users.list;
        let obj = null;
        if (idx >= 0) {
            obj = list[idx];
        }
        if (!obj) {
            sendErr('Item data not found');
            return;
        }
        this.setState({ showConfirm: true, deleting: obj, deleteIndex: idx });
        if (!isObjectEmpty(alert)) clearAlert();
    }

    onConfirmDelete() {
        const { monitor, remove, alert, clearAlert } = this.props;
        const { deleting,  deleteIndex } = this.state;
        if (!deleting) return;
        remove(monitor, deleting, deleteIndex);
        this.setState({ showConfirm: false, deleting: null, deleteIndex: null });
        if (!isObjectEmpty(alert)) clearAlert();
    }

    onConfirmCancel() {
        this.setState({ showConfirm: false, deleting: null, deleteIndex: null });
    }
    
    onSortClick(evt) {
        let field = evt.currentTarget.attributes['data-field'].value;
        const { monitor, sort, alert, clearAlert } = this.props;
        sort(monitor, field);
        if (!isObjectEmpty(alert)) clearAlert();
    }
};

function mapStateToProps(state) {
    const alert = state.alert
    const auth = state.authentication.user;
    const monitor = state.monitor
    return { alert, auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
      unauthorized() { dispatch(alertActions.error('You are not authorized for that page.')); }
    , clearAlert() { dispatch(alertActions.clear()); }
    , get(monitor) { dispatch(siteUserActions.get(monitor)); }
    , getSite(monitor, site) { dispatch(siteActions.get(monitor, site, false)); }
    , page(monitor, page) { dispatch(siteUserActions.page(monitor, page)); }
    , sendErr(msg) { dispatch(alertActions.error(msg)); }
    , remove(monitor, obj, idx) { dispatch(siteUserActions.delete(monitor, obj, idx)); }
    , sort(monitor, field) { dispatch(siteUserActions.sort(monitor, field)); }
});

const connectedSiteUsers = connect(mapStateToProps, mapDispatchToProps)(SiteUsers);
export { connectedSiteUsers as SiteUsers };
