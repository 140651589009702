import React from 'react';

export const EventEditNewForm = props => {
    const processing = props.processing;
    const changeHandler = props.changeHandler;
    const checkHandler = props.checkHandler;
    const validity = props.validity;
    const inputs = props.inputs;
    const dtmin = props.dtmin;
    const isAllDay = inputs && inputs.all_day === '1' ? true : false;
    let startDt = <input type="date" id="startdate" name="start_date" min={dtmin} pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}" className={'col-md-2 form-control' + (validity ? validity.start_date ? '' : ' is-invalid' : '')} disabled={processing} onChange={changeHandler} required />;
    if (inputs && inputs.start_date){
        startDt = null;
        startDt = <input type="date" id="startdate" name="start_date" pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}" className={'col-md-2 form-control' + (validity ? validity.start_date ? '' : ' is-invalid' : '')} value={inputs && inputs.start_date ? inputs.start_date : ''} disabled={processing} onChange={changeHandler} required />;
    }
    let enddt = <input type="date" id="enddate" name="end_date" min={dtmin} pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}" className={'col-sm-2 form-control' + (validity ? validity.end_date ? '' : ' is-invalid' : '')} value={inputs && inputs.end_date ? inputs.end_date : ''} disabled={processing || isAllDay} onChange={changeHandler} />;
    if (inputs && inputs.end_date) {
        enddt = null;
        enddt = <input type="date" id="enddate" name="end_date" pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}" className={'col-sm-2 form-control' + (validity ? validity.end_date ? '' : ' is-invalid' : '')} value={inputs && inputs.end_date ? inputs.end_date : ''} disabled={processing || isAllDay} onChange={changeHandler} />;
    }


    return(
        <div>
            <form onSubmit={props.onSubmit} className="mt-5">
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label text-right"></label>
                    <div className="form-check mt-2">
                        <input type="checkbox" className="form-check-input" id="active" name="active" checked={inputs && inputs.active === '1' ? true : false} onChange={checkHandler}></input>
                        <label className="form-check-label" for="active">Active?</label>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label text-right">Title:</label>
                    <input type="text"
                        id="title"
                        name="title"
                        className={'col-sm-6 form-control' + (validity ? validity.title ? '' : ' is-invalid' : '')}
                        placeholder="Title"
                        onChange={changeHandler}
                        disabled={processing}
                        value={inputs && inputs.title ? inputs.title : ''} 
                        required />
                    {validity && !validity.valid && !inputs.title &&
                        <div className='offset-sm-2 invalid-feedback'>Title is required</div>
                    }
                </div>
                <div className="form-group row">
                    <label htmlFor="startdate" className="col-md-2 col-form-label text-right">Start Date:</label>
                    {startDt}
                    {validity && !validity.valid && !inputs.start_date &&
                        <div className='offset-sm-2 invalid-feedback'>Start Date is required</div>
                    }
                    <label htmlFor="starttime" className="col-sm-2 col-form-label text-right">Start Time:</label>
                    <input type="time"
                        id="starttime"
                        name="start_time"
                        className={'col-sm-2 form-control' + (validity ? validity.start_time ? '' : ' is-invalid' : '')}
                        value={inputs && inputs.start_time ? inputs.start_time : ''}
                        disabled={processing}
                        onChange={changeHandler} />
                    {validity && !validity.valid && !inputs.start_time &&
                        <div className='offset-sm-6 invalid-feedback'>Start Time is required</div>
                    }
                    <div className="form-check ml-4 mt-2">
                        <input type="checkbox" className="form-check-input" id="all_day" name="all_day" checked={isAllDay} onChange={checkHandler}></input>
                        <label className="form-check-label" for="all_day">All Day?</label>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="enddate" className="col-sm-2 col-form-label text-right">End Date:</label>
                    {enddt}
                    {validity && !validity.valid && validity.end_date_err &&
                        <div className='offset-sm-2 invalid-feedback'>{validity.end_date_err}</div>
                    }
                    <label htmlFor="endtime" className="col-sm-2 col-form-label text-right">End Time:</label>
                    <input type="time"
                        id="endtime"
                        name="end_time"
                        className={'col-sm-2 form-control' + (validity ? validity.end_time ? '' : ' is-invalid' : '')}
                        value={inputs && inputs.end_time ? inputs.end_time : ''}
                        disabled={processing || isAllDay}
                        onChange={changeHandler} />
                </div>
                <div className="form-group row">
                    <label htmlFor="location" className="col-sm-2 col-form-label text-right">Location:</label>
                    <input type="text"
                        id="location"
                        name="location"
                        className={'col-sm-6 form-control' + (validity ? validity.location ? '' : ' is-invalid' : '')}
                        placeholder="Location"
                        disabled={processing}
                        onChange={changeHandler}
                        value={inputs && inputs.location ? inputs.location : ''} 
                        required />
                    {validity && !validity.valid && !inputs.location &&
                        <div className='offset-sm-2 invalid-feedback'>Location is required</div>
                    }
                </div>
                <div className="form-group row">
                    <label htmlFor="description" className="col-sm-2 col-form-label text-right">Description:</label>
                    <input type="text"
                        id="description"
                        name="description"
                        className="col-sm-8 form-control"
                        placeholder="Not Required"
                        disabled={processing}
                        onChange={changeHandler}
                        value={inputs && inputs.description ? inputs.description : ''} />
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label text-right"></label>
                    <div className="form-check mt-2">
                        <input type="checkbox" className="form-check-input mt-2" id="recurrence" name="recurrence" checked={inputs && inputs.recurrence === '1' ? true : false} onChange={checkHandler}></input>
                        <label className="form-check-label" for="recurrence">Repeating Event?</label>
                        {inputs && inputs.recurrence === '1' && 
                            <button type="button" className="btn btn-light left-margin-10" disabled={processing} onClick={props.modifyRecurrence}>Modify</button>
                        }
                        {validity && !validity.valid && !validity.iteration &&
                            <div className="alert alert-danger" role="alert">{validity.iteration_err}</div>
                        }
                        {validity && !validity.valid && !validity.ends_date &&
                            <div className="alert alert-danger" role="alert">{validity.ends_date_err}</div>
                        }
                        {validity && !validity.valid && !validity.ends_count &&
                            <div className="alert alert-danger" role="alert">{validity.ends_count_err}</div>
                        }
                    </div>
                </div>
                <div className="form-group">
                    <button type="submit" className="btn btn-primary" disabled={processing}>
                        <span>Submit</span>
                        {processing &&
                            <div className="d-inline fa-2x left-margin-10"><i className="fas fa-spinner fa-pulse"></i></div>
                        }
                    </button>
                    <button type="button" className="btn btn-secondary left-margin-10" disabled={processing} onClick={props.onCancel}>Exit</button>
                </div>
            </form>
        </div>
    );
};
