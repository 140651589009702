import React from 'react';
import { prepTableInfo } from '../../../_helpers';

import { DataTable } from '../../dataTable';

export const DuplicateStbs = props => {
    const { site } = props;
    const { dups } = site;

    if (!dups) {
        return <div>No Duplicate Room Numbers Found</div>;
    }

    // Format the data to be readable
    let d = [];
    for (let i = 0, j = dups.length; i < j; i++) {
        const el = dups[i];
        if (d.length > 0) {
            const del = d.find(itm => {
                return itm.room === el.room_number;
            });
            if (del) {
                del.serial_numbers += ', ' + el.serial_number;
            }
            else {
                d.push({room: el.room_number, serial_numbers: el.serial_number, created: "yes"});
            }
        }
        else {
            d.push({room: el.room_number, serial_numbers: el.serial_number, created: "yes"});
        }
    }

    let dataTable = null;
    const tableInfoObj = prepTableInfo(d);
    // hideFields is required as a dataTable param. A lack of foresight on my part.
    // Also, do not use the room_number for a column name when avoidable. It has special handling in the dataTable
    const tableDisplayParams = {
          hideFields: ['created']
        , allowOptions: {
                edit: { allowed: false, handler: null }
            , delete: { allowed: false, handler: null }
            , select: { allowed: false, handler: null }
         }
    };
    dataTable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} pagination={null} />;

    return (
        <div>
            <div>
                Duplicate Room Numbers: {dups.length}
                <button key="btndupsmore" type="button" className="btn btn-info ml-2" data-toggle="collapse" data-target="#dupslist" aria-expanded="false" aria-controls="dupslist">Toggle Duplicates</button>
            </div>
            <div className='collapse' id='dupslist'>{dataTable}</div>
        </div>
    );
};
