import React from 'react';
import { connect } from 'react-redux';

import { alertActions, sitesActions, siteActions } from '../../../_actions';
import { permissionCheck, history, isObjectEmpty } from '../../../_helpers';

import { NavigationBar } from '../../navigation';
import { ProcessIndicator } from '../../processIndicator';

import { SiteData } from '../siteData';
import { SiteTabs } from '../siteTabs';

import { StreamingChannels } from './chan';

class StreamingMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        authorized: false
      , showSiteIp: false
      , showSiteList: false
    };

    this.onSiteSelect = this.onSiteSelect.bind(this);
    this.onSiteDisplay = this.onSiteDisplay.bind(this);
    this.onSiteDisplayClose = this.onSiteDisplayClose.bind(this);
  }

  componentDidMount() {
    const { auth, unauthorized, monitor, getSiteList } = this.props;
    const { authorized } = this.state;
    if (auth) {
      const hasPermission = permissionCheck(auth.permissions, 'portal.streaming');
      const showSiteIp = permissionCheck(auth.permissions, 'portal.sites.link'); // Permission to determine if site link displayed
      if (hasPermission) {
        this.setState({ authorized: true, showSiteIp: showSiteIp });
        const { sites } = monitor;
        if (!sites.list) {
          getSiteList(monitor);
        }
      }
      else {
        unauthorized();
        history.push('/');
      }
    }
  }

  componentDidUpdate() {
    const { auth, unauthorized, monitor, getSiteList, alert, clearAlert } = this.props;
    const { authorized } = this.state;
    const { sites, site } = monitor;
    if (auth && !authorized) {
      const hasPermission = permissionCheck(auth.permissions, 'portal.streaming');
      if (hasPermission) {
        this.setState({ authorized: true });
        if (!sites.list) {
          getSiteList(monitor);
        }
      }
      else {
        unauthorized();
        history.push('/');
      }
    }
    if (!isObjectEmpty(alert)) window.setTimeout(clearAlert, 5000);
  }

  render() {
    const { auth, monitor, alert } = this.props;
    if (!auth) return null;
    const { authorized, showSiteIp, showSiteList } = this.state;
    const { sites, site } = monitor;
    const viewtype = this.props.match.params.disp ? this.props.match.params.disp : null;

    let headerText = 'Monitor - Site - Streaming';
    let processIndShow = sites.processing ? sites.processing : false;

    if (sites.list) {
      if (site.edit) {
        headerText = 'Monitor - Site: ' + site.edit.site_id + ' - Streaming';
      }
    }

    return (
      <div id="streaming">
        <NavigationBar header={headerText} />
        <ProcessIndicator show={processIndShow} />
        <div className="container-fluid">
          <SiteTabs active="streaming" />
          <SiteData site={site.edit} more={false} showSiteIp={showSiteIp} siteList={sites.list} showList={showSiteList} clickHandler={this.onSiteSelect} displayHandler={this.onSiteDisplay} closeHandler={this.onSiteDisplayClose} />
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
          <StreamingChannels authorized={authorized} pause={showSiteList} viewtype={viewtype} />
        </div>
      </div>
    );
  }

  onSiteSelect(evt) {
    let siteIdx = evt.currentTarget.attributes['data-idx'].value;
    this.setState({ showSiteList: false });
    const { monitor, getSite, alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    let selectedsite = monitor.sites.list[siteIdx];
    getSite(monitor, selectedsite);
  }

  onSiteDisplay() {
    this.setState({ showSiteList: true });
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) this.props.clearAlert();
  }

  onSiteDisplayClose() {
    this.setState({ showSiteList: false });
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) this.props.clearAlert();
  }
}

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const monitor = state.monitor;
  return { alert, auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    unauthorized() { dispatch(alertActions.error('You are not authorized for that page.')); }
  , getSiteList(monitor) { dispatch(sitesActions.list(monitor)); }
  , clearAlert() { dispatch(alertActions.clear()); }
  , getSite(monitor, site) { dispatch(siteActions.get(monitor, site, false)); }
});

const connectedStreamingMain = connect(mapStateToProps, mapDispatchToProps)(StreamingMain);
export { connectedStreamingMain as StreamingMain };
