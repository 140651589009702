import React from 'react';
import { connect } from 'react-redux';
import { WciModal } from '../wcimodal';

import { alertActions, siteActions } from '../../_actions';
import { config } from '../../_helpers';

import { ProcessIndicator } from '../processIndicator';

const RoomNumberChanger = props => {
    const { alert, monitor, dispatch } = props;
    const { site } = monitor;
    const { stb } = site;
    const { rmChgObj } = stb; // object where all data on this form is kept;

    const isPublic = rmChgObj.isPublic ? rmChgObj.isPublic : false;
    const rmnum = rmChgObj.rmnum ? rmChgObj.rmnum : '';
    const locationslist = isPublic ? config.locations.publicspace : config.locations.guestroom;
    const locidx = rmChgObj.locationidx ? rmChgObj.locationidx : 0;
    const locnumber = rmChgObj.locnum ? rmChgObj.locnum : '';
    const locnumStyle = {display: allowMultiple ? 'block' : 'none'};
    const location = rmChgObj.locationidx ? locationslist[parseInt(rmChgObj.locationidx)] : locationslist[0];
    const allowMultiple = isPublic ? isPublic : location ? location.allowMultiple : false;
    const locName = location ? location.data : '';

    let locations = locationslist.map(function(obj, idx) {
        return <option key={'loc' + idx} value={idx}>{obj.label.en}</option>
    });
  
    let processing = rmChgObj.saving;
    let roomName = rmnum;
    if (rmChgObj.locationidx) {
      if (isPublic) roomName = locName; // public area location
      else roomName += '-' + locName; // guestroom location
    }
    roomName += locnumber;
  
    const cancelHandler = onCancel.bind(null, dispatch, monitor);
    const publicChangeHandler = onIsPublicChange.bind(null, dispatch, monitor);
    const numberChangeHandler = onRoomChangeInput.bind(null, dispatch, monitor);
    const locationChangeHandler = onLocationChange.bind(null, dispatch, monitor, isPublic);
    const locationnumberChangeHandler = onLocationNumberInput.bind(null, dispatch, monitor);
    const submitHandler = onRoomChangeSubmit.bind(null, dispatch, monitor, roomName);

    return (
        <WciModal show={true} className="baseModal">
            <div className="roomChangeModal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Change Room Number</h5>
                        <button type="button"
                            className="close"
                            onClick={cancelHandler}
                            disabled={processing}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <div>
                            {alert.message &&
                                <div className={`alert ${alert.type}`}>{alert.message}</div>
                            }
                            <ProcessIndicator show={processing} />
                        </div>
                        <p>Current Room Number: {rmChgObj.stb.room_number}</p>
                        <p>New Room Number: {roomName}</p>
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="public_question">Public TV</span>
                            </div>
                            <input type="checkbox" checked={isPublic} className="align-content-start ml-2 mt-2" aria-label="Public TV" aria-describedby="publictv" onChange={publicChangeHandler} />
                        </div>
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="new_room_number">Room Number</span>
                            </div>
                            <input type="text" className="form-control" aria-label="Room Number" aria-describedby="new_room_number" onChange={numberChangeHandler} value={rmnum} disabled={isPublic} />
                        </div>
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="tv_location">Location</span>
                            </div>
                            <select aria-label="Location" aria-describedby="tv_location" onChange={locationChangeHandler} value={locidx}>{locations}</select>
                        </div>
                        <div className="input-group input-group-sm mb-3">
                            <div style={locnumStyle}>A location number is not required, but is necessary when there is more than one TV within a space (room or public area) with the same designation.</div>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="location_number">Location Number</span>
                            </div>
                            <input type="number" className="form-control" min="1" max="50" aria-label="Location Number" aria-describedby="location_number" onChange={locationnumberChangeHandler} value={locnumber} disabled={!allowMultiple} />
                        </div>
                    </div>
                    <div className="modal-footer">
                    <button type="button"
                            className="btn btn-primary"
                            onClick={submitHandler}
                            disabled={processing}>
                    <span>Save</span>
                    {processing &&
                        <div className="d-inline fa-2x ml-1"><i className="fas fa-spinner fa-pulse"></i></div>
                    }
                    </button>
                    <button type="button"
                            className="btn btn-secondary"
                            onClick={cancelHandler}
                            disabled={processing}>Cancel</button>
                    </div>
        
                </div>
            </div>
        </WciModal>
    );
};

function mapStateToProps(state) {
    const alert = state.alert;
    const monitor = state.monitor;
    return { alert, monitor };
}

const connectedRoomNumberChanger = connect(mapStateToProps)(RoomNumberChanger);
export { connectedRoomNumberChanger as RoomNumberChanger };

function onCancel(dispatch, monitor) {
    dispatch(alertActions.clear());
    dispatch(siteActions.rcCancel(monitor));
}

function onIsPublicChange(dispatch, monitor, e) {
    dispatch(alertActions.clear());
    const { checked } = e.currentTarget;
    if (checked) {
        dispatch(alertActions.info('This will be a public space location.'));
    }
    dispatch(siteActions.rcpublic(monitor, checked));
}

function onRoomChangeInput(dispatch, monitor, e) {
  dispatch(alertActions.clear());
  const { value } = e.target;
  if (value.length > 0) {
      if (isNaN(value)) {
          dispatch(alertActions.error('Only numbers are accepted for room number.'));
          return;
      }
  }
  dispatch(siteActions.rcinput(monitor, value));
}

function onLocationChange(dispatch, monitor, isPublic, e) {
    dispatch(alertActions.clear());
    const { value } = e.target;
    const list = isPublic ? config.locations.publicspace : config.locations.guestroom;
    const idx = parseInt(value);
    let item = list[idx];
    dispatch(siteActions.rclocation(monitor, isPublic, idx));
}

function onLocationNumberInput(dispatch, monitor, e) {
  dispatch(alertActions.clear());
  const { value } = e.target;
  if (isNaN(value)) {
    dispatch(alertActions.error('Only numbers are accepted for location number.'));
    return;
  }
  if (value <= 0) {
    dispatch(alertActions.error('Must be a value greater than zero.'));
    return;
  }
  dispatch(siteActions.rcLocationNum(monitor, value));
}

function onRoomChangeSubmit(dispatch, monitor, roomName) {
  dispatch(alertActions.clear());
  if (roomName.length === 0) {
    return;
  }
  dispatch(siteActions.rcSave(monitor, roomName));
}
