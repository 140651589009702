import React from 'react';
import { connect } from 'react-redux';

import { alertActions, adminUserActions } from '../../../_actions';
import { permissionCheck, prepTableInfo, history, calcTotalPages, isObjectEmpty, confirmOptions } from '../../../_helpers';

import { DataTable } from '../../dataTable';
import { NavigationBar } from '../../navigation';
import { ProcessIndicator } from '../../processIndicator';
import { SimpleSearch } from '../../search';
import { ConfirmModal } from '../../confirm';

import { UserData } from '../userData';

import { AvailableRoles } from './availableRoles';

class UserRoles extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      showRoles: false, 
      roleRowCount: 10, 
      searchInput: '',
      delRoleIdx: null,
    };

    this.onCancelClick = this.onCancelClick.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onRoleSelect = this.onRoleSelect.bind(this);
    this.onAddCancel = this.onAddCancel.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchKeydown = this.onSearchKeydown.bind(this);
    this.onDeleteProceed = this.onDeleteProceed.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
  }

  componentDidMount() {
    const { auth, admin, list } = this.props;
    if (!auth) {
      history.push('/admin/users');
      return null;
    }
    const allowEdit = permissionCheck(auth.permissions, 'admin.user.edit');
    if (!allowEdit) {
      alertActions.error('You do not have permission to access that page.');
      history.push('/');
      return null;
    }
    list(admin);
  }

  render() {
    const { auth, admin, alert, pagerole } = this.props;
    if (!auth) return null;
    const allowEdit = permissionCheck(auth.permissions, 'admin.user.edit');
    if (!allowEdit) return null;
    if (!admin.user) return null;
    const { user } = admin;
    if (!user.edit) return null;
    const { edit } = user;
    const processIndShow = user.processing ? user.processing : false;

    let addDisabled = edit.roles.length > 0 ? true : processIndShow;
    let userRolesTable = null;
    let arModal = null;
    let confirmOpts = new confirmOptions();

    if (edit.roles && edit.roles.length > 0) {
        var tableInfoObj = prepTableInfo(edit.roles);
        var tableDisplayParams = {
            hideFields: ['user_id', 'role_id', 'created', 'created_id', 'created_user_name', 'role_created', 'role_modified'],
            allowOptions: {
                edit: { allowed: false, handler: null },
                delete: { allowed: true, handler: this.onDeleteClick },
                select: { allowed: false, handler: null }
            }
        };
        userRolesTable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} />;
    }

    if (this.state.showRoles && user.roles) {
        const roledata = user.found ? user.found : user.roles;
        const rolePaginationHandler = (evt) => { pagerole(admin, evt); };
        const { roleRowCount } = this.state;
        const rolelist = prepTableInfo(roledata);
        const rolepages = calcTotalPages(roledata.length, roleRowCount);
        let currRolePage = (user.rolepage) ? user.rolepage : 1;
        if (currRolePage > rolepages) currRolePage = rolepages;

        const rolePageParams = {
              rowCount: roleRowCount
            , dataPage: currRolePage
            , pages: rolepages
            , handler: rolePaginationHandler
        };
        const roleTableDisplayParams = {
            hideFields: ['role_id', 'created', 'created_id', 'created_user_name', 'modified', 'modified_id', 'modified_user_name'],
            allowOptions: {
                edit: { allowed: false, handler: this.onRoleSelect },
                delete: { allowed: false, handler: null },
                select: { allowed: false, handler: null }
            },
            pagination: {
                rowCount: roleRowCount,
                dataPage: rolePageParams.dataPage
            }
        };
        let availRoleSearch = <SimpleSearch submitHandler={this.onSearchSubmit}
                                            changeHandler={this.onSearchChange}
                                            searchValue={this.state.searchInput}
                                            searching={user.roleSearch}
                                            searchDisable={user.roleSearch}
                                            keydownHandler={this.onSearchKeydown} />;
        let availRolesTable = <DataTable data={rolelist.data} header={rolelist.header} displayParams={roleTableDisplayParams} pagination={rolePageParams} />;
        arModal = <AvailableRoles show={true} title="Roles Available to User" table={availRolesTable} search={availRoleSearch} cancel={this.onAddCancel} />;
    }

    if (this.state.delRoleIdx !== null) {
      confirmOpts.show = true;
      confirmOpts.title = 'Confirm Delete';
      confirmOpts.question = 'Are you sure you want to delete the role?';
      confirmOpts.handlers.proceed = this.onDeleteProceed;
      confirmOpts.handlers.cancel = this.onDeleteCancel;
      confirmOpts.proceed.title = 'Delete';
      confirmOpts.proceed.className = 'btn-danger';
    }

    return(
      <div>
        {arModal}
        <NavigationBar header="Admin - Users - Assign Role" />
        <div className="container">
          <UserData item={edit} more={false} />
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
          <ProcessIndicator show={processIndShow} />
          <button type="button"
                  className="btn btn-success "
                  disabled={addDisabled}
                  onClick={this.onAddClick}>
              <span>+ Add</span>
          </button>
          {userRolesTable}
          <div>
            <button type="button"
                    className="btn btn-secondary"
                    disabled={processIndShow}
                    onClick={this.onCancelClick}>Close</button>
          </div>
        </div>
        <ConfirmModal options={confirmOpts} />
      </div>
    );
  }

  onCancelClick() {
      const { admin, cancel, alert, clearAlerts } = this.props;
      if (!isObjectEmpty(alert)) clearAlerts();
      cancel(admin);
  }

  onAddClick() {
      const { alert, clearAlerts } = this.props;
      if (!isObjectEmpty(alert)) clearAlerts();
      this.setState({ showRoles: true });
  }

  onDeleteClick(evt) {
      evt.stopPropagation();
      var idx = parseInt(evt.currentTarget.attributes['data-index'].value);
      this.setState({ delRoleIdx: idx });
  }

  onRoleSelect(e) {
      const { admin, select, alert, clearAlerts } = this.props;
      var idx = parseInt(e.currentTarget.attributes['data-index'].value);
      if (!isObjectEmpty(alert)) clearAlerts();
      select(admin, idx);
      this.setState({ showRoles: false });
  }

  onAddCancel() {
      const { alert, clearAlerts } = this.props;
      if (!isObjectEmpty(alert)) clearAlerts();
      this.setState({ showRoles: false });
  }

  onSearchSubmit(evt) {
      if (evt) evt.preventDefault();
      const { admin, alert, search, clearAlerts } = this.props;
      const { searchInput } = this.state;
      if (!isObjectEmpty(alert)) this.props.clearAlerts();
      search(admin, searchInput);
  }

  onSearchChange(evt) {
      const { value } = evt.target;
      this.setState({ searchInput: value });
      const { alert, clearAlerts } = this.props;
      if (!isObjectEmpty(alert)) clearAlerts();
  }

  onSearchKeydown(evt) {
      if (evt.keyCode === 13) {
          evt.preventDefault();
          evt.stopPropagation();
          this.onSearchSubmit();
      }
  }

  onDeleteProceed() {
      const { admin, remove, alert, clearAlerts } = this.props;
      if (!isObjectEmpty(alert)) clearAlerts();
      remove(admin, this.state.delRoleIdx);
      this.setState({ delRoleIdx: null });
  }

  onDeleteCancel() {
    this.setState({ delRoleIdx: null });
  }
};

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const admin = state.administration
  return { alert, auth, admin };
}

const mapDispatchToProps = (dispatch) => ({
    clearAlerts() { dispatch(alertActions.clear()); }
  , list(admin) { dispatch(adminUserActions.getAvailableRoles(admin)); }
  , cancel(admin) { dispatch(adminUserActions.cancelAvailableRoles(admin)); }
  , select(admin, roleidx) { dispatch(adminUserActions.addRole(admin, roleidx)); }
  , remove(admin, roleidx) { dispatch(adminUserActions.deleteRole(admin, roleidx)); }
  , pagerole(admin, evt) { dispatch(adminUserActions.rolePage(admin, evt)); }
  , search(admin, searchInput) { dispatch(adminUserActions.roleSearch(admin, searchInput)); }
});

const connectedUserRoles = connect(mapStateToProps, mapDispatchToProps)(UserRoles);
export { connectedUserRoles as UserRoles };
