import { navConstants } from '../_constants';
import { alertActions } from './';
import { history, getComponentFromPath } from '../_helpers';
import { trackingService } from '../_services';

export const navActions = {
    navigate
  , notify
};

function navigate(path) {
  return dispatch => {
    dispatch(request());
    dispatch(alertActions.clear());
    history.push(path);
  };
  function request() { return { type: navConstants.NAV_REQUEST }; }
}

function notify(action, path) {
  const componentName = getComponentFromPath(path);
  const d = { component: componentName };
  const ds = JSON.stringify(d);
  trackingService.trackNavigation(ds)
    .then(
      result => { /* really nothing to do here. */ },
      error => { /* really nothing to do here. */ }
    );
}
