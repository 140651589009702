import React from 'react';
import { connect } from 'react-redux';

import { NavigationBar } from '../_components';
import { PageContact } from '../_components';

class ContactPage extends React.Component {
  render() {
    return (
      <div>
        <NavigationBar header="Contact World Cinema" />
        <PageContact />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = (dispatch) => ({
});

const connectedContactPage = connect(mapStateToProps, mapDispatchToProps)(ContactPage);
export { connectedContactPage as ContactPage };
