import React from 'react';
import { connect } from 'react-redux';

import { alertActions, sitesActions, statsActions, siteActions } from '../../../_actions';

import { NavigationBar } from '../../navigation';
import { ProcessIndicator } from '../../processIndicator';

import { SiteData } from '../siteData';
import { SiteTabs } from '../siteTabs';

class NetworkStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showSiteList: false, getnetworkimage: true };

    this.onSiteDisplay = this.onSiteDisplay.bind(this);
    this.onSiteDisplayClose = this.onSiteDisplayClose.bind(this);
    this.onSiteSelect = this.onSiteSelect.bind(this);
    this.getNetworkStats = this.getNetworkStats.bind(this);
  }

  componentDidMount() {
    const { getSiteList, monitor } = this.props;
    if (!monitor.sites.list) {
      getSiteList(monitor);
    }
    else {
      if (monitor.site.edit && !monitor.stats.network) {
        this.getNetworkStats();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { monitor } = this.props;
    if (monitor.site.edit && !monitor.stats.network && this.state.getnetworkimage) {
      this.getNetworkStats();
    }
  }

  render() {
    const { alert, auth, monitor } = this.props;
    if (!auth) return null;
    const { sites, site, stats } = monitor;
    let img = null;
    let img2 = null;
    let img3 = null;
    let headerText = 'Monitor - Site - Network';
    let processing = false;
    if (sites.processing) processing = true;
    if (site.processing) processing = true;
    if (stats.processing) processing = true;

    if (stats.network) {
      if (stats.network.day) {
        let objUrl = URL.createObjectURL(stats.network.day);
        img = <div className="networkImg"><div className="networkImgHdr">24 Hour</div><img src={objUrl} /></div>;
      }
      if (stats.network.week) {
        let objUrl2 = URL.createObjectURL(stats.network.week);
        img2 = <div className="networkImg"><div className="networkImgHdr">One Week</div><img src={objUrl2} /></div>;
      }
      if (stats.network.month) {
        let objUrl3 = URL.createObjectURL(stats.network.month);
        img3 = <div className="networkImg"><div className="networkImgHdr">One Month</div><img src={objUrl3} /></div>;
      }
    }

    if (site.edit) {
      headerText = 'Monitor - Site: ' + site.edit.site_id + ' - Network';
    }

    return (
      <div>
        <NavigationBar header={headerText} />
        <div className="container-fluid">
          <SiteTabs active="network" />
          <SiteData site={site.edit} more={false} siteList={sites.list} showList={this.state.showSiteList} clickHandler={this.onSiteSelect} displayHandler={this.onSiteDisplay} closeHandler={this.onSiteDisplayClose} />
          <ProcessIndicator show={processing} />
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
          <div>
            {img}
            {img2}
            {img3}
          </div>
        </div>
      </div>
    );
  }

  onSiteDisplay() {
    this.props.clearAlert();
    this.setState({ showSiteList: true });
  }

  onSiteDisplayClose() {
    this.setState({ showSiteList: false });
  }

  onSiteSelect(e) {
    this.setState({ showSiteList: false });
    const { monitor, getSite } = this.props;
    let siteIdx = e.currentTarget.attributes['data-idx'].value;
    let selectedsite = monitor.sites.list[siteIdx];
    getSite(monitor, selectedsite);
  }

  getNetworkStats() {
    this.setState({ getnetworkimage: false });
    const { monitor, getNetworkStats } = this.props;
    getNetworkStats(monitor);
  }
};

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const monitor = state.monitor
  return { alert, auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    clearAlert() { dispatch(alertActions.clear()); }
  , getSiteList(monitor) { dispatch(sitesActions.list(monitor)); }
  , getSite(monitor, site) { dispatch(siteActions.get(monitor, site, false)); }
  , getNetworkStats(monitor) { dispatch(statsActions.network(monitor)); }
});

const connectedNetworkStats = connect(mapStateToProps, mapDispatchToProps)(NetworkStats);
export { connectedNetworkStats as NetworkStats };
