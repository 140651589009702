import React from 'react';

import Charts from '../../charts';

import { createChartArrayFromArray } from '../../../_helpers';

export const ChannelUsage = props => {
  const { chan } = props;
  let entries = Object.entries(chan);
  entries.sort(function(a, b){
    return b[1] - a[1];
  });
  let chans = entries.slice(0, 10);
  let pieData = createChartArrayFromArray(chans, 'channel', 'hours');
  let ch = {
      type: 'pie'
    , data: pieData
    , options:{
          "title": "Top 10 Channels - Hours"
        , "pieHole": 0.3
        , "is3D": false
        , "pieSliceText": "value"
        , height:'300px'
        , width:'400px'
      }
  };
  return (
    <div className="statchart">
      <Charts data={ch} />
    </div>
  );
};
