import { registrationConstants } from '../_constants';

export function registration(state = {}, action) {
  switch(action.type) {
    case registrationConstants.REGISTER_RETURN:
      return {};
    case registrationConstants.REGISTER_REQUEST:
      return { registering: true };
    case registrationConstants.REGISTER_SUCCESS:
      return {};
    case registrationConstants.REGISTER_FAILURE:
      return {};
    case registrationConstants.REGISTER_CLEAR:
      return {};
    case registrationConstants.REGISTER_LIST_REQUEST:
      return action.obj;
    case registrationConstants.REGISTER_LIST_SUCCESS:
      return action.obj;
    case registrationConstants.REGISTER_LIST_FAILURE:
      return action.obj;
    case registrationConstants.REGISTER_SELECT_SUCCESS:
      return action.obj;
    case registrationConstants.REGISTER_SELECT_FAILURE:
      return action.obj;
    case registrationConstants.REGISTER_EDIT_INPUT:
      return action.obj;
    case registrationConstants.REGISTER_EDIT_CANCEL:
      return action.obj;
    case registrationConstants.REGISTER_EDIT_SAVE_REQUEST:
      return action.obj;
    case registrationConstants.REGISTER_EDIT_SAVE_SUCCESS:
      return action.obj;
    case registrationConstants.REGISTER_EDIT_SAVE_FAILURE:
      return action.obj;
    case registrationConstants.REGISTER_REMOVE_REQUEST:
      return action.obj;
    case registrationConstants.REGISTER_REMOVE_SUCCESS:
      return action.obj;
    case registrationConstants.REGISTER_REMOVE_FAILURE:
      return action.obj;
    default:
      return state;
  }
}
