export const textTokenConstants = {
   TEXT_TOKEN_GET_REQUEST: 'TEXTTOKEN_GET_REQUEST'
 , TEXT_TOKEN_GET_SUCCESS: 'TEXTTOKEN_GET_SUCCESS'
 , TEXT_TOKEN_GET_FAILURE: 'TEXTTOKEN_GET_FAILURE'
 , TEXT_TOKEN_SAVE_REQUEST: 'TEXTTOKEN_SAVE_REQUEST'
 , TEXT_TOKEN_SAVE_SUCCESS: 'TEXTTOKEN_SAVE_SUCCESS'
 , TEXT_TOKEN_SAVE_FAILURE: 'TEXTTOKEN_SAVE_FAILURE'
 , TEXT_TOKEN_ADD_TOKEN: 'TEXTTOKEN_ADD_TOKEN'
 , TEXT_TOKEN_SAVE_TOKEN: 'TEXTTOKEN_SAVE_TOKEN'
 , TEXT_TOKEN_CANCEL_TOKEN: 'TEXTTOKEN_CANCEL_TOKEN'
 , TEXT_TOKEN_DELETE_TOKEN: 'TEXTTOKEN_DELETE_TOKEN'
 , TEXT_TOKEN_UPDATE_TOKEN: 'TEXTTOKEN_UPDATE_TOKEN'
};
