import React from 'react';

export const InvalidRoomNumbers = props => {
    const { site } = props;
    const { invalid } = site;

    if (!invalid) {
        return <div>No Invalid Room Numbers Found</div>;
    }

    return (
        <div>
            Invalid Room Numbers: {invalid.length} - Sort on Room Number to see invalid STB's
        </div>
    );
};
