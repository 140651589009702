import { permission } from './admin.permission.reducer';
import { group } from './admin.group.reducer';
import { role } from './admin.role.reducer';
import { user } from './admin.user.reducer';
import { site } from './admin.site.reducer';

let initialState = {
    permission: {}
  , group: {}
  , role: {}
  , user:{}
  , site: {}
};

export function administration(state = initialState, action) {
  if (!action) return state;
  var actionSection = action.type.split('_');
  switch(actionSection[0]) {
    case 'PERMISSION':
      return permission(state, action);
    case 'GROUP':
      return group(state, action);
    case 'ROLE':
      return role(state, action);
    case 'USER':
      return user(state, action);
    case 'SITE':
      return site(state, action);
    default:
      return state;
  }
}
