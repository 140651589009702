const standardPost = (url, data) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            body: data,
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            credentials: 'include'
        })
        .then(response => {
            response.json().then(data => {
                if (response.status === 200) {
                    resolve(data);
                }
                else {
                    reject(data);
                }
            });
        })
        .catch(response => { reject(response.message); });
    });
};

const standardGet = (url) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            credentials: 'include'
        })
        .then(response => {
            response.json().then(data => {
                if (response.status === 200) {
                    resolve(data);
                }
                else {
                    reject(data);
                }
            });
        })
        .catch(response => {
             reject(response.statusText);

            });
        });
};

module.exports = {
    standardPost,
    standardGet
};
