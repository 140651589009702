import React from 'react';
import { connect } from 'react-redux';

import { alertActions, sitesActions, siteActions } from '../../../_actions';
import { history, permissionCheck, prepTableInfo, calcTotalPages, confirmOptions, isObjectEmpty, config } from '../../../_helpers';

import { NavigationBar } from '../../navigation';
import { SimpleSearchWClear } from '../../search';
import { DataTable } from '../../dataTable';
import { ConfirmModal } from '../../confirm';
import { ProcessIndicator } from '../../processIndicator';

import { Dashboard } from '../dashboard';
import { RoomNumberChanger } from '../newRoom';
import { SiteData } from '../siteData';
import { SiteTabs } from '../siteTabs';
import { DuplicateStbs } from './duplicates';
import { InvalidRoomNumbers } from './invalidRoom';
import { ScriptCommandButtons } from './scriptCmds';
import { ChannelModal } from './channelModal';

require('../../../styles/site');

class Site extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        authorized: false
      , showSiteList: false
      , tableitemcount: 10
      , deactivate: false
      , reboot: false
      , modalitem: null
      , setChannel: false
      , chScriptData: null
      , channel: ""
    };

    this.onSiteSelect = this.onSiteSelect.bind(this);
    this.onSiteDisplay = this.onSiteDisplay.bind(this);
    this.onSiteDisplayClose = this.onSiteDisplayClose.bind(this);
    this.onChartEvent = this.onChartEvent.bind(this);
    this.onSearchInput = this.onSearchInput.bind(this);
    this.onSearchClear = this.onSearchClear.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.onPageClick = this.onPageClick.bind(this);
    this.onPaginationCountChange = this.onPaginationCountChange.bind(this);
    this.onSortClick = this.onSortClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onRebootClick = this.onRebootClick.bind(this);
    this.onRoomChangeClick = this.onRoomChangeClick.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDeleteProceed = this.onDeleteProceed.bind(this);
    this.onRebootProceed = this.onRebootProceed.bind(this);
    this.onkeypressHandler = this.onkeypressHandler.bind(this);
    this.onScriptButtonClick = this.onScriptButtonClick.bind(this);
    this.onChannelSet = this.onChannelSet.bind(this);
    this.onChannelModalClose = this.onChannelModalClose.bind(this);
    this.onChannelSubmit = this.onChannelSubmit.bind(this);
  }

  componentDidMount() {
    const { auth, unauthorized, monitor, siteslist, setupCharts } = this.props;
    if (auth) {
      if (permissionCheck(auth.permissions, 'portal.site')) {
        this.setState({ authorized: true });
        const { sites, site  } = monitor;
        if (!sites.list) {
          siteslist(monitor);
        }
        if (site.stbs && !site.stb.stats) {
          let wci = permissionCheck(auth.permissions, 'portal.site.wci');
          setupCharts(monitor, wci);
        }
      }
      else {
        unauthorized();
        history.push('/');
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { auth, unauthorized, monitor, siteslist, setupCharts } = this.props;
    const { authorized } = this.state;
    if (auth && !authorized) {
      if (permissionCheck(auth.permissions, 'portal.site')) {
        this.setState({ authorized: true });
        const { sites, site } = monitor;
        if (!sites.list) {
          siteslist(monitor);
        }
        if (site.stbs && !site.stb.stats) {
          let wci = permissionCheck(auth.permissions, 'portal.site.wci');
          setupCharts(monitor, wci);
        }
      }
      else {
        unauthorized();
        history.push('/');
      }
    }
    else {
      const { site } = monitor;
      if (site.stbs && !site.stb.stats) {
        let wci = permissionCheck(auth.permissions, 'portal.site.wci');
        setupCharts(monitor, wci);
      }
    }
  }

  render() {
    const { alert, auth, monitor } = this.props;
    if (!auth) return null;
    if (!auth.permissions) return null;
    const { sites, site } = monitor;
    const { stb } = site;

    const wci = permissionCheck(auth.permissions, 'portal.site.wci'); // WCI employee? Determine how to calculate down STB's. At 15 minutes or 120 minutes.
    const showSiteIp = permissionCheck(auth.permissions, 'portal.sites.link'); // Permission to determine if site link displayed
    const showScripts = permissionCheck(auth.permissions, 'portal.site.scripts'); // Permission to determine ability to view extra script buttons

    let search = null;
    let siteData = null;
    let dashboard = null;
    let dataTable = null;
    let rcModal = null;
    let cModal = null;
    let charts = [];
    let scriptCmds = null;
    let _this = this;
    let headerText = 'Monitor - Site';
    let processIndShow = site.processing ? site.processing : false;
    if (!processIndShow) {
      processIndShow = sites.processing ? sites.processing : false;
    }

    if (stb) {
      if (stb.onStats) {
        const { total, on, down } = stb.onStats;
        let off = parseInt(total) - parseInt(on) - parseInt(down);
        let pieData = [['Stb', 'Status'], ['Off', off], ['Down', parseInt(down)], ['On', parseInt(on)]];
        let chartEventSelect = {eventName: 'select', callback(chart) { let selection = chart.chart.getSelection(); _this.onChartEvent(pieData, selection); }};
        let ch1 = {
            type: 'pie'
          , data: pieData
          , options:{
                "title": "STB Status - Total Number " + total
              , "pieHole": 0.3
              , "is3D": false
              , "pieSliceText": "value"
              , slices: {1: {offset: 0.2}, 2: {color:'#17b20f'}}
              , height:'200px'
              , width:'300px'
            }
          , eventHandler: [chartEventSelect]
        };
        charts.push(ch1);
      }
      if (stb.inStats) {
        const _in = stb.inStats.in;
        const { out, unknown, total } = stb.inStats;
        let pieData2 = [['Stb', 'CheckedIn'], ['In', parseInt(_in)], ['Out', parseInt(out)], ['Unknown', parseInt(unknown)]];
        let chart2EventSelect = {eventName: 'select', callback(chart) { let selection = chart.chart.getSelection(); _this.onChartEvent(pieData2, selection); }};
        let ch2 = {
            type: 'pie'
          , data: pieData2
          , options:{
                "title": "STB Checked In - Total Number " + total
              , "pieHole": 0.3
              , "is3D": false
              , "pieSliceText": "value"
              , slices: {0: {color: '#17b20f'}, 1: {color:'#6610f2'}}
              , height:'200px'
              , width:'300px'
            }
          , eventHandler: [chart2EventSelect]
        };
        charts.push(ch2);
      }
      if (stb.stateCodes) {
        const total = stb.stateCodes.total
        let countTotal = 0;
        let pieData3 = [['Stb', 'Code']];
        //let sliceOpts = {};
        const keys = Object.keys(stb.stateCodes)
        for (let k in keys) {
          const key = keys[k];
          let arryItm = null;
          let pos = null;
          switch(key) {
            case '100':
              arryItm = [key + '-' + config.STBStateCodeText[key], parseInt(stb.stateCodes[key])];
              //sliceOpts = {1: {color: '#17b20f'}};
              pos = 1;
              break;
            case '200':
              arryItm = [key + '-' + config.STBStateCodeText[key], parseInt(stb.stateCodes[key])];
              //sliceOpts = {2: {color: '#6610f2'}};
              pos = 4;
              break;
            case '500':
              arryItm = [key + '-' + config.STBStateCodeText[key], parseInt(stb.stateCodes[key])];
              //sliceOpts = {2: {offset: 0.2}};
              pos = 2;
              break;
            case '501':
              arryItm = [key + '-' + config.STBStateCodeText[key], parseInt(stb.stateCodes[key])];
              //sliceOpts = {3: {color: '#1d3995'}};
              pos = 3;
              break;
            case 'total':
            case 'null':
              // Do nothing with total and null
              break;
            default:
              // incase a new status shows up
              arryItm = [key + '-' + config.STBStateCodeText['999'], parseInt(stb.stateCodes[key])];
              break;
          }
          if (pos !== null) {
            pieData3.splice(pos, 0, arryItm);
          }
          else {
            if (arryItm) pieData3.push(arryItm);
          }
        }
        const chart3EventSelect = {eventName: 'select', callback(chart) { let selection = chart.chart.getSelection(); _this.onChartEvent(pieData3, selection); }};
        const ch3 = {
          type: 'pie'
          , data: pieData3
          , options:{
                "title": "STB State Codes"
              , "pieHole": 0.3
              , "is3D": false
              , "pieSliceText": "value"
              , slices: {1: {offset: 0.2}}
              , height:'200px'
              , width:'475px'
            }
          , eventHandler: [chart3EventSelect]
        };
        charts.push(ch3);
      }
    }
    
    if (charts.length > 0) {
      dashboard = <div><Dashboard charts={charts} /></div>;
    }

    let stblist = (site.search && site.search.found) ? site.search.found : site.stbs;
    if (stblist && stblist.length > 0) {
      const isdemo = permissionCheck(auth.permissions, 'portal.demo');
      const tableInfoObj = prepTableInfo(stblist, isdemo);

      const rowCount = site.tableRows ? site.tableRows : this.state.tableitemcount;
      const totalpages = calcTotalPages(stblist.length, rowCount);
      let currPage = (site.dataPage) ? site.dataPage : 1;
      if (currPage > totalpages) currPage = totalpages;

      let pageParams = {
          rowCount: rowCount
        , dataPage: currPage
        , pages: totalpages
        , handler: this.onPageClick
        , selectcount: true
        , numselectHandler: this.onPaginationCountChange
        , hasBtn: true
      };
      const tableDisplayParams = {
          name: 'site'
        , wci: wci
        , hideFields: ['site_id', 'since_last_update', 'check_in_flag', 'power_state']
        , allowOptions: {
              edit: { allowed: false, handler: null }
            , delete: { allowed: true, handler: this.onDeleteClick }
            , select: { allowed: false, handler: null }
            , reboot: { allowed: true, handler: this.onRebootClick }
            , roomNum: { allowed: true, handler: this.onRoomChangeClick }
          }
        , pagination: {
              rowCount: pageParams.rowCount
            , dataPage: pageParams.dataPage
          }
        , sorting: {
              columns: ['room_number', 'last_update_time_utc']
            , handler: this.onSortClick
            , activeSort: (site.sort) ? site.sort.field : null
            , direction: (site.sort) ? site.sort.direction : null
          }
        , columnHiding: {
              xs: ['ip_address', 'mac_address', 'app_version']
            , sm: ['ptc_version', 'cpu_version', 'max_guide_time']
          }
        , export: {
              csv: true
            , excel: false
            , name: site.stbs[0].site_id + '_stbs-'
            , data: site.stbs
          }
      };
      dataTable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} pagination={pageParams} />;
    }

    if (this.state.deactivate) {
      let confirmOpts = new confirmOptions();
      confirmOpts.show = true;
      confirmOpts.title = 'Confirm Delete';
      confirmOpts.question = 'Are you sure you want to deactivate the STB for room: ' + this.state.modalitem.room_number;
      confirmOpts.handlers.proceed = this.onDeleteProceed;
      confirmOpts.handlers.cancel = this.onCancel;
      confirmOpts.proceed.title = 'Delete';
      confirmOpts.proceed.className = 'btn-danger';
      cModal = <ConfirmModal options={confirmOpts} />;
    }
    if (this.state.reboot) {
      let confirmOpts = new confirmOptions();
      confirmOpts.show = true;
      confirmOpts.title = 'Confirm Reboot';
      confirmOpts.question = 'Are you sure you want to reboot the STB for ' + this.state.modalitem.room_number;
      confirmOpts.handlers.proceed = this.onRebootProceed;
      confirmOpts.handlers.cancel = this.onCancel;
      confirmOpts.proceed.title = 'Reboot';
      confirmOpts.proceed.className = 'btn-primary';
      cModal = <ConfirmModal options={confirmOpts} />;
    }
    if (this.state.setChannel) {
        rcModal = <ChannelModal submitHandler={this.onChannelSubmit} cancelHandler={this.onChannelModalClose} changeHandler={this.onChannelSet} val={this.state.channel} />;
    }
    if (stb && stb.rmChgObj) {
      rcModal = <RoomNumberChanger />;
    }

    if (site.search) {
      if (site.search.found) stblist = site.search.found;
    }

    if (site.edit) {
      headerText = 'Monitor - Site: ' + site.edit.site_id;
      if (showScripts) scriptCmds = <ScriptCommandButtons siteId={site.edit.site_id} handler={this.onScriptButtonClick} processing={processIndShow} />;
      if (site.stbs && site.stbs.length > 0) {
        let searchText = '';
        let searching = false;
        if (site.search) {
          searchText = site.search.text ? site.search.text : searchText;
          if (site.processing && site.search.looking) searching = true;
        }
        search = (<div>
                    <SimpleSearchWClear submitHandler={this.onSearchSubmit}
                                        changeHandler={this.onSearchInput}
                                        clearHandler={this.onSearchClear}
                                        searchValue={searchText}
                                        searching={searching}
                                        searchDisable={processIndShow}
                                        keydownHandler={this.onkeypressHandler}
                                        displayText="Search" />
                  </div>);
      }
    }
    siteData = <SiteData site={site.edit} more={true} showSiteIp={showSiteIp} siteList={sites.list} showList={this.state.showSiteList} clickHandler={this.onSiteSelect} displayHandler={this.onSiteDisplay} closeHandler={this.onSiteDisplayClose} />;

    const dups = <DuplicateStbs site={site} />;
    const invalid = <InvalidRoomNumbers site={site} />;

    return (
      <div>
        <NavigationBar header={headerText} />
        <div className="container-fluid">
          <SiteTabs active="site" />
          <ProcessIndicator show={processIndShow} />
          {siteData}
          {dashboard}
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
          {scriptCmds}
          {dups}
          {invalid}
          {search}
          {dataTable}
          {cModal}
          {rcModal}
        </div>
      </div>
    );
  }

  onSiteDisplay() {
    this.setState({ showSiteList: true });
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
  }
  onSiteDisplayClose() {
    this.setState({ showSiteList: false });
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
  }
  onSiteSelect(evt) {
    let siteIdx = evt.currentTarget.attributes['data-idx'].value;
    this.setState({ showSiteList: false });
    const { monitor, auth, get, alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    let selectedsite = monitor.sites.list[siteIdx];
    get(monitor, selectedsite, auth);
  }

  onChartEvent(pieData, selection) {
    const { auth, monitor, searchByStatus, alert, clearAlert } = this.props;
    const wci = permissionCheck(auth.permissions, 'portal.site.wci');
    const pieDataSelection = pieData[selection[0].row + 1]; // plus 1 because of the required header in the data array
    let searchType = pieDataSelection[0].toLowerCase();
    if (searchType.indexOf('-') >= 0) {
      searchType = searchType.substring(0, searchType.indexOf('-'));
    }
    searchByStatus(monitor, searchType, wci);
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onSearchInput(evt) {
    const { value } = evt.target;
    const { monitor, searchInput, alert, clearAlert } = this.props;
    searchInput(monitor, value);
    if (!isObjectEmpty(alert)) clearAlert();
  }
  onSearchClear() {
    const { monitor, searchClear, alert, clearAlert } = this.props;
    searchClear(monitor);
    if (!isObjectEmpty(alert)) clearAlert();
  }
  onSearchSubmit(evt) {
    if (evt) evt.preventDefault();
    const { monitor, searchByRoom, alert, clearAlert } = this.props;
    const { site } = monitor;
    if (!site.search) return;
    searchByRoom(monitor);
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onPageClick(evt) {
    const page = parseInt(evt.currentTarget.attributes['data-pagenumber'].value);
    const { monitor, pageChange, alert, clearAlert } = this.props;
    pageChange(monitor, page);
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onPaginationCountChange(evt) {
    const { value } = evt.target;
    const { monitor, rowCountChange, alert, clearAlert } = this.props;
    rowCountChange(monitor, parseInt(value));
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onCancel() {
    const {  alert, clearAlert } = this.props;
    this.setState({ deactivate: false, reboot: false, modalitem: null });
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onDeleteClick(evt) {
    var idx = parseInt(evt.currentTarget.attributes['data-index'].value);
    const { monitor, sendAlert, alert, clearAlert } = this.props;
    const { site } = monitor
    const { search } = site;
    const list = (search && search.found) ? search.found : site.stbs;
    let obj = null;
    if (idx >= 0) {
      obj = list[idx];
    }
    if (!obj) {
      sendAlert('Item data not found');
      return;
    }
    this.setState({ deactivate: true, modalitem: obj });
    if (!isObjectEmpty(alert)) clearAlert();
  }
  onDeleteProceed() {
    const stb = this.state.modalitem;
    const { monitor, deactivate } = this.props;
    deactivate(monitor, stb);
    this.setState({ deactivate: false, modalitem: null });
  }

  onRoomChangeClick(evt) {
    var idx = parseInt(evt.currentTarget.attributes['data-index'].value);
    const { monitor, roomchange, sendAlert, alert, clearAlert } = this.props;
    const { site } = monitor
    const { search } = site;
    const list = (search && search.found) ? search.found : site.stbs;
    let obj = null;
    if (idx >= 0) {
      obj = list[idx];
    }
    if (!obj) {
      sendAlert('Item data not found');
      return;
    }
    roomchange(monitor, obj);
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onRebootClick(evt) {
    var idx = parseInt(evt.currentTarget.attributes['data-index'].value);
    const { monitor, sendAlert, alert, clearAlert } = this.props;
    const { site } = monitor
    const { search } = site;
    const list = (search && search.found) ? search.found : site.stbs;
    let obj = null;
    if (idx >= 0) {
      obj = list[idx];
    }
    if (!obj) {
      sendAlert('Item data not found');
      return;
    }
    this.setState({ reboot: true, modalitem: obj });
    if (!isObjectEmpty(alert)) clearAlert();
  }
  onRebootProceed() {
    const stb = this.state.modalitem;
    const { monitor, reboot } = this.props;
    reboot(monitor, stb);
    this.setState({ reboot: false, modalitem: null });
  }

  onSortClick(evt) {
    let field = evt.currentTarget.attributes['data-field'].value;
    const { monitor, sort, alert, clearAlert } = this.props;
    sort(monitor, field);
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onkeypressHandler(evt) {
      if (evt.keyCode === 13) {
          evt.preventDefault();
          evt.stopPropagation();
          this.onSearchSubmit();
      }
  }

  onScriptButtonClick(evt) {
      const { auth, monitor, alert, clearAlert, callScript } = this.props;
      if (!isObjectEmpty(alert)) clearAlert();
      const { site } = monitor;
      const { edit } = site;
      let scriptidx = evt.currentTarget.attributes['data-scriptidx'].value;
      //console.log('onScriptButtonClick scriptidx: ' + scriptidx);
      let script = config.scriptButtons[edit.site_id][scriptidx];
      console.log('script: ' + JSON.stringify(script));
      if (script.script === 'changeChannel') {
          this.setState({ setChannel: true, chScriptData: script });
          return;
      }
      // ON/OFF SCRIPT CALLED HERE
      callScript(auth, monitor, script);
  }

  onChannelSet(evt) {
    const { value } = evt.target;
    this.setState({ channel: value });
  }

  onChannelModalClose() {
    this.setState({ setChannel: false, channel: '', chScriptData: null });
  }

  onChannelSubmit() {
    const { chScriptData, channel } = this.state;
    const { auth, monitor, alert, clearAlert, sendAlert, callScript } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    console.log('onChannelSubmit - channel = ' + channel);
    if (channel.length === 0 || channel === '0') {
      sendAlert("channel cannot be empty or zero");
      this.setState({ setChannel: false, channel: '' });
      return;
    }
    // CHANNEL SCRIPT CALLED HERE
    const newScriptData = {
      ...chScriptData,
      extra: chScriptData.extra + '=' + channel
    };
    this.setState({ setChannel: false, channel: '', chScriptData: null });
    callScript(auth, monitor, newScriptData);
  }
};

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const monitor = state.monitor;
  return { alert, auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    unauthorized() { dispatch(alertActions.error('You are not authorized for that page.')); }
  , clearAlert() { dispatch(alertActions.clear()); }
  , siteslist(monitor) { dispatch(sitesActions.list(monitor)); }
  , get(monitor, site, auth) { dispatch(siteActions.get(monitor, site, false, auth)); }
  , setupCharts(monitor, wci) { dispatch(siteActions.setupCharts(monitor, wci)); }
  , searchByStatus(monitor, status, wci) { dispatch(siteActions.searchByStatus(monitor, status, wci)); }
  , searchInput(monitor, value) { dispatch(siteActions.searchInput(monitor, value)); }
  , searchClear(monitor) { dispatch(siteActions.searchClear(monitor)); }
  , searchByRoom(monitor) { dispatch(siteActions.search(monitor)); }
  , pageChange(monitor, page) { dispatch(siteActions.pageChange(monitor, page)); }
  , rowCountChange(monitor, rows) { dispatch(siteActions.tableRowCount(monitor, rows)); }
  , sort(monitor, field) { dispatch(siteActions.sort(monitor, field)); }
  , sendAlert(msg) { dispatch(alertActions.error(msg)); }
  , deactivate(monitor, stb) { dispatch(siteActions.deactivate(monitor, stb)); }
  , reboot(monitor, stb) { dispatch(siteActions.reboot(monitor, stb)); }
  , roomchange(monitor, stb) { dispatch(siteActions.roomchange(monitor, stb)); }
  , callScript(auth, monitor, scriptInfo) { dispatch(siteActions.callScript(auth, monitor, scriptInfo)); }
});

const connectedSite = connect(mapStateToProps, mapDispatchToProps)(Site);
export { connectedSite as Site };
