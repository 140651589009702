import React, { useState } from 'react';

import { WciModal } from '../wcimodal';

export const EmailModal = props => {
    const [eaddr, setEAddr] = useState('');

    return(
        <WciModal show={props.show} className={props.className}>
            <div className={props.placementClass}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Email Required</h5>
                        <button type="button"
                                className="close"
                                onClick={props.close}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <p>Please enter your email as it exists in this application.</p>
                        <div>
                            <input type="email" value={eaddr} onChange={(evt) => setEAddr(evt.target.value)} style={{width:'400px'}} />
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button"
                              className="btn btn-secondary"
                              onClick={props.close}>Cancel</button>
                      <button type="button"
                              className="btn btn-primary"
                              onClick={(evt) => {props.proceed(evt, eaddr);}}>Submit</button>
                    </div>
                </div>
            </div>
        </WciModal>
    );
};
