import { adminSiteConstants, adminUserConstants } from '../_constants';
import { alertActions } from './';
import { adminService } from '../_services';
import { history } from '../_helpers';
import { user } from '../_reducers/admin.user.reducer';

export const adminSiteActions = {
    searchInput
  , searchClear
  , search
  , gotoUser
};

function searchInput(admin, value) {
  return dispatch => {
    const { site } = admin;
    let newobj = {
      ...site,
      searchValue: value
    };
    dispatch(request(newobj));
  };
  function request(obj) { return { type: adminSiteConstants.SITE_INPUT, obj }; }
}

function searchClear(admin) {
  return dispatch => {
    const { site } = admin;
    const { searchValue, list, ...cleared } = site;
    dispatch(request(cleared));
  };
  function request(obj) { return { type: adminSiteConstants.SITE_CLEAR, obj }; }
}

function search(admin) {
  return dispatch => {
    const { site } = admin;
    let newsite = {
      ...site,
      processing: true
    };
    dispatch(request(newsite));

    const { list, ...nolist } = site;
    adminService.getextend('site', site.searchValue, 'users')
      .then(
        items => {
          if (items.length === 0) {
            dispatch(alertActions.info('No users found associated with that site.'));
            dispatch(success(nolist));
          }
          else {
            let nextsite = {
              ...nolist,
              list: items
            };
            dispatch(alertActions.info('Found ' + items.length + ' users associated with that site.'));
            dispatch(success(nextsite));
          }
        },
        error => {
          dispatch(failure(site));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: adminSiteConstants.SITE_SEARCH_REQUEST, obj }; }
  function success(obj) { return { type: adminSiteConstants.SITE_SEARCH_SUCCESS, obj }; }
  function failure(obj) { return { type: adminSiteConstants.SITE_SEARCH_FAILURE, obj }; }
}

function gotoUser(admin, item) {
  return dispatch => {
    const { site } = admin;
    let newsite = {
      ...site,
      processing: true
    };
    dispatch(request(newsite));

    //const { searchValue, list, ...cleared } = site;
    //adminService.get('user', user.user_id)
    //  .then(
    //    item => {
    //      if (item.length === 0) {
    //        dispatch(alertActions.info('user id not found.'));
    //        dispatch(success(site));
    //      }
    //      else {
      //const { user } = admin;
      const nextuser = {
        ...user,
        edit: item
      };
      dispatch(usersuccess(nextuser));
      dispatch(success(site));
      let editroles = [];
      let editsites = [];

      adminService.getextend('user', item.user_id, 'roles')
        .then(
          items => {
            editroles = items;
            const roleedit = {
              ...item,
              roles: editroles,
              sites: editsites
            };
            const roleuser = {
              ...user,
              edit: roleedit
            };
            dispatch(usersuccess(roleuser));
          },
          error => {
            dispatch(rolefailure());
            dispatch(alertActions.error(error));
          }
        );
      adminService.getextend('user', item.user_id, 'site')
        .then(
          items => {
            editsites = items;
            const siteedit = {
              ...item,
              roles: editroles,
              sites: editsites
            };
            const siteuser = {
              ...user,
              edit: siteedit
            };
            dispatch(usersuccess(siteuser));
          },
          error => {
            dispatch(sitefailure());
            dispatch(alertActions.error(error));
          }
        );
      history.push('/admin/users/edit');
    //      }
    //    },
    //    error => {
    //      dispatch(failure(site));
    //      dispatch(alertActions.error(error));
    //    }
    //  );
  };
  function request(obj) { return { type: adminSiteConstants.SITE_USER_REQUEST, obj }; }
  function success(obj) { return { type: adminSiteConstants.SITE_USER_SUCCESS, obj }; }
  //function failure(obj) { return { type: adminSiteConstants.SITE_USER_FAILURE, obj }; }
  function usersuccess(obj) { return { type: adminUserConstants.USER_GET_SUCCESS, obj }; }
  //function rolesuccess(items) { return { type: adminUserConstants.USER_ROLES_SUCCESS, items }; }
  function rolefailure() { return { type: adminUserConstants.USER_ROLES_FAILURE }; }
  //function sitesuccess(items) { return { type: adminUserConstants.USER_SITE_SUCCESS, items }; }
  function sitefailure() { return { type: adminUserConstants.USER_SITE_FAILURE }; }
}
