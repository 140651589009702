import React from 'react';
import { connect } from 'react-redux';

import { alertActions, adminRoleActions, adminUserActions } from '../../../_actions';
import { permissionCheck, history, isObjectEmpty, prepTableInfo } from '../../../_helpers';

import { NavigationBar } from '../../navigation';
import { DataTable } from '../../dataTable';

import { RolePermissions } from './permissions';
import { RoleUsers } from './users';
import { RoleSubTable } from './subtable';

class RoleEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        name: ''
      , description: ''
      , subtblDisplay: null
    };

    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onPermissionChange = this.onPermissionChange.bind(this);
    this.onPermissionsShow = this.onPermissionsShow.bind(this);
    this.onUsersShow = this.onUsersShow.bind(this);
    this.onUserClick = this.onUserClick.bind(this);
  }

  componentDidMount() {
    const { auth, admin } = this.props;
    if (!auth) {
      history.push('/admin/roles');
      return;
    }
    if (!admin) {
      history.push('/admin/roles');
      return;
    }
    const { role } = admin;
    if (!role) {
      history.push('/admin/roles');
      return;
    }
    if (!role.items || role.items.length === 0) {
      history.push('/admin/roles');
      return;
    }
    if (role.editidx >= 0) {
      if (role.editidx >= role.items.length) {
        history.push('/admin/roles');
        return;
      }
      const item = role.items[role.editidx];
      this.setState({ name: item.name, description: item.description });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.admin.role.editidx !== prevProps.admin.role.editidx) {
      const role = this.props.admin.role;
      const item = role.items[role.editidx];
      this.setState({ name: item.name, description: item.description });
    }
  }

  render() {
    const { auth, alert, admin } = this.props;
    if (!auth) return null;
    if (!admin) return null;
    const { role } = admin;
    if (!role) return null;

    if (!role.items || role.items.length === 0) return null;
    const item = role.items[role.editidx];
    if (!item) return null;

    const btnText = 'Cancel';

    let showPermissionsAdd = true;
    let roleSubTable = null;
    if (this.state.subtblDisplay) {
        let dataTable = <div>No {this.state.subtblDisplay} currently assigned</div>;
        if (this.state.subtblDisplay === 'permissions' && role.permissions && role.permissions.length > 0) {
            dataTable = setupPermissionTable(role.permissions);
            //showPermissionsAdd = true;
        }
        else if (this.state.subtblDisplay === 'users' && role.users && role.users.length > 0) {
            dataTable = setupUserTable(role.users, auth, this.onUserClick);
        }
        roleSubTable = <RoleSubTable tbldata={dataTable} />;
    }

    return(
      <div>
        <NavigationBar header="Admin - Roles - Edit" />
        <div className="jumbotron">
          <div className="container">
            <div className="row justify-content-md-center">
              <h5>Edit Role</h5>
            </div>
            <form onSubmit={this.onSubmit}>
              <div className="form-group row">
                <label htmlFor="role_id" className="col-sm-2 col-form-label text-right">ID:</label>
                <input type="text"
                  id="role_id"
                  className="col-sm-2 form-control-plaintext"
                  readOnly
                  value={item.role_id} />
                <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#moreinfo" aria-expanded="false" aria-controls="moreinfo">
                  Show More
                </button>
              </div>
              <div className="collapse" id="moreinfo">
                <div className="form-group row">
                  <label htmlFor="created" className="col-sm-2 col-form-label text-right">Created:</label>
                  <input type="text"
                    id="created"
                    className="col-sm-3 form-control-plaintext"
                    readOnly
                    value={item.created} />
                  <label htmlFor="createduser" className="col-sm-2 col-form-label text-right">Created By:</label>
                  <input type="text"
                    id="createduser"
                    className="col-sm-3 form-control-plaintext"
                    readOnly
                    value={(item.created_user_name) ? item.created_user_name : ''} />
                </div>
                <div className="form-group row">
                  <label htmlFor="modified" className="col-sm-2 col-form-label text-right">Modified:</label>
                  <input type="text"
                    id="modified"
                    readOnly
                    className="col-sm-3 form-control-plaintext"
                    value={(item.modified === null) ? '' : item.modified} />
                  <label htmlFor="modifieduser" className="col-sm-2 col-form-label text-right">Modified By:</label>
                  <input type="text"
                    id="modifieduser"
                    readOnly
                    className="col-sm-3 form-control-plaintext"
                    value={(item.modified_user_name === null) ? '' : item.modified_user_name} />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="name" className="col-sm-2 col-form-label text-right">Name:</label>
                <input type="text" id="name"
                      className={'col-sm-6 form-control' + (role.validity ? role.validity.name ? ' is-valid' : ' is-invalid' : ' is-valid')}
                      name="name"
                      onChange={this.onChange}
                      readOnly={role.updating}
                      value={this.state.name} />
                    {role.validity && !role.validity.valid && item.name.length === 0 &&
                  <div className='invalid-feedback offset-sm-2'>Name is required</div>
                }
              </div>
              <div className="form-group row">
                <label htmlFor="description" className="col-sm-2 col-form-label text-right">Description:</label>
                <input type="text" id="description"
                      className={'col-sm-6 form-control' + (role.validity ? role.validity.description ? ' is-valid' : ' is-invalid' : ' is-valid')}
                      name="description"
                      onChange={this.onChange}
                      readOnly={role.updating}
                      value={this.state.description} />
                    {role.validity && !role.validity.valid && item.description.length === 0 &&
                  <div className='invalid-feedback offset-sm-2'>Description is required</div>
                }
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary" disabled={role.updating}>
                  <span>Submit</span>
                  {role.updating &&
                    <div className="d-inline fa-2x left-margin-10"><i className="fas fa-spinner fa-pulse"></i></div>
                  }
                </button>
                <button type="button" className="btn btn-secondary left-margin-10" disabled={role.updating} onClick={this.onCancel}>{btnText}</button>
              </div>
            </form>
          </div>
          {alert.message &&
              <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
          <div className='container'>
              <div className='row align-items-start'>
                  <RolePermissions {...this.props} addHandler={this.onPermissionChange} permissionShowHandler={this.onPermissionsShow} showAdd={showPermissionsAdd} />
                  <RoleUsers {...this.props} usersShowHandler={this.onUsersShow} />
              </div>
          </div>
          {roleSubTable}
        </div>
      </div>
    );
  }
  
  onChange(evt) {
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    const { name, value } = evt.target;
    this.setState({ [name]: value });
  }

  onCancel() {
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    history.push('/admin/roles');
  }

  onSubmit(evt) {
    evt.preventDefault();
    const { alert, admin, clearAlert, update } = this.props;
    const { name, description } = this.state;
    const params = {
        name: name
      , description: description
    };
    update(admin, params);
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onPermissionChange() {
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    history.push('/admin/roles/addpermission');
  }

  onPermissionsShow() {
      if (this.state.subtblDisplay && this.state.subtblDisplay === 'permissions') {
          this.setState({ subtblDisplay: null });
      }
      else {
          this.setState({ subtblDisplay: 'permissions' });
      }
  }

  onUsersShow() {
      if (this.state.subtblDisplay && this.state.subtblDisplay === 'users') {
          this.setState({ subtblDisplay: null });
      }
      else {
          this.setState({ subtblDisplay: 'users' });
      }
  }

  onUserClick(evt) {
      var idx = parseInt(evt.currentTarget.attributes['data-index'].value);
      const { admin, userEdit } = this.props;
      const { role } = admin;
      const { users } = role;
      const item = users[idx];
      userEdit(admin, item.user_id);
  }
};

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const admin = state.administration
  return { alert, auth, admin };
}

const mapDispatchToProps = (dispatch) => ({
    clearAlert() { dispatch(alertActions.clear()); }
  , update(admin, params) { dispatch(adminRoleActions.update(admin, params)); }
  , userEdit(admin, user_id) { dispatch(adminUserActions.get(admin, user_id)); }
});

const connectedRoleEdit = connect(mapStateToProps, mapDispatchToProps)(RoleEdit);
export { connectedRoleEdit as RoleEdit };

function setupPermissionTable(items) {
    var tableInfoObj = prepTableInfo(items);
    var tableDisplayParams = {
        hideFields: ['role_id', 'permission_id', 'created', 'created_id', 'created_user_name', 'permission_created', 'permission_modified', 'selected']
      , allowOptions: {
          edit: { allowed: false, handler: null },
          delete: { allowed: false, handler: null },
          select: { allowed: false, handler: null }
        }
    };
    return <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} />;
}

function setupUserTable(items, auth, userEditHandler) {
    const tableInfoObj = prepTableInfo(items);
    const usereditauth = permissionCheck(auth.permissions, 'admin.user.edit');
    const tableDisplayParams = {
        hideFields: ['role_id', 'user_id']
      , allowOptions: {
          edit: { allowed: usereditauth, handler: userEditHandler },
          delete: { allowed: false, handler: null },
          select: { allowed: false, handler: null }
        }
    };
    return <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} />;
}