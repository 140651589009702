import React from "react";

import { WciModal } from '../../../wcimodal';
import { EndsView } from './ends';

export const MonthlyIteration = props => {
    return(
        <WciModal show={true} className="baseModal">
            <div className="eventsIterationModal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Monthly Options</h5>                    
                        <button type="button"
                                className="close"
                                onClick={props.onclose}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <EndsView {...props} />
                    </div>
                    <div className="modal-footer">
                        <button type="button"
                                className="btn btn-primary"
                                onClick={props.onclose}>Done</button>
                    </div>
                </div>
            </div>
        </WciModal>
    );
};