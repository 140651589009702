import { checkedIn, stbOn, stbUp } from './status';
import { config } from './config';

function stbReservationState(stbs, filter) {
  let found = [];
  for (let i in stbs) {
    let _in = checkedIn(stbs[i]);
    let chkIn = 'unknown';
    if (_in) chkIn = 'in';
    if (_in === false) chkIn = 'out';
    if (chkIn === filter) {
      found.push(stbs[i]);
    }
  }
  return found;
}

function stbPowerState(filter, stbs, wci) {
  let compareto = wci ? config.stbCommTime.wci : config.stbCommTime.hotel;
  let found = [];
  for (let i in stbs) {
    let itm = stbs[i];
    let up = stbUp(itm, compareto);
    let on = stbOn(itm);
    let result = 'off';
    if (up && on) result = 'on';
    if (!up) result = 'down';

    if (result === filter) {
      found.push(itm);
    }
  }
  return found;
}

function stbStateCode(filter, stbs) {
    let found = [];
    for (let i in stbs) {
        const stb = stbs[i];
        if (parseInt(stb.status_code) === parseInt(filter)) {
            found.push(stb);
        }
    }
    return found;
}

function roomCleaner(stbs) {
    let found = [];
    let excludeRegex = /\[/g;
    let cleanregex = /\d+/g;
    for (let i in stbs) {
        let add = true;
        let stb = stbs[i];
        let testresult = stb.room_number.match(excludeRegex);
        if (!testresult) {
            let roomarr = stb.room_number.match(cleanregex);
            if(roomarr) {
                let room = roomarr[0];
                for (let a = 0, b = found.length; a < b; a++) {
                    if (found[a].room_number === room) {
                        add = false;
                        break;
                    }
                }
                if (add) {
                    found.push({room_number: room, check_in_flag: stb.check_in_flag});
                }
            }
        }
    }
    return found;
}

function listFilter(list, searchFields, searchValues, direct = false) {
    let matches = [];
    for(let l in list) {
        const item = list[l];
        let match = checkFields(item, searchFields, searchValues, direct);
        if (match) matches.push(item);
    }
    return matches;
}

function duplicates(list, searchField) {
    const dups = [];
    list.forEach(el => {
        const searchval = el[searchField].toLowerCase();
        const result = list.filter(itm => itm[searchField].toLowerCase() === searchval);
        if (result.length > 1) {
            dups.push(el);
        }
    });
    return dups;
}

function serialLabels(list) {
    const badlabels = [];
    let serialRegex = /\[/g;
    list.forEach(el => {
        const testresult = el.room_number.match(serialRegex);
        if (testresult) badlabels.push(el.room_number);
    });
    return badlabels;
}

function checkFields(item, fields, values, direct) {
    for (let f in fields) {
        const field = fields[f];
        let fieldval = item[field];
        if (!fieldval) continue;
        fieldval = fieldval.toLowerCase();
        if (direct) {
            if (checkValuesDirect(fieldval, values)) {
                return true;
            }
        }
        else {
            if (checkValues(fieldval, values)) {
                return true;
            }    
        }
    }
    return false;
}

function checkValues(value, values) {
    for (let v in values) {
        const val = values[v].toLowerCase();
        if (value.indexOf(val) >= 0) {
            return true;
        }
    }
    return false;
}
function checkValuesDirect(value, values) {
    for (let v in values) {
        const val = values[v].toLowerCase();
        if (value === val) {
            return true;
        }
    }
    return false;
}



export {
    stbReservationState
  , stbPowerState
  , roomCleaner
  , listFilter
  , duplicates
  , serialLabels
  , stbStateCode
};

