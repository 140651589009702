import React from 'react';
import { objectToCSV, objectArrayToCSV, convertSeconds, textsorter } from '../../../_helpers';

export const StatTable = props => {
  const { data, header, site, dte, tname, toggleHandler, show, cssclass, sort, title } = props;

  let tdata = data;
  if (sort) {
    let entries = Object.entries(tdata);
    entries.sort(function(a, b){
      return b[1] - a[1];
    });
    tdata = Object.fromEntries(entries);
  }

  let hdr = null;
  let totals = null;
  let exportbtn = null;
  let togglebtn = null;
  let tbltitle = null;
  let togglebtntext = 'Show ' + tname + ' Table';

  const exportName = site + '_' + dte + '_' + tname + '-';

  if (show) {
    togglebtntext = 'Hide Table';
    hdr = header.map((name, idx) => {
      return <TableHeader key={'sthdr' + idx} hdrtxt={name} />;
    });

    const keys = Object.keys(tdata);
    totals = keys.map((key, idx) => {
      return <TotalsTableRow key={tname + idx} name={key} val={tdata[key]} index={idx} />;
    });

    exportbtn = <button type="button" onClick={() => {objectToCSV(header, tdata, exportName);}} className="btn btn-primary d-none d-lg-inline-block">Export as CSV</button>;
  }

  if (toggleHandler) {
    togglebtn = <button type="button" onClick={() => {toggleHandler(tname);}} className="btn btn-primary d-none d-lg-inline-block mr-1">{togglebtntext}</button>;
  }

  if (title) {
    tbltitle = <span className="stattbltitle">{tname}</span>;
  }

  return (
    <div className={cssclass}>
      <div className="row justify-content-end mr-1">
        {tbltitle}
        {togglebtn}
        {exportbtn}
      </div>
      <table className="table table-sm table-striped">
        <thead className="thead-dark">
          <tr>{hdr}</tr>
        </thead>
        <tbody>
          {totals}
        </tbody>
      </table>
    </div>
  );
}

export const RoomsTotalTable = props => {
  const { tbldata, header, site, dte, tname, toggleHandler, show, drilldownHandler, cssclass, sort } = props;

  let hdr = null;
  let totals = null;
  let exportbtn = null;
  let togglebtn = null;
  let togglebtntext = 'Show ' + tname + ' Table';

  const exportName = site + '_' + dte + '_' + tname + '-';

  if (sort) {
    textsorter(tbldata, 'room', 'asc');
  }

  if (show) {
    togglebtntext = 'Hide Table';
    hdr = header.map((name, idx) => {
      return <TableHeader key={'sthdr' + idx} hdrtxt={name} />;
    });

    totals = tbldata.map((room, idx) => {
      return <RoomTotalsTableRow key={tname + idx} room={room} index={idx} columnClickHandler={props.drilldownHandler} />;
    });

    let csvData = [];
    for (let i = 0, j = tbldata.length; i < j; i++) {
      let droom = tbldata[i];
      let output = {room: droom.room, stbs: droom.stbs, dwell: droom.dwell, app: droom.app, chan: droom.chan, page: droom.con};
      csvData.push(output);
    }
    exportbtn = <button type="button" onClick={() => {objectArrayToCSV(header, csvData, exportName);}} className="btn btn-primary d-none d-lg-inline-block">Export as CSV</button>;
  }

  return(
    <div className={cssclass}>
      <div className="row justify-content-end mr-1">
        <button type="button" onClick={() => {toggleHandler(tname);}} className="btn btn-primary d-none d-lg-inline-block mr-1">{togglebtntext}</button>
        {exportbtn}
      </div>
      <table className="table table-sm table-striped">
        <thead className="thead-dark">
          <tr>{hdr}</tr>
        </thead>
        <tbody>
          {totals}
        </tbody>
      </table>
    </div>
  );
}

const TableHeader = props => (
  <th>{props.hdrtxt}</th>
)

const TotalsTableRow = props => {
  //<td>{Number.parseFloat(props.val).toPrecision(3)}</td>
  return (
    <tr>
      <td>{props.name}</td>
      <td>{props.val}</td>
    </tr>
  );
}

const RoomTotalsTableRow = props => {
  const {columnClickHandler, room} = props;
//  const { total } = room;
  const apptotal = convertSeconds(room.app, false, 3);
  const chantotal = convertSeconds(room.chan, false, 3);
  const contotal = convertSeconds(room.con, false, 3);
  const dwelltotal = convertSeconds(room.dwell, false, 3);

  let apptd = null;
  let chantd = null;
  let contd = null;

  if (apptotal === 0) {
    apptd = <td>0</td>;
  }
  else {
    apptd = <td onClick={columnClickHandler} data-room={room.room} data-type="apps" className="selectableColumn">{apptotal}</td>;
//    apptd = <td onClick={columnClickHandler} data-room={room.room} data-type="apps" className="selectableColumn">{Number.parseFloat(apptotal).toPrecision(3)}</td>;
  }

  if (chantotal === 0) {
    chantd = <td>0</td>;
  }
  else {
    chantd = <td onClick={columnClickHandler} data-room={room.room} data-type="chans" className="selectableColumn">{chantotal}</td>;
//    chantd = <td onClick={columnClickHandler} data-room={room.room} data-type="chans" className="selectableColumn">{Number.parseFloat(chantotal).toPrecision(3)}</td>;
  }

  if (contotal === 0) {
    contd = <td>0</td>
  }
  else {
    contd = <td onClick={columnClickHandler} data-room={room.room} data-type="cons" className="selectableColumn">{contotal}</td>;
//    contd = <td onClick={columnClickHandler} data-room={room.room} data-type="cons" className="selectableColumn">{Number.parseFloat(contotal).toPrecision(3)}</td>;
  }

  return (
    <tr>
      <td>{room.room}</td>
      <td>{room.stbs}</td>
      <td>{dwelltotal}</td>
      {apptd}
      {chantd}
      {contd}
    </tr>
  );
};
