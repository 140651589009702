import React from 'react';
import { connect } from 'react-redux';

import { alertActions, adminPermissionActions } from '../../../_actions';
import { permissionCheck, history, isObjectEmpty }  from '../../../_helpers';

import { NavigationBar } from '../../navigation';

class PermissionEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        authorized: false
      , name: ''
      , description: ''
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onExit = this.onExit.bind(this);
  }

  componentDidMount() {
    const { auth, unauthorized, admin } = this.props;
    if (auth) {
      const hasPermission = permissionCheck(auth.permissions, 'admin.permission.edit');
      if (hasPermission) {
        const { permission } = admin;
        if (!permission.edititem) {
          history.push('/admin/permissions');
          return;
        }
        this.setState({ authorized: true, name: permission.edititem.name, description: permission.edititem.description });
      }
      else {
        unauthorized();
        history.push('/');
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { auth, unauthorized, admin } = this.props;
    const { authorized } = this.state;
    if (auth && !authorized) {
      const hasPermission = permissionCheck(auth.permissions, 'admin.permission.edit');
      if (hasPermission) {
        const { permission } = admin;
        if (!permission.edititem) {
          history.push('/admin/permissions');
          return;
        }
        this.setState({ authorized: true, name: permission.edititem.name, description: permission.edititem.description });
      }
      else {
        unauthorized();
        history.push('/');
      }
    }
  }

  render() {
    const { auth, alert, admin } = this.props;
    if (!auth) return null;
    const { authorized } = this.state;
    if (!authorized) return null;
    const { permission } = admin;
    const { edititem, validity } = permission;

    let btntxt = permission.refresh ? 'Exit' : 'Cancel';

    return(
      <div>
        <NavigationBar header="Admin - Permissions - Edit" />
        <div className="jumbotron">
          <div className="container">
            <div className="row justify-content-md-center">
              <h5>Edit Permission</h5>
            </div>
            <form onSubmit={this.onSubmit}>
              <div className="form-group row">
                <label htmlFor="permission_id" className="col-sm-2 col-form-label text-right">ID:</label>
                <input type="text"
                  id="permission_id"
                  className="col-sm-2 form-control-plaintext"
                  readOnly
                  value={edititem.permission_id} />
                <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#moreinfo" aria-expanded="false" aria-controls="moreinfo">
                  Show More
                </button>
              </div>
              <div className="collapse" id="moreinfo">
                <div className="form-group row">
                  <label htmlFor="created" className="col-sm-2 col-form-label text-right">Created:</label>
                  <input type="text"
                    id="created"
                    className="col-sm-3 form-control-plaintext"
                    readOnly
                    value={edititem.created} />
                  <label htmlFor="createduser" className="col-sm-2 col-form-label text-right">Created By:</label>
                  <input type="text"
                    id="createduser"
                    className="col-sm-3 form-control-plaintext"
                    readOnly
                    value={(edititem.created_user_name) ? edititem.created_user_name : ''} />
                </div>
                <div className="form-group row">
                  <label htmlFor="modified" className="col-sm-2 col-form-label text-right">Modified:</label>
                  <input type="text"
                    id="modified"
                    readOnly
                    className="col-sm-3 form-control-plaintext"
                    value={(edititem.modified === null) ? '' : edititem.modified} />
                  <label htmlFor="modifieduser" className="col-sm-2 col-form-label text-right">Modified By:</label>
                  <input type="text"
                    id="modifieduser"
                    readOnly
                    className="col-sm-3 form-control-plaintext"
                    value={(edititem.modified_user_name === null) ? '' : edititem.modified_user_name} />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="name" className="col-sm-2 col-form-label text-right">Name:</label>
                <input type="text" id="name"
                      className={'col-sm-6 form-control' + (validity ? validity.name ? ' is-valid' : ' is-invalid' : '')}
                      name="name"
                      onChange={this.onChange}
                      value={this.state.name} />
                {validity && !validity.valid && edititem.name.length === 0 &&
                  <div className='invalid-feedback'>Name is required</div>
                }
              </div>
              <div className="form-group row">
                <label htmlFor="description" className="col-sm-2 col-form-label text-right">Description:</label>
                <input type="text" id="description"
                      className={'col-sm-6 form-control' + (validity ? validity.description ? ' is-valid' : ' is-invalid' : '')}
                      name="description"
                      onChange={this.onChange}
                      value={this.state.description} />
                {validity && !validity.valid && edititem.description.length === 0 &&
                  <div className='invalid-feedback'>Description is required</div>
                }
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary" disabled={permission.processing}>Submit</button>&nbsp;&nbsp;
                <button type="button" className="btn btn-secondary ml-2" onClick={this.onExit} disabled={permission.processing}>{btntxt}</button>
              </div>
            </form>
          </div>
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
        </div>
      </div>
    );
  }

  onSubmit(evt) {
    evt.preventDefault();
    const { alert, clearAlert, admin, edit } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    const data = { name: this.state.name, description: this.state.description };
    edit(admin, data);
  }

  onChange(evt) {
    const { alert, clearAlert } = this.props;
    const { name, value } = evt.target;
    this.setState({ [name]: value });
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onExit() {
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    history.push('/admin/permissions');
  }
};

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const admin = state.administration
  return { alert, auth, admin };
}

const mapDispatchToProps = (dispatch) => ({
    unauthorized() { dispatch(alertActions.error('You are not authorized for that page')); }
  , clearAlert() { dispatch(alertActions.clear()); }
  , edit(admin, data) { dispatch(adminPermissionActions.update(admin, data)); }
});

const connectedPermissionEdit = connect(mapStateToProps, mapDispatchToProps)(PermissionEdit);
export { connectedPermissionEdit as PermissionEdit };
