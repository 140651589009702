import { getApiUrl } from '../_helpers';

export const documentationService = {
    add
  , list
  , categories
};

function add(data) {
  var url = getApiUrl() + 'site/documentation/addField';

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response.json().then(data => data));
        }
        else if (response.status === 409) {
          reject("There was an error saving the documentation data.");
        }
        else {
          reject(response.statusText);
        }
      })
      .catch(response => {
        reject(response.statusText);
      });
  });
}

function list() {
  var url = getApiUrl() + 'site/documentation/list';

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response.json().then(data => data));
        }
        else if (response.status === 409) {
          reject("There was an error getting the documentation list.");
        }
        else {
          reject(response.statusText);
        }
      })
      .catch(response => {
        reject(response.statusText);
      });
  });
}

function categories(data) {
  var url = getApiUrl() + 'site/documentation/categories';

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response.json().then(data => data));
        }
        else if (response.status === 409) {
          reject("There was an error getting the documentation categories.");
        }
        else {
          reject(response.statusText);
        }
      })
      .catch(response => {
        reject(response.statusText);
      });
  });
}

