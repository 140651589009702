import React from 'react';
import { connect } from 'react-redux';

import { alertActions, adminUserActions } from '../../../_actions';
import { permissionCheck, history, isObjectEmpty } from '../../../_helpers';

import { NavigationBar } from '../../navigation';

import { UserData } from '../userData';

var clearEdit = true; // this won't work as a state variable because the component unmounts before the state changes.

class UserAdminEdit extends React.Component {
  constructor(props) {
    super(props);

    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.resetPw = this.resetPw.bind(this);
    this.onAssignRole = this.onAssignRole.bind(this);
    this.onAssignSites = this.onAssignSites.bind(this);
  }

  componentDidMount() {
    clearEdit = true;
    const {alert, auth, admin} = this.props;
    if (!auth) {
      history.push('/admin/users');
      return null;
    }
    const allowEdit = permissionCheck(auth.permissions, 'admin.user.edit');
    if (!allowEdit) {
      alertActions.error('You do not have permission to access that page.');
      history.push('/');
      return null;
    }
    if (!admin.user) {
      history.push('/admin/users');
      return null;
    }
    const user = admin.user;
    if (!user.edit) {
      history.push('/admin/users');
      return null;
    }
  }

  componentWillUnmount() {
    const { admin, clear, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    if (clearEdit) {
      clear(admin);
    }
  }

  render() {
    const {alert, auth, admin} = this.props;
    if (!auth) return null;
    const allowEdit = permissionCheck(auth.permissions, 'admin.user.edit');
    if (!allowEdit) return null;
    if (!admin.user) return null;
    const { user } = admin;
    if (!user.edit) return null;
    const { edit, validity } = user;

    const processing = user.processing ? user.processing : false;
    const btnText = user.saved ? 'Exit' : 'Cancel';
    let exit = <button type="button" className="btn btn-secondary left-margin-10" disabled={processing} onClick={this.onCancel}>{btnText}</button>;
    let userData = <UserData item={edit} edit={true} more={true} />;
    let roleList = '';
    if (edit.roles && edit.roles.length > 0) {
      for (let i = 0, j = edit.roles.length; i < j; i++) {
        if (i > 0) roleList += ', ';
        roleList += edit.roles[i].name;
      }
    }

    return (
      <div>
        <NavigationBar header="Admin - Users - Edit" />
        <div className="jumbotron">
          <div className="container">
            {alert.message &&
              <div className={`alert ${alert.type}`}>{alert.message}</div>
            }
            <form onSubmit={this.onSubmit}>
              {userData}
              <div className="form-group row">
                <label htmlFor="first_name" className="col-sm-2 col-form-label text-right">First Name:</label>
                <input type="text"
                  id="first_name"
                  name="first_name"
                  className={'col-sm-6 form-control' + (validity ? validity.first_name ? '' : ' is-invalid' : '')}
                  placeholder="First Name"
                  onChange={this.onChange}
                  value={edit.first_name} />
                {validity && !validity.valid && !edit.first_name &&
                  <div className='offset-sm-2 invalid-feedback'>First name is required</div>
                }
              </div>
              <div className="form-group row">
                <label htmlFor="last_name" className="col-sm-2 col-form-label text-right">Last Name:</label>
                <input type="text"
                  name="last_name"
                  className={'col-sm-6 form-control' + (validity ? validity.last_name ? '' : ' is-invalid' : '')}
                  placeholder="Last Name"
                  onChange={this.onChange}
                  value={edit.last_name} />
                {validity && !validity.valid && !edit.last_name &&
                  <div className='offset-sm-2 invalid-feedback'>Last name is required</div>
                }
              </div>
              <div className="form-group row">
                <label htmlFor="phone" className="col-sm-2 col-form-label text-right">Phone:</label>
                <input type="text"
                  name="phone"
                  className={'col-sm-6 form-control' + (validity ? validity.phone ? '' : ' is-invalid' : '')}
                  placeholder="Phone"
                  onChange={this.onChange}
                  value={edit.phone} />
                {validity && !validity.valid && !edit.ldap_user && !edit.phone &&
                  <div className='offset-sm-2 invalid-feedback'>Phone is required for external users</div>
                }
                {validity && !validity.valid && edit.phone && edit.phone.length < 10 &&
                  <div className='offset-sm-2 invalid-feedback'>Phone has to be at least 10 digits</div>
                }
              </div>
              <div className="form-group row">
                <label htmlFor="email" className="col-sm-2 col-form-label text-right">Email:</label>
                <input type="email"
                  name="email"
                  className={'col-sm-6 form-control' + (validity ? user.validity.email ? '' : ' is-invalid' : '')}
                  placeholder="Email"
                  onChange={this.onChange}
                  value={edit.email} />
                {validity && !validity.valid && !edit.email &&
                  <div className='offset-sm-2 invalid-feedback'>Email is required</div>
                }
              </div>
              <div className="form-group row" style={{display:"none"}}>
                <div className="d-inline col-sm-2"></div>
                <div className="d-inline form-check col-sm-6">
                  <input type="checkbox"
                    className="form-check-input position-static"
                    id="ldapuser"
                    name="ldap_user"
                    onChange={this.onChange}
                    checked={edit.ldap_user} />&nbsp;
                  <label className="form-check-label" htmlFor="ldapuser">LDAP User</label>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2 col-form-label text-right">Roles: </div>
                <input type="text"
                  className="col-sm-6 form-control-plaintext"
                  readOnly
                  value={roleList} />
              </div>
              <div className="form-group row">
                <div className="col-sm-2 col-form-label text-right">Sites: </div>
                <input type="text"
                  className="col-sm-6 form-control-plaintext"
                  readOnly
                  value={edit.sites ? edit.sites.length : 0} />
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary" disabled={processing}>
                  <span>Submit</span>
                  {processing &&
                    <div className="d-inline fa-2x left-margin-10"><i className="fas fa-spinner fa-pulse"></i></div>
                  }
                </button>
                {exit}
                <button type="button" className="btn btn-warning left-margin-10" disabled={processing} onClick={this.resetPw}>Reset Password</button>
                <button type="button" className="btn btn-success left-margin-10" disabled={processing} onClick={this.onAssignRole}>Manage Roles</button>
                <button type="button" className="btn btn-success left-margin-10" disabled={processing} onClick={this.onAssignSites}>Manage Sites</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  onCancel() {
    const { admin, cancel, alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    cancel(admin);
  }

  onChange(e) {
    const { admin, input } = this.props;
    input(admin, e);
  }

  resetPw() {
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    clearEdit = false;
    history.push('/admin/users/reset/password');
  }

  onAssignSites() {
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    clearEdit = false;
    history.push('/admin/users/assign/site');
  }

  onSubmit(e) {
    e.preventDefault();
    const { admin, submit, clearAlert } = this.props;
    clearAlert();
    clearEdit = false;
    submit(admin);
  }

  onAssignRole() {
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    clearEdit = false;
    history.push('/admin/users/assign/role');
  }
}

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const admin = state.administration;
  return { alert, auth, admin };
}

const mapDispatchToProps = (dispatch) => ({
    clearAlert() { dispatch(alertActions.clear()); }
  , input(admin, e) { dispatch(adminUserActions.input(admin, e)); }
  , cancel(admin) { dispatch(adminUserActions.editCancel(admin)); }
  , submit(admin) { dispatch(adminUserActions.update(admin)); }
  , clear(admin) { dispatch(adminUserActions.clearEdit(admin)); }
});

const connectedUserAdminEdit = connect(mapStateToProps, mapDispatchToProps)(UserAdminEdit);
export { connectedUserAdminEdit as UserAdminEdit };
