import React from 'react';
import { WciModal } from './wcimodal';

export const ConfirmModal = props => (
  <WciModal show={props.options.show} className="baseModal">
    <div className="deleteConfirmModal">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{props.options.title}</h5>
          <button type="button"
                  className="close"
                  onClick={props.options.handlers.cancel}>&times;</button>
        </div>
        <div className="modal-body">
          <p>{props.options.question}</p>
        </div>
        <div className="modal-footer">
          <button type="button"
                  className="btn btn-secondary"
                  onClick={props.options.handlers.cancel}>Cancel</button>
          <button type="button"
                  className={'btn ' + props.options.proceed.className}
                  onClick={props.options.handlers.proceed}>{props.options.proceed.title}</button>
        </div>
      </div>
    </div>
  </WciModal>
);
