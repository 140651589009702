import { userConstants } from '../_constants';
import { alertActions, sitesActions, trackingActions, eventActions, streamingActions } from './';
import { history, permissionCheck, generateTemporaryPassword } from '../_helpers';
import { userService } from '../_services';

export const userActions = {
    init
  , login
  , logout
  , resetTempPW
  , verifypw
  , cancelverifypw
  , inputverifypw
  , checkverifypw
  , sendUserName
  , resetPw
  , gotosites
};

function init() {
  return dispatch => {
    dispatch(request());

    userService.init()
      .then(
        data => {
          if (data.logged_in) {
            dispatch(success(data));
            if (parseInt(data.new) === 0) {
              let admin = hasAdmin(data);
              let portal = hasPortal(data);
              if (!admin && !portal) {
                // If neither admin or portal are assigned the user does not have access to WorldVue
                dispatch(alertActions.error('Your permissions are not set properly. Please let the admin know.'));
                return;
              }
              history.push('/');
//              let hasSinglePermission = (admin && portal) ? false : admin || portal;
//              if (hasSinglePermission) {
//                if(admin) {
//                  history.push('/admin/users');
//                  return;
//                }
//                if(portal) {
//                  history.push('/portal/sites');
//                  return;
//                }
//              }
            }
            else {
              history.push('/setpassword');
            }
          }
          else {
            dispatch(failure(data.logged_in))
            history.push('/login'); // Go to Login page.
          }
        },
        error => {
          dispatch(failure(false));
          history.push('/login'); // Go to Login page.
        }
      );
  };

  function request() { return { type: userConstants.INIT_REQUEST }; }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user }; }
  function failure(loggedIn) { return { type: userConstants.LOGIN_FAILURE, loggedIn }; }
}

function login(username, password, tracking) {
    return dispatch => {
        dispatch(request({ username: username }));

        userService.login(username, password)
            .then(
                user => {
                  dispatch(success(user));
                  dispatch(trackingActions.notifylogon(tracking));
                  if (parseInt(user.new) === 1) {
                    history.push('/setpassword');
                  }
                  else {
                    let admin = hasAdmin(user);
                    let portal = hasPortal(user);
                    if (!admin && !portal) {
                      // If niether admin or portal are assigned the user does not have access to WorldVue
                      dispatch(alertActions.error('Your permissions are not set properly. Please let the admin know.'));
                      return;
                    }
//                    let hasSinglePermission = (admin && portal) ? false : admin || portal;
//                    if (hasSinglePermission) {
//                      if(admin) {
//                        history.push('/admin/user');
//                        return;
//                      }
//                      if(portal) {
//                        if (permissionCheck(user.permissions, 'portal.site')) {
//                          history.push('/portal/sites');
//                          return;
//                        }
//                      }
//                    }
                    history.push('/');
                  }
                },
                error => {
                  dispatch(failure());
                  if (error.error) {
                      if (error.error.toLowerCase().indexOf('unauthorized') >= 0) {
                          dispatch(alertActions.error('User name or Password is incorrect'));
                      }
                      else {
                          dispatch(alertActions.error('Login not successful because: ' + error.error));
                      }
                  }
                  else {
                      dispatch(alertActions.error(`Login not successful because: ${error}`));                    
                  }
                }
            );
    };

  function request(user) { return { type: userConstants.LOGIN_REQUEST, user }; }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user }; }
  function failure() { return { type: userConstants.LOGIN_FAILURE }; }
}

function logout() {
  return dispatch => {
    dispatch(sitesActions.clear());
    dispatch(eventActions.clear());
    dispatch(streamingActions.clear());

    userService.logout()
      .then(
        response => {
          dispatch(success());
          history.push('/login');
        },
        error => {
          // No need to do anything here.
        }
      );
  }
  function success() { return { type: userConstants.LOGOUT }; }
}

function resetTempPW(auth, allpw) {
  return dispatch => {
    const d = { user_id: auth.user_id, password: allpw.currpw, newpw: allpw.newpw };
    const ds = JSON.stringify(d);
    userService.resetTempPW(ds)
      .then(
        result => {
          dispatch(alertActions.info('Password change successful'));
          history.push('/login');
        },
        error => {
          dispatch(alertActions.error(error));
        }
      );
  };
}

function verifypw(authentication) {
  return dispatch => {
    let newauthentication = {
      ...authentication,
      verify: true
    };
    dispatch(request(newauthentication));
  };

  function request(obj) { return { type: userConstants.VERIFY_PASSWORD_DISPLAY, obj }; }
}

function cancelverifypw(authentication) {
  return dispatch => {
    const { verify, pwinput, proceed, ...cleanedauthentication } = authentication;
    dispatch(request(cleanedauthentication));
  };
  function request(obj) { return { type: userConstants.VERIFY_PASSWORD_CANCEL, obj }; }
}

function inputverifypw(newauthentication) {
  return dispatch => {
    dispatch(request(newauthentication));
  };
  function request(obj) { return { type: userConstants.VERIFY_PASSWORD_INPUT, obj }; }
}

function checkverifypw(authentication) {
  return dispatch => {
    let newauthentication = {
      ...authentication,
      submitted: true
    };
    dispatch(request(newauthentication));

    const { user } = authentication;
    const d = {
        user_id: user.user_id
      , password: authentication.pwinput
    };
    const ds = JSON.stringify(d);
    userService.verifypassword(ds)
      .then(
        result => {
          const { verify, pwinput, ...cleanedauthentication } = authentication;
          cleanedauthentication.proceed = true;
          dispatch(request(cleanedauthentication));
        },
        error => {
          dispatch(failure(authentication));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: userConstants.VERIFY_PASSWORD_REQUEST, obj }; }
  function success(obj) { return { type: userConstants.VERIFY_PASSWORD_SUCCESS, obj }; }
  function failure(obj) { return { type: userConstants.VERIFY_PASSWORD_FAILURE, obj }; }
}

function sendUserName(auth, email) {
    return dispatch => {
        const reqauth = {
            ...auth,
            submitted: true
        };
        dispatch(request(reqauth));

        const d = { email:email };
        const ds = JSON.stringify(d);
        userService.verifyEmail(ds)
        .then(
            result => {
                if(result === 'true') {
                    userService.sendUserName(ds)
                    .then(
                        result => {
                            dispatch(success(auth));
                            dispatch(alertActions.info('Your user name has been sent to you.'));
                        },
                        error => {
                            dispatch(failure(auth));
                            dispatch(alertActions.error(error));
                        }
                    );
                }
                else {
                    dispatch(failure(auth));
                    dispatch(alertActions.error('Could not find the email specified.'));
                }
            },
            error => {
                dispatch(failure(auth));
                dispatch(alertActions.error(error));
            }
        );
    };
    function request(obj) { return { type: userConstants.SEND_USERNAME_REQUEST, obj }; }
    function success(obj) { return { type: userConstants.SEND_USERNAME_SUCCESS, obj }; }
    function failure(obj) { return { type: userConstants.SEND_USERNAME_FAILURE, obj }; }
}

function resetPw(auth, username) {
    return dispatch => {
        const reqauth = {
            ...auth,
            submitted: true
        };
        dispatch(request(reqauth));

        const d = { username:username };
        const ds = JSON.stringify(d);
        userService.verifyUserName(ds)
        .then(
            result => {
                if(!isNaN(result)) {
                    dispatch(alertActions.info('Found the user name specified.'));
                    const temppw = generateTemporaryPassword();
                    const d = { user_id: result, temppw: temppw, user_name: username };
                    const ds = JSON.stringify(d);
                    userService.resetForgottenPw(ds).then(
                        result => {
                            dispatch(alertActions.info('Temporary password has been set.'));
                            dispatch(success(auth));
                        },
                        error => {
                            dispatch(failure(auth));
                            dispatch(alertActions.error(error));
                        }
                    );
                }
                else {
                    dispatch(failure(auth));
                    dispatch(alertActions.error(error));
                }
            },
            error => {
                dispatch(failure(auth));
                dispatch(alertActions.error(error));
            }
        );
    };
    function request(obj) { return { type: userConstants.RESET_PASSWORD_REQUEST, obj }; }
    function success(obj) { return { type: userConstants.RESET_PASSWORD_SUCCESS, obj }; }
    function failure(obj) { return { type: userConstants.RESET_PASSWORD_FAILURE, obj }; }
}

function gotosites(auth) {
    return dispatch => {
        dispatch(request(auth));
        history.push('/portal/sites');
    };
    function request(obj) { return { type: userConstants.GO_TO_SITES, obj }; }
}




// PRIVATE METHODS
function hasAdmin(auth) {
  if (auth.permissions.indexOf('admin') >= 0) {
    return true;
  }
  return false;
}

function hasPortal(auth) {
  if (auth.permissions.indexOf('portal') >= 0) {
    return true;
  }
  return false;
}
