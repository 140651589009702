import React from 'react';
import { connect } from 'react-redux';

import { history, generateTemporaryPassword } from '../../../_helpers';
import { registrationActions } from '../../../_actions';

import { NavigationBar } from '../../navigation';
import { ProcessIndicator } from '../../processIndicator';
import { UserDataEdit } from '../../userDataEdit';

class RegisteredEdit extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onGenTempPW = this.onGenTempPW.bind(this);
    this.onEditCancel = this.onEditCancel.bind(this);
  }

  componentDidMount() {
    const { alert, reg } = this.props;
    if (!reg) history.push('/admin/register/list');
    if (!reg.selected) history.push('/admin/register/list');
  }

  render() {
    const { alert, reg } = this.props;
    if (!reg) return null;
    if (!reg.selected) return null;

    let header = 'Admin - Registrations - Edit';
    let processIndShow = reg.processing ? reg.processing : false;
    const validity = reg.selected.validity ? reg.selected.validity : null;

    let editForm = null;
    if (reg.selected.edit){
      editForm = <UserDataEdit user={reg.selected.edit}
                                submitHandler={this.onSubmit}
                                changeHandler={this.onChangeHandler}
                                genPWHandler={this.onGenTempPW}
                                cancelHandler={this.onEditCancel}
                                pwresetHandler={null}
                                allowPWChange={false}
                                pwChangeCancelHandler={null}
                                validity={validity}
                                disable={processIndShow} />;
    }

    return (
      <div>
        <NavigationBar header={header} />
        <div className="container">
          <ProcessIndicator show={processIndShow} />
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
          {editForm}
        </div>
      </div>
    );
  }

  onSubmit(e) {
    e.preventDefault();
    const { submit, reg } = this.props;
    submit(reg);
//    const { submit, reg, admin } = this.props;
//    submit(reg, admin);
  }

  onChangeHandler(e) {
    const { input, reg } = this.props;
    let { name, value } = e.target;
    const change = {name: name, value: value};
    input(reg, change);
  }

  onGenTempPW() {
    const pw = generateTemporaryPassword();
    const { input, reg } = this.props;
    const change = {name: 'password', value: pw};
    input(reg, change);
  }

  onEditCancel() {
    const { cancel, reg } = this.props;
    cancel(reg);
  }
};

function mapStateToProps(state) {
  const alert = state.alert;
  const reg = state.registration;
//  const admin = state.administration;
  return { alert, reg }; //, admin };
}

const mapDispatchToProps = (dispatch) => ({
    input(reg, change) { dispatch(registrationActions.input(reg, change)); }
  , cancel(reg) { dispatch(registrationActions.cancel(reg)); }
  , submit(reg) { dispatch(registrationActions.save(reg)); }
//  , submit(reg, admin) { dispatch(registrationActions.save(reg, admin)); }
});

const connectedRegisteredEdit = connect(mapStateToProps, mapDispatchToProps)(RegisteredEdit);
export { connectedRegisteredEdit as RegisteredEdit };
