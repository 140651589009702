import React from "react";

export const EndsView = props => {
    const validity = props.validity;
    const inputs = props.inputs;
    const occurtext = inputs && !inputs.ends_count ? 'occurrence' : (parseInt(inputs.ends_count) === 1) ? 'occurrence' : 'occurrences';
    const neverChecked = inputs && !inputs.ends ? true : inputs.ends.length === 0 ? true : false;
    return(
        <div className="form-group">
            <div className="font-weight-bold mb-2">Ends</div>
            <div className="form-check">
                <input className="form-check-input" type="radio" id="ends1" name="ends" value="" onClick={props.onselect} checked={neverChecked} />
                <label className="form-check-label" for="ends1">Never</label>
            </div>
            <div className="row">
                <div className="col-md-2">
                    <div className="form-check">
                        <input className="form-check-input" type="radio" id="ends2" name="ends" value="on" onClick={props.onselect} checked={inputs && inputs.ends && inputs.ends === 'on' ? true : false} />
                        <label className="form-check-label" for="ends2">On</label>
                    </div>
                </div>
                <div className="col-md-8">
                    <input className="form-control" 
                            type="date" 
                            name="ends_date"
                            required pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
                            min={props.dtmin}
                            value={inputs && inputs.ends_date ? inputs.ends_date : ''}
                            disabled={inputs && inputs.ends !== 'on'}
                            onChange={props.onchange} />
                    {validity && !validity.valid && validity.ends_date_err &&
                        <div className='offset-sm-2 invalid-feedback'>{validity.ends_date_err}</div>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-md-2">
                    <div className="form-check">
                        <input className="form-check-input" type="radio" id="ends3" name="ends" value="after" onClick={props.onselect} checked={inputs && inputs.ends && inputs.ends === 'after' ? true : false} />
                        <label className="form-check-label" for="ends3">After</label>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="input-group">
                        <input className="form-control" 
                                type="number" 
                                name="ends_count"
                                min="1"
                                max="99"
                                placeholder="Ends Count"
                                value={inputs && inputs.ends_count ? inputs.ends_count : ''}
                                disabled={inputs && inputs.ends !== 'after'}
                                onChange={props.onchange} />
                        {validity && !validity.valid && validity.ends_count_err &&
                            <div className='offset-sm-2 invalid-feedback'>{validity.ends_count_err}</div>
                        }
                        <div className="input-group-append pl-1">{occurtext}</div>
                    </div>
                </div>
                
            </div>
        </div>
    );
};