export const registrationConstants = {
    REGISTER_RETURN: 'REGISTER_RETURN'
  , REGISTER_REQUEST: 'REGISTER_REQUEST'
  , REGISTER_SUCCESS: 'REGISTER_SUCCESS'
  , REGISTER_FAILURE: 'REGISTER_FAILURE'
  , REGISTER_CLEAR: 'REGISTER_CLEAR'
  , REGISTER_LIST_REQUEST: 'REGISTER_LIST_REQUEST'
  , REGISTER_LIST_SUCCESS: 'REGISTER_LIST_SUCCESS'
  , REGISTER_LIST_FAILURE: 'REGISTER_LIST_FAILURE'
  , REGISTER_SELECT_SUCCESS: 'REGISTER_SELECT_SUCCESS'
  , REGISTER_SELECT_FAILURE: 'REGISTER_SELECT_FAILURE'
  , REGISTER_EDIT_INPUT: 'REGISTER_EDIT_INPUT'
  , REGISTER_EDIT_CANCEL: 'REGISTER_EDIT_CANCEL'
  , REGISTER_EDIT_SAVE_REQUEST: 'REGISTER_EDIT_SAVE_REQUEST'
  , REGISTER_EDIT_SAVE_SUCCESS: 'REGISTER_EDIT_SAVE_SUCCESS'
  , REGISTER_EDIT_SAVE_FAILURE: 'REGISTER_EDIT_SAVE_FAILURE'
  , REGISTER_REMOVE_REQUEST: 'REGISTER_REMOVE_REQUEST'
  , REGISTER_REMOVE_SUCCESS: 'REGISTER_REMOVE_SUCCESS'
  , REGISTER_REMOVE_FAILURE: 'REGISTER_REMOVE_FAILURE'
};
