import React, { useState } from 'react';

import { WciModal } from '../wcimodal';

export const UserNameModal = props => {
    const [usrnm, setUsrNm] = useState('');

    return(
        <WciModal show={props.show} className={props.className}>
            <div className={props.placementClass}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">What is your user name?</h5>
                        <button type="button"
                                className="close"
                                onClick={props.close}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <p>Please provide your user name for this site.</p>
                        <div>
                            <input type="input" value={usrnm} onChange={(evt) => { setUsrNm(evt.target.value); }} style={{width:'400px'}} />
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button"
                              className="btn btn-secondary"
                              onClick={props.close}>Cancel</button>
                      <button type="button"
                              className="btn btn-primary"
                              onClick={(evt) => { props.proceed(evt, usrnm); }}>Submit</button>
                    </div>
                </div>
            </div>
        </WciModal>
    );
};
