import React from 'react';

import { history } from '../../../_helpers';

export const RoleUsers = props => {
    const { auth, admin } = props;
    if (!auth) {
        history.push('/admin/roles');
        return null;
    }
    const role = admin.role;
    const users = role.users;

    return (
        <div className='col'>
            <div className="row justify-content-md-center">
                <h5 style={{paddingTop: "8px"}}>Current Users ({users.length})</h5>
                <button type="button" className="btn btn-info left-margin-10" onClick={props.usersShowHandler}>
                    Show Users
                </button>
            </div>
        </div>
    );
};