export const streamingConstants = {
    CHAN_CLEAR: 'CHAN_CLEAR'

  , CHAN_LIST_REQUEST: 'CHAN_LIST_REQUEST'
  , CHAN_LIST_SUCCESS: 'CHAN_LIST_SUCCESS'
  , CHAN_LIST_FAILURE: 'CHAN_LIST_FAILURE'

  , CHAN_EDIT_REQUEST: 'CHAN_EDIT_REQUEST'
  , CHAN_EDIT_SUCCESS: 'CHAN_EDIT_SUCCESS'
  , CHAN_EDIT_FAILURE: 'CHAN_EDIT_FAILURE'

  , CHAN_ADD_REQUEST: 'CHAN_ADD_REQUEST'
  , CHAN_EDIT_CLEAR_REQUEST: 'CHAN_EDIT_CLEAR_REQUEST'
  , CHAN_EDIT_REQUEST: 'CHAN_EDIT_REQUEST'

  , CHAN_DEACTIVATE_REQUEST: 'CHAN_DEACTIVATE_REQUEST'
  , CHAN_DEACTIVATE_CANCEL: 'CHAN_DEACTIVATE_CANCEL'
};
