import React from 'react';
import { connect } from 'react-redux';

import { streamingActions } from '../../../_actions';
import { history } from '../../../_helpers';

class StreamingChannelAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = { inputs: {"active": 1} };

        this.onInputChange = this.onInputChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onClear = this.onClear.bind(this);
    }

    render() {
        const { monitor } = this.props;
        const { inputs } = this.state;
        const { chan } = monitor;

        const changeHandler = this.onInputChange;

        let processing = chan.processing ? chan.processing: false;
        let validity = chan.validity ? chan.validity : null;

        const btnText = chan.saved ? 'Exit' : 'Cancel';

        return(
            <div className="container">
                <div className="row justify-content-md-center mt-3 ts-2">Add New Streaming Channel</div>
                <form onSubmit={this.onSubmit} className="mt-2">
                    <div className="form-group row">
                        <label htmlFor="title" className="col-sm-2 col-form-label text-right">Logical Channel:</label>
                        <input type="number"
                            id="logical_channel"
                            name="logical_channel"
                            className={'col-sm-6 form-control' + (validity ? validity.logical_channel ? '' : ' is-invalid' : '')}
                            placeholder="Logical Channel no more than 2 digits"
                            onChange={changeHandler}
                            disabled={processing}
                            value={inputs && inputs.logical_channel ? inputs.logical_channel : ''} />
                        {validity && !validity.valid && (!inputs || !inputs.logical_channel) &&
                            <div className='offset-sm-1 invalid-feedback'>Logical channel is required</div>
                        }
                        {validity && !validity.valid && inputs && inputs.logical_channel && inputs.logical_channel.length > 2 &&
                            <div className='offset-sm-2 invalid-feedback'>Logical channel must be less than 2 digits</div>
                        }
                        {validity && !validity.valid && inputs && isNaN(inputs.logical_channel) &&
                            <div className='offset-sm-2 invalid-feedback'>Logical channel must be numeric</div>
                        }
                    </div>
                    <div className="form-group row">
                        <label htmlFor="title" className="col-sm-2 col-form-label text-right">Menu Text:</label>
                        <input type="text"
                            id="menu_text"
                            name="menu_text"
                            className={'col-sm-6 form-control' + (validity ? validity.menu_text ? '' : ' is-invalid' : '')}
                            placeholder="Menu Text"
                            onChange={changeHandler}
                            disabled={processing}
                            maxLength="25"
                            value={inputs && inputs.menu_text ? inputs.menu_text : ''} />
                        {validity && !validity.valid && (!inputs || !inputs.menu_text) &&
                            <div className='offset-sm-1 invalid-feedback'>Menu Text is required</div>
                        }
                    </div>
                    <div className='form-group row'>
                        <label htmlFor="no_code" className='col-sm-2 col-form-label text-right'>No Code Required:</label>
                        <input type="checkbox" 
                            id="no_code"
                            name="no_code"
                            className={'col-sm-4 form-check-input' + (validity ? validity.access_code ? '' : ' is-invalid' : '')}
                            style={{marginTop: "14px"}}
                            onChange={changeHandler}
                            disabled={processing}
                            value={inputs && inputs.no_code ? inputs.no_code : false} />
                    </div>
                    <div className="form-group row">
                        <label htmlFor="title" className="col-sm-2 col-form-label text-right">Access Code:</label>
                        <input type="number"
                            id="access_code"
                            name="access_code"
                            className={'col-sm-6 form-control' + (validity ? validity.access_code ? '' : ' is-invalid' : '')}
                            placeholder="Access Code between 4 to 6 digits"
                            onChange={changeHandler}
                            disabled={processing}
                            value={inputs && inputs.access_code && inputs.access_code >= 0 ? inputs.access_code : ''} />
                        {validity && !validity.valid && (!inputs || !inputs.access_code) &&
                            <div className='offset-sm-1 invalid-feedback'>Access Code is required</div>
                        }
                        {validity && !validity.valid && inputs && inputs.access_code &&  inputs.access_code > 0 && inputs.access_code.length < 4 &&
                            <div className='offset-sm-2 invalid-feedback'>Access code must be at least 4 digits</div>
                        }
                        {validity && !validity.valid && inputs && inputs.access_code && inputs.access_code.length > 6 &&
                            <div className='offset-sm-2 invalid-feedback'>Access code can be no more than 6 digits</div>
                        }
                        {validity && !validity.valid && inputs && isNaN(inputs.access_code) &&
                            <div className='offset-sm-2 invalid-feedback'>Access code must be numeric</div>
                        }
                    </div>
                    <div className="form-group row">
                        <label htmlFor="description" className="col-sm-2 col-form-label text-right">Description:</label>
                        <input type="text"
                            id="description"
                            name="description"
                            className={'col-sm-6 form-control' + (validity ? validity.description ? '' : ' is-invalid' : '')}
                            placeholder="Channel Description"
                            onChange={changeHandler}
                            disabled={processing}
                            maxLength="100"
                            value={inputs && inputs.description ? inputs.description : ''} />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary" disabled={processing}>
                            <span>Submit</span>
                            {processing &&
                            <div className="d-inline fa-2x left-margin-10"><i className="fas fa-spinner fa-pulse"></i></div>
                            }
                        </button>
                        <button type="button" className="btn btn-secondary left-margin-10" disabled={processing} onClick={this.onCancel}>{btnText}</button>
                        <button type="button" className="btn btn-secondary left-margin-10" disabled={processing} onClick={this.onClear}>Clear</button>
                    </div>
                </form>
            </div>
        );
    }

  onInputChange(evt) {
    const { name, value, checked } = evt.target;
    const { inputs } = this.state;
    let inputval = value;
    let inputname = name;
    if (name === 'no_code') {
        inputname = 'access_code'
        if (checked) inputval = '-1';
        else inputval = null;
    }
    const newinputs = {
      ...inputs,
      [inputname]: inputval
    };
    this.setState({ inputs: newinputs });
  }

  onCancel() {
    history.push('/portal/streaming');
  }

  onSubmit(evt) {
    evt.preventDefault();
    const { auth, monitor, save } = this.props;
    const { inputs } = this.state;
    save(monitor, inputs, auth);
  }

  onClear() {
    const { monitor, clear } = this.props;
    clear(monitor);
    this.setState({ inputs: null });
  }
}

function mapStateToProps(state) {
  const auth = state.authentication.user;
  const monitor = state.monitor;
  return { auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    save(monitor, inputs, auth) { dispatch(streamingActions.channeledit(monitor, inputs, auth)); }
  , clear(monitor) { dispatch(streamingActions.chaneditclear(monitor)); }
});

const connectedStreamingChannelAdd = connect(mapStateToProps, mapDispatchToProps)(StreamingChannelAdd);
export { connectedStreamingChannelAdd as StreamingChannelAdd };
