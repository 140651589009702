import React from 'react';
import { connect } from 'react-redux';

import { streamingActions } from '../../../_actions';

import { ProcessIndicator } from '../../processIndicator';

import { StreamingChannelData } from './chandata';
import { StreamingChannelList } from './chanlist';
import { StreamingChannelAdd } from './chanadd';
import { StreamingChannelEdit } from './chanedit';

class StreamingChannels extends React.Component {
  componentDidMount() {
    const { authorized, monitor, list } = this.props;
    const { site, chan } = monitor;
    if (authorized && site.edit) {
      if (!chan.list) {
        list(monitor);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { authorized, monitor, list } = this.props;
    const { site, chan } = monitor;
    if (authorized && site.edit) {
      if (!chan.list && !chan.processing) {
        list(monitor);
      }
    }
  }

  render() {
    const { monitor, pause, authorized, viewtype } = this.props;
    if (!authorized) return null;
    const { site, chan } = monitor;
    if (site && !site.edit) return null;

    let processIndShow = false;
    if (site.processing) processIndShow = site.processing;
    if (chan.processing) processIndShow = chan.processing;
    const paused = pause || processIndShow;

    let scd = null;
    let scl = null;
    let sca = null;
    let sce = null;
    switch(viewtype) {
      case 'chanadd':
        sca = <StreamingChannelAdd pause={paused} />;
        break;
      case 'chanedit':
        sce = <StreamingChannelEdit pause={paused} />;
        break;
      default:
        scd = <StreamingChannelData />;
        scl = <StreamingChannelList pause={paused} />;
    }

    return(
      <div id="streamchans">
        <ProcessIndicator show={processIndShow} />
        {scd}
        {scl}
        {sca}
        {sce}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const monitor = state.monitor;
  return { monitor };
}

const mapDispatchToProps = (dispatch) => ({
  list(monitor) { dispatch(streamingActions.channellist(monitor)); }
});

const connectedStreamingChannels = connect(mapStateToProps, mapDispatchToProps)(StreamingChannels);
export { connectedStreamingChannels as StreamingChannels };
