import React from "react";

import { WciModal } from '../../../wcimodal';
import { EndsView } from './ends';

export const DailyIteration = props => {
    return(
        <WciModal show={true} className="baseModal">
            <div className="eventsIterationModal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Daily Options</h5>                    
                        <button type="button"
                            className="close"
                            onClick={props.onclose}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className="font-weight-bold mb-2">Repeats</div>
                        <div className="row">
                            <div className="col-md-2">
                                <label htmlFor="iterationNumber" className="col-md-2 text-right">Every:</label>
                            </div>
                            <div className="col-md-4">
                                <input type="number" 
                                        id="iterationNumber"
                                        name="iteration_number"
                                        className="col-md-5"
                                        min="1"
                                        max="99"
                                        onChange={props.onchange}
                                        value={props.inputs && props.inputs.iteration_number ? props.inputs.iteration_number : 1} />&nbsp;Days
                            </div>
                        </div>
                        <EndsView {...props} />
                    </div>
                    <div className="modal-footer">
                        <button type="button"
                                className="btn btn-primary"
                                onClick={props.onclose}>Done</button>
                    </div>
                </div>
            </div>
        </WciModal>
    );
};