var generatePassword = require('password-generator');

function generateTemporaryPassword() {
  return generatePassword(8, false, /\d/, 'wci-');
}

function generateRandomPassword() {
  return generatePassword(10, false);
}

module.exports = {
    generateTemporaryPassword
  , generateRandomPassword
}
