import React from 'react';
import { connect } from 'react-redux';

const GroupsAdmin = function(props) {
  return (
    <div>Group Admin</div>
  );
}

function mapStateToProps(state) {
  const currUser = state.authentication.user;
  return { currUser };
}

const connectedGroupsAdmin = connect(mapStateToProps)(GroupsAdmin);
export { connectedGroupsAdmin as GroupsAdmin };
