export const profileConstants = {
    CLEAR: 'PROFILE_CLEAR'
  , INPUT: 'PROFILE_INPUT'
  , GET_REQUEST: 'PROFILE_GET_REQUEST'
  , GET_SUCCESS: 'PROFILE_GET_SUCCESS'
  , GET_FAILURE: 'PROFILE_GET_FAILURE'
  , UPDATE_REQUEST: 'PROFILE_UPDATE_REQUEST'
  , UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS'
  , UPDATE_FAILURE: 'PROFILE_UPDATE_FAILURE'
};
