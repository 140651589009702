import React from 'react';
import { connect } from 'react-redux';

import { registrationActions } from '../../../_actions';
import { permissionCheck, config, prepTableInfo } from '../../../_helpers';

import { NavigationBar } from '../../navigation';
import { ProcessIndicator } from '../../processIndicator';
import { DataTable } from '../../dataTable';

class RegisteredList extends React.Component {
  constructor(props) {
    super(props);

    this.onRowClick = this.onRowClick.bind(this);
    this.onRowDelete = this.onRowDelete.bind(this);
  }

  componentDidMount() {
    const { list, reg } = this.props;
    if (!reg.list) list();
  }

  componentDidUpdate() {
    const { list, reg } = this.props;
    if (!reg.list && !reg.processing) {
      list();
    }
  }

  render() {
    const { auth, alert, reg } = this.props;
    if (!reg) return null;

    let processIndShow = reg.processing ? reg.processing : false;
    let dataTable = null;
    let header = 'Admin - Registrations - List';

    if (reg.list && reg.list.length > 0) {
      const editHandler = this.onRowClick;
      const deleteHandler = this.onRowDelete;
      const canDelete = permissionCheck(auth.permissions, 'admin.registered.delete')
      var tableInfoObj = prepTableInfo(reg.list);
      var tableDisplayParams = {
          hideFields: ['register_id']
        , allowOptions: {
            edit: { allowed: true, handler: editHandler },
            delete: { allowed: canDelete, handler: deleteHandler },
            select: { allowed: false, handler: null }
          }
      };
      dataTable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} />;
    }

    return (
      <div>
        <NavigationBar header={header} />
        <div className="container">
            <ProcessIndicator show={processIndShow} />
            {alert.message &&
              <div className={`alert ${alert.type}`}>{alert.message}</div>
            }
            {dataTable}
        </div>
      </div>
    );
  }

  onRowClick(e) {
    const { select, reg } = this.props;
    var idx = parseInt(e.currentTarget.attributes['data-index'].value);
    select(reg, idx);
  }

  onRowDelete(e) {
    e.stopPropagation();
    const { remove, reg } = this.props;
    var idx = parseInt(e.currentTarget.attributes['data-index'].value);
    remove(reg, idx);
  }
};

function mapStateToProps(state) {
  const auth = state.authentication.user;
  const alert = state.alert
  const reg = state.registration
  return { auth, alert, reg };
}

const mapDispatchToProps = (dispatch) => ({
    list() { dispatch(registrationActions.list()); }
  , select(reg, idx) { dispatch(registrationActions.select(reg, idx)); }
  , remove(reg, idx) { dispatch(registrationActions.remove(reg, idx)); }
});

const connectedRegisteredList = connect(mapStateToProps, mapDispatchToProps)(RegisteredList);
export { connectedRegisteredList as RegisteredList };
