export const statsConstants = {
    STATS_CLEAR: 'STATS_CLEAR'
  , STATS_GENERAL_REQUEST:'STATS_GENERAL_REQUEST'
  , STATS_GENERAL_SUCCESS:'STATS_GENERAL_SUCCESS'
  , STATS_GENERAL_FAILURE:'STATS_GENERAL_FAILURE'

  , STATS_RESV_REQUEST:'STATS_RESV_REQUEST'
  , STATS_RESV_SUCCESS:'STATS_RESV_SUCCESS'
  , STATS_RESV_FAILURE:'STATS_RESV_FAILURE'

  , STATS_NETWORK_REQUEST:'STATS_NETWORK_REQUEST'
  , STATS_NETWORK_SUCCESS:'STATS_NETWORK_SUCCESS'
  , STATS_NETWORK_FAILURE:'STATS_NETWORK_FAILURE'
};
