import React from 'react';
import { connect } from 'react-redux';

import { alertActions, sitesActions, siteActions, fileActions } from '../../../_actions';
import { permissionCheck, history, isObjectEmpty } from '../../../_helpers';

import { NavigationBar } from '../../navigation';
import { ProcessIndicator } from '../../processIndicator';

import { DropZone } from '../../dropzone';

import { SiteData } from '../siteData';
import { SiteTabs } from '../siteTabs';

import { FileContentTypes } from './contenttypes';

require('../../../styles/uploader');

class UploaderMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        authorized: false
      , showSiteList: false
      , dropzoneClass: 'notdropped'
      , file: null
      , contentType: 0
    };

    this.onSiteSelect = this.onSiteSelect.bind(this);
    this.onSiteDisplay = this.onSiteDisplay.bind(this);
    this.onSiteDisplayClose = this.onSiteDisplayClose.bind(this);
    this.dropHandler = this.dropHandler.bind(this);
    this.dragEnterHandler = this.dragEnterHandler.bind(this);
    this.dragOverHandler = this.dragOverHandler.bind(this);
    this.dragLeaveHandler = this.dragLeaveHandler.bind(this);
    this.fileSelectHandler = this.fileSelectHandler.bind(this);
    this.fileRemoveHandler = this.fileRemoveHandler.bind(this);
    this.contentTypeChanged = this.contentTypeChanged.bind(this);
    this.uploadHandler = this.uploadHandler.bind(this);
  }

  componentDidMount() {
    const { auth, unauthorized, monitor, getSiteList } = this.props;
    const { authorized } = this.state;
    if (auth) {
      const hasPermission = permissionCheck(auth.permissions, 'portal.upload');
      if (hasPermission) {
        this.setState({ authorized: true });
        const { sites } = monitor;
        if (!sites.list) {
          getSiteList(monitor);
        }
      }
      else {
        unauthorized();
        history.push('/');
      }
    }
  }

  componentDidUpdate() {
    const { auth, unauthorized, monitor, getSiteList, alert, clearAlert } = this.props;
    const { authorized } = this.state;
    const { sites, site } = monitor;
    if (auth && !authorized) {
      const hasPermission = permissionCheck(auth.permissions, 'portal.upload');
      if (hasPermission) {
        this.setState({ authorized: true });
        if (!sites.list) {
          getSiteList(monitor);
        }
      }
      else {
        unauthorized();
        history.push('/');
      }
    }
    if (!isObjectEmpty(alert)) window.setTimeout(clearAlert, 5000);
  }

  render() {
    const { auth, monitor, alert } = this.props;
    if (!auth) return null;
    const { sites, site, file } = monitor;
    if (!sites.list) return null;

    let headerText = 'Monitor - Site - Uploader';
    let dz = null;
    let fz = null;
    let tz = null;
    let bz = null;
    let processing = file.processing ? file.processing : false;
    if (site.edit) {
      headerText = 'Monitor - Site: ' + site.edit.site_id + ' - Uploader';
      dz = <DropZone onDragOver={this.dragOverHandler}
                onDragEnter={this.dragEnterHandler}
                onDragLeave={this.dragLeaveHandler}
                onDrop={this.dropHandler}
                onChange={this.fileSelectHandler}
                displayClass={this.state.dropzoneClass} />;
    }
    if (this.state.file) {
      fz = <div className="filezone"><span onClick={processing ? null : this.fileRemoveHandler}><i className="fas fa-times-circle fa-2x mr-1"></i></span>{this.state.file.name}</div>
      tz = <FileContentTypes changeHandler={this.contentTypeChanged} focus={this.state.contentType} disable={processing} />;
      bz = <div className="buttonzone"><button type="button" className="btn btn-primary" onClick={this.uploadHandler} disabled={processing}>Upload File</button></div>;
    }

    return(
      <div id="uploader">
        <NavigationBar header={headerText} />
        <div className="container-fluid">
          <SiteTabs active="uploader" />
          <SiteData site={site.edit} more={false} showSiteIp={false} siteList={sites.list} showList={this.state.showSiteList} clickHandler={this.onSiteSelect} displayHandler={this.onSiteDisplay} closeHandler={this.onSiteDisplayClose} />
          <div className="container">
            <ProcessIndicator show={processing} />
            {alert.message &&
              <div className={`alert ${alert.type}`}>{alert.message}</div>
            }
            <div className="droppablezone">
              {dz}
            </div>
            {fz}
            {tz}
            {bz}
          </div>
        </div>
      </div>
    );
  }

  onSiteDisplay() {
    this.setState({ showSiteList: true });
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) this.props.clearAlert();
  }

  onSiteDisplayClose() {
    this.setState({ showSiteList: false });
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) this.props.clearAlert();
  }

  onSiteSelect(evt) {
    let siteIdx = evt.currentTarget.attributes['data-idx'].value;
    this.setState({ showSiteList: false });
    const { monitor, getSite, alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    let selectedsite = monitor.sites.list[siteIdx];
    getSite(monitor, selectedsite);
  }

  dropHandler(evt) {
    evt.preventDefault();
    if (this.state.file) {
      const { sendErr } = this.props;
      sendErr('Only one file can be uploaded at a time');
      return;
    }
    const file = evt.dataTransfer.files[0];
    const mime = evt.dataTransfer.items[0].type;
    let validType = false;
    if (mime.indexOf('jpg') > 0) validType = true;
    else if (mime.indexOf('jpeg') > 0) validType = true;
    else if (mime.indexOf('png') > 0) validType = true;
    else if (mime.indexOf('pdf') > 0) validType = true;
    if (validType) {
      this.setState({ dropzoneClass: 'dropped', file: file });
    }
    else {
      const { sendErr } = this.props;
      sendErr('That is not a valid file type to upload');
    }
  }

  dragEnterHandler(evt) {
    if (this.state.file) {
      return;
    }
    this.setState({ dropzoneClass: 'droppable' });
  }

  dragOverHandler(evt) {
    evt.preventDefault();
  }

  dragLeaveHandler(evt) {
    if (this.state.file) {
      return;
    }
    this.setState({ dropzoneClass: 'notdropped' });
  }

  fileSelectHandler(evt) {
    if (this.state.file) {
      const { sendErr } = this.props;
      sendErr('Only one file can be uploaded at a time');
      return;
    }
    const f = evt.target.files[0];
    const mime = f.type;
    let validType = false;
    if (mime.indexOf('jpg') > 0) validType = true;
    else if (mime.indexOf('jpeg') > 0) validType = true;
    else if (mime.indexOf('png') > 0) validType = true;
    else if (mime.indexOf('pdf') > 0) validType = true;
    if (validType) {
      this.setState({ dropzoneClass: 'dropped', file: f });
    }
    else {
      const { sendErr } = this.props;
      sendErr('That is not a valid file type to upload');
    }
  }

  fileRemoveHandler() {
    this.setState({ dropzoneClass: 'notdropped', file: null });
  }

  contentTypeChanged() {
    /// TODO: Create handler to valid file type against the expect content type. ie - event calendar = pdf or jpg, video file = mp4.
  }

  uploadHandler() {
    const { monitor, upload, alert, clearAlert } = this.props;
    const { file, contentType } = this.state;
    if (!isObjectEmpty(alert)) clearAlert();
    upload(monitor, file, contentType);
  }
};

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const monitor = state.monitor;
  return { alert, auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    unauthorized() { dispatch(alertActions.error('You are not authorized for that page.')); }
  , getSiteList(monitor) { dispatch(sitesActions.list(monitor)); }
  , clearAlert() { dispatch(alertActions.clear()); }
  , getSite(monitor, site) { dispatch(siteActions.get(monitor, site, false)); }
  , sendErr(msg) { dispatch(alertActions.error(msg)); }
  , upload(monitor, file, contenttype) { dispatch(fileActions.upload(monitor, file, contenttype)); }
});

const connectedUploaderMain = connect(mapStateToProps, mapDispatchToProps)(UploaderMain);
export { connectedUploaderMain as UploaderMain };
