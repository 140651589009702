import { sitesConstants } from '../_constants';

export function sites(state = {}, action) {
  switch(action.type) {
    case sitesConstants.SITES_CLEAR:
    case sitesConstants.SITES_LIST_REQUEST:
    case sitesConstants.SITES_LIST_SUCCESS:
    case sitesConstants.SITES_LIST_FAILURE:
    case sitesConstants.SITES_SEARCH_REQUEST:
    case sitesConstants.SITES_SEARCH_SUCCESS:
    case sitesConstants.SITES_SEARCH_FAILURE:
    case sitesConstants.SITES_SEARCH_CLEAR_REQUEST:
    case sitesConstants.SITES_SORT_REQUEST:
    case sitesConstants.SITES_SORT_SUCCESS:
      return {
        ...state,
        sites: action.obj
      };
    default:
      return state;
  }
}
