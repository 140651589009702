import React from 'react';
import Charts from '../charts';

export const Dashboard = (props) => {
  let ch1 = null;
  let ch2 = null;
  let ch3 = null;
  let ch4 = null;
  let ch5 = null;
  let ch1style = {};
  let ch2style = {};
  let ch3style = {};
  let ch4style = {};
  let ch5style = {};
  if (props.charts[0]) {
    ch1style = {width:props.charts[0].options.width,height:props.charts[0].options.height};
    ch1 = <Charts data={props.charts[0]} />;
  }
  if (props.charts[1]) {
    ch2style = {width:props.charts[1].options.width,height:props.charts[1].options.height};
    ch2 = <Charts data={props.charts[1]} />;
  }
  if (props.charts[2]) {
    ch3style = {width:props.charts[2].options.width,height:props.charts[2].options.height};
    ch3 = <Charts data={props.charts[2]} />;
  }
  return (
    <div>
      <div className="d-inline-block" style={ch1style}>
        {ch1}
      </div>
      <div className="d-none d-sm-inline-block" style={ch2style}>
        {ch2}
      </div>
      <div className="d-none d-md-inline-block ml-2" style={ch3style}><span className="d-none">Visible until small</span>
        {ch3}
      </div>
      <div className="d-none d-lg-inline ml-2"><span className="d-none">Visible until medium</span>
        {ch4}
      </div>
      <div className="d-none d-xl-inline ml-2"><span className="d-none">Visible until large</span>
        {ch5}
      </div>
    </div>
  );
};
