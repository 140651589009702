import React from 'react';

export const SimpleSearch = props => (
  <form onKeyDown={props.keydownHandler} onSubmit={() => { return false; }}>
    <div className="form-group row justify-content-md-center">
      <div className="col-6">
        <div className="input-group">
          <input type="text"
            onChange={props.changeHandler}
            name="searchText"
            className="form-control"
            placeholder={props.displayText ? props.displayText : "Find"}
            value={props.searchValue}
            disabled={props.searchDisable} />
          <div className="input-group-append" onClick={props.submitHandler}>
            <span className="input-group-text" data-toggle="tooltip" data-placement="top" title="Search">
              <span className="fas fa-search"></span>
              {props.searching &&
                <div className="d-inline fa-2x ml-2"><i className="fas fa-spinner fa-pulse"></i></div>
              }
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
);

export const SimpleSearchWClear = props => (
  <form onKeyDown={props.keydownHandler} onSubmit={() => { return false; }}>
    <div className="form-group row justify-content-md-center">
      {props.docView?
      <div className="col-3" style={{textAlign:"center"}}>
       <img data-name="list" className="docView" src="/images/icons8-list-32.png" onClick={props.docViewChange}></img>
       <img data-name="thumbnail" className="docView" src="/images/thumbnail.png" onClick={props.docViewChange}></img>
     </div>:''}
      <div className="col-6">
        <div className="input-group">
          <input type="text"
            onChange={props.changeHandler}
            name="searchText"
            className="form-control"
            placeholder={props.displayText ? props.displayText : "Find"}
            value={props.searchValue}
            disabled={props.searchDisable} />
          <div className="input-group-append" onClick={props.submitHandler}>
            <span className="input-group-text" data-toggle="tooltip" data-placement="top" title="Search">
              <span className="fas fa-search"></span>
              {props.searching &&
                <div className="d-inline fa-2x ml-2"><i className="fas fa-spinner fa-pulse"></i></div>
              }
            </span>
          </div>
        </div>
      </div>
      <div className="col-3">
        <button type="button" onClick={props.clearHandler} className="btn btn-secondary" disabled={props.searching || props.searchDisable}>Clear</button>
      </div>
    </div>
  </form>
);


export const ComplexUserSearch = props => {
  const { submitHandler, changeHandler, clearHandler, searchParams, searching } = props;
  return (
    <form onSubmit={submitHandler}>
      <div className="form-group row justify-content-md-center">
        <div className="col-3">
          <input type="text"
            name="first_name"
            className="form-control"
            placeholder="First Name"
            onChange={changeHandler}
            value={searchParams.first_name} />
        </div>
        <div className="col-3">
          <input type="text"
            name="last_name"
            className="form-control"
            placeholder="Last Name"
            onChange={changeHandler}
            value={searchParams.last_name} />
        </div>
        <div className="col-3">
          <input type="text"
            name="phone"
            className="form-control"
            placeholder="Phone"
            onChange={changeHandler}
            value={searchParams.phone} />
        </div>
      </div>
      <div className="form-group row justify-content-md-center">
        <div className="col-3">
          <input type="text"
            name="user_name"
            className="form-control"
            placeholder="User Name"
            onChange={changeHandler}
            value={searchParams.user_name} />
        </div>
        <div className="col-6">
          <input type="text"
            name="email"
            className="form-control"
            placeholder="Email"
            onChange={changeHandler}
            value={searchParams.email} />
        </div>
      </div>
      <div className="form-group row justify-content-md-center">
        <button type="submit" className="btn btn-primary" disabled={searching}>
          <span>Search</span>&nbsp;
          {searching &&
            <div className="d-inline fa-2x"><i className="fas fa-spinner fa-pulse"></i></div>
          }
        </button>&nbsp;&nbsp;
        <button type="button" className="btn btn-secondary" onClick={clearHandler} disabled={searching}>
          <span>Clear Form</span>&nbsp;
        </button>
      </div>
    </form>
  );
};

export const ComplexSiteSearch = props => {
  const { submitHandler, changeHandler, clearHandler, site } = props;
  const { searchParams, searching } = site;
/*
<div className="d-flex flex-column mb-1">
  <input type="text"
    name="city"
    className="form-control"
    placeholder="City"
    onChange={changeHandler}
    value={searchParams.city} />
</div>
<div className="d-flex flex-column mb-1">
  <input type="text"
    name="state"
    className="form-control"
    placeholder="State"
    onChange={changeHandler}
    value={searchParams.state} />
</div>
*/
  return (
    <form onSubmit={submitHandler}>
      <div className="d-flex flex-column mb-1">
        <input type="text"
          name="site_id"
          className="form-control"
          placeholder="Site ID"
          onChange={changeHandler}
          value={searchParams.site_id} />
      </div>
      <div className="d-flex flex-column mb-1">
        <input type="text"
          name="their_id"
          className="form-control"
          placeholder="Their ID"
          onChange={changeHandler}
          value={searchParams.their_id} />
      </div>
      <div className="d-flex flex-column mb-1">
        <input type="text"
          name="name"
          className="form-control"
          placeholder="Site Name"
          onChange={changeHandler}
          value={searchParams.name} />
      </div>
      <div className="d-flex flex-column mb-1">
        <input type="text"
          name="site_ip_address"
          className="form-control"
          placeholder="Site IP"
          onChange={changeHandler}
          value={searchParams.site_ip_address} />
      </div>
      <div className="form-group row justify-content-md-center">
        <button type="submit" className="btn btn-primary" disabled={searching}>
          <span>Search</span>&nbsp;
          {searching &&
            <div className="d-inline fa-2x"><i className="fas fa-spinner fa-pulse"></i></div>
          }
        </button>&nbsp;&nbsp;
        <button type="button" className="btn btn-secondary" onClick={clearHandler} disabled={searching}>
          <span>Clear Form</span>&nbsp;
        </button>
      </div>
    </form>
  );
};
