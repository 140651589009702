import React from 'react';
import { WciModal } from '../../wcimodal';

export const ChannelModal = props => {
    const { submitHandler, cancelHandler, changeHandler, val } = props;

    return(
        <WciModal show={true} className="baseModal">
            <div className="roomChangeModal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Set To Channel</h5>
                        <button type="button"
                            className="close"
                            onClick={cancelHandler}>&times;</button>
                    </div>
                    <div className="modal-body">
                            <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="set_channel">Channel</span>
                            </div>
                            <input type="text" className="form-control" aria-label="Set Channel" aria-describedby="set_channel" onChange={changeHandler} value={val} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button"
                                className="btn btn-primary"
                                onClick={submitHandler}>
                        <span>Save</span>
                       </button>
                        <button type="button"
                                className="btn btn-secondary"
                                onClick={cancelHandler}>Cancel</button>
                     </div>
                </div>
            </div>
        </WciModal>
    );
};