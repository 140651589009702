import { eventsConstants } from '../_constants';

///TODO: Cleanup needed here
export function events(state = {}, action) { 
  switch(action.type) {
    case eventsConstants.EVENTS_GET_REQUEST:
    case eventsConstants.EVENTS_GET_SUCCESS:
    case eventsConstants.EVENTS_GET_FAILURE:
    case eventsConstants.EVENTS_SORT_REQUEST:
    case eventsConstants.EVENTS_SORT_SUCCESS:
    case eventsConstants.EVENTS_SEARCH_INPUT:
    case eventsConstants.EVENTS_SEARCH_REQUEST:
    case eventsConstants.EVENTS_SEARCH_SUCCESS:
    case eventsConstants.EVENTS_SEARCH_CLEAR:
    case eventsConstants.EVENTS_DEACTIVATE_REQUEST:
    case eventsConstants.EVENTS_DEACTIVATE_SUCCESS:
    case eventsConstants.EVENTS_DEACTIVATE_FAILURE:
    case eventsConstants.EVENTS_SAVE_REQUEST:
    case eventsConstants.EVENTS_SAVE_SUCCESS:
    case eventsConstants.EVENTS_SAVE_FAILURE:
    case eventsConstants.EVENTS_EDIT_SELECT:
    case eventsConstants.EVENTS_PAGE_CHANGE:
      return{
        ...state,
        events: action.obj
      };
    case eventsConstants.EVENTS_CLEAR:
      return {
        ...state,
        events: {}
      };
    default:
      return state;
  }
}
