export const userConstants = {
      INIT_REQUEST: 'USERS_INIT_REQUEST'
    , INIT_SUCCESS: 'USERS_INIT_SUCCESS'
    , INIT_FAILURE: 'USERS_INIT_FAILURE'

    , LOGIN_REQUEST: 'USERS_LOGIN_REQUEST'
    , LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS'
    , LOGIN_FAILURE: 'USERS_LOGIN_FAILURE'

    , LOGOUT: 'USERS_LOGOUT'

    , VERIFY_PASSWORD_DISPLAY: 'VERIFY_PASSWORD_DISPLAY'
    , VERIFY_PASSWORD_CANCEL: 'VERIFY_PASSWORD_CANCEL'
    , VERIFY_PASSWORD_INPUT: 'VERIFY_PASSWORD_INPUT'
    , VERIFY_PASSWORD_REQUEST: 'VERIFY_PASSWORD_REQUEST'
    , VERIFY_PASSWORD_SUCCESS: 'VERIFY_PASSWORD_SUCCESS'
    , VERIFY_PASSWORD_FAILURE: 'VERIFY_PASSWORD_FAILURE'

    , SEND_USERNAME_REQUEST: 'SEND_USERNAME_REQUEST'
    , SEND_USERNAME_SUCCESS: 'SEND_USERNAME_SUCCESS'
    , SEND_USERNAME_FAILURE: 'SEND_USERNAME_FAILURE'

    , RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST'
    , RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS'
    , RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE'

    , GO_TO_SITES: 'GO_TO_SITES'
};
