import React from "react";
import { connect } from "react-redux";

import { userActions } from "../_actions";

import { NavigationBar } from "../_components";
import { FooterContact } from "../_components";

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.onGoToSite = this.onGoToSite.bind(this);
  }

  render() {
    const { alert } = this.props;
    //let mobileLink = null;
    //if (navigator.userAgent.match(/android/i)) {
    //  mobileLink = <div><a href="https://wvapi.worldvue.com/app-release.apk">Download Android Application</a></div>;
    //}
    //    else if (navigator.userAgent.match(/iPhone/i)) {
    //      mobileLink = <div><a href="http://wciwvdev:86/wvmobile.app">Download iPhone Application</div>
    //    }
    //{mobileLink}
    return (
      <div>
        <NavigationBar header="Home" />
        <div
          className="ml-3 text-primary"
          style={{ cursor: "pointer", paddingBottom: "10px" }}
          onClick={this.onGoToSite}
        >
          <u>Go To Your Site</u>
        </div>
        {alert.message && (
          <div className={`alert ${alert.type}`}>{alert.message}</div>
        )}
        <div id="hubMsg" className="ml-3">
          <div style={{ fontWeight: "bold" }}>
            <div>
              This portal is designed for monitoring and management of your property's TV or set-top-box guest room 
              entertainment system.
            </div>
            <div style={{ marginTop: "20px" }}>
              Access to the WorldVue service portal can be found here:{" "}
              <a href="https://support.wcitv.com/csm" target="_self">
                https://support.wcitv.com/csm
              </a>
            </div>
            <div style={{ marginTop: "20px" }}>
              Access to the WiFi system portal, can be found here:{" "}
              <a href="https://cp.hospitalitywifi.com/portal/" target="_self">
                https://cp.hospitalitywifi.com/portal/
              </a>
            </div>
          </div>
        </div>
        <div className="fixedfooter">
          <FooterContact />
        </div>
      </div>
    );
  }

  onGoToSite() {
    const { auth, gotosites } = this.props;
    gotosites(auth);
  }
}

function mapStateToProps(state) {
  const alert = state.alert;
  const auth = state.authentication.user;
  return { alert, auth };
}

const mapDispatchToProps = (dispatch) => ({
  gotosites(auth) {
    dispatch(userActions.gotosites(auth));
  },
});

const connectedHomePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);
export { connectedHomePage as HomePage };
