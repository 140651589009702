'use strict';

import React from 'react';

export const DropZone = props => (
  <div
       onDragOver={ (e) => props.onDragOver(e) }
       onDragEnter={ (e) => props.onDragEnter(e) }
       onDragLeave={ (e) => props.onDragLeave(e) }
       onDrop={ (e) => props.onDrop(e) }
  >
    <label htmlFor="dzfile" className="dzlabel">
      <div className={'dropzone ' + props.displayClass}>Drop a file here,<br />or click to select.</div>
      <input type="file" id="dzfile" onChange={ (e) => props.onChange(e) } />
    </label>
  </div>
);
