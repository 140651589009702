import React from 'react';

export const Response = props => {
  let response0 = '';
  let response1 = '';
  let labelclass = 'disabledLabel';
  let respreq = true;
  let req = null;
  let inputdisabled = true;

  if (props.response) {
    if (props.response.input0) response0 = props.response.input0;
    if (props.response.input1) response1 = props.response.input1;
    inputdisabled = false;
    labelclass = 'enabledLabel';
    req = <span className="requiredField" style={{color:"red"}}>*</span>
    respreq = false;
  }
  if (props.disable) inputdisabled = true;

  return(
    <div className="responseContainer">
      <div className="baseAreaHdr">Response</div>
      <div>
        <input type="checkbox" id="response" checked={respreq} onChange={props.changeHandler} disabled={props.disable} />&nbsp;
        <label htmlFor="response">No Response Requested</label>
      </div>
      <div>
        <label htmlFor="response0" className={labelclass}>Response 1:
          <input type="text" id="response0" name="input0" value={response0} disabled={inputdisabled} onChange={(e) => { props.inputHandler(e); }} />
          {req}
        </label>
      </div>
      <div>
        <label htmlFor="response1" className={labelclass}>Response 2:
          <input type="text" id="response1" name="input1" value={response1} disabled={inputdisabled} onChange={(e) => { props.inputHandler(e); }} />
        </label>
      </div>
    </div>
  );
};
