export const housekeepingConstants = {
    HOUSEKEEPING_ADD_REQUEST: 'HOUSEKEEPING_ADD_REQUEST'
  , HOUSEKEEPING_ADD_SUCCESS: 'HOUSEKEEPING_ADD_SUCCESS'
  , HOUSEKEEPING_ADD_FAILURE: 'HOUSEKEEPING_ADD_FAILURE'

  , HOUSEKEEPING_GET_REQUEST: 'HOUSEKEEPING_GET_REQUEST'
  , HOUSEKEEPING_GET_SUCCESS: 'HOUSEKEEPING_GET_SUCCESS'
  , HOUSEKEEPING_GET_FAILURE: 'HOUSEKEEPING_GET_FAILURE'

  , HOUSEKEEPING_SORT_REQUEST: 'HOUSEKEEPING_SORT_REQUEST'
  , HOUSEKEEPING_SORT_SUCCESS: 'HOUSEKEEPING_SORT_SUCCESS'

  , HOUSEKEEPING_SEARCH_INPUT: 'HOUSEKEEPING_SEARCH_INPUT'
  , HOUSEKEEPING_SEARCH_REQUEST: 'HOUSEKEEPING_SEARCH_REQUEST'
  , HOUSEKEEPING_SEARCH_SUCCESS: 'HOUSEKEEPING_SEARCH_SUCCESS'
  , HOUSEKEEPING_SEARCH_CLEAR: 'HOUSEKEEPING_SEARCH_CLEAR'

  , HOUSEKEEPING_DELETE_REQUEST: 'HOUSEKEEPING_DELETE_REQUEST'
  , HOUSEKEEPING_DELETE_SUCCESS: 'HOUSEKEEPING_DELETE_SUCCESS'
  , HOUSEKEEPING_DELETE_FAILURE: 'HOUSEKEEPING_DELETE_FAILURE'

  , HOUSEKEEPING_EDIT_SELECT: 'HOUSEKEEPING_EDIT_SELECT'
  , HOUSEKEEPING_EDIT_REQUEST: 'HOUSEKEEPING_EDIT_REQUEST'
  , HOUSEKEEPING_EDIT_SUCCESS: 'HOUSEKEEPING_EDIT_SUCCESS'
  , HOUSEKEEPING_EDIT_FAILURE: 'HOUSEKEEPING_EDIT_FAILURE'

  , HOUSEKEEPING_PAGE_CHANGE: 'HOUSEKEEPING_PAGE_CHANGE'
};
