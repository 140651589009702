import React from 'react';
import { connect } from 'react-redux';

import { alertActions, sitesActions, siteActions, housekeepingActions } from '../../../_actions';
import { permissionCheck, history, isObjectEmpty, prepTableInfo, calcTotalPages, confirmOptions } from '../../../_helpers';

import { NavigationBar } from '../../navigation';
import { ProcessIndicator } from '../../processIndicator';
import { DataTable } from '../../dataTable';
import { SimpleSearchWClear } from '../../search';
import { Pagination } from '../../pagination';
import { ConfirmModal } from '../../confirm';

import { SiteData } from '../siteData';
import { SiteTabs } from '../siteTabs';


class HousekeepingMain extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
              authorized: false
            , showSiteList: false
            , tableRows: 10
            , showConfirm: false
            , deleting: null
        };

        this.onSiteSelect = this.onSiteSelect.bind(this);
        this.onSiteDisplay = this.onSiteDisplay.bind(this);
        this.onSiteDisplayClose = this.onSiteDisplayClose.bind(this);
       // this.onAddClick = this.onAddClick.bind(this);
        this.onPageClick = this.onPageClick.bind(this);
        this.onPaginationCountChange = this.onPaginationCountChange.bind(this);
       // this.onEditClick = this.onEditClick.bind(this);
       // this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onSortClick = this.onSortClick.bind(this);
        this.onSearchInput = this.onSearchInput.bind(this);
        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.onSearchClear = this.onSearchClear.bind(this);
       // this.onConfirmCancel = this.onConfirmCancel.bind(this);
      //this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.onkeydownHandler = this.onkeydownHandler.bind(this);
    }


    componentDidMount()
    {
        const { auth, unauthorized, monitor, getSiteList, getHousekeepingData } = this.props;
        if (auth) {
          const hasPermission = permissionCheck(auth.permissions, 'portal.housekeeping');
          if (hasPermission) {
            this.setState({ authorized: true });
            const { sites, site, housekeeping } = monitor;
            if (!sites.list) {
              getSiteList(monitor);
            }
            else if (site.edit && !housekeeping.list) {
              // pull events from site when site_id changes
              getHousekeepingData(monitor);
            }
          }
          else {
            unauthorized();
            history.push('/');
          }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { auth, unauthorized, monitor, getSiteList, getHousekeepingData, alert, clearAlert } = this.props;
        const { authorized } = this.state;
        const { sites, site, housekeeping } = monitor;
        if (auth && !authorized) {
          const hasPermission = permissionCheck(auth.permissions, 'portal.housekeeping');
          if (hasPermission) {
            this.setState({ authorized: true });
            if (!sites.list) {
              getSiteList(monitor);
            }
          }
          else {
            unauthorized();
            history.push('/');
          }
        }
        else if (authorized) {
          if (!prevProps.monitor.site.edit && site.edit) {
            // pull housekeeping data from site when site_id changes
            getHousekeepingData(monitor);
          }
        }
        if (!isObjectEmpty(alert)) window.setTimeout(clearAlert, 5000);
      }

    render(){
        const { auth, monitor, alert } = this.props;
        if (!auth) return null;
        const { sites, site, housekeeping } = monitor;
        if (!sites.list) return null;

        const showSiteIp = permissionCheck(auth.permissions, 'portal.sites.link'); // Permission to determine if site link displayed

        const { search } = housekeeping;
        let searchTxt = '';
        let searching = false;
        let searchDisable = false;
        if (search) {
        searchTxt = search.input;
        searching = search.processing ? search.processing : false;
        if (!searchDisable) searchDisable = search.processing;
        }

        let searchform = null;
        let addBtn = null;
        let dataTable = null;
        let confirmform = null;
        let processIndShow = false;
        let headerText = 'Monitor - Site - Housekeeping';

        if (site.edit) {
            headerText = 'Monitor - Site: ' + site.edit.site_id + ' - Housekeeping';



            searchform = <SimpleSearchWClear changeHandler={this.onSearchInput}
                                displayText="Search Data"
                                searchValue={searchTxt}
                                submitHandler={this.onSearchSubmit}
                                searching={searching}
                                clearHandler={this.onSearchClear}
                                searchDisable={searchDisable}
                                keydownHandler={this.onkeydownHandler} />;
          }

          let datatable = null;
          let pagination = null;
          let cmodal = null
          let list = (housekeeping.list && housekeeping.list.length > 0) ? housekeeping.list : null;
          if (search && search.found) list = search.found;
          if (list) {

            const hKlist = (search && search.found) ? search.found : housekeeping.list;
            const tableInfoObj = prepTableInfo(hKlist);
            const rowCount = housekeeping.tableRows ? housekeeping.tableRows : this.state.tableRows;
            const totalpages = calcTotalPages(hKlist.length, rowCount);
            let currPage = housekeeping.page ? housekeeping.page : 1;
            if (currPage > totalpages) currPage = totalpages;

            let pageParams = {
                rowCount: rowCount
              , dataPage: currPage
              , pages: totalpages
              , handler: this.onPageClick
              , selectcount: true
              , numselectHandler: this.onPaginationCountChange
              , hasBtn: true
            };
            const tableDisplayParams = {
                name: 'housekeeping'
              , hideFields: ['Id', 'CorrectRoom', 'Checked Out Date','Alert','Serviced','Occupied']
              , allowOptions: {
                edit: { allowed: false, handler: null}
              , delete: { allowed: false, handler: null }
              , select: { allowed: false, handler: null }
                }
              , pagination: {
                    rowCount: pageParams.rowCount
                  , dataPage: pageParams.dataPage
                }
              , sorting: {
                    columns: ['Pms Room', 'Pms CheckedIn','Vacant Time', 'Cleaned', 'Serviced', 'Sanitized', 'Occupied','Updated']
                  , handler: this.onSortClick
                  , activeSort: (housekeeping.sort) ? housekeeping.sort.field : null
                  , direction: (housekeeping.sort) ? housekeeping.sort.direction : null
                }
              /*, columnHiding: {
                    xs: ['created']
                  , sm: ['modified']
                  , md: ['description']
                }*/
            };
            datatable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} />;
            pagination = <Pagination data={tableInfoObj.data} pageParams={pageParams} />;
          }

          if (housekeeping.processing) processIndShow = housekeeping.processing;
          if (site.processing) processIndShow = site.processing;
          if (!searchDisable) searchDisable = processIndShow;

         /* if (this.state.showConfirm) {
            let confirmOpts = confirmOptions();
            confirmOpts.show = true;
            confirmOpts.title = 'Confirm Delete';
            confirmOpts.question = 'Are you sure you want to delete: ' + this.state.deleting.title;
            confirmOpts.handlers.proceed = this.onConfirmDelete;
            confirmOpts.handlers.cancel = this.onConfirmCancel;
            confirmOpts.proceed.title = 'Delete';
            confirmOpts.proceed.className = 'btn-danger';
            cmodal = <ConfirmModal options={confirmOpts} />;
          }*/
        return(
            <div id="housekeeping">
                    <NavigationBar header={headerText} />
                    <div className="container-fluid">
                        <SiteTabs active="housekeeping" />
                        <SiteData site={site.edit} more={false} showSiteIp={showSiteIp} siteList={sites.list} showList={this.state.showSiteList} clickHandler={this.onSiteSelect} displayHandler={this.onSiteDisplay} closeHandler={this.onSiteDisplayClose} />
                            <ProcessIndicator show={processIndShow} />
                            <div className="container">
                                {searchform}
                                {alert.message &&
                                <div className={`alert ${alert.type}`}>{alert.message}</div>
                                }
                            </div>
                            <div className="row">
                                <div className="col-sm paginationWBtn2 row justify-content-end mr-1">{pagination}</div>
                            </div>
                            {datatable}

                    </div>
            </div>
        );
    }


    onSiteSelect(evt) {
        let siteIdx = evt.currentTarget.attributes['data-idx'].value;
        this.setState({ showSiteList: false });
        const { monitor, getSite, alert, clearAlert } = this.props;
        if (!isObjectEmpty(alert)) clearAlert();
        let selectedsite = monitor.sites.list[siteIdx];
        getSite(monitor, selectedsite);
      }

      onSiteDisplay() {
        this.setState({ showSiteList: true });
        const { alert, clearAlert } = this.props;
        if (!isObjectEmpty(alert)) this.props.clearAlert();
      }

      onSiteDisplayClose() {
        this.setState({ showSiteList: false });
        const { alert, clearAlert } = this.props;
        if (!isObjectEmpty(alert)) this.props.clearAlert();
      }


      onSortClick(evt) {
        let field = evt.currentTarget.attributes['data-field'].value;
        const { monitor, sort, alert, clearAlert } = this.props;
        sort(monitor, field);
        if (!isObjectEmpty(alert)) clearAlert();
      }

      onSearchInput(evt) {
        const { value } = evt.target;
        const { monitor, searchInput, alert, clearAlert } = this.props;
        searchInput(monitor, value);
        if (!isObjectEmpty(alert)) clearAlert();
      }

      onSearchSubmit(evt) {
        const { monitor, search, alert, clearAlert } = this.props;
        search(monitor);
        if (!isObjectEmpty(alert)) clearAlert();
      }

      onSearchClear(evt) {
        const { monitor, searchClear, alert, clearAlert } = this.props;
        searchClear(monitor);
        if (!isObjectEmpty(alert)) clearAlert();
      }

      /*onDeleteClick(evt) {
        evt.stopPropagation();
        var idx = parseInt(evt.currentTarget.attributes['data-index'].value);
        const { monitor, sendErr, alert, clearAlert } = this.props;
        const { events } = monitor;
        const { search } = events;
        let list = events.list;
        if (search && search.found) list = search.found;
        let obj = null;
        if (idx >= 0) {
          obj = list[idx];
        }
        if (!obj) {
          sendErr('Item data not found');
          return;
        }
        this.setState({ showConfirm: true, deleting: obj });
        if (!isObjectEmpty(alert)) clearAlert();
      }
      onConfirmCancel() {
        this.setState({ showConfirm: false, deleting: null });
      }
      onConfirmDelete() {
        const { monitor, remove, alert, clearAlert } = this.props;
        const { deleting } = this.state;
        if (!deleting) return;
        remove(monitor, deleting);
        this.setState({ showConfirm: false, deleting: null });
      }

      onEditClick(evt) {
        var idx = parseInt(evt.currentTarget.attributes['data-index'].value);
        const { monitor, select, sendErr, alert, clearAlert } = this.props;
        const { events } = monitor;
        const { search } = events;
        let list = events.list;
        if (search && search.found) list = search.found;
        let obj = null;
        if (idx >= 0) {
          obj = list[idx];
        }
        if (!obj) {
          sendErr('Item data not found');
          return;
        }
        select(monitor, obj);
        if (!isObjectEmpty(alert)) clearAlert();
      }*/

      onPageClick(evt) {
        const pagenum = parseInt(evt.currentTarget.attributes['data-pagenumber'].value);
        const { monitor, page, alert, clearAlert } = this.props;
        page(monitor, pagenum);
        if (!isObjectEmpty(alert)) clearAlert();
      }

      onPaginationCountChange(evt) {
        const { value } = evt.target;
        this.setState({ tableRows: value });
      }

      onkeydownHandler(evt) {
          if (evt.keyCode === 13) {
              evt.preventDefault();
              evt.stopPropagation();
              this.onSearchSubmit();
          }
      }
}

function mapStateToProps(state) {
    const alert = state.alert;
    const auth = state.authentication.user;
    const monitor = state.monitor;
    return { alert, auth, monitor };
  }

  const mapDispatchToProps = (dispatch) => ({
      unauthorized() { dispatch(alertActions.error('You are not authorized for that page.')); }
    , getSiteList(monitor) { dispatch(sitesActions.list(monitor)); }
    , clearAlert() { dispatch(alertActions.clear()); }
    , getSite(monitor, site) { dispatch(siteActions.get(monitor, site, false)); }
    , sendErr(msg) { dispatch(alertActions.error(msg)); }
    , getHousekeepingData(monitor) { dispatch(housekeepingActions.get(monitor)); }
    , sort(monitor, field) { dispatch(housekeepingActions.sort(monitor, field)); }
    , searchInput(monitor, value) { dispatch(housekeepingActions.searchInput(monitor, value)); }
    , search(monitor) { dispatch(housekeepingActions.search(monitor)); }
    , searchClear(monitor) { dispatch(housekeepingActions.searchClear(monitor)); }
    , remove(monitor, obj) { dispatch(housekeepingActions.delete(monitor, obj)); }
    //, select(monitor, obj) { dispatch(housekeepingActions.select(monitor, obj)); }
    , page(monitor, page) { dispatch(housekeepingActions.page(monitor, page)); }
  });

  const housekeepingMain = connect(mapStateToProps, mapDispatchToProps)(HousekeepingMain);
  export { housekeepingMain as HousekeepingMain };
