import { sitesConstants } from '../_constants';
import { alertActions, siteActions } from './';
import { textsorter, history, config, numericsorter, listFilter, permissionCheck } from '../_helpers';
import { siteService } from '../_services';

export const sitesActions = {
    clear
  , list
  , search
  , searchClear
  , sort
};

function clear() {
  return dispatch => {
    const cleared = {};
    dispatch(siteActions.clear());
    dispatch(request(cleared));
  };
  function request(obj) { return { type: sitesConstants.SITES_CLEAR, obj }; }
}

function list(monitor, auth = null) {
  return dispatch => {
    const { sites } = monitor;
    const reqsites = {
      processing: true
    };
    dispatch(request(reqsites));
    dispatch(siteActions.clear());

    siteService.list()
      .then(
        items => {
          // If there is an invalid character in the name field of the data, the JSON conversion will fail.
          // This will keep those failures from breaking the site.
          if (items.length === 0) {
            dispatch(success(sites));
            dispatch(alertActions.info('No sites found. Your account does not appear to be set correctly'));
            history.push('/');
          }
          else {
            textsorter(items, 'site_id', 'asc');
            const nextsites = {
                list: items
              , sort: { direction: 'asc', field: 'site_id' }
            };
            dispatch(success(nextsites));
            if (auth && permissionCheck(auth.permissions, 'portal.site')) {
              if (items.length === 1) {
                dispatch(siteActions.get(monitor, items[0], true, auth));
              }
            }
          }
        },
        error => {
          dispatch(failure(sites));
          dispatch(alertActions.error(error.message));
        }
      );
  };
  function request(obj) { return { type: sitesConstants.SITES_LIST_REQUEST, obj }; }
  function success(obj) { return { type: sitesConstants.SITES_LIST_SUCCESS, obj }; }
  function failure(obj) { return { type: sitesConstants.SITES_LIST_FAILURE, obj }; }
}

function search(monitor, params) {
  return dispatch=> {
    const { sites } = monitor;
    const reqsites = {
      ...sites,
      processing: true
    };
    dispatch(request(reqsites));

    let searches = [];
    let searchVals = [];
    let searchKeys = Object.keys(params);
    for (let a = 0, b = searchKeys.length; a < b; a++) {
      let val = params[searchKeys[a]];
      if (val.length > 0) {
        searches.push(searchKeys[a]);
        searchVals.push(val);
      }
    }
    let searchresult = listFilter(sites.list, searches, searchVals);
    if (searchresult.length > 0) {
      dispatch(alertActions.info('Search complete found ' + searchresult.length + ' sites.'));
      const nextsites = {
        ...sites,
        found: searchresult
      };
      dispatch(success(nextsites));
    }
    else {
      dispatch(alertActions.info('No sites found matching the criteria.'));
      dispatch(failure(sites));
    }
  };
  function request(obj) { return { type: sitesConstants.SITES_SEARCH_REQUEST, obj }; }
  function success(obj) { return { type: sitesConstants.SITES_SEARCH_SUCCESS, obj }; }
  function failure(obj) { return { type: sitesConstants.SITES_SEARCH_FAILURE, obj }; }
}

function searchClear(monitor) {
  return dispatch => {
    const { found, ...listsites } = monitor;
    dispatch(request(listsites));
  };
  function request(obj) { return { type: sitesConstants.SITES_SEARCH_CLEAR_REQUEST, obj }; }
}

function sort(monitor, field) {
  return dispatch => {
    const { sites } = monitor;
    const reqsites = {
      ...sites,
      processing: true
    };
    dispatch(request(reqsites));

    if (sites.list.length === 1) {
      dispatch(success(sites));
      return;
    }

    let direction = 'asc';
    let list = sites.list;
    let found = sites.found ? sites.found : null;
    const { sort } = sites;
    if (sort) {
      if (field === sort.field) {
        if (sort.direction === 'asc') direction = 'desc';
      }
    }
    let isNumeric = config.numericColumns.sites.indexOf(field) >= 0 ? true : false;
    if (isNumeric) {
      numericsorter(list, field, direction);
      if (found) numericsorter(found, field, direction);
    }
    else {
      textsorter(list, field, direction);
      if (found) textsorter(found, field, direction);
    }
    const nextsites = {
      ...sites,
        list: list
      , sort: { direction: direction, field: field }
    };
    if (found) nextsites.found = found;
    dispatch(success(nextsites));
  };
  function request(obj) { return { type: sitesConstants.SITES_SORT_REQUEST, obj }; }
  function success(obj) { return { type: sitesConstants.SITES_SORT_SUCCESS, obj }; }
}
