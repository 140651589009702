import React from 'react';
import { connect } from 'react-redux';

import { alertActions, adminUserActions } from '../../../_actions';
import { permissionCheck, prepTableInfo, calcTotalPages, confirmOptions, history } from '../../../_helpers';

import { NavigationBar } from '../../navigation';
import { ComplexUserSearch } from '../../search';
import { DataTable } from '../../dataTable';
import { ConfirmModal } from '../../confirm';
import { Pagination } from '../../pagination';
import { ProcessIndicator } from '../../processIndicator';

class UserAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        searchVals: { user_name:'', email:'', first_name:'', last_name:'', phone:'' }
      , searchInput: false
      , rowsPerPage: 10
      , viewpage: 1
      , deleteConfirm: false
      , deleteIndex: null
    };

    this.searchOnChange = this.searchOnChange.bind(this);
    this.searchOnClear = this.searchOnClear.bind(this);
    this.searchOnSubmit = this.searchOnSubmit.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onPageClick = this.onPageClick.bind(this);
    this.onDeleteProceed = this.onDeleteProceed.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
  }

  componentDidMount() {
    const { auth, admin, reg, get } = this.props;
    const { user } = admin;
    if (reg.userid && !user.edit) {
      if (permissionCheck(auth.permissions, 'admin.user.edit')) {
        get(admin, reg.userid);
      }
    }
  }

  render() {
    const { alert, auth, admin } = this.props;
    if (!auth) return null;
    if (!admin) return null;
    const { user } = admin;
    const allowEdit = permissionCheck(auth.permissions, 'admin.user.edit');

    let dataTable = null;
    let pagination = null;
    let addBtn = null;
    let confirmform = null;

    let processIndShow = user && user.processing ? user.processing : false;

    if (user && user.list && user.list.length > 0) {
      const allowDel = permissionCheck(auth.permissions, 'admin.user.delete');
      const editIconClickHandler = this.onEditClick;
      const deleteIconClickHandler = this.onDeleteClick;
      const paginationHandler = this.onPageClick;
      const tableInfoObj = prepTableInfo(user.list);
      const totalpages = calcTotalPages(user.list.length, this.state.rowsPerPage);

      let currPage = this.state.viewpage;
      if (currPage > totalpages) currPage = totalpages;

      const pageParams = {
          rowCount: this.state.rowsPerPage
        , dataPage: currPage
        , pages: totalpages
        , handler: paginationHandler
        , hasBtn: false
      };
      const tableDisplayParams = {
          hideFields: ['user_id', 'created', 'created_id', 'created_user_name', 'modified', 'modified_id', 'modified_user_name']
        , allowOptions: {
            edit: { allowed: allowEdit, handler: (allowEdit) ? editIconClickHandler : null },
            delete: { allowed: allowDel, handler: (allowDel) ? deleteIconClickHandler : null },
            select: { allowed: false, handler: null }
          }
        , pagination: {
              rowCount: pageParams.rowCount
            , dataPage: pageParams.dataPage
          }
      };
      dataTable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} />;
      pagination = <Pagination data={tableInfoObj.data} pageParams={pageParams} />;

      if (allowDel && this.state.deleteConfirm) {
        let item = user.list[this.state.deleteIndex];
        let confirmOpts = new confirmOptions();
        confirmOpts.show = true;
        confirmOpts.title = 'Confirm Delete';
        confirmOpts.question = 'Are you sure you want to delete the User: ' + item.first_name + ' ' + item.last_name;
        confirmOpts.handlers.proceed = this.onDeleteProceed;
        confirmOpts.handlers.cancel = this.onDeleteCancel;
        confirmOpts.proceed.title = 'Delete';
        confirmOpts.proceed.className = 'btn-danger';
        confirmform = <ConfirmModal options={confirmOpts} />;
      }
    }
    if (allowEdit) {
      addBtn = <button type="button"
                      className="btn btn-success visible"
                      onClick={this.onAddClick}>+ Add</button>;
    }

    return (
      <div>
        <NavigationBar header="Admin - Users" />
        <div className="container">
          <ProcessIndicator show={processIndShow} />
          <ComplexUserSearch submitHandler={this.searchOnSubmit}
                             changeHandler={this.searchOnChange}
                             clearHandler={this.searchOnClear}
                             searchParams={this.state.searchVals}
                             searching={user && user.searching ? user.searching : false}
                             searchDisable={processIndShow} />
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
        </div>
        <div className="container">
          {confirmform}
          <div className="row">
            <div className="col-sm">{addBtn}</div>
            <div className="col-sm paginationWBtn row justify-content-end mr-1">{pagination}</div>
          </div>
          {dataTable}
        </div>
      </div>
    );
  }

  searchOnChange(e) {
    const { name, value } = e.target;
    const { searchVals, searchInput } = this.state;
    const newsearch = {
      ...searchVals,
      [name]: value
    };
    let input = false;
    const keys = Object.keys(newsearch);
    for (let k in keys) {
      const searchVal = newsearch[keys[k]];
      if(searchVal.length > 0) {
        input = true;
        break;
      }
    }
    this.setState({ searchVals: newsearch, searchInput: input });
  }

  searchOnClear() {
    const vals = {user_name:'', email:'', first_name:'', last_name:'', phone:''};
    const { admin, search } = this.props;
    const { searchVals, searchInput } = this.state;
    search(admin, vals, false);
    this.setState({ searchVals: vals, searchInput: false, viewpage: 1 });
  }

  searchOnSubmit(e) {
    if (e) e.preventDefault();
    const { admin, search } = this.props;
    const { searchVals, searchInput } = this.state;
    search(admin, searchVals, searchInput);
    this.setState({ viewpage: 1 });
  }

  onPageClick(e) {
    this.props.clearAlert();
    const page = parseInt(e.currentTarget.attributes['data-pagenumber'].value);
    this.setState({ viewpage: page });
  }

  onEditClick(e) {
    var idx = parseInt(e.currentTarget.attributes['data-index'].value);
    const { admin, get } = this.props;
    const { user } = admin;
    const { list } = user;
    let item = list[idx];
    get(admin, item.user_id);
  }

  onDeleteClick(e) {
    e.stopPropagation();
    var idx = parseInt(e.currentTarget.attributes['data-index'].value);
    this.setState({ deleteIndex: idx, deleteConfirm: true });
  }

  onDeleteProceed() {
    const { admin, remove } = this.props;
    const { deleteIndex } = this.state;
    remove(admin, deleteIndex);
    this.setState({ deleteIndex: null, deleteConfirm: false });
  }

  onDeleteCancel() {
    this.setState({ deleteIndex: null, deleteConfirm: false });
  }

  onAddClick() {
    history.push('/admin/users/add');
  }
};

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const admin = state.administration;
  const reg = state.registration;
  return { alert, auth, admin, reg };
}

const mapDispatchToProps = (dispatch) => ({
    clearAlert() { dispatch(alertActions.clear()); }
  , get(admin, id) { dispatch(adminUserActions.get(admin, id)); }
  , search(admin, searchVals, searchInput) { dispatch(adminUserActions.search(admin, searchVals, searchInput)); }
  , remove(admin, idx) { dispatch(adminUserActions.remove(admin, idx)); }
});

const connectedUserAdmin = connect(mapStateToProps, mapDispatchToProps)(UserAdmin);
export { connectedUserAdmin as UserAdmin };
