import { getApiUrl } from '../_helpers';

export const housekeepingService = {
   
  list

};


function list(data) {
  var url = getApiUrl() + 'site/housekeeping/list';

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response.json().then(data => data));
        }
        else if (response.status === 409) {
          reject("There was an error getting the housekeeping data.");
        }
        else {
          reject(response.statusText);
        }
      })
      .catch(response => {
        reject(response.statusText);
      });
  });
}

