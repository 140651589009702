import React from 'react';
import { connect } from 'react-redux';

import { alertActions, adminPermissionActions } from '../../../_actions';
import { permissionCheck, history, isObjectEmpty } from '../../../_helpers';

import { NavigationBar } from '../../navigation';

class PermissionAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        authorized: false
      , name: ''
      , description: ''
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onExit = this.onExit.bind(this);
  }

  componentDidMount() {
    const { auth, unauthorized } = this.props;
    if (auth) {
      const hasPermission = permissionCheck(auth.permissions, 'admin.permission.edit');
      if (hasPermission) {
        this.setState({ authorized: true });
      }
      else {
        unauthorized();
        history.push('/');
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { auth, unauthorized, alert, clearAlert } = this.props;
    const { authorized } = this.state;
    if (auth && !authorized) {
      const hasPermission = permissionCheck(auth.permissions, 'admin.permission.edit');
      if (hasPermission) {
        this.setState({ authorized: true });
      }
      else {
        unauthorized();
        history.push('/');
      }
    }
    if (this.props.admin.permission.refresh && !prevProps.admin.permission.refresh) {
      this.setState({ name: '', description: '' });
    }
  }

  render() {
    const { auth, alert, admin } = this.props;
    if (!auth) return null;
    const { authorized } = this.state;
    if (!authorized) return null;
    const { permission } = admin;
    const { validity } = permission;

    let exitTxt = permission.refresh ? 'Exit' : 'Cancel';

    return(
      <div>
        <NavigationBar header="Admin - Permissions - Add" />
        <div className="jumbotron">
          <div className="container">
            <div className="row justify-content-md-center">
              <h5>New Permission</h5>
            </div>
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <input type="text"
                      className={'form-control' + (validity ? validity.name ? ' is-valid' : ' is-invalid' : '')}
                      name="name"
                      onChange={this.onChange}
                      value={this.state.name}
                      placeholder="Name" />
                {validity && !validity.valid && this.state.name.length === 0 &&
                  <div className='invalid-feedback'>Permission name is required</div>
                }
              </div>
              <div className="form-group">
                <input type="text"
                      className={'form-control' + (validity ? validity.description ? ' is-valid' : ' is-invalid' : '')}
                      name="description"
                      onChange={this.onChange}
                      value={this.state.description}
                      placeholder="Description" />
                {validity && !validity.valid && this.state.description.length === 0 &&
                  <div className='invalid-feedback'>Description is required</div>
                }
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary" disabled={permission.processing}>
                  <span>Submit</span>&nbsp;&nbsp;
                  {permission.processing &&
                    <div className="d-inline fa-2x"><i className="fas fa-spinner fa-pulse"></i></div>
                  }
                </button>
                <button type="submit" className="btn btn-primary ml-2" disabled={permission.processing} onClick={this.onExit}>
                  <span>{exitTxt}</span>
                </button>
              </div>
            </form>
          </div>
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
        </div>
      </div>
    );
  }

  onSubmit(evt) {
    evt.preventDefault();
    const { admin, add, alert, clearAlert } = this.props;
    const { name, description } = this.state;
    if(!isObjectEmpty(alert)) clearAlert();
    const newpermission = {
      name: name,
      description: description
    };
    add(admin, newpermission);
  }

  onChange(evt) {
    const { name, value } = evt.target;
    this.setState({ [name]: value });
    const { alert, clearAlert } = this.props;
    if(!isObjectEmpty(alert)) clearAlert();
  }

  onExit() {
    history.push('/admin/permissions');
  }
};

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const admin = state.administration
  return { alert, auth, admin };
}

const mapDispatchToProps = (dispatch) => ({
    unauthorized() { dispatch(alertActions.error('You are not authorized for that page')); }
  , clearAlert() { dispatch(alertActions.clear()); }
  , add(admin, data) { dispatch(adminPermissionActions.add(admin, data)); }
});

const connectedPermissionAdd = connect(mapStateToProps, mapDispatchToProps)(PermissionAdd);
export { connectedPermissionAdd as PermissionAdd };
