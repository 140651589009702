import { getApiUrl, standardPost } from '../_helpers';

export const parameterService = {
    getparams,
    getparamstb,
    saveparam
};

function getparams(data) {
    // gets app level data from site
    const url = getApiUrl() + 'site/appparam/getappparams';
    return new Promise((resolve, reject) => {
        standardPost(url, data)
        .then((r) => { resolve(r); })
        .catch((e) => { reject(e); });
    });
}

function getparamstb(data) {
    // gets stb level data from site
    const url = getApiUrl() + 'site/appparam/getappparamstbs';
    return new Promise((resolve, reject) => {
        standardPost(url, data)
        .then((r) => { resolve(r); })
        .catch((e) => { reject(e); });
    });   
}

function saveparam(data) {
    // save data to site
    const url = getApiUrl() + 'site/appparam/save';
    return new Promise((resolve, reject) => {
        standardPost(url, data)
        .then(r => { 
            resolve(r); 
        })
        .catch(e => { 
            reject(e); 
        });
    });
}
