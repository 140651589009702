module.exports = { confirmOptions };

function confirmOptions() {
  return {
      show: false
    , title: ''
    , question: ''
    , handlers: {
          proceed: null
        , cancel: null
      }
    , proceed: {
          title: ''
        , className: ''
      }
  };
}
