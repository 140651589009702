import React from 'react';

import { config } from '../../../_helpers';

export const Priority = props => {
  let priority0 = false;
  let priority1 = false;
  let priority2 = false;
  switch (props.priority) {
    case "0":
      priority0 = true;
      break;
    case "1":
      priority1 = true;
      break;
    case "2":
      priority2 = true;
      break;
    default:
      priority2 = true;
  }

  return (
    <div className="priorityContainer">
      <div className="baseAreaHdr">Message Priority</div>
      <div>
        <div className="radioOpts"><input type="radio" id="rdopriority0" name="priority" value="0" onChange={(e) => { props.changeHandler(e); }} checked={priority0} disabled={props.disable} /><label htmlFor="rdopriority0" className="rdoLabel">&nbsp;{config.messages.priority[0]}</label></div>
        <div className="radioOpts"><input type="radio" id="rdopriority1" name="priority" value="1" onChange={(e) => { props.changeHandler(e); }} checked={priority1} disabled={props.disable} /><label htmlFor="rdopriority1" className="rdoLabel">&nbsp;{config.messages.priority[1]}</label></div>
        <div className="radioOpts"><input type="radio" id="rdopriority2" name="priority" value="2" onChange={(e) => { props.changeHandler(e); }} checked={priority2} disabled={props.disable} /><label htmlFor="rdopriority2" className="rdoLabel">&nbsp;{config.messages.priority[2]}</label></div>
      </div>
    </div>
  );
};
