import React from 'react';
import { connect } from 'react-redux';

import { streamingActions, alertActions } from '../../../_actions';
import { prepTableInfo, confirmOptions } from '../../../_helpers';

import { DataTable } from '../../dataTable';
import { ConfirmModal } from '../../confirm';

class StreamingChannelList extends React.Component {
  constructor(props) {
    super(props);

    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onConfirmCancel = this.onConfirmCancel.bind(this);
    this.onRefreshClick = this.onRefreshClick.bind(this);
  }

  render() {
    const { monitor, pause } = this.props;
    const { chan } = monitor;
    const { list } = chan;

    let addBtn = null;
    let datatable = null;
    let cmodal = null;
    let refreshbtn = null;

    if (list) {
      if (list.length > 0) {
        const tableInfoObj = prepTableInfo(list);
        const tableDisplayParams = {
            name: 'streamingChannels'
          , hideFields: ['created_id', 'modified_id']
          , allowOptions: {
                edit: { allowed: true, handler: this.onEditClick }
              , delete: { allowed: true, handler: this.onDeleteClick }
              , select: { allowed: false, handler: null }
            }
          , columnHiding: {
                xs: ['created']
              , sm: ['modified']
            }
        };
        datatable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} />;
        refreshbtn = <button type="button" className="btn btn-primary visible" disabled={pause} onClick={this.onRefreshClick}>Refresh List</button>
      }

      addBtn = <button type="button"
                      className='btn btn-success visible'
                      disabled={pause}
                      onClick={this.onAddClick}>+ Add</button>;

      if (chan.deactivate) {
        let confirmOpts = confirmOptions();
        confirmOpts.show = true;
        confirmOpts.title = 'Confirm Deactivate';
        confirmOpts.question = 'Are you sure you want to deactivate channel: ' + chan.deactivate.logical_channel;
        confirmOpts.handlers.proceed = this.onConfirmDelete;
        confirmOpts.handlers.cancel = this.onConfirmCancel;
        confirmOpts.proceed.title = 'Deactivate';
        confirmOpts.proceed.className = 'btn-danger';
        cmodal = <ConfirmModal options={confirmOpts} />;
      }
    }

    return(
      <div className="container-fluid mt-4">
        {addBtn}
        {datatable}
        {refreshbtn}
        {cmodal}
      </div>
    );
  }

  onEditClick(evt) {
    const { monitor, select, sendAlert } = this.props;
    const { chan } = monitor;
    let idx = parseInt(evt.currentTarget.attributes['data-index'].value);
    let obj = null;
    if (idx >= 0) {
      obj = chan.list[idx];
      if (!obj) {
        sendAlert('Selected logical channel not found');
        return;
      }
    }
    select(monitor, obj);
  }

  onDeleteClick(evt) {
    evt.stopPropagation();
    const { monitor, deactivate, sendAlert } = this.props;
    const { chan } = monitor;
    let idx = parseInt(evt.currentTarget.attributes['data-index'].value);
    let obj = null;
    if (idx >= 0) {
      obj = chan.list[idx];
      if (!obj) {
        sendAlert('Selected logical channel not found');
        return;
      }
      if (parseInt(obj.active) === 0) {
        sendAlert('Channel ' + obj.logical_channel + ' is currently inactive.');
        return;
      }
    }
    deactivate(monitor, obj);
  }
  onConfirmDelete() {
    const { auth, monitor, save } = this.props;
    const { chan } = monitor;
    const { deactivate } = chan;
    const edit = {
      ...deactivate,
      active: 0
    };
    save(monitor, edit, auth);
  }
  onConfirmCancel() {
    const { monitor, deactivateCancel } = this.props;
    deactivateCancel(monitor);
  }

  onAddClick() {
    const { monitor, add } = this.props;
    add(monitor);
  }

  onRefreshClick() {
    const { monitor, list } = this.props;
    list(monitor);
  }
}

function mapStateToProps(state) {
  const auth = state.authentication.user;
  const monitor = state.monitor;
  return { auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    add(monitor) { dispatch(streamingActions.chanadd(monitor)); }
  , select(monitor, obj) { dispatch(streamingActions.chanedit(monitor, obj)); }
  , deactivate(monitor, obj) { dispatch(streamingActions.chanDeactivate(monitor, obj)); }
  , deactivateCancel(monitor) { dispatch(streamingActions.chanDeactivatecancel(monitor)); }
  , save(monitor, inputs, auth) { dispatch(streamingActions.channeledit(monitor, inputs, auth)); }
  , sendAlert(msg) { dispatch(alertActions.error(msg)); }
  , list(monitor) { dispatch(streamingActions.channellist(monitor)); }
});

const connectedStreamingChannelList = connect(mapStateToProps, mapDispatchToProps)(StreamingChannelList);
export { connectedStreamingChannelList as StreamingChannelList };
