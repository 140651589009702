import { adminUserConstants } from '../_constants';

export function user(state = {}, action) {
  switch(action.type) {
    case adminUserConstants.USER_GET_REQUEST:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_GET_SUCCESS:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_ROLES_SUCCESS:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_SITE_SUCCESS:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_GET_FAILURE:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_SEARCH_REQUEST:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_SEARCH_SUCCESS:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_SEARCH_FAILURE:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_DELETE_REQUEST:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_DELETE_FAILURE:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_DELETE_SUCCESS:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_INPUT:
      return {
        ...state,
        user: action.obj
      }
    case adminUserConstants.USER_ADD_REQUEST:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_ADD_FAILURE:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_ADD_SUCCESS:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_UPDATE_REQUEST:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_UPDATE_SUCCESS:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_UPDATE_FAILURE:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_GET_AVAILABLESITES_REQUEST:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_GET_AVAILABLESITES_SUCCESS:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_GET_AVAILABLESITES_FAILURE:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_ADDSITE_CANCEL:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_SITESEARCH_REQUEST:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_SITESEARCH_SUCCESS:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_SITESEARCH_FAILURE:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_SITESEARCH_CLEAR:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_SITESUBMIT_REQUEST:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_SITESUBMIT_SUCCESS:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_SITESUBMIT_FAILURE:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_DELETESITE_REQUEST:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_DELETESITE_SUCCESS:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_DELETESITE_FAILURE:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_AVAILABLEROLES_REQUEST:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_AVAILABLEROLES_SUCCESS:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_AVAILABLEROLES_FAILURE:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_AVAILABLEROLES_CANCEL:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_ADDROLE_REQUEST:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_ADDROLE_SUCCESS:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_ADDROLE_FAILURE:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_DELETEROLE_REQUEST:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_DELETEROLE_SUCCESS:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_DELETEROLE_FAILURE:
      return {
        ...state,
        user: action.obj
      };
    case adminUserConstants.USER_EDIT_CANCEL:
    case adminUserConstants.USER_AVAILABLEROLES_PAGE:
    case adminUserConstants.USER_ROLESEARCH_REQUEST:
    case adminUserConstants.USER_ROLESEARCH_SUCCESS:
    case adminUserConstants.USER_ROLESEARCH_FAILURE:
      return {
        ...state,
        user: action.obj
      };
    default:
     return state;
  }
}
