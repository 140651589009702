import React from 'react';
import { connect } from 'react-redux';

import { alertActions, sitesActions, siteActions, eventActions } from '../../../_actions';
import { permissionCheck, history, isObjectEmpty, prepTableInfo, calcTotalPages, confirmOptions } from '../../../_helpers';

import { NavigationBar } from '../../navigation';
import { ProcessIndicator } from '../../processIndicator';
import { DataTable } from '../../dataTable';
import { SimpleSearchWClear } from '../../search';
import { Pagination } from '../../pagination';
import { ConfirmModal } from '../../confirm';
import { DataGrid } from '../../dataGrid';

import { SiteData } from '../siteData';
import { SiteTabs } from '../siteTabs';
import { documentationActions } from '../../../_actions/monitor.documentation.actions';

class DocumentationMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authorized: false
          , showSiteList: false
          , tableRows: 10
          , showConfirm: false
          , deleting: null
          , completing: null
          , docView: 'list'
          , input:""
          , categoryValue:""
        };

        this.onSiteSelect = this.onSiteSelect.bind(this);
        this.onSiteDisplay = this.onSiteDisplay.bind(this);
        this.onSiteDisplayClose = this.onSiteDisplayClose.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onPageClick = this.onPageClick.bind(this);
        this.onPaginationCountChange = this.onPaginationCountChange.bind(this);
        this.onSortClick = this.onSortClick.bind(this);
        this.onSearchInput = this.onSearchInput.bind(this);
        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.onSearchClear = this.onSearchClear.bind(this);
        this.onViewClick= this.onViewClick.bind(this);
        this.onChangeDocViewClick= this.onChangeDocViewClick.bind(this);
        this.onCategoryChange=this.onCategoryChange.bind(this);
        this.onkeydownHandler=this.onkeydownHandler.bind(this);
      }

      componentDidMount() {
        const { auth, unauthorized, monitor, getSiteList, getDocList,getCategoryList } = this.props;
        if (auth) {
          const hasPermission = permissionCheck(auth.permissions, 'portal.documentation');
          if (hasPermission) {
            this.setState({ authorized: true });
            const { sites, site, documentation } = monitor;
            if (!sites.list) {
              getSiteList(monitor);
            }
            else if (!documentation.list) {
              // pull documents data
              getDocList(monitor);
            }
          }
          else {
            unauthorized();
            history.push('/');
          }
        }
      }

      componentDidUpdate(prevProps, prevState) {
        const { auth, unauthorized, monitor, getSiteList, getDocList, alert, clearAlert,getCategoryList} = this.props;
        const { authorized } = this.state;
        const { sites, site, documentation } = monitor;
        if (auth && !authorized) {
          const hasPermission = permissionCheck(auth.permissions, 'portal.documentation');
          if (hasPermission) {
            this.setState({ authorized: true });
            if (!sites.list) {
              getSiteList(monitor);
            }
          }
          else {
            unauthorized();
            history.push('/');
          }
        }
        else if (authorized) {
          if (!prevProps.monitor.site.edit && site.edit) {
            getDocList(monitor);
          }
        }
        if (!isObjectEmpty(alert)) window.setTimeout(clearAlert, 5000);
      }

      render() {
        const { auth, monitor, alert, getCategoryList } = this.props;
        if (!auth) return null;
        const { sites, site, documentation } = monitor;
        if (!sites.list) return null;

        const showSiteIp = permissionCheck(auth.permissions, 'portal.sites.link'); // Permission to determine if site link displayed
        const allowAdd = permissionCheck(auth.permissions, 'portal.documentation.addField');

        const { search } = documentation;
        let searchTxt = '';
        let searching = false;
        let searchDisable = false;
        if (search) {
          searchTxt = search.input;
          searching = search.processing ? search.processing : false;
          if (!searchDisable) searchDisable = search.processing;
        }

        let searchform = null;
        let addBtn = null;
        let viewBtn= null;
        let processIndShow = false;
        let headerText = 'Monitor - Site - Documentation';

          headerText = 'Monitor - Site: - Documentation';

          searchform = <SimpleSearchWClear changeHandler={this.onSearchInput}
                              docView="true"
                              displayText="Search document"
                              searchValue={searchTxt}
                              submitHandler={this.onSearchSubmit}
                              searching={searching}
                              clearHandler={this.onSearchClear}
                              searchDisable={searchDisable}
                              keydownHandler={this.onkeydownHandler}
                              docViewChange={this.onChangeDocViewClick} />;

        let datatable = null;
        let datagrid= null;
        let pagination = null;
        let cmodal = null
        let list = (documentation.list && documentation.list.length > 0) ? documentation.list : null;
        let categories = (documentation.categories && documentation.categories.length > 0) ? documentation.categories : null;
        if (search && search.found) list = search.found;
        if (list) {
          const allowEdit = permissionCheck(auth.permissions, 'portal.roomservice.edit');
          const allowDelete = permissionCheck(auth.permissions, 'portal.roomservice.delete');
          const documentationlist = (search && search.found) ? search.found : documentation.list;
          const tableInfoObj = prepTableInfo(documentationlist);
          const rowCount = documentation.tableRows ? documentation.tableRows : this.state.tableRows;
          const totalpages = calcTotalPages(documentationlist.length, rowCount);
          let currPage = documentation.page ? documentation.page : 1;
          if (currPage > totalpages) currPage = totalpages;

          let pageParams = {
              rowCount: rowCount
            , dataPage: currPage
            , pages: totalpages
            , handler: this.onPageClick
            , selectcount: true
            , numselectHandler: this.onPaginationCountChange
            , hasBtn: true
          };
          const tableDisplayParams = {
              name: 'documentation'
            , hideFields: ['Path']
            , allowOptions: {
                  edit: { allowed: false, handler: null}
                 , delete: { allowed: allowDelete, handler: null }
                , select: { allowed: false, handler: null }
              }
            , pagination: {
                  rowCount: pageParams.rowCount
                , dataPage: pageParams.dataPage
              }
            , sorting: {
                  columns: ['File Name','Category']
                , handler: this.onSortClick
                , activeSort: (documentation.sort) ? documentation.sort.field : null
                , direction: (documentation.sort) ? documentation.sort.direction : null
              }
            , columnHiding: {
                  xs: ['created']
                , sm: ['modified']
                , md: ['description']
              }
              , linkfields: {
                allowed: true
              , path: true
              , list: ['File Name']
              , isLink: true
            }
            , optField: {
               descriptionField:['Description']
              ,categoryField:['Category']
              ,value: this.state.input
              , handler: this.onAddClick
              ,permission: allowAdd
              ,categories: categories
              ,handleChange: this.onCategoryChange
              ,selectedCategory: this.state.value
            }

          };
          datatable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} />;
          datagrid= <DataGrid data={tableInfoObj.data} displayParams={tableDisplayParams} />;
          pagination = <Pagination data={tableInfoObj.data} pageParams={pageParams} />;
        }

        if (documentation.processing) processIndShow = documentation.processing;
        if (site.processing) processIndShow = site.processing;
        if (!searchDisable) searchDisable = processIndShow;

        return(
          <div id="documentation">
            <NavigationBar header={headerText} />
            <div className="container-fluid">
              <SiteTabs active="documentation" />
              <br/>
              <ProcessIndicator show={processIndShow} />
              <div className="container">

                {searchform}
                {alert.message &&
                  <div className={`alert ${alert.type}`}>{alert.message}</div>
                }
              </div>
              <div className="row">
                <div className="col-sm">{addBtn}</div>
                <div className="col-sm">{viewBtn}</div>
                <div className="col-sm paginationWBtn2 row justify-content-end mr-1">{pagination}</div>
              </div>
              {this.state.docView=='list'?datatable:datagrid}
              {cmodal}
            </div>
          </div>
        );
      }

      onSiteSelect(evt) {
        let siteIdx = evt.currentTarget.attributes['data-idx'].value;
        this.setState({ showSiteList: false });
        const { monitor, getSite, alert, clearAlert } = this.props;
        if (!isObjectEmpty(alert)) clearAlert();
        let selectedsite = monitor.sites.list[siteIdx];
        getSite(monitor, selectedsite);
      }

      onSiteDisplay() {
        this.setState({ showSiteList: true });
        const { alert, clearAlert } = this.props;
        if (!isObjectEmpty(alert)) this.props.clearAlert();
      }

      onSiteDisplayClose() {
        this.setState({ showSiteList: false });
        const { alert, clearAlert } = this.props;
        if (!isObjectEmpty(alert)) this.props.clearAlert();
      }

      //To add description or category field
      onAddClick(evt) {
        evt.preventDefault();
        if (!isObjectEmpty(alert)) this.props.clearAlert();

        const { auth, monitor, save, alert, clearAlert } = this.props;
        if (!isObjectEmpty(alert)) clearAlert();
        const parentRow=evt.target.parentElement.parentElement;
        const selectedElement=parentRow.children[0].children[0];
        const inputs = [{category:selectedElement?selectedElement.options[selectedElement.selectedIndex].text:"",
                        file:parentRow.children[1].textContent,
                        desc:parentRow.children[2].children[0]?parentRow.children[2].children[0].value:""}];
        save(monitor, inputs, auth);

      }

      onViewClick() {
      if (!isObjectEmpty(alert)) this.props.clearAlert();
        const { monitor, sendErr } = this.props;
        const { site } = monitor;
        if (!site.edit) {
          sendErr('A site must be selected to create meny');
          return;
        }
        history.push('/portal/roomservice/viewMenu');
      }

      onSortClick(evt) {
        let field = evt.currentTarget.attributes['data-field'].value;
        const { monitor, sort, alert, clearAlert } = this.props;
        sort(monitor, field);
        if (!isObjectEmpty(alert)) clearAlert();
      }

      onSearchInput(evt) {
        const { value } = evt.target;
        const { monitor, searchInput, alert, clearAlert } = this.props;
        searchInput(monitor, value);
        if (!isObjectEmpty(alert)) clearAlert();
      }

      onSearchSubmit(evt) {
        const { monitor, search, alert, clearAlert } = this.props;
        search(monitor);
        if (!isObjectEmpty(alert)) clearAlert();
      }

      onSearchClear(evt) {
        const { monitor, searchClear, alert, clearAlert } = this.props;
        searchClear(monitor);
        if (!isObjectEmpty(alert)) clearAlert();
      }

      onPageClick(evt) {
        const pagenum = parseInt(evt.currentTarget.attributes['data-pagenumber'].value);
        const { monitor, page, alert, clearAlert } = this.props;
        page(monitor, pagenum);
        if (!isObjectEmpty(alert)) clearAlert();
      }

      onPaginationCountChange(evt) {
        const { value } = evt.target;
        this.setState({ tableRows: value });
      }

      onChangeDocViewClick(evt) {
        const type=evt.target.getAttribute('data-name');
        switch(type)
        {
            case 'list': this.setState({docView:'list'});
                        break;
            case 'thumbnail': this.setState({docView:'thumbnail'});
                        break;
        }
      }

      onCategoryChange(evt)
      {
        this.setState({categoryValue: evt.target.value});
      }

      onkeydownHandler(evt) {
          if (evt.keyCode === 13) {
              evt.preventDefault();
              evt.stopPropagation();
              onSearchSubmit(evt);
          }
      }

    }

    function mapStateToProps(state) {
      const alert = state.alert;
      const auth = state.authentication.user;
      const monitor = state.monitor;
      return { alert, auth, monitor };
    }

    const mapDispatchToProps = (dispatch) => ({
        unauthorized() { dispatch(alertActions.error('You are not authorized for that page.')); }
      , getSiteList(monitor) { dispatch(sitesActions.list(monitor)); }
      , clearAlert() { dispatch(alertActions.clear()); }
      , getSite(monitor, site) { dispatch(siteActions.get(monitor, site, false)); }
      , sendErr(msg) { dispatch(alertActions.error(msg)); }
      , getDocList(monitor) { dispatch(documentationActions.get(monitor)); }
      , getCategoryList(monitor) {dispatch(documentationActions.categories(monitor));}
      , sort(monitor, field) { dispatch(documentationActions.sort(monitor, field)); }
      , searchInput(monitor, value) { dispatch(documentationActions.searchInput(monitor, value)); }
      , search(monitor) { dispatch(documentationActions.search(monitor)); }
      , searchClear(monitor) { dispatch(documentationActions.searchClear(monitor)); }
      , page(monitor, page) { dispatch(documentationActions.page(monitor, page)); }
      , save(monitor, values, auth) { dispatch(documentationActions.add(monitor, values, auth)); }
    });

    const connectedDocumentationMain = connect(mapStateToProps, mapDispatchToProps)(DocumentationMain);
    export { connectedDocumentationMain as DocumentationMain };
