import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { history } from '../_helpers';
import { userActions, trackingActions } from '../_actions';

import { HomePage, LoginPage, RegisterPage, SetPasswordPage, ContactPage } from './';
import { Sites, Site, Stats, ReservationStats, NetworkStats, Messaging, SiteUsers } from '../_components';
import { UserAdmin, UserAdminAdd, UserAdminEdit, UserResetPw, UserRoles, UserSites } from '../_components';
import { RoleAdmin, RoleAdd, RoleEdit, RoleAddPermission } from '../_components';
import { PermissionAdmin, PermissionAdd, PermissionEdit } from '../_components';
import { SiteAdmin } from '../_components';
import { RegisteredList, RegisteredEdit } from '../_components';
import { Profile } from '../_components';
import { EventsMain, EventsEdit } from '../_components';
import { UploaderMain } from '../_components';
import { StreamingMain } from '../_components';
import { HousekeepingMain } from '../_components/portal/housekeeping/main';
/*import {RoomServiceMain} from '../_components/portal/roomservice/main';
import {CreateMenu} from '../_components/portal/roomservice/createMenu';
import {ViewMenu} from '../_components/portal/roomservice/viewMenu';
import {DiningMenuEdit} from '../_components/portal/roomservice/editMenu';*/ // 01.25.2021 Commenting room service stuff. TODO: Uncomment it later
import {DocumentationMain} from '../_components/portal/documentation/main';
import {StbParamsMain} from '../_components/portal/stbParams/main';
import {TextTokensMain} from '../_components/portal/textTokens/TextTokensMain';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.init());
  }

  componentDidMount() {
    let accessType = 'web';
    let device = 'browser';
    if (navigator.userAgent.match(/android/i)) {
      device = 'android';
    }
    else if (navigator.userAgent.match(/iPhone/i)) {
      device = 'ios';
    }
    this.props.dispatch(trackingActions.notifyconnection(accessType, device));
  }

  render() {
    return (
      <Router history={history}>
          <div>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/register" component={RegisterPage} />
              <Route exact path="/setpassword" component={SetPasswordPage} />
              <Route exact path="/contact" component={ContactPage} />
              <Route exact path="/portal/sites" component={Sites} />
              <Route exact path="/portal/site" component={Site} />
              <Route exact path="/portal/stats" component={Stats} />
              <Route exact path="/portal/resv" component={ReservationStats} />
              <Route exact path="/portal/network" component={NetworkStats} />
              <Route exact path="/portal/messaging" component={Messaging} />
              <Route exact path="/portal/events" component={EventsMain} />
              <Route exact path="/portal/events/edit" component={EventsEdit} />
              <Route exact path="/portal/uploader" component={UploaderMain} />
              <Route exact path="/portal/streaming" component={StreamingMain} />
              <Route exact path="/portal/streaming/:disp" component={StreamingMain} />
              <Route exact path="/portal/housekeeping" component={HousekeepingMain}/>
              <Route exact path="/portal/documentation" component={DocumentationMain}/>
              <Route exact path="/portal/site/users" component={SiteUsers} />
              <Route exact path="/portal/stbparams" component={StbParamsMain} />
              <Route exact path="/portal/texttokens" component={TextTokensMain} />
              {/*<Route exact path="/portal/roomservice" component={RoomServiceMain}/>
              <Route exact path="/portal/roomservice/createMenu" component={CreateMenu}/>
              <Route exact path="/portal/roomservice/viewMenu" component={ViewMenu}/>
              <Route exact path="/portal/roomservice/editMenu" component={DiningMenuEdit}/>  // 01.25.2021 Commenting room service stuff. TODO: Uncomment it later*/}
              <Route exact path="/admin/users" component={UserAdmin} />
              <Route exact path="/admin/users/add" component={UserAdminAdd} />
              <Route exact path="/admin/users/edit" component={UserAdminEdit} />
              <Route exact path="/admin/users/reset/password" component={UserResetPw} />
              <Route exact path="/admin/users/assign/role" component={UserRoles} />
              <Route exact path="/admin/users/assign/site" component={UserSites} />
              <Route exact path="/admin/roles" component={RoleAdmin} />
              <Route exact path="/admin/roles/add" component={RoleAdd} />
              <Route exact path="/admin/roles/edit" component={RoleEdit} />
              <Route exact path="/admin/roles/addpermission" component={RoleAddPermission} />
              <Route exact path="/admin/permissions" component={PermissionAdmin} />
              <Route exact path="/admin/permissions/add" component={PermissionAdd} />
              <Route exact path="/admin/permissions/edit" component={PermissionEdit} />
              <Route exact path="/admin/sites" component={SiteAdmin} />
              <Route exact path="/admin/register/list" component={RegisteredList} />
              <Route exact path="/admin/register/edit" component={RegisteredEdit} />
              <Route exact path="/user/profile" component={Profile} />
            </Switch>
          </div>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
