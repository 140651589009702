export const adminRoleConstants = {
    ROLE_SEARCH_REQUEST: 'ROLE_SEARCH_REQUEST'
  , ROLE_SEARCH_SUCCESS: 'ROLE_SEARCH_SUCCESS'
  , ROLE_SEARCH_FAILURE: 'ROLE_SEARCH_FAILURE'

  , ROLE_ADD_REQUEST: 'ROLE_ADD_REQUEST'
  , ROLE_ADD_SUCCESS: 'ROLE_ADD_SUCCESS'
  , ROLE_ADD_FAILURE: 'ROLE_ADD_FAILURE'

  , ROLE_DELETE_REQUEST: 'ROLE_DELETE_REQUEST'
  , ROLE_DELETE_SUCCESS: 'ROLE_DELETE_SUCCESS'
  , ROLE_DELETE_FAILURE: 'ROLE_DELETE_FAILURE'

  , ROLE_EDIT_REQUEST: 'ROLE_EDIT_REQUEST'
  , ROLE_EDIT_FAILURE: 'ROLE_EDIT_FAILURE'

  , ROLE_UPDATE_REQUEST: 'ROLE_UPDATE_REQUEST'
  , ROLE_UPDATE_SUCCESS: 'ROLE_UPDATE_SUCCESS'
  , ROLE_UPDATE_FAILURE: 'ROLE_UPDATE_FAILURE'

  , ROLE_PERMISSION_SEARCH_REQUEST: 'ROLE_PERMISSION_SEARCH_REQUEST'
  , ROLE_PERMISSION_SEARCH_SUCCESS: 'ROLE_PERMISSION_SEARCH_SUCCESS'
  , ROLE_PERMISSION_SEARCH_FAILURE: 'ROLE_PERMISSION_SEARCH_FAILURE'

  , ROLE_PERMISSION_ADD_SELECT: 'ROLE_PERMISSION_ADD_SELECT'

  , ROLE_PERMISSION_UPDATE_REQUEST: 'ROLE_PERMISSION_UPDATE_REQUEST'
  , ROLE_PERMISSION_UPDATE_SUCCESS: 'ROLE_PERMISSION_UPDATE_SUCCESS'
  , ROLE_PERMISSION_UPDATE_FAILURE: 'ROLE_PERMISSION_UPDATE_FAILURE'

  , ROLE_SORT_REQUEST: 'ROLE_SORT_REQUEST'
  , ROLE_UPDATE_CANCEL: 'ROLE_UPDATE_CANCEL'
};
