import React from 'react';
import { connect } from 'react-redux';

import { alertActions, sitesActions, siteActions, statsActions } from '../../../_actions';
import { formatForMySql, addDays } from '../../../_helpers';

import { ProcessIndicator } from '../../processIndicator';

import { NavigationBar } from '../../navigation';
import { SiteData } from '../siteData';
import { AppUsage } from './appUsage';
import { ChannelUsage } from './channelUsage';
import { TotalUsage } from './totals';
import { SiteTabs } from '../siteTabs';

require('../../../styles/site');
require('../../../styles/stats');

class Stats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        showSiteList: false
      , statsStart: null
      , statsEnd: null
    };

    this.onSiteDisplay = this.onSiteDisplay.bind(this);
    this.onSiteSelect = this.onSiteSelect.bind(this);
    this.onSiteDisplayClose = this.onSiteDisplayClose.bind(this);
  }

  componentDidMount() {
    var dte = new Date();
    let end = formatForMySql(dte);
    let start = formatForMySql(addDays(dte, -7));
    this.setState({ statsStart: start, statsEnd: end });

    const { getSiteList, getStats, monitor } = this.props;
    if (!monitor.sites.list) {
      getSiteList(monitor);
    }
    else {
      const { site, stats } = monitor;
      if (site.edit && !stats.data) {
        getStats(monitor, start, end);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { monitor, getStats } = this.props;
    if (monitor.site.edit && !monitor.stats.data && !monitor.stats.processing) {
      getStats(monitor, this.state.statsStart, this.state.statsEnd);
    }
  }

  render() {
    const { alert, auth, monitor } = this.props;
    if (!auth) return null;
    const { sites, site, stats } = monitor;
    let appUsage = null;
    let chanUsage = null;
    let totals = null;
    let headerText = 'Monitor - Site - Stats';
    let processing = false;
    if (sites.processing) processing = true;
    if (site.processing) processing = true;
    if (stats.processing) processing = true;

    if (stats.data) {
      appUsage = <AppUsage apps={stats.data.app} />;
      chanUsage = <ChannelUsage chan={stats.data.chan} />;
      totals = <TotalUsage total={stats.data.total} />;
    }
    if (site.edit) {
      headerText = 'Monitor - Site: ' + site.edit.site_id + ' - Stats';
    }

    return (
      <div>
        <NavigationBar header={headerText} />
        <div className="container-fluid">
          <SiteTabs active="stats" />
          <SiteData site={site.edit} more={false} siteList={sites.list} showList={this.state.showSiteList} clickHandler={this.onSiteSelect} displayHandler={this.onSiteDisplay} closeHandler={this.onSiteDisplayClose} />
          <ProcessIndicator show={processing} />
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
          <div>
            <div className='text-center'>Stats are from the previous seven days</div>
            {appUsage}
            {chanUsage}
            {totals}
          </div>
        </div>
      </div>
    );
  }

  onSiteDisplay() {
    this.props.clearAlert();
    this.setState({ showSiteList: true });
  }

  onSiteDisplayClose() {
    this.setState({ showSiteList: false });
  }

  onSiteSelect(e) {
    this.setState({ showSiteList: false });
    const { monitor, clearAlert, getSite } = this.props;
    clearAlert();
    let siteIdx = e.currentTarget.attributes['data-idx'].value;
    let selectedsite = monitor.sites.list[siteIdx];
    getSite(monitor, selectedsite);
  }
};

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const monitor = state.monitor
  return { alert, auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
      getSiteList(monitor) { dispatch(sitesActions.list(monitor)); }
    , clearAlert() { dispatch(alertActions.clear()); }
    , getSite(monitor, site) { dispatch(siteActions.get(monitor, site, false)); }
    , getStats(monitor, start, end) { dispatch(statsActions.general(monitor, start, end)); }
});

const connectedStats = connect(mapStateToProps, mapDispatchToProps)(Stats);
export { connectedStats as Stats };
