import { documentationConstants } from '../_constants';

export function documentation(state = {}, action) {
  switch(action.type) {
    case documentationConstants.DOCUMENTATION_ADD_REQUEST:
    case documentationConstants.DOCUMENTATION_ADD_SUCCESS:
    case documentationConstants.DOCUMENTATION_ADD_FAILURE:
    case documentationConstants.DOCUMENTATION_GET_REQUEST:
    case documentationConstants.DOCUMENTATION_GET_SUCCESS:
    case documentationConstants.DOCUMENTATION_GET_FAILURE:
    case documentationConstants.DOCUMENTATION_SORT_REQUEST:
    case documentationConstants.DOCUMENTATION_SORT_SUCCESS:
    case documentationConstants.DOCUMENTATION_SEARCH_INPUT:
    case documentationConstants.DOCUMENTATION_SEARCH_REQUEST:
    case documentationConstants.DOCUMENTATION_SEARCH_SUCCESS:
    case documentationConstants.DOCUMENTATION_SEARCH_CLEAR:
    case documentationConstants.DOCUMENTATION_DELETE_REQUEST:
    case documentationConstants.DOCUMENTATION_DELETE_SUCCESS:
    case documentationConstants.DOCUMENTATION_DELETE_FAILURE:
    case documentationConstants.DOCUMENTATION_EDIT_SELECT:
    case documentationConstants.DOCUMENTATION_EDIT_REQUEST:
    case documentationConstants.DOCUMENTATION_EDIT_SUCCESS:
    case documentationConstants.DOCUMENTATION_EDIT_FAILURE:
    case documentationConstants.DOCUMENTATION_PAGE_CHANGE:
    case documentationConstants.DOCUMENTATION_COMPLETE_REQUEST:
    case documentationConstants.DOCUMENTATION_COMPLETE_FAILURE:
    case documentationConstants.DOCUMENTATION_COMPLETE_SUCCESS:
    case documentationConstants.DOCUMENTATION_CATEGORIES_REQUEST:
    case documentationConstants.DOCUMENTATION_CATEGORIES_SUCCESS:
    case documentationConstants.DOCUMENTATION_CATEGORIES_FAILURE:
      return{
        ...state,
        documentation: action.obj
      };
    default:
      return state;
  }
}
