import { profileConstants } from '../_constants';
import { alertActions } from '../_actions';
import { profileService } from '../_services';
import { phoneValidator, usernameValidator } from '../_helpers';

export const profileActions = {
    clear
  , get
  , input
  , update
};

function clear() {
  return dispatch => {
    dispatch(clear());
  };
  function clear() { return { type: profileConstants.CLEAR }; }
}

function get(auth) {
  return dispatch => {
    dispatch(request({ processing: true }));

    profileService.get('user', auth.user_id)
      .then(
        item => {
          // detect if an object or array is returned
          let newprofile = {
            user: item
          };
          dispatch(success(newprofile));
        },
        error => {
          dispatch(failure());
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: profileConstants.GET_REQUEST, obj }; }
  function success(obj) { return { type: profileConstants.GET_SUCCESS, obj }; }
  function failure() { return { type: profileConstants.GET_FAILURE }; }
}

function input(profile) {
  return dispatch => {
    dispatch(input(profile));
  };
  function input(obj) { return { type: profileConstants.INPUT, obj }; }
}

function update(profile) {
  return dispatch => {
    let newprofile = {
      ...profile,
      processing: true
    };
    dispatch(request(newprofile));

    var validity = isValid(profile.user);
    if (validity.valid) {
      const { active, created, created_id, created_user_name, modified, modified_id, modified_user_name, last_login, verifypassword, ...userprofile } = profile.user;
      const ds = JSON.stringify(userprofile);
      profileService.update('user', ds)
        .then(
          result => {
            const { validity, ...novalidity } = profile;
            dispatch(success(novalidity));
            dispatch(alertActions.success('Update Complete'));
          },
          error => {
            dispatch(failure(profile));
            dispatch(alertActions.error(error));
          }
        );
    }
    else {
      let invalidprofile = {
        ...profile,
        validity: validity
      };
      dispatch(failure(invalidprofile));
    }
  };
  function request(obj) { return { type: profileConstants.UPDATE_REQUEST, obj }; }
  function success(obj) { return { type: profileConstants.UPDATE_SUCCESS, obj }; }
  function failure(obj) { return { type: profileConstants.UPDATE_FAILURE, obj }; }
}



/* PRIVATE METHODS */
function isValid(user) {
  let validity = {
      valid: true
    , user_name: true
    , email: true
    , first_name: true
    , last_name: true
    , phone: true
  };
  if (!usernameValidator(user.user_name)) {
    validity.user_name = false;
    validity.valid = false;
  }
  if (user.email.length === 0) {
    validity.email = false;
    validity.valid = false;
  }
  if (user.first_name.length === 0) {
    validity.first_name = false;
    validity.valid = false;
  }
  if (user.last_name.length === 0) {
    validity.last_name = false;
    validity.valid = false;
  }
  if (!phoneValidator(user.phone, true)) {
    validity.phone = false;
    validity.valid = false;
  }
  return validity;
}
