import React from 'react';

export const UserData = props => {
  let ud = null;
  let udm = null;
  let udmb = null;
  let cssClasses = props.edit ? 'form-group row' : 'row';

  if (props.item) {
    ud = [];
    ud.push(<UDLabel key="ud0" labelId="user_id" labelVal="ID:" />);
    ud.push(<UDInput key="ud1" inputId="user_id" inputVal={props.item.user_id} cssClasses={'col-sm-2 form-control-plaintext'} />);
    ud.push(<UDLabel key="ud2" labelId="user_name" labelVal="User Name:" />);
    ud.push(<UDInput key="ud3" inputId="user_name" inputVal={props.item.user_name} cssClasses={'col-sm-2 form-control-plaintext'} />);
  }
  if (props.more) {
    udmb = <UDMoreBtn />;
    udm = <UDMore item={props.item} cssClasses={cssClasses} />
  }

  return (
    <div>
      <div className={cssClasses}>
        {ud}{udmb}
      </div>
      {udm}
    </div>
  );
}

const UDLabel = props => (
  <label htmlFor={props.labelId} className="col-sm-2 col-form-label text-right">{props.labelVal}</label>
);

const UDInput = props => (
  <input type="text"
    id={props.inputId}
    className={props.cssClasses}
    readOnly
    value={props.inputVal} />
);

const UDMoreBtn = props => (
  <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#moreinfo" aria-expanded="false" aria-controls="moreinfo">
    Show More
  </button>
);

const UDMore = props => (
  <div className="collapse" id="moreinfo">
    <div className={props.cssClasses}>
      <label htmlFor="created" className="col-sm-2 col-form-label text-right">Created:</label>
      <input type="text"
        id="created"
        className="col-sm-3 form-control-plaintext"
        readOnly
        value={props.item.created} />
      <label htmlFor="createduser" className="col-sm-2 col-form-label text-right">Created By:</label>
      <input type="text"
        id="createduser"
        className="col-sm-3 form-control-plaintext"
        readOnly
        value={(props.item.created_user_name === null) ? '' : props.item.created_user_name} />
    </div>
    <div className="form-group row">
      <label htmlFor="modified" className="col-sm-2 col-form-label text-right">Modified:</label>
      <input type="text"
        id="modified"
        readOnly
        className="col-sm-3 form-control-plaintext"
        value={(props.item.modified === null) ? '' : props.item.modified} />
      <label htmlFor="modifieduser" className="col-sm-2 col-form-label text-right">Modified By:</label>
      <input type="text"
        id="modifieduser"
        readOnly
        className="col-sm-3 form-control-plaintext"
        value={(props.item.modified_user_name === null) ? '' : props.item.modified_user_name} />
    </div>
    <div className="form-group row">
      <label htmlFor="created" className="col-sm-2 col-form-label text-right">Last Login:</label>
      <input type="text"
        id="last_login"
        className="col-sm-3 form-control-plaintext"
        readOnly
        value={(props.item.last_login === null) ? '' : props.item.last_login} />
    </div>
  </div>
);
