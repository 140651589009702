import { statsConstants } from '../_constants';

export function stats(state = {}, action) {
  switch(action.type) {
    case statsConstants.STATS_CLEAR:
    case statsConstants.STATS_GENERAL_REQUEST:
    case statsConstants.STATS_GENERAL_SUCCESS:
    case statsConstants.STATS_GENERAL_FAILURE:
    case statsConstants.STATS_RESV_REQUEST:
    case statsConstants.STATS_RESV_SUCCESS:
    case statsConstants.STATS_RESV_FAILURE:
    case statsConstants.STATS_NETWORK_REQUEST:
    case statsConstants.STATS_NETWORK_SUCCESS:
    case statsConstants.STATS_NETWORK_FAILURE:
      return {
        ...state,
        stats: action.obj
      };
    default:
      return state;
  }
}
