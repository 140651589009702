import { eventsConstants } from '../_constants';
import { eventsService } from '../_services';
import { alertActions } from './';
import { formatForMySqlWTime, textsorter, listFilter, history, formatYYYYMMDD, formatHHMMSS, formatForMySql } from '../_helpers';
 
const constants = {
  searchFields: ['title', 'start', 'end', 'location', 'description']
};

export const eventActions = {
    get
  , sort
  , searchInput
  , search
  , searchClear
  , select
  , page
  , clear
  , deactivate
  , save
};

// PRIVATE METHODS
const processValuesForSave = (site, auth, values) => {
    const starttm = formatForMySqlWTime(new Date(values.start_date + ' ' + values.start_time));
    let endtm = null;
    if (values.end_date && values.end_date.length > 0) {
        const enddt = new Date(values.end_date + ' ' + values.end_time);
        endtm = formatForMySqlWTime(enddt);
    }
    let endsdt = null;
    if (values.ends_date && values.ends_date.length > 0){
        const endsdate = new Date(values.ends_date);
        endsdt = formatForMySql(endsdate);
    }
    const regex = /'/gi; // look for contactions in text.
    let title = values.title.replace(regex, "''"); // escape contractions
    let location = values.location.replace(regex, "''"); // escape contractions
    let description = values.description.replace(regex, "''"); // escape contractions
    const d = {
          event_id: values.event_id ? values.event_id : null
        , active: values.active
        , title: title
        , start: starttm
        , end: endtm
        , location: location
        , description: description
        , all_day: values.all_day ? values.all_day : null
        , recurrence: values.recurrence ? values.recurrence : null
        , iteration_number: values.iteration_number ? values.iteration_number : null
        , iteration_day: values.iteration_day ? values.iteration_day : null
        , iteration: values.iteration ? values.iteration : null
        , ends: values.ends ? values.ends : null
        , ends_count: values.ends_count ? values.ends_count : null
        , ends_date: endsdt
        , edit_id: parseInt(auth.user_id)
        , site_id: site.edit.site_id
    };
    return d;
};

const saveEventData = (dataString) => {
    try {
      return new Promise((resolve, reject) => {
        eventsService.save(dataString)
        .then(
            result => {
                resolve(result);
            },
            error => {
              reject(error);
            }
        );
      });
    } catch (err) {
        throw err;
    }
};

const isValid = (event) => {
    let validity = {
          valid: true
        , title: true
        , start_date: true
        , start_date_err: null
        , start_time: true
        , start_time_err: null
        , end_date: true
        , end_date_err: null
        , end_time: true
        , end_time_err: null
        , location: true
        , iteration: true
        , iteration_err: null
        , ends_count: true
        , ends_count_err: null
        , ends_date: true
        , ends_date_err: null
    };
    if (!event.title || event.title.length === 0) {
        validity.title = false;
        validity.valid = false;
    }
    if (event.start_date.length === 0) {
        validity.start_date = false;
        validity.valid = false;
    }
    if (!event.start_time || event.start_time.length === 0) {
        validity.start_time = false;
        validity.valid = false;
    }
    if (!event.location || event.location.length === 0) {
        validity.location = false;
        validity.valid = false;
    }

    if (!event.all_day || event.all_day === '0') {
        if (!event.end_date || event.end_date.length === 0) {
            validity.end_date = false;
            validity.valid = false;
        }
        if (!event.end_time || event.end_time.length === 0) {
              validity.end_time = false;
              validity.valid = false;
        }
        if (event.end_date && event.end_date.length > 0) {
            const date1 = Date.parse(new Date(event.start_date));
            const date2 = Date.parse(new Date(event.end_date));
            const diffTime = date2 - date1;
            if (diffTime < 0) {
                validity.end_date = false;
                validity.valid = false;
                validity.end_date_err = 'End date cannot be before the Start date.';
            }
        }
    }

    if (event.recurrence && event.recurrence === '1') {
        if (!event.iteration) {
            validity.iteration = false;
            validity.valid = false;
            validity.iteration_err = 'An iteration length is required. Use the Modify button.';
        }
    }

    if (event.ends && event.ends === 'on') {
        if (!event.ends_date || event.ends_date.length === 0) {
            validity.ends_date = false;
            validity.valid = false;
            validity.ends_date_err = 'Need a date on which to stop showing the event. Use the Modify button.';
        }
        else{
            const date1 = Date.parse(new Date(event.start_date));
            const date2 = Date.parse(new Date(event.ends_date));
            const diffTimeEnds = date2 - date1;
            if (diffTimeEnds < 0) {
                validity.ends_date = false;
                validity.valid = false;
                validity.ends_date_err = 'Ends On date cannot be before the Start date. Use the Modify button.';
            }
        }
    }
    if (event.ends && event.ends === 'after') {
        if (!event.ends_count || event.ends_count === '0') {
            validity.ends_count = false;
            validity.valid = false;
            validity.ends_count_err = 'Ends Count is required. Use the Modify Button.';
        }
    }
    return validity;
};

const prepareSelection = obj => {
    let newobj = null;
    if (obj) {
      const evtId = obj.event_id === '' ? null : obj.event_id;
      const startdt = new Date(obj.start);
      const sdt = formatYYYYMMDD(startdt);
      const stm = formatHHMMSS(startdt);
      const enddt = obj.end ? new Date(obj.end) : null;
      const edt = enddt ? formatYYYYMMDD(enddt) : null;
      const etm = enddt ? formatHHMMSS(enddt): null;
      newobj = {
          ...obj,
          event_id: evtId
        , start_date: sdt
        , start_time: stm
        , end_date: edt
        , end_time: etm
      };
    }
    else {
      newobj = {
          event_id: null
        , active: '1'
        , title: ''
        , start: null
        , end: null
        , start_date: null
        , start_time: null
        , end_date: null
        , end_time: null
        , location: ''
        , description: ''
        , all_day: null
        , recurrence: null
        , iteration_number: null
        , iteration_day: null
        , iteration: null
        , ends: null
        , ends_count: null
        , ends_date: null
        , created: null
        , created_id: null
        , modified: null
        , modified_id: null
      };
    }
    return newobj;
};
// END PRIVATE METHODS

function deactivate(monitor, obj, auth) {
    return dispatch => {
        const { events, site } = monitor;
        const reqevents = {
            ...events,
            processing: true
        };
        dispatch(request(reqevents));

        const newobj = prepareSelection(obj);
        const deactivatedEvtObj = {
            ...newobj,
            active: '0'
        };
        const nextevtobj = processValuesForSave(site, auth, deactivatedEvtObj);
        const ds = JSON.stringify(nextevtobj);
        try {
            saveEventData(ds)
            .then(resp => {
                dispatch(alertActions.success('Event successfully saved.'));
                const list = resp;
                const { search, ...nosearch } = events; // remove any search results to display all events
                const nextevents = {
                    ...nosearch,
                    list: list
                };
                dispatch(success(nextevents));
            })
            .catch(err => {
                dispatch(failure(events));
                dispatch(alertActions.error(err));
            });
        } catch (err) {
            dispatch(failure(events));
            dispatch(alertActions.error(err));
        }
    };
    function request(obj) { return { type: eventsConstants.EVENTS_DEACTIVATE_REQUEST, obj }; }
    function success(obj) { return { type: eventsConstants.EVENTS_DEACTIVATE_SUCCESS, obj }; }
    function failure(obj) { return { type: eventsConstants.EVENTS_DEACTIVATE_FAILURE, obj }; }
}

function select(monitor, obj) {
  return dispatch => {
    const { events } = monitor;
    const newobj = prepareSelection(obj);
    const editevent = {
      ...events,
      edit: newobj
    };
    dispatch(request(editevent));
    history.push('/portal/events/edit');
  };
  function request(obj) { return { type: eventsConstants.EVENTS_EDIT_SELECT, obj }; }
}

function save(monitor, values, auth) {
    return dispatch => {
        const { events, site } = monitor;
        const reqevents = {
            ...events,
            processing: true
        };
        dispatch(request(reqevents));

        var validity = isValid(values);
        if (!validity.valid) {
            const invalidevents = {
                ...events,
                validity: validity
            };
            dispatch(failure(invalidevents));
            return;
        }
    
        const nextevtobj = processValuesForSave(site, auth, values);
        const ds = JSON.stringify(nextevtobj);
        try {
            saveEventData(ds)
            .then(resp => {
                dispatch(alertActions.success('Event successfully saved.'));
                const list = resp;
                const { search, ...nosearch } = events; // remove any search results to display all events
                const nextevents = {
                    ...nosearch,
                    list: list,
                    validity: null
                };
                dispatch(success(nextevents));
            })
            .catch(err => {
                dispatch(failure(events));
                dispatch(alertActions.error(err));
            });
        } catch (err) {
            dispatch(failure(events));
            dispatch(alertActions.error(err));
        }
    };
    function request(obj) { return { type: eventsConstants.EVENTS_SAVE_REQUEST, obj }; }
    function success(obj) { return { type: eventsConstants.EVENTS_SAVE_SUCCESS, obj }; }
    function failure(obj) { return { type: eventsConstants.EVENTS_SAVE_FAILURE, obj }; }
}

function get(monitor) {
  return dispatch => {
    const { events, site } = monitor;
    const reqevents = {
      ...events,
      processing: true
    };
    dispatch(request(reqevents));

    const gd = {
      site_id: site.edit.site_id
    };
    const gds = JSON.stringify(gd);
    eventsService.list(gds)
      .then(
        items => {
          const list = items;
          const sort = { field: 'start', direction: 'asc' };
          const nextevents = {
            ...events,
              list: list
            , sort: sort
          };
          dispatch(success(nextevents));
        },
        error => {
          dispatch(failure(events));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: eventsConstants.EVENTS_GET_REQUEST, obj }; }
  function success(obj) { return { type: eventsConstants.EVENTS_GET_SUCCESS, obj }; }
  function failure(obj) { return { type: eventsConstants.EVENTS_GET_FAILURE, obj }; }
}

function sort(monitor, field) {
  return dispatch => {
    const { events } = monitor;
    const reqevents = {
      ...events,
      processing: true
    };
    dispatch(request(reqevents));

    let newlist = [];
    for (let i = 0, j = events.list.length; i < j; i++) {
      newlist.push(events.list[i]);
    }

    const { sort } = events;
    let direction = 'asc';
    if (sort) {
      if (field === sort.field) {
        if (sort.direction === 'asc') { direction = 'desc'; }
      }
    }
    textsorter(newlist, field, direction);
    const nextsort = { field: field, direction: direction };
    const nextEvents = {
      ...events,
        list: newlist
      , sort: nextsort
    };

    if (events.search) {
      const { search } = events;
      if (search.found && search.found.length > 1) {
        const { found } = search;
        let newfound = [];
        for(let a = 0, b = found.length; a < b; a++) {
          newfound.push(found[a]);
        }
        textsorter(newfound, field, direction);
        const nextsearch = {
          ...search,
          found: newfound
        };
        nextEvents.search = nextsearch;
      }
    }
    dispatch(success(nextEvents));
  };
  function request(obj) { return { type: eventsConstants.EVENTS_SORT_REQUEST, obj }; }
  function success(obj) { return { type: eventsConstants.EVENTS_SORT_SUCCESS, obj }; }
}

function searchInput(monitor, value) {
  return dispatch => {
    const { events } = monitor;
    const { search } = events;
    const  nextsearch = {
      ...search,
      input: value
    };
    const nextevents = {
      ...events,
      search: nextsearch
    };
    dispatch(request(nextevents));
  };
  function request(obj) { return { type: eventsConstants.EVENTS_SEARCH_INPUT, obj }; }
}

function search(monitor) {
  return dispatch => {
    const { events } = monitor;
    const { search } = events;
    if (!search || !search.input) {
      dispatch(alertActions.error('Need some input to search'));
      return;
    }
    const reqevents = {
      ...events,
      processing: true
    };
    dispatch(request(reqevents));

    let found = [];
    for (let i = 0, j = constants.searchFields.length; i < j; i++) {
      const field = constants.searchFields[i];
      let doit = true;
      if ((field === 'start' || field === 'end') && isNaN(search.input)) {
        doit = false;
      }
      if (doit) {
        let matches = listFilter(events.list, [field], [search.input]);
        if (matches.length > 0) {
          if (found.length === 0) { found = matches; }
          else {
            for(let a = 0, b = matches.length; a < b; a++) {
              const mitem = matches[a];
              let exists = false;
              for (let c = 0, d = found.length; c < d; c++) {
                const fitem = found[c];
                if(mitem.event_id === fitem.event_id) {
                  exists = true;
                  break;
                }
              }
              if(!exists) {
                found.push(mitem);
              }
            }
          }
        }
      }
    }
    dispatch(alertActions.info('Found ' + found.length + ' events'));
    if (found.length === 0) {
      const { found, ...notfound } = search;
      const nofoundevents = {
        ...events,
        search: notfound
      };
      dispatch(success(nofoundevents));
    }
    else {
      const nextsearch = {
        ...search,
        found: found
      };
      const foundevents = {
        ...events,
        search: nextsearch
      };
      dispatch(success(foundevents));
    }
  };
  function request(obj) { return { type: eventsConstants.EVENTS_SEARCH_REQUEST, obj }; }
  function success(obj) { return { type: eventsConstants.EVENTS_SEARCH_SUCCESS, obj }; }
}

function searchClear(monitor) {
  return dispatch => {
    const { events } = monitor;
    const { search, ...nosearch } = events;
    dispatch(request(nosearch));
  };
  function request(obj) { return { type: eventsConstants.EVENTS_SEARCH_CLEAR, obj }; }
}


function page(monitor, page) {
  return dispatch => {
    const { events } = monitor;
    const reqevents = {
      ...events,
      page: page
    };
    dispatch(request(reqevents));
  };
  function request(obj) { return { type: eventsConstants.EVENTS_PAGE_CHANGE, obj }; }
}

function clear() {
  return dispatch => {
    dispatch(request());
  };
  function request() { return { type: eventsConstants.EVENTS_CLEAR }; }
}
