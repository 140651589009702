import React from 'react';

import { config } from '../_helpers';

export const Pagination = props => {
  if (!props.pageParams) return null;
  if (props.pageParams.rowCount >= props.data.length) return null;
  let width = window.outerWidth;
  let number = width < 720 ? 3 : 10;
  let pages = generateTablePagination(props.data, props.pageParams, number);
  let numselect = null;
  let divclass = '';

  if (props.pageParams.hasBtn) divclass = 'paginationWBtn';

  if (props.pageParams.selectcount) {
    let options = config.itemCountOptions.map((count, index) => {
      return <option key={'ico' + index} value={count}>{count}</option>
    });
    numselect = <select className="paginationSelect" onChange={props.pageParams.numselectHandler} value={props.pageParams.rowCount}>{options}</select>
  }
  return (
      <div className={divclass}>
        <div style={{display:"inline-block"}}>{numselect}</div>
        <div style={{display:"inline-block"}}><ul className="pagination pagination-sm">{pages}</ul></div>
      </div>
  );
};

function generateTablePagination(data, pageParams, number) {
  const currPage = pageParams.dataPage;
  const fullPages = pageParams.pages;
  const handler = pageParams.handler;
  const beginIdx = calcBeginIdx(currPage, number);
  let lis = [];
  let endIdx = 0;
  lis.push(<li key="pgPrev" className={currPage === 1 ? "page-item disabled" : "page-item"}><a className="page-link" onClick={handler} data-pagenumber={currPage - 1}>Prev</a></li>);
  if (currPage > number) {
    lis.push(<li key={'pgOne'} className="page-item"><a className="page-link" onClick={handler} data-pagenumber={1}>{1}</a></li>);
  }
  if (beginIdx >= number) {
    lis.push(<li key={'pgBack'} className="page-item"><a className="page-link" onClick={handler} data-pagenumber={beginIdx - (number - 1)}>...</a></li>);
  }
  for (let i = beginIdx, j = fullPages; i < j; i++) {
    if (i > beginIdx && i % number === 0) {
      lis.push(<li key={'pg' + i} className="page-item"><a className="page-link" onClick={handler} data-pagenumber={i + 1}>...</a></li>);
      break;
    }
    lis.push(<li key={'pg' + i} className={currPage === i + 1 ? "page-item active" : "page-item"}><a className="page-link" onClick={handler} data-pagenumber={i + 1}>{i + 1}</a></li>);
    endIdx = i;
  }
  if (endIdx !== (fullPages - 1)) {
    lis.push(<li key={'pg' + fullPages} className="page-item"><a className="page-link" onClick={handler} data-pagenumber={fullPages}>{fullPages}</a></li>);
  }
  lis.push(<li key="pgNext" className={currPage === fullPages ? "page-item disabled" : "page-item"}><a className="page-link" onClick={handler} data-pagenumber={currPage + 1}>Next</a></li>);
  return lis;
}

function calcBeginIdx(currPage, number) {
  let currIdx = currPage - 1;
  if (currIdx === 0) return 0;
  if (currIdx % number === 0) return currIdx;
  let rmdr = currIdx % number;
  let retval = currIdx - rmdr;
  return retval;
}
