import React from 'react';
class TextTokensList extends React.Component {
   constructor(props){
      super(props)
      this.state = {
         'newlabel': ''
      }
   }

   onSave(event){
      this.props.onSave(this.state.newlabel)
      this.setState({'newlabel': ''})
   }

   onCancel(event){
      this.setState({'newlabel': ''});
      this.props.onCancel();
   }
   onNewLabelChange(event){
      this.setState({'newlabel': event.target.value});
   }

   render(){
      return <React.Fragment>
            {  this.props.list.map((item)=>{
                  return <div key={item.token} className='row textTokenRow'>
                        {item.mode === 'new' ? (<div>
                           <input id={"newLabel"} onKeyDown={(event)=>{
                              if((event.key === 'Enter' || event.key === 'Tab') && this.state.newlabel !== ''){
                                 //if pressing tab or enter and the feild is not blank. Save the new token row.
                                 this.onSave()
                              }
                           }} autoFocus placeholder='New Token Label' className='form-control' onChange={this.onNewLabelChange.bind(this)} value={this.state.newlabel}/>
                        </div>)
                           :(<div key={item.token} className='col textTokenCell'>{item.label}</div>
                        )}

                        <div className='col textTokenCell'>
                           {item.mode === 'new' ? (null):(<input id={item.token} onChange={this.props.onValueChange.bind(this)} value={item.value} className='form-control'/>)}
                        </div>
                        <div className='col textTokenCell'>
                           {item.mode === 'new' ? (
                              <React.Fragment>
                                 <button type="button" className="btn btn-secondary left-margin-10"  onClick={this.onSave.bind(this, item.token)}>Save</button>
                                 <button type="button" className="btn btn-secondary left-margin-10"  onClick={this.onCancel.bind(this, item.token)}>Cancel</button>
                              </React.Fragment>
                           ) : (<button type="button" className="btn btn-secondary left-margin-10"  onClick={this.props.onDelete.bind(this, item.token)}>Delete</button>)}
                        </div>
                        <div className='col textTokenCell'>
                           {item.mode === 'new' ? (
                              null
                           ) : (<button type="button" className="btn btn-secondary left-margin-10"  onClick={this.props.onEdit.bind(this, item.token)}>Edit</button>)}
                        </div>
                     </div>
               })
            }
      </React.Fragment>
   }
}

export default TextTokensList