export const siteUserConstants = {
      SITE_USER_GET_REQUEST: 'SITE_USER_GET_REQUEST'
    , SITE_USER_GET_SUCCESS: 'SITE_USER_GET_SUCCESS'
    , SITE_USER_GET_FAILURE: 'SITE_USER_GET_FAILURE'

    , SITE_USER_PAGE_CHANGE: 'SITE_USER_PAGE_CHANGE'

    , SITE_USER_DELETE_REQUEST: 'SITE_USER_DELETE_REQUEST'
    , SITE_USER_DELETE_SUCCESS: 'SITE_USER_DELETE_SUCCESS'
    , SITE_USER_DELETE_FAILURE: 'SITE_USER_DELETE_FAILURE'
};