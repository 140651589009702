import React from 'react';
import { connect } from 'react-redux';

import { alertActions, registrationActions } from '../_actions';
import { phoneValidator, usernameValidator, marshaCodeValidator } from '../_helpers';
import { FooterContact, Icon } from '../_components';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        user: {
            user_name: ''
          , first_name: ''
          , last_name: ''
          , email: ''
          , phone: ''
          , marsha: ''
        }
      , submitted: false
      , validity: {
          user_name: true
        , first_name: true
        , last_name: true
        , email: true
        , phone: true
        , marsha: true
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  render() {
    const { registration, alert } = this.props;
    const { user, submitted, validity } = this.state;

    let registering = false;
    if (registration) {
      registering = registration.registering ? registration.registering : registering;
    }

    return(
      <div>
        <div style={{textAlign: "center"}}><Icon /></div>
        <div className="jumbotron">
          <div className="container">
            <div className="col-md-7 col-md-offset-3">
              <h2>Register for Access to WCI World Vue</h2>
              <form name="registerForm" onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <input type="text"
                    className={'form-control' + (validity.user_name ? ' is-valid' : ' is-invalid')}
                    name="user_name"
                    value={user.user_name}
                    onChange={this.handleChange}
                    placeholder="User Name" autoComplete="user-name"
                    autoFocus />
                  {submitted && !user.user_name &&
                    <div className='invalid-feedback'>User Name is required</div>
                  }
                  {submitted && user.user_name && user.user_name.length < 3 &&
                    <div className="invalid-feedback">User name has to be at least 3 characters</div>
                  }
                </div>
                <div className="form-group">
                  <input type="text"
                    className={'form-control' + (validity.first_name ? ' is-valid' : ' is-invalid')}
                    name="first_name"
                    value={user.first_name}
                    onChange={this.handleChange}
                    placeholder="First Name" autoComplete="given-name" />
                  {submitted && !user.first_name &&
                    <div className='invalid-feedback'>First Name is required</div>
                  }
                </div>
                <div className="form-group">
                  <input type="text"
                    className={'form-control' + (validity.last_name ? ' is-valid' : ' is-invalid')}
                    name="last_name"
                    value={user.last_name}
                    onChange={this.handleChange}
                    placeholder="Last Name"
                    autoComplete="family-name" />
                  {submitted && !user.last_name &&
                    <div className='invalid-feedback'>Last Name is required</div>
                  }
                </div>
                <div className="form-group">
                  <input type="email"
                    className={'form-control' + (validity.email ? ' is-valid' : ' is-invalid')}
                    name="email"
                    value={user.email}
                    onChange={this.handleChange}
                    placeholder="Email"
                    autoComplete="email" />
                  {submitted && !user.email &&
                    <div className='invalid-feedback'>Email is required</div>
                  }
                </div>
                <div className="form-group">
                  <input type="tel"
                    className={'form-control' + (validity.phone ? ' is-valid' : ' is-invalid')}
                    name="phone"
                    value={user.phone}
                    onChange={this.handleChange}
                    placeholder="Primary Phone"
                    autoComplete="tel-national" />
                  {submitted && !user.phone &&
                    <div className='invalid-feedback'>Primary contact phone number is required</div>
                  }
                  {submitted && user.phone && !validity.phone &&
                    <div className='invalid-feedback'>Phone number needs to be 10 numeric characters</div>
                  }
                </div>
                <div className="form-group">
                  <input type="text"
                    className={'form-control' + (validity.marsha ? ' is-valid' : ' is-invalid')}
                    name="marsha"
                    value={user.marsha}
                    onChange={this.handleChange}
                    placeholder="Property Site Code(s). If multiple, separate by comma."
                    data-toggle="tooltip" title="Site code(s) for all properties you need to access." />
                  {submitted && !user.marsha &&
                    <div className='invalid-feedback'>At least one Site code is required</div>
                  }
                  {submitted && user.marsha && !validity.marsha &&
                    <div className='invalid-feedback'>A Site code has more than 3 characters</div>
                  }
                </div>
                <div className="form-group">
                  <button className="btn btn-primary" disabled={submitted}>
                    <span>Submit Registration</span>&nbsp;&nbsp;
                    {registering &&
                      <div className="d-inline fa-2x"><i className="fas fa-spinner fa-pulse"></i></div>
                    }
                  </button>
                  <span className="btn btn-link" onClick={this.props.return} disabled={!registering}>Return to Sign In</span>
                </div>
              </form>
            </div>
            {alert.message &&
              <div className={`alert ${alert.type}`}>{alert.message}</div>
            }
          </div>
          <div className="relativefooter">
            <FooterContact />
          </div>
        </div>
      </div>
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value
      },
      submitted: false
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    if (this.isValid()) {
      this.props.submit(this.state.user);
    }
  }

  isValid() {
    var validity = {
        user_name: true
      , first_name: true
      , last_name: true
      , email: true
      , phone: true
      , marsha: true
    };
    var valid = true;
    if (!usernameValidator(this.state.user.user_name)) {
      validity.user_name = false;
      valid = false;
    }
    if (!this.state.user.first_name) {
      validity.first_name = false;
      valid = false;
    }
    if (!this.state.user.last_name) {
      validity.last_name = false;
      valid = false;
    }
    if (!this.state.user.email) {
      validity.email = false;
      valid = false;
    }
    if (!phoneValidator(this.state.user.phone, true)) {
      validity.phone = false;
      valid = false;
    }
    if (!marshaCodeValidator(this.state.user.marsha, true)) {
      validity.marsha = false;
      valid = false;
    }
    this.setState({ validity: validity });
    return valid;
  }
}

function mapStateToProps(state) {
  const { alert, registration } = state;
  return { alert, registration };
}

const mapDispatchToProps = (dispatch) => ({
    return() { dispatch(registrationActions.returnToLogin()); }
  , submit(user) { dispatch(registrationActions.register(user)); }
});

const connectedRegisterPage = connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
export { connectedRegisterPage as RegisterPage };
