import React from 'react';

export const GroupList = props => {
  let selectedOpt = 'none';
  let disable = props.disable ? props.disable : false;
  let groups = props.list.map((item, idx) => {
    if (idx === props.selected) {
      selectedOpt = item.group_id
    }
      return <option key={'gp' + idx} value={item.group_id} data-index-value={idx}>{item.name}</option>;
  });
  groups.splice(0, 0, (<option key='gpna' value='none'>Select Group</option>));

  return (
    <div>
      <select className="groupselect" onChange={!props.disable ? (e) => {props.changeHandler(e); } : null} value={selectedOpt} disabled={disable}>{groups}</select>
    </div>
  );
};
