import React from 'react';
import { Chart } from 'react-google-charts';

const Charts = props => {
  var view = null;
  const { data } = props;
  switch(data.type) {
    case 'pie':
     view = <Pie data={data.data} options={data.options} eventHandler={data.eventHandler} />;
  }

  return view;
};

export default Charts;

const Pie = props => (
  <Chart
    chartType="PieChart"
    data={props.data}
    width={props.options.width}
    height={props.options.height}
    options={props.options}
    chartEvents={props.eventHandler}
  />
);
