import React from 'react';

export const FooterContact = props => (
  <div className="row" style={{"justifyContent":"center", "textAlign": 'center'}}>
    <div className="col">24/7 Support: 1-800-944-9441</div>
    <div className="col"><a href="https://support.wcitv.com/csm" target="_blank">Service Portal</a></div>
    <div className="d-none d-md-inline col">Email: service@worldvue.com</div>
    <div className="d-none d-xl-inline col"><a href="https://worldvue.com/" target="_blank">Corporate Web Site</a></div>
  </div>
);

export const PageContact = props => (
  <div className="container-fluid">
    <div>24/7 Support: 1-800-944-9441</div>
    <div>For Hotel Service Issues: service@worldvue.com</div>
    <div>For problems or questions about the WorldVue Portal: wvsupport@worldvue.com</div>
    <div><a href="https://worldvue.com/" target="_blank">Corporate Web Site</a></div>
  </div>
);
