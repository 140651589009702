import { housekeepingConstants } from '../_constants';
import { housekeepingService } from '../_services';
import { alertActions } from '../_actions';
import { formatForMySqlWTime, textsorter, listFilter, history } from '../_helpers';


const constants = {
  searchFields: ['Pms Room']
};

export const housekeepingActions = {
   
    get
  , sort
  , searchInput
  , search
  , searchClear
  , page
};


function get(monitor) {
  return dispatch => {
    const { housekeeping, site } = monitor;
    const reqHkData = {
      ...housekeeping,
      processing: true
    };
    dispatch(request(reqHkData));

    const gd = {
      site_id: site.edit.site_id
    };
    const gds = JSON.stringify(gd);
    housekeepingService.list(gds)
      .then(
        items => {
          const list = items;
          const sort = { field: 'Id', direction: 'asc' };
          const nextHk = {
            ...housekeeping,
              list: list
            , sort: sort
          };
          dispatch(success(nextHk));
        },
        error => {
          dispatch(failure(housekeeping));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: housekeepingConstants.HOUSEKEEPING_GET_REQUEST, obj }; }
  function success(obj) { return { type: housekeepingConstants.HOUSEKEEPING_GET_SUCCESS, obj }; }
  function failure(obj) { return { type: housekeepingConstants.HOUSEKEEPING_GET_FAILURE, obj }; }
}

function sort(monitor, field) {
  return dispatch => {
    const { housekeeping } = monitor;
    const reqHkData = {
      ...housekeeping,
      processing: true
    };
    dispatch(request(reqHkData));

    let newlist = [];
    for (let i = 0, j = housekeeping.list.length; i < j; i++) {
      newlist.push(housekeeping.list[i]);
    }

    const { sort } = housekeeping;
    let direction = 'asc';
    if (sort) {
      if (field === sort.field) {
        if (sort.direction === 'asc') { direction = 'desc'; }
      }
    }
    textsorter(newlist, field, direction);
    const nextsort = { field: field, direction: direction };
    const nextHk = {
      ...housekeeping,
        list: newlist
      , sort: nextsort
    };

    /*if (hk.search) {
      const { search } = hk;
      if (search.found && search.found.length > 1) {
        const { found } = search;
        let newfound = [];
        for(let a = 0, b = found.length; a < b; a++) {
          newfound.push(found[a]);
        }
        textsorter(newfound, field, direction);
        const nextsearch = {
          ...search,
          found: newfound
        };
        nextHk.search = nextsearch;
      }
    }*/
    dispatch(success(nextHk));
  };
  function request(obj) { return { type: housekeepingConstants.HOUSEKEEPING_SORT_REQUEST, obj }; }
  function success(obj) { return { type: housekeepingConstants.HOUSEKEEPING_SORT_SUCCESS, obj }; }
}

function searchInput(monitor, value) {
  return dispatch => {
    const { housekeeping } = monitor;
    const { search } = housekeeping;
    const  nextsearch = {
      ...search,
      input: value
    };
    const nextHk = {
      ...housekeeping,
      search: nextsearch
    };
    dispatch(request(nextHk));
  };
  function request(obj) { return { type: housekeepingConstants.HOUSEKEEPING_SEARCH_INPUT, obj }; }
}

function search(monitor) {
  return dispatch => {
    const { housekeeping } = monitor;
    const { search } = housekeeping;
    if (!search || !search.input) {
      dispatch(alertActions.error('Need some input to search'));
      return;
    }
    const reqHkData = {
      ...housekeeping,
      processing: true
    };
    dispatch(request(reqHkData));

    let found = [];
    for (let i = 0, j = constants.searchFields.length; i < j; i++) {
      const field = constants.searchFields[i];
      let doit = true;
      if ((field === 'start' || field === 'end') && isNaN(search.input)) {
        doit = false;
      }
      if (doit) {
        let matches = listFilter(housekeeping.list, [field], [search.input]);
        if (matches.length > 0) {
          if (found.length === 0) { found = matches; }
          else {
            for(let a = 0, b = matches.length; a < b; a++) {
              const mitem = matches[a];
              let exists = false;
              for (let c = 0, d = found.length; c < d; c++) {
                const fitem = found[c];
                if(mitem.event_id === fitem.event_id) {
                  exists = true;
                  break;
                }
              }
              if(!exists) {
                found.push(mitem);
              }
            }
          }
        }
      }
    }
    dispatch(alertActions.info('Found ' + found.length + ' Data'));
    if (found.length === 0) {
      const { found, ...notfound } = search;
      const nofoundHkdata = {
        ...housekeeping,
        search: notfound
      };
      dispatch(success(nofoundHkdata));
    }
    else {
      const nextsearch = {
        ...search,
        found: found
      };
      const foundHkData = {
        ...housekeeping,
        search: nextsearch
      };
      dispatch(success(foundHkData));
    }
  };
  function request(obj) { return { type: housekeepingConstants.HOUSEKEEPING_SEARCH_REQUEST, obj }; }
  function success(obj) { return { type: housekeepingConstants.HOUSEKEEPING_SEARCH_SUCCESS, obj }; }
}

function searchClear(monitor) {
  return dispatch => {
    const { housekeeping } = monitor;
    const { search, ...nosearch } = housekeeping;
    dispatch(request(nosearch));
  };
  function request(obj) { return { type: housekeepingConstants.HOUSEKEEPING_SEARCH_CLEAR, obj }; }
}


function page(monitor, page) {
  return dispatch => {
    const { housekeeping } = monitor;
    const reqHkData = {
      ...housekeeping,
      page: page
    };
    dispatch(request(reqHkData));
  };
  function request(obj) { return { type: housekeepingConstants.HOUSEKEEPING_PAGE_CHANGE, obj }; }
}




/* PRIVATE METHODS */
/*function isValid(event) {
  let validity = {
      valid: true
    , title: true
    , start_date: true
    , start_date_err: null
    , start_time: true
    , start_time_err: null
    , end_date: true
    , end_date_err: null
    , end_time: true
    , end_time_err: null
    , location: true
  };
  if (!event.title || event.title.length === 0) {
    validity.title = false;
    validity.valid = false;
  }
  if (event.start_date.length === 0) {
  }
  if (!event.start_time || event.start_time.length === 0) {
    validity.start_time = false;
    validity.valid = false;
  }
  if (event.end_date.length === 0) {
    validity.end_date = false;
    validity.valid = false;
  }
  else {
    const date1 = Date.parse(new Date(event.start_date));
    const date2 = Date.parse(new Date(event.end_date));
    const diffTime = date2 - date1;
    if (diffTime < 0) {
      validity.end_date = false;
      validity.valid = false;
      validity.end_date_err = 'End date cannot be before the Start date.';
    }
  }
  if (!event.end_time || event.end_time.length === 0) {
    validity.end_time = false;
    validity.valid = false;
  }
  if (!event.location || event.location.length === 0) {
    validity.location = false;
    validity.valid = false;
  }
  return validity;
}*/

function hasSearchResult(hkObj) {
  if (!hkObj.search) return false;
  if (!hkObj.search.found) return false;
  return true;
}

/*function removeDeletedFromSearchResults(found, deleted) {
  let newfound = [];
  for(let i = 0, j = found.length; i < j; i++) {
    if (found[i].event_id !== deleted.event_id) {
      newfound.push(found[i]);
    }
  }
  if (newfound.length > 0) return newfound;
  else return null;
}*/
