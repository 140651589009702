import { msgConstants } from '../_constants';

export function msg(state = {}, action) {
  switch(action.type) {
    case msgConstants.MSG_CLEAR:
    case msgConstants.MSG_GENROOMLIST_REQUEST:
    case msgConstants.MSG_GENROOMLIST_SUCCESS:
    case msgConstants.MSG_SORT_REQUEST:
    case msgConstants.MSG_SORT_SUCCESS:
    case msgConstants.MSG_FILTER_REQUEST:
    case msgConstants.MSG_FILTER_SUCCESS:
    case msgConstants.MSG_ROOM_SELECT:
    case msgConstants.MSG_ROOM_REMOVE:
    case msgConstants.MSG_GROUP_CREATEGROUP:
    case msgConstants.MSG_GROUP_CANCEL:
    case msgConstants.MSG_GROUP_INPUT:
    case msgConstants.MSG_GROUP_SUBMIT_REQUEST:
    case msgConstants.MSG_GROUP_SUBMIT_SUCCESS:
    case msgConstants.MSG_GROUP_SUBMIT_FAILURE:
    case msgConstants.MSG_GROUP_SELECT_REQUEST:
    case msgConstants.MSG_GROUP_SELECT_SUCCESS:
    case msgConstants.MSG_GROUP_SELECT_FAILURE:
    case msgConstants.MSG_GROUP_MANAGE:
    case msgConstants.MSG_GROUP_MANAGE_CANCEL:
    case msgConstants.MSG_GROUP_MANAGE_SELECT:
    case msgConstants.MSG_GROUP_MANAGE_DELETE:
    case msgConstants.MSG_GROUP_MANAGE_DELETE_REQUEST:
    case msgConstants.MSG_GROUP_MANAGE_DELETE_SUCCESS:
    case msgConstants.MSG_GROUP_MANAGE_DELETE_FAILURE:
    case msgConstants.MSG_GROUP_MANAGE_DELETE_CANCEL:
    case msgConstants.MSG_SELECT_ALL:
    case msgConstants.MSG_SELECT_CHECKEDIN:
    case msgConstants.MSG_REMOVE_ALL:
    case msgConstants.MSG_RESET:
    case msgConstants.MSG_PRIORITY:
    case msgConstants.MSG_TYPE:
    case msgConstants.MSG_RESPONSE:
    case msgConstants.MSG_RESPONSE_INPUT:
    case msgConstants.MSG_EXPIRE:
    case msgConstants.MSG_EXPIRE_INPUT:
    case msgConstants.MSG_MESSAGE:
    case msgConstants.MSG_SEND_REQUEST:
    case msgConstants.MSG_SEND_SUCCESS:
    case msgConstants.MSG_SEND_FAILURE:
    case msgConstants.MSG_REVIEW_REQUEST:
    case msgConstants.MSG_REVIEW_SUCCESS:
    case msgConstants.MSG_REVIEW_REQUEST:
    case msgConstants.MSG_REVIEW_CLOSE:
    case msgConstants.MSG_REVIEW_PAGE:
    case msgConstants.MSG_REVIEW_SORT:
    case msgConstants.MSG_REVIEW_DEACTIVATE:
    case msgConstants.MSG_REVIEW_DEACTIVATE_CANCEL:
    case msgConstants.MSG_REVIEW_DEACTIVATE_REQUEST:
    case msgConstants.MSG_REVIEW_DEACTIVATE_SUCCESS:
    case msgConstants.MSG_REVIEW_DEACTIVATE_FAILURE:
    case msgConstants.MSG_REVIEW_SELECT_REQUEST:
    case msgConstants.MSG_REVIEW_SELECT_SUCCESS:
    case msgConstants.MSG_REVIEW_SELECT_FAILURE:
    case msgConstants.MSG_REVIEW_DETAIL_CLOSE:
    case msgConstants.MSG_REVIEW_DETAIL_PAGE:
    case msgConstants.MSG_REVIEW_DETAIL_SORT:
      return {
        ...state,
        msg: action.obj
      };
    default:
      return state;
  }
}
