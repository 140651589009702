import { siteUserConstants } from '../_constants';
import { siteService } from '../_services';
import { alertActions } from './alert.actions';

export const siteUserActions = {
      get
    , page
    , delete: _delete
    , sort
};

function get(monitor) {
    return dispatch => {
        const { site } = monitor;
        const { users } = site;
        const requsers = {
            ...users,
            processing: true
        };
        const reqsite = {
            ...site,
            users: requsers
        };
        dispatch(request(reqsite));

        siteService.listUsers(site.edit.site_id)
        .then(r => {
            dispatch(alertActions.info(`Found ${r.length} users.`));
            const nextusers = {
                list: r
            };
            const nextsite = {
                ...site,
                users: nextusers
            };
            dispatch(success(nextsite));
        })
        .catch(e => { dispatch(failure(site)); });
    };
    function request(obj) { return { type: siteUserConstants.SITE_USER_GET_REQUEST, obj }; }
    function success(obj) { return { type: siteUserConstants.SITE_USER_GET_SUCCESS, obj }; }
    function failure(obj) { return { type: siteUserConstants.SITE_USER_GET_FAILURE, obj }; }
}

function page(monitor, page) {
    return dispatch => {
        const { site } = monitor;
        const { users } = site;
        const userspage = {
            ...users,
            page: page
        }
        const nextsite = {
            ...site,
            users: userspage
        };
        dispatch(request(nextsite));
    };
    function request(obj) { return { type: siteUserConstants.SITE_USER_PAGE_CHANGE, obj }; }
}

function _delete(monitor, obj, idx) {
    return dispatch => {
        const { site } = monitor;
        const { users } = site;
        const requsers = {
            ...users,
            processing: true
        };
        const reqsite = {
            ...site,
            users: requsers
        };
        dispatch(request(reqsite));

        const { list } = users;
        siteService.deleteUser(obj.user_id)
        .then(r => {
            let newlist = [];
            for(let i = 0, j = list.length; i < j; i++) {
              if (i !== idx) {
                newlist.push(list[i]);
              }
            }
            const successusers = {
              ...users,
              list: newlist
            };
            const successsite = {
                ...site,
                users: successusers
            };
            dispatch(success(successsite));
            dispatch(alertActions.success('User successfully removed from the site'));  
        })
        .catch(e => { 
            dispatch(alertActions.error(e));
            dispatch(failure(site));
        });
    };
    function request(obj) { return { type: siteUserConstants.SITE_USER_DELETE_REQUEST, obj }; }
    function success(obj) { return { type: siteUserConstants.SITE_USER_DELETE_SUCCESS, obj }; }
    function failure(obj) { return { type: siteUserConstants.SITE_USER_DELETE_FAILURE, obj }; }
}

function sort(monitor, field) {
 /// TODO: if a sort is needed build this functionality
}