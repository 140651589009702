import { getApiUrl } from '../_helpers';

export const siteService = {
    list
  , get
  , stbDeactivate
  , stbReboot
  , stbRoomChange
  , listUsers
  , deleteUser
  , callScript
};

function list(limit = 0) {
  var url = getApiUrl() + 'site/list';
  if (limit > 0) url += '/' + limit;

  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
            response.json().then(data => {
              if (data) { 
                resolve(data); 
              }
              else {
                resolve([]);
              }
            })
            .catch(() => {
              resolve([]);
            });         
        }
        else if (response.status === 409) {
          reject('There was an error looking for your sites.');
        }
        else {
          reject(response.statusText);
        }
      })
      .catch(response => {
        reject(response.statusText);
      });
  });
}

function get(site) {
  var url = getApiUrl() + 'site/stb/list/' + site.site_id;

  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response.json().then(data => data));
        }
        else if (response.status === 409) {
          reject('There was an error looking for your sites.');
        }
        else {
          reject(response.statusText);
        }
      })
      .catch(response => {
        reject(response.statusText);
      });
  });
}

function stbDeactivate(data) {
  var url = getApiUrl() + 'site/stb/deactivate';
  var body = {
      site_id: data.site_id
    , serial_number: data.serial_number
  };
  body = JSON.stringify(body);

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: body,
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
    .then(response => {
      if (response.status === 200) {
        resolve(response.json().then(data => data));
      }
      else {
        if (response.status === 409) {
          reject(response.statusText);
        }
        else {
          reject('STB deactivation not successful because of error: ' + response.status + ' - ' + response.statusText);
        }
      }
    })
    .catch(response => { reject('STB deactivation not successful because of error: ' + response.status + ' - ' + response.statusText); });
  });
}

function stbReboot(data) {
  var url = getApiUrl() + 'site/stb/reboot';
  var body = {
      site_id: data.site_id
    , serial_number: data.serial_number
  };
  body = JSON.stringify(body);

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: body,
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
    .then(response => {
      if (response.status === 200) {
        response.json().then(data => {
          let d = JSON.parse(data);
          resolve(d.message);
        });
      }
      else {
        if (response.status === 409) {
          if (response.statusText === 'Conflict') {
            reject('Connection to STB could not be established.')
          }
          else {
            reject(response.statusText);
          }
        }
        else {
          reject('Room Change not successful because of error: ' + response.status + ' - ' + response.statusText);
        }
      }
    })
    .catch(response => { reject('Room Change not successful because of error: ' + response.status + ' - ' + response.statusText); });
  });
}

function stbRoomChange(data) {
  var url = getApiUrl() + 'site/stb/roomchange';

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
    .then(response => {
      if (response.status === 200) {
        resolve(response.json().then(data => data));
      }
      else {
        if (response.status === 409) {
          reject(response.statusText);
        }
        else {
          reject('Room Change not successful because of error: ' + response.status + ' - ' + response.statusText);
        }
      }
    })
    .catch(response => { reject('Room Change not successful because of error: ' + response.status + ' - ' + response.statusText); });
  });
}

function listUsers(site) {
    var url = getApiUrl() + 'site/users/list/' + site;

    return new Promise((resolve, reject) => {
        fetch(url, {
            credentials: 'include'
        })
        .then(r => {
            if (r.status === 200) { resolve(r.json().then(data => data)); }
            else if (response.status === 409) { reject(r.statusText); }
            else { reject(r.statusText); }
        })
        .catch(r => { reject(r.statusText); });
    });
}

function deleteUser(user_id) {
  var url = getApiUrl() + 'site/users/' + user_id;

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'DELETE',
      credentials: 'include'
    })
    .then(response => {
      if (response.status === 200) {
        response.json().then(data => {
          if (data.count == 1 && data.status === 'success') {
            resolve(data.count);
          }
          else {
            reject('An error occurred during the delete process.');
          }
        });
      }
      else {
        reject('Delete user from site failed');
      }
    })
    .catch(response => {
      reject('Delete user from site failed');
    });
  });
}

function callScript(data) {
  var url = getApiUrl() + 'site/pyscript';

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
    .then(response => {
      if (response.status === 200) {
        resolve(response.json().then(data => data));
      }
      else {
        if (response.status === 409) {
          reject(response.statusText);
        }
        else {
          reject('Script not run because of error: ' + response.status + ' - ' + response.statusText);
        }
      }
    })
    .catch(response => {
      reject('Script not run because of error:' + response.status + ' - ' + response.statusText);
    });
  });
}
