import React from 'react';
import {WciModal} from './wcimodal';

export const VerifyPassword = props => {
    if (!props.options) return null;
    if (!props.options.show) return null;
  return (
    <WciModal show={props.options.show} className="baseModal">
      <div className="verifyPwModal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Verify Your Password</h5>
            <button type="button"
                    className="close"
                    disabled={props.options.disable}
                    onClick={props.options.handlers.cancel}>&times;</button>
          </div>
          <div className="modal-body">
            <p>Please input your current password</p>
            {props.options.errorMsg &&
              <div className='alert alert-danger'>{props.options.errorMsg}</div>
            }
            <input type="password" name="verifypassword" placeholder="Password" onChange={props.options.handlers.change} value={props.options.pwval} />
          </div>
          <div className="modal-footer">
            <button type="button"
                    className="btn btn-secondary"
                    disabled={props.options.disable}
                    onClick={props.options.handlers.cancel}>Cancel</button>
            <button type="button"
                    className="btn btn-primary"
                    disabled={props.options.disable}
                    onClick={props.options.handlers.proceed}>Verify</button>
          </div>
        </div>
      </div>
    </WciModal>
  );
};
