import React from 'react';

import { convertSeconds } from '../../../_helpers';

export const ReservationHeadlines = props => {
  const { rooms, total, occupancy, addata } = props;
  const apptime = convertSeconds(total.app, true, 3);
  const chantime = convertSeconds(total.chan, true, 3);
  const powertime = convertSeconds(total.power, true, 3);

  let adview = null;

  if (addata) {
    adview = [];
    const adpagetime = convertSeconds(total.adstime, true, 3);
    const adtime = convertSeconds(addata.total_time, true, 3);
    const adrun = convertSeconds(total.adsrun, true, 3);
    adview.push(<div key='addata0' className="headline-strong">Dwell Time: {adrun}</div>)
    adview.push(<div key='addata1' className="headline-strong">Page Time: {adpagetime}</div>);
    //adview.push(<div key='addata2' className="headline-strong">Total Ad Calls: {addata.total_calls}</div>);
    //adview.push(<div key='addata3' className="headline-strong">Completed Ads Count: {addata.total_completed}</div>);
    //adview.push(<div key='addata4' className="headline-strong">Completed Ads Time: {adtime}</div>);
  }

//  <div className="headline-strong">Total Application Time: {apptime}</div>
//  <div className="headline-strong">Total Channel Time: {chantime}</div>
  return (
    <div className="headlines-section">
      <div className="headline-occupancy">
        <span>Occupancy {occupancy}</span>&nbsp;
        <span className="headline-disclaimer">(Will not be accurate for hotels without an integrated PMS system.)</span>
      </div>
      <div className="headline-strong">Total Power On Time: {powertime}</div>
      {adview}
    </div>
  );
};
