import { appParameterConstants } from "../_constants";

export function appparam(state = {}, action) {
    switch (action.type) {
        case appParameterConstants.APPPARAM_CLEAR:
            return {
                ...state,
                appparam: {}
            };
        case appParameterConstants.APPPARAM_GET_REQUEST:
        case appParameterConstants.APPPARAM_GET_SUCCESS:
        case appParameterConstants.APPPARAM_GET_FAILURE:
        case appParameterConstants.APPPARAM_ADD_REQUEST:
        case appParameterConstants.APPPARAM_ADD_CANCEL:
        case appParameterConstants.APPPARAM_ADD_SELECT:
        case appParameterConstants.APPPARAM_STB_GET_REQUEST:
        case appParameterConstants.APPPARAM_STB_GET_SUCCESS:
        case appParameterConstants.APPPARAM_STB_GET_FAILURE:
        case appParameterConstants.APPPARAM_EDIT_REQUEST:
        case appParameterConstants.APPPARAM_EDIT_CANCEL:
        case appParameterConstants.APPPARAM_DEACTIVATE_REQUEST:
        case appParameterConstants.APPPARAM_DEACTIVATE_CANCEL:
        case appParameterConstants.APPPARAM_DEACTIVATE_PROCEED_REQUEST:
        case appParameterConstants.APPPARAM_DEACTIVATE_PROCEED_SUCCESS:
        case appParameterConstants.APPPARAM_DEACTIVATE_PROCEED_FAILURE:
        case appParameterConstants.APPPARAM_CHANGE_SUCCESS:
        case appParameterConstants.APPPARAM_RESET_SUCCESS:
        case appParameterConstants.APPPARAM_SAVE_REQUEST:
        case appParameterConstants.APPPARAM_SAVE_SUCCESS:
        case appParameterConstants.APPPARAM_SAVE_FAILURE:
        //case appParameterConstants.APPPARAM_STB_CLEAR:
            return {
                ...state,
                appparam: action.obj
            };
        default:
            return state;
    }
}