import React from 'react';
import { connect } from 'react-redux';
import { WciModal } from '../../wcimodal';

import { msgActions } from '../../../_actions';
import { prepTableInfo, calcTotalPages, confirmOptions } from '../../../_helpers';

import { ProcessIndicator } from '../../processIndicator';
import { DataTable } from '../../dataTable';
import { ConfirmModal } from '../../confirm';

const MsgReview = props => {
  const { alert, monitor } = props;
  const { msg } = monitor;
  const { review } = msg;

  const rowCount = 5;

  let tableInfoObj = null;
  let dataTable = null;
  let cModal = null;
  let processIndShow = review.processing ? true : false;

  if (review && review.list && review.list.length > 0) {
    tableInfoObj = prepTableInfo(review.list);
    if (tableInfoObj) {
      const paginationHandler = (e) => { props.page(monitor, e); };
      const messageClickHandler = (e) => { props.select(monitor, e); };
      const deactivateHandler = (e) => { props.deactivate(monitor, e); };
      const sortHandler = (e) => { props.sort(monitor, e); };

      const totalpages = calcTotalPages(review.list.length, rowCount);
      let currPage = (review.page) ? review.page : 1;
      if (currPage > totalpages) currPage = totalpages;

      const pageParams = {
          rowCount: rowCount
        , dataPage: currPage
        , pages: totalpages
        , handler: paginationHandler
      };
      const tableDisplayParams = {
          name: 'messages'
        , hideFields: ['message_id']
        , allowOptions: {
              edit: { allowed: true, handler: messageClickHandler }
            , delete: { allowed: true, handler: deactivateHandler }
            , select: { allowed: false, handler: null }
          }
        , configLookups: ['priority', 'type']
        , pagination: {
              rowCount: pageParams.rowCount
            , dataPage: pageParams.dataPage
          }
        , sorting: {
              columns: ['priority', 'response_option', 'created']
            , handler: sortHandler
            , activeSort: (review.sort) ? review.sort.field : null
            , direction: (review.sort) ? review.sort.direction : null
          }
      };
      dataTable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} pagination={pageParams} />;
    }
  }

  if (review.deactivate) {
    let confirmOpts = confirmOptions();
    const deactivateProceedHandler = () => { props.deactivateConfirm(monitor); };
    const deactivateCancelHandler = () => { props.deactivateCancel(monitor); };
    confirmOpts.show = true;
    confirmOpts.title = 'Confirm Deactivate';
    confirmOpts.question = 'Are you sure you want to deactivate the message.';
    confirmOpts.handlers.proceed = deactivateProceedHandler;
    confirmOpts.handlers.cancel = deactivateCancelHandler;
    confirmOpts.proceed.title = 'Deactivate Message';
    confirmOpts.proceed.className = 'btn-danger';
    cModal = <ConfirmModal options={confirmOpts} />;
  }

  return (
    <WciModal show={true} className="baseModal">
      <div className="reviewMsgModal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Review Messages</h5>
            <button type="button"
                    className="close"
                    onClick={() => { props.close(monitor); }}>&times;</button>
          </div>
          <div className="modal-body">
            <ProcessIndicator show={processIndShow} />
            {alert.message &&
              <div className={`alert ${alert.type}`}>{alert.message}</div>
            }
            <div className="grpMgrSection">
              {dataTable}
            </div>
          </div>
        </div>
      </div>
      {cModal}
    </WciModal>
  );
};

function mapStateToProps(state) {
  const alert = state.alert;
  const monitor = state.monitor;
  return { alert, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    close(monitor) { dispatch(msgActions.reviewClose(monitor)); }
  , page(monitor, e) { dispatch(msgActions.reviewPage(monitor, e)); }
  , sort(monitor, e) { dispatch(msgActions.reviewSort(monitor, e)); }
  , deactivate(monitor, e) { e.stopPropagation(); dispatch(msgActions.reviewDeactivate(monitor, e)); }
  , deactivateCancel(monitor) { dispatch(msgActions.reviewDeactivateCancel(monitor)); }
  , deactivateConfirm(monitor) { dispatch(msgActions.reviewDeactiveConfirm(monitor)); }
  , select(monitor, e) { dispatch(msgActions.reviewSelect(monitor, e)); }
});

const connectedMsgReview = connect(mapStateToProps, mapDispatchToProps)(MsgReview);
export { connectedMsgReview as MsgReview };
