import React from 'react';
import { connect } from 'react-redux';

import { alertActions, adminRoleActions } from '../../../_actions';
import { permissionCheck, prepTableInfo, calcTotalPages, confirmOptions, history, isObjectEmpty } from '../../../_helpers';

import { NavigationBar } from '../../navigation';
import { SimpleSearch } from '../../search';
import { DataTable } from '../../dataTable';
import { ConfirmModal } from '../../confirm';
import { ProcessIndicator } from '../../processIndicator';
import { Pagination } from '../../pagination';


class RoleAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        authenticated: false
      , searchInput: ''
      , confirm: false
      , confirmIdx: null
      , viewpage: 1
      , rowsPerPage: 10
    };

    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onRowDelete = this.onRowDelete.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.onDeleteProceed = this.onDeleteProceed.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
    this.onkeydown = this.onkeydown.bind(this);
    this.onPageClick = this.onPageClick.bind(this);
    this.onSortClick = this.onSortClick.bind(this);
  }

  componentDidMount() {
    const { auth, admin, search } = this.props;
    if (auth) {
      if (permissionCheck(auth.permissions, 'admin.role')) {
        this.setState({ authenticated: true });
        const { role } = admin;
        if (role.refresh) {
          search(admin, this.state.searchInput);
        }
      }
    }
  }

  componentDidUpdate() {
    const { auth, admin, unauthorized } = this.props;
    if (auth) {
      if (!this.state.authenticated) {
        if (permissionCheck(auth.permissions, 'admin.role')) {
          this.setState({ authenticated: true });
        }
        else {
          unauthorized();
        }
      }
      else {
        const { role } = admin;
        if (role.refresh) {
          this.props.search(admin, this.state.searchInput);
        }
      }
    }
  }

  render() {
    const { alert, auth, admin } = this.props;
    if (!auth) return null;
    if (!admin) return null;

    const role = admin.role;
    const allowEdit = permissionCheck(auth.permissions, 'admin.role.edit');
    const allowDel = permissionCheck(auth.permissions, 'admin.role.delete');
    const processIndShow = role.deleting ? role.deleting : false;
    let dataTable = null;
    let pagination = null;
    let addBtn = null;
    let confirmOpts = new confirmOptions();
    const paginationHandler = this.onPageClick;

    if (role.items && role.items.length > 0) {
      var tableInfoObj = prepTableInfo(role.items);
      const totalpages = calcTotalPages(role.items.length, this.state.rowsPerPage);

      let currPage = this.state.viewpage;
      if (currPage > totalpages) currPage = totalpages;

      const pageParams = {
          rowCount: this.state.rowsPerPage
        , dataPage: currPage
        , pages: totalpages
        , handler: paginationHandler
        , hasBtn: false
      };
      var tableDisplayParams = {
          hideFields: ['role_id', 'created', 'created_id', 'created_user_name', 'modified', 'modified_id', 'modified_user_name']
        , allowOptions: {
            edit: { allowed: allowEdit, handler: (allowEdit) ? this.onRowClick : null },
            delete: { allowed: allowDel, handler: (allowDel) ? this.onRowDelete : null },
            select: { allowed: false, handler: null }
        }
        , pagination: {
              rowCount: pageParams.rowCount
            , dataPage: pageParams.dataPage
        }
        , sorting: {
              columns: ['name', 'user_count', 'permission_count']
            , handler: this.onSortClick
            , activeSort: (role.sort) ? role.sort.field : null
            , direction: (role.sort) ? role.sort.direction : null
        }
    };
      dataTable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} />;
      pagination = <Pagination data={tableInfoObj.data} pageParams={pageParams} />;
    }

    if (allowEdit) {
      addBtn = <button type="button"
                      className={(role.items) ? 'btn btn-success visible' : 'btn btn-success invisible'}
                      disabled={processIndShow}
                      onClick={this.onAddClick}>+ Add</button>;
    }

    if (this.state.confirm) {
      const item = role.items[this.state.confirmIdx];
      confirmOpts.show = true;
      confirmOpts.title = 'Confirm Delete';
      confirmOpts.question = 'Are you sure you want to delete the Role: ' + item.name;
      confirmOpts.handlers.proceed = this.onDeleteProceed;
      confirmOpts.handlers.cancel = this.onDeleteCancel;
      confirmOpts.proceed.title = 'Delete';
      confirmOpts.proceed.className = 'btn-danger';
    }

    return (
      <div>
        <NavigationBar header="Admin - Roles" />
        <div className="container">
          <SimpleSearch submitHandler={this.onSearchSubmit}
                        changeHandler={this.onSearchChange}
                        searchValue={this.state.searchInput}
                        searching={role.searching}
                        searchDisable={false}
                        keydownHandler={this.onkeydown} />
        </div>
        <div className="container">
          <ProcessIndicator show={processIndShow} />
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
          {addBtn}
          <div className="col-sm paginationWBtn row justify-content-end">{pagination}</div>
          {dataTable}
          <ConfirmModal options={confirmOpts} />
        </div>
      </div>
    );
  }

  onSearchChange(evt) {
    const { value } = evt.target;
    this.setState({ searchInput: value });
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onSearchSubmit(evt) {
    if (evt) evt.preventDefault();
    const { admin, search } = this.props;
    const { searchInput } = this.state;
    search(admin, searchInput);
  }

  onRowClick(evt) {
    var idx = parseInt(evt.currentTarget.attributes['data-index'].value);
    const { admin, alert, edit, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    edit(admin, idx);
  }

  onRowDelete(evt) {
    evt.stopPropagation();
    var idx = parseInt(evt.currentTarget.attributes['data-index'].value);
    this.setState({ confirm: true, confirmIdx: idx });
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onAddClick(evt) {
    history.push('/admin/roles/add');
  }

  onDeleteProceed() {
    const { admin, remove } = this.props;
    remove(admin, this.state.confirmIdx);
    this.setState({ confirm: false, confirmIdx: null });
  }

  onDeleteCancel() {
    this.setState({ confirm: false, confirmIdx: null });
  }

  onkeydown(evt) {
      if (evt.keyCode === 13) {
          evt.preventDefault();
          evt.stopPropagation();
          this.onSearchSubmit();
      }
  }

  onPageClick(evt) {
      this.props.clearAlert();
      const page = parseInt(evt.currentTarget.attributes['data-pagenumber'].value);
      this.setState({ viewpage: page });
  }

  onSortClick(evt) {
      let field = evt.currentTarget.attributes['data-field'].value;
      const { alert, admin, clearAlert, sort } = this.props;
      sort(admin, field);
      if (!isObjectEmpty(alert)) clearAlert();
  }
};

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const admin = state.administration
  return { alert, auth, admin };
}

const mapDispatchToProps = (dispatch) => ({
    unauthorized() { dispatch(adminRoleActions.unauthorized()); }
  , clearAlert() { dispatch(alertActions.clear()); }
  , search(admin, val) { dispatch(adminRoleActions.search(admin, val)); }
  , edit(admin, idx) { dispatch(adminRoleActions.edit(admin, idx)); }
  , remove(admin, idx) { dispatch(adminRoleActions.delete(admin, idx)); }
  , sort(admin, field) { dispatch(adminRoleActions.sort(admin, field)); }
});

const connectedRoleAdmin = connect(mapStateToProps, mapDispatchToProps)(RoleAdmin);
export { connectedRoleAdmin as RoleAdmin };
