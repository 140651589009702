import React from 'react';
import { connect } from 'react-redux';

import { WciModal } from '../../wcimodal';
import { config, prepColumnHeaders } from '../../../_helpers';
import { paramActions } from '../../../_actions';

const ParamList = props => {
    const { monitor, onCancel, onSelect } = props;

    const plkeys = Object.keys(config.stbParams);
    const arrtxt = prepColumnHeaders(plkeys);

    let arrparamlist = [];
    arrtxt.forEach(el => {
        const obj = { val: el.fieldkey, txt: el.fieldText };
        arrparamlist.push(obj);
    });

    let selectoptions = null;
    if (arrparamlist) {
        selectoptions = arrparamlist.map(el => {
            return <option value={el.val}>{el.txt}</option>;
        });
        selectoptions.splice(0,0,(<option value='none'>Select A Parameter</option>));
    }

    const cancelHandler = evt => { onCancel(monitor, evt); };
    const selectChangeHandler = evt => { onSelect(monitor, arrparamlist, evt); };
    const paramSelector = <select id="paramlistoptions" onChange={selectChangeHandler}>{selectoptions}</select>;

    return(
        <WciModal show={true} className="baseModal">
            <div className="createGroupModal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Parameters Available</h5>
                        <button type="button"
                            className="close"
                            onClick={cancelHandler}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <div id="paramitem" className="col">
                            {paramSelector}
                        </div> 
                    </div>
                </div>
            </div>
        </WciModal>
    );
};

function mapStateToProps(state) {
    const monitor = state.monitor;
    return { monitor };
}

const mapDispatchToProps = (dispatch) => ({
      onCancel(monitor, evt) { dispatch(paramActions.addCancel(monitor)); }
    , onSelect(monitor, arrparamlist, evt) { dispatch(paramActions.addSelect(monitor, arrparamlist, evt)); }
});

const connectedParamList = connect(mapStateToProps, mapDispatchToProps)(ParamList);
export { connectedParamList as ParamList };