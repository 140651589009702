import { getApiUrl } from '../_helpers';

export const trackingService = {
    trackNavigation
  , trackConnection
};

function trackNavigation(data) {
  const url = getApiUrl() + 'trackNavigation';

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
    .then(response => { resolve(true); })
    .catch(response => { reject(false); });
  });
}

function trackConnection(data) {
  const url = getApiUrl() + 'trackConnection';

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
    .then(response => { resolve(true); })
    .catch(response => { reject(false); });
  });

}
