import { appParameterConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { parameterService } from '../_services';

export const paramActions = {
      clear
    , get
    , add
    , addCancel
    , addSelect
    , setParam
    , appLevelDeactivateParam
    , appLevelDeactivateCancel
    , appLevelDeactiveConfirm
    , editParam
    , cancelEdit
    , resetEdit
    , setAppParam
    , setStbParam
//    , clearStbs
};

function clear() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: appParameterConstants.APPPARAM_CLEAR }; }
}

function get(monitor) {
    return dispatch => {
        const { site, appparam } = monitor;
        const reqappparam = {
            ...appparam,
            processing: true
        };
        dispatch(request(reqappparam));

        const d = { site_id: site.edit.site_id };
        const ds = JSON.stringify(d);
        parameterService.getparams(ds)
        .then(
            items => {
                const nextappparam = {
                    ...appparam,
                    list: items
                };
                dispatch(success(nextappparam));
            },
            error => {
                dispatch(failure(appparam));
                dispatch(alertActions.error(error));
            });
    };

    function request(obj) { return { type: appParameterConstants.APPPARAM_GET_REQUEST, obj }; }
    function success(obj) { return { type: appParameterConstants.APPPARAM_GET_SUCCESS, obj }; }
    function failure(obj) { return { type: appParameterConstants.APPPARAM_GET_FAILURE, obj }; }
}

function add(monitor) {
    return dispatch => {
        const { appparam } = monitor;
        const nextappparam = {
            ...appparam,
            add: true
        };
        dispatch(request(nextappparam));
    };
    function request(obj) { return { type: appParameterConstants.APPPARAM_ADD_REQUEST, obj }; }
}

function addCancel(monitor) {
    return dispatch => {
        const { appparam } = monitor;
        const { add, ...noadd } = appparam;
        dispatch(cancel(noadd));
    };
    function cancel(obj) { return { type: appParameterConstants.APPPARAM_ADD_CANCEL, obj }; }
}

function addSelect(monitor, list, evt) {
    return dispatch => {
        const { appparam } = monitor;
        const { add, edit, ...noadd } = appparam;
        const idx = parseInt(evt.target.selectedIndex) - 1;
        if (idx < 0) {
            dispatch(alertActions.error('A parameter option was not selected'));
            dispatch(select(noadd));
            return;
        }
        const edititem = {
              app_param_id: list[idx].val
            , active: 1
            , app_param_value: null
            , stb_level: 0
            , created: null
            , created_id: null
            , modified: null
            , modified_id: null
            , stb_count: 0
        };
        const nextappparam = {
            ...noadd,
            edit: edititem
        };
        dispatch(select(nextappparam));
    };
    function select(obj) { return { type: appParameterConstants.APPPARAM_ADD_SELECT, obj }; }
}

function setParam(monitor, evt) {
    return dispatch => {
        const { appparam } = monitor;
        const { list } = appparam;
        const idx = parseInt(evt.currentTarget.attributes['data-index'].value);
        const edititem = list[idx];
        const nextappparam = {
            ...appparam,
            edit: edititem
        };
        const nextmonitor = {
            ...monitor,
            appparam: nextappparam
        };
        dispatch(request(nextappparam));
        dispatch(getStbs(nextmonitor, edititem));
    };
    function request(obj) { return { type: appParameterConstants.APPPARAM_EDIT_REQUEST, obj }; }
}

function appLevelDeactivateParam(monitor, evt) {
    return dispatch => {
        const { appparam } = monitor;
        const idx = parseInt(evt.currentTarget.attributes['data-index'].value);
        const newappparam = {
            ...appparam,
            deactivate: {index: idx}
        };
        dispatch(request(newappparam));
    };
    function request(obj) { return { type: appParameterConstants.APPPARAM_DEACTIVATE_REQUEST, obj }; }
}

function appLevelDeactivateCancel(monitor) {
    return dispatch => {
        const { appparam } = monitor;
        const { deactivate, ...newappparam } = appparam;
        dispatch(cancel(newappparam));
    };
    function cancel(obj) { return { type: appParameterConstants.APPPARAM_DEACTIVATE_CANCEL, obj }; }
}

function appLevelDeactiveConfirm(monitor, auth) {
    return dispatch => {
        const { site, appparam } = monitor;
        const reqappparam = {
            ...appparam,
            processing: true
        };
        dispatch(request(reqappparam));

        const { deactivate, edit, list, ...emptyappparam } = appparam;
        const deactivateitem = list[deactivate.index];
        const d = {
              site_id: site.edit.site_id
            , app_param_id: deactivateitem.app_param_id
            , active: 0
            , app_param_value: deactivateitem.app_param_value
            , stb_level: deactivateitem.stb_level
            , created_id: auth.user_id
            , pms_room_number: deactivateitem.pms_room_number ? deactivateitem.pms_room_number : ''
            , serial_number: deactivateitem.serial_number ? deactivateitem.serial_number : ''
            , stb_param_value: deactivateitem.stb_param_value ? deactivateitem.stb_param_value : ''
        };
        const ds = JSON.stringify(d);
        parameterService.saveparam(ds)
        .then(
            items => {
                const nextappparam = {
                    ...emptyappparam,
                    list: items
                };
                dispatch(success(nextappparam));
            },
            error => {
                const failureobj = {
                    ...emptyappparam,
                    edit: edit,
                    list: list
                };
                dispatch(failure(failureobj));
                dispatch(alertActions.error(error));
            }
        );
    };
    function request(obj) { return { type: appParameterConstants.APPPARAM_DEACTIVATE_PROCEED_REQUEST, obj }; }
    function success(obj) { return { type: appParameterConstants.APPPARAM_DEACTIVATE_PROCEED_SUCCESS, obj }; }
    function failure(obj) { return { type: appParameterConstants.APPPARAM_DEACTIVATE_PROCEED_FAILURE, obj }; }
}

function editParam(monitor, evt) {
    return dispatch => {
        const { name } = evt.target;
        const { appparam } = monitor;
        const { edit, ...noedit } = appparam;

        let value = null;
        switch(name) {
            case 'active':
                if (evt.target.checked) {
                    value = "1";
                }
                else {
                    value = "0";
                }
                break;
            case 'app_param_value':
                if (evt.target.selectedIndex === 0) {
                    break;
                }
                else if (evt.target.selectedIndex === 1) {
                    value = 'true';
                    break;
                }
                else if (evt.target.selectedIndex === 2) {
                    value = 'false';
                }
                break;
        }

        const nextedit = {
            ...edit,
            [name]: value
        };
        const nextappparam = {
            ...noedit,
            edit: nextedit
        };
        dispatch(change(nextappparam));
    };
    function change(obj) { return { type: appParameterConstants.APPPARAM_CHANGE_SUCCESS, obj }; }
}

function cancelEdit(monitor) {
    return dispatch => {
        const { appparam } = monitor;
        const { edit, processing, ...noedit} = appparam;
        dispatch(cancel(noedit));
    };
    function cancel(obj) { return { type: appParameterConstants.APPPARAM_EDIT_CANCEL, obj }; }
}

function resetEdit(monitor) {
    return dispatch => {
        const { appparam } = monitor;
        const { edit, list } = appparam;
        let newedit = null;
        for (let i = 0, j = list.length; i < j; i++) {
            const item = list[i];
            if (edit.app_param_id === item.app_param_id) {
                newedit = item;
                break;
            }
        }
        const nextappparam = {
            ...appparam,
            edit: newedit
        };
        dispatch(reset(nextappparam));
    };
    function reset(obj) { return { type: appParameterConstants.APPPARAM_RESET_SUCCESS, obj }; }
}

function setAppParam(auth, monitor) {
    return dispatch => {
        const { site, appparam } = monitor;
        const { edit, list, ...emptyappparam } = appparam;
        const reqappparam = {
            ...appparam,
            processing: true
        };
        dispatch(request(reqappparam));

        if (edit) {
            const d = {
                  site_id: site.edit.site_id
                , app_param_id: edit.app_param_id
                , active: edit.active
                , app_param_value: edit.app_param_value
                , stb_level: edit.stb_level
                , created_id: auth.user_id
                , pms_room_number: edit.pms_room_number ? edit.pms_room_number : ''
                , serial_number: edit.serial_number ? edit.serial_number : ''
                , stb_param_value: edit.stb_param_value ? edit.stb_param_value : ''
            };
            const ds = JSON.stringify(d);
            parameterService.saveparam(ds)
            .then(
                items => {
                    const nextappparam = {
                        ...emptyappparam,
                        list: items
                    };
                    dispatch(success(nextappparam));
                },
                error => {
                    const failureobj = {
                        ...emptyappparam,
                        edit: edit,
                        list: list
                    };
                    dispatch(failure(failureobj));
                    dispatch(alertActions.error(error.message));
                }
            );
        }
        else {
            dispatch(failure(appparam));
            dispatch(alertActions.error('Nothing to save'));
        }
    };
    function request(obj) { return { type: appParameterConstants.APPPARAM_SAVE_REQUEST, obj }; }
    function success(obj) { return { type: appParameterConstants.APPPARAM_SAVE_SUCCESS, obj }; }
    function failure(obj) { return { type: appParameterConstants.APPPARAM_SAVE_FAILURE, obj }; }
}

function setStbParam(monitor, evt) {

}



// PRIVATE Functions
function getStbs(monitor, paramitem){
    return dispatch => {
        const { site, appparam } = monitor;
        const reqappparam = {
            ...appparam,
            processing: true
        };
        dispatch(request(reqappparam));

        const { stbs, ...cleared } = appparam;
        const { list } = appparam;
        if (!list) {
            // If there is no data on the site server no need to look for stb data. 
            dispatch(success(cleared));
            return;
        }

//        let listitem = null;
//        for(let i = 0, j = list.length; i < j; i++) {
//            const li = list[i];
//            if (li.app_param_id === paramid) {
//                listitem = li;
//                break;
//            }
//        }
        if (paramitem.stb_level == 0) {
            // if the parameter is not set for STB level control, no need to look for set STBs.
            dispatch(success(cleared));
            return;
        }
        if (paramitem.stb_count == 0) {
            // if no STB's are set for this item don't bother looking for them.
            dispatch(success(cleared));
            return;
        }

        // If we got here, this app parameter can be set for STB's and some stbs are set for it.
        const d = { site_id: site.edit.site_id, app_param_id: paramitem.app_param_id };
        const ds = JSON.stringify(d);
        parameterService.getparamstb(ds)
        .then(
            items => {
                const nextappparam = {
                    ...appparam,
                    stbs: items
                };
                dispatch(success(nextappparam));
            },
            error => {
                dispatch(failure(appparam));
                dispatch(alertActions.error(error));
            });
    };

    function request(obj) { return { type: appParameterConstants.APPPARAM_STB_GET_REQUEST, obj }; }
    function success(obj) { return { type: appParameterConstants.APPPARAM_STB_GET_SUCCESS, obj }; }
    function failure(obj) { return { type: appParameterConstants.APPPARAM_STB_GET_FAILURE, obj }; }
}

function clearStbs(monitor) {
    return dispatch => {
        const { appparam } = monitor;
        const { stbs, ...cleared } = appparam;
        dispatch(request(cleared));
    };
    function request(obj) { return { type: appParameterConstants.APPPARAM_STB_CLEAR, obj }; }
}
