import React from 'react';
import { connect } from 'react-redux';

import { alertActions, userActions } from '../_actions';

class SetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        allpw: { currpw: '', newpw: '', validatenewpw: '' }
      , showprocessing: false
      , resetting: false
      , validity: { currpw: true, newpw: true, validatenewpw: true }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.alert.message && !prevProps.alert.message) {
        this.setState({showprocessing: false, resetting: false});
    }
  }

  componentWillUnmount() {
    this.props.dispatch(alertActions.clear());
  }

  render() {
    const { allpw, resetting, validity, showprocessing } = this.state;
    const { alert } = this.props;
    return (
      <div className="jumbotron">
        <div className="container">
          <div className="col-md-6 col-md-offset-3">
            <h4>Reset Your Temporary Password</h4>
            {alert.message &&
              <div className={`alert ${alert.type}`}>{alert.message}</div>
            }
            <form name="resetPWForm" onSubmit={this.handleSubmit}>
              <div className="form-group">
                <input type="password"
                   className={'form-control' + (validity.currpw ? ' is-valid' : ' is-invalid')}
                   name="currpw"
                   value={allpw.currpw}
                   onChange={this.handleChange}
                   placeholder="Temporary Password" />
                 {resetting && !allpw.currpw &&
                  <div className="invalid-feedback">Temp Password is required</div>
                }
              </div>
              <div className="form-group">
                <input type="password"
                   className={'form-control' + (validity.newpw ? ' is-valid' : ' is-invalid')}
                   name="newpw"
                   value={allpw.newpw}
                   onChange={this.handleChange}
                   placeholder="New Password" />
                 {resetting && !allpw.newpw &&
                  <div className="invalid-feedback">New Password is required</div>
                }
                {resetting && allpw.newpw && allpw.newpw.length < 8 &&
                  <div className="invalid-feedback">New password has to be at least 8 characters</div>
                }
              </div>
              <div className="form-group">
                <input type="password"
                   className={'form-control' + (validity.validatenewpw ? ' is-valid' : ' is-invalid')}
                   name="validatenewpw"
                   value={allpw.validatenewpw}
                   onChange={this.handleChange}
                   placeholder="Verify New Password" />
                {resetting && !allpw.validatenewpw &&
                  <div className="invalid-feedback">New Password validator is required</div>
                }
              </div>
              <button className="btn btn-primary" disabled={showprocessing}>
                <span>Reset Password</span>&nbsp;&nbsp;&nbsp;
                {showprocessing &&
                  <div className="d-inline fa-2x"><i className="fas fa-spinner fa-pulse"></i></div>
                }
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }

  handleChange(e) {
    this.props.dispatch(alertActions.clear());
    const { name, value } = e.target;
    const { allpw } = this.state;
    let newpws = {
      ...allpw,
      [name]: value
    };
    this.setState({ allpw : newpws });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ resetting: true });
    const { dispatch, auth } = this.props;
    dispatch(alertActions.clear());
    if (this.isValid()) {
      const { allpw } = this.state;
      this.setState({ showprocessing: true });
      dispatch(userActions.resetTempPW(auth, allpw));
    }
  }

  isValid() {
    let validity = { currpw: true, newpw: true, validatenewpw: true, validated: true };
    let valid = true;
    const { allpw } = this.state;
    if (allpw.currpw.length === 0) {
      validity.currpw = false;
      valid = false;
    }
    if (allpw.newpw.length === 0) {
      validity.newpw = false;
      valid = false;
    }
    if (allpw.newpw.length < 8) {
      validity.newpw = false;
      valid = false;
    }
    if (allpw.validatenewpw.length === 0) {
      validity.validatenewpw = false;
      valid = false;
    }
    if (allpw.validatenewpw.length < 8) {
      validity.validatenewpw = false;
      valid = false;
    }
    if (allpw.validatenewpw !== allpw.newpw) {
      this.props.dispatch(alertActions.error('New password and validation password do not match.'));
      valid = false;
    }
    this.setState({ validity: validity });
    return valid;
  }
}

function mapStateToProps(state) {
  const alert = state.alert;
  const auth = state.authentication.user;
  return { alert, auth };
}

const connectedSetPasswordPage = connect(mapStateToProps)(SetPasswordPage);
export { connectedSetPasswordPage as SetPasswordPage };
