export const sitesConstants = {
    SITES_CLEAR: 'SITES_CLEAR'

  , SITES_LIST_REQUEST: 'SITES_LIST_REQUEST'
  , SITES_LIST_SUCCESS: 'SITES_LIST_SUCCESS'
  , SITES_LIST_FAILURE: 'SITES_LIST_FAILURE'

  , SITES_SEARCH_REQUEST: 'SITES_SEARCH_REQUEST'
  , SITES_SEARCH_SUCCESS: 'SITES_SEARCH_SUCCESS'
  , SITES_SEARCH_FAILURE: 'SITES_SEARCH_FAILURE'
  , SITES_SEARCH_CLEAR_REQUEST: 'SITES_SEARCH_CLEAR_REQUEST'
  
  , SITES_SORT_REQUEST: 'SITES_SORT_REQUEST'
  , SITES_SORT_SUCCESS: 'SITES_SORT_SUCCESS'
};
