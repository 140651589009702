import { navConstants } from '../_constants';

export function nav(state={}, action) {
  switch(action.type) {
    case navConstants.NAV_REQUEST:
      return state;
    default:
      return state;
  }
}
