import React from 'react';
import { connect } from 'react-redux';

import { UrgentMsgParam } from './editors/urgentmsg';

const ParamEditor = props => {
    const { appparam } = props.monitor;
    if(!appparam.edit) return null;
    const { edit } = appparam;
    let editor = null;

    switch(edit.app_param_id) {
        case 'message_urgent_turn_tv_on':
            editor = <UrgentMsgParam />;
            break;
        default:
            break;
    }

    return <div>{editor}</div>;
};

function mapStateToProps(state) {
    const monitor = state.monitor;
    return { monitor };
}

const connectedParamEditor = connect(mapStateToProps)(ParamEditor);
export { connectedParamEditor as ParamEditor };
