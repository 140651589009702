import React from 'react';
import { connect } from 'react-redux';
import { WciModal } from '../wcimodal';

const UserSiteList = props => {
  const { monitor } = props;
  const { sites } = monitor;
  const { list } = sites;
  let items = null;
  let display = null;
  let width = window.outerWidth;
  let displayClass = width < 720 ? 'siteSideListSm' : 'siteSideList';

  if (props.show && props.list.length > 0) {

    items = props.list.map(function(item, idx) {
      let tdstyle = {};
      switch(idx) {
        case 0:
          tdstyle = {width: '54px'};
          break;
        case 1:
          tdstyle = {width: '57px'};
          break;
        case 2:
          if (props.showSiteIp) {
            tdstyle = {width: '87px'};
          }
          else {
            tdstyle = {width: '0'};
          }
          break;
        default:
          break;
      }
      return (<tr key={'site' + idx} onClick={props.clickHandler} data-idx={idx}><td style={tdstyle}>{item.site_id}</td><td>{item.their_id}</td><td>{props.showSiteIp ? item.site_ip_address : ''}</td></tr>);
    });
  }
  if (props.show) {
    display = <SitesModal show={props.show} list={items} close={props.closeHandler} displayClass={displayClass} showSiteIp={props.showSiteIp} />;
  }
  else {
    display = <SitesListHidden handler={props.displayHandler} />;
  }

  return <div className="siteListHidden">{display}</div>;
};

function mapStateToProps(state) {
  const monitor = state.monitor
  return { monitor };
}

const connectedUserSiteList = connect(mapStateToProps)(UserSiteList);
export { connectedUserSiteList as UserSiteList };

const SitesModal = props => (
  <WciModal show={props.show} className="baseModal">
    <div className={props.displayClass}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Available Sites</h5>
          <button type="button" className="close" onClick={props.close} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <table id="siteSideList">
            <thead>
              <tr>
                <th style={{width:"54px"}}>Site</th>
                <th style={{width:"57px"}}>Code</th>
                <th style={{width: `${props.showSiteIp ? '87px' : '0'}`}}>IP</th>
              </tr>
            </thead>
            <tbody>
              {props.list}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </WciModal>
);

const SitesListHidden = props => <span data-toggle="tooltip" data-placement="top" title="Sites List" onClick={props.handler}><i className="fas fa-bars fa-2x"></i></span>;
