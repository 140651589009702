import { trackingConstants } from '../_constants';
import { trackingService } from '../_services';

export const trackingActions = {
    notifyconnection
  , notifylogon
};

function notifyconnection(accessType, device) {
  return dispatch => {
    const d = { access_type: accessType, device: device };
    dispatch(request(d));
    const ds = JSON.stringify(d);
    trackingService.trackConnection(ds)
      .then(
        result => { /* really nothing to do here. */ },
        error => { /* really nothing to do here. */ }
      );
  };
  function request(obj) { return { type: trackingConstants.TRACK_CONNECTION, obj }; }
}

function notifylogon(tracker) {
  return dispatch => {
    let newtracker = { ...tracker };
    dispatch(request(newtracker));
    const ds = JSON.stringify(tracker);
    trackingService.trackConnection(ds)
      .then(
        result => {
        },
        error => { /* really nothing to do here. */ }
      );
  };
  function request(obj) { return { type: trackingConstants.TRACK_LOGON, obj }; }
}
