import React from 'react';

import Charts from '../../charts';

import { createChartArrayFromArray } from '../../../_helpers';

export const TotalUsage = props => {
  const { total } = props;
  let entries = Object.entries(total);
  let totals = [];
  for (let i = 0, j= entries.length; i < j; i++) {
    let entry = entries[i];
    let key = entry[0];
    let val = entry[1];
    let newkey = '';
    switch (key) {
      case 'app':
        newkey = 'Applications';
        break;
      case 'chan':
        newkey = 'Channels';
        break
      default:
        newkey = key;
    }
    totals.push([newkey, val]);
  }
  let pieData = createChartArrayFromArray(totals, 'total', 'hours');
  let ch = {
      type: 'pie'
    , data: pieData
    , options:{
          "title": "Total Usage - Hours"
        , "pieHole": 0.3
        , "is3D": false
        , "pieSliceText": "value"
        , height:'300px'
        , width:'400px'
      }
  };
  return (
    <div className="statchart">
      <Charts data={ch} />
    </div>
  );
};
