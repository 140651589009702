import React from 'react';
import { connect } from 'react-redux';

import { alertActions, sitesActions, siteActions, eventActions } from '../../../_actions';
import { permissionCheck, history, isObjectEmpty, prepTableInfo, calcTotalPages, confirmOptions } from '../../../_helpers';

import { NavigationBar } from '../../navigation';
import { ProcessIndicator } from '../../processIndicator';
import { DataTable } from '../../dataTable';
import { SimpleSearchWClear } from '../../search';
import { Pagination } from '../../pagination';
import { ConfirmModal } from '../../confirm';

import { SiteData } from '../siteData';
import { SiteTabs } from '../siteTabs';

class EventsMain extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
        authorized: false
      , showSiteList: false
      , tableRows: 10
      , showConfirm: false
      , deleting: null
    };

    this.onSiteSelect = this.onSiteSelect.bind(this);
    this.onSiteDisplay = this.onSiteDisplay.bind(this);
    this.onSiteDisplayClose = this.onSiteDisplayClose.bind(this);
    this.onAddClickNew = this.onAddClickNew.bind(this);
    this.onPageClick = this.onPageClick.bind(this);
    this.onPaginationCountChange = this.onPaginationCountChange.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onSortClick = this.onSortClick.bind(this);
    this.onSearchInput = this.onSearchInput.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.onSearchClear = this.onSearchClear.bind(this);
    this.onkeydownHandler = this.onkeydownHandler.bind(this);
    this.onConfirmCancel = this.onConfirmCancel.bind(this);
    this.onDeactivateClick = this.onDeactivateClick.bind(this);
    this.onConfirmDeactivate = this.onConfirmDeactivate.bind(this);
  }

  componentDidMount() {
    const { auth, unauthorized, monitor, getSiteList, getEvents } = this.props;
    if (auth) {
      const hasPermission = permissionCheck(auth.permissions, 'portal.events');
      if (hasPermission) {
        this.setState({ authorized: true });
        const { sites, site, events } = monitor;
        if (!sites.list) {
          getSiteList(monitor);
        }
        else if (site.edit && !events.list) {
          // pull events from site when site_id changes
          getEvents(monitor);
        }
      }
      else {
        unauthorized();
        history.push('/');
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { auth, unauthorized, monitor, getSiteList, getEvents, alert, clearAlert } = this.props;
    const { authorized } = this.state;
    const { sites, site, events } = monitor;
    if (auth && !authorized) {
      const hasPermission = permissionCheck(auth.permissions, 'portal.events');
      if (hasPermission) {
        this.setState({ authorized: true });
        if (!sites.list) {
          getSiteList(monitor);
        }
      }
      else {
        unauthorized();
        history.push('/');
      }
    }
    else if (authorized) {
      if (!prevProps.monitor.site.edit && site.edit) {
        // pull events from site when site_id changes
        getEvents(monitor);
      }
    }
    if (!isObjectEmpty(alert)) window.setTimeout(clearAlert, 5000);
  }

  render() {
    const { auth, monitor, alert } = this.props;
    if (!auth) return null;
    const { sites, site, events } = monitor;
    if (!sites.list) return null;

    const showSiteIp = permissionCheck(auth.permissions, 'portal.sites.link'); // Permission to determine if site link displayed
    const allowAdd = permissionCheck(auth.permissions, 'portal.events.add');

    const { search } = events;
    let searchTxt = '';
    let searching = false;
    let searchDisable = false;
    if (search) {
      searchTxt = search.input;
      searching = search.processing ? search.processing : false;
      if (!searchDisable) searchDisable = search.processing;
    }

    let searchform = null;
    let addBtn = null;
    let processIndShow = false;
    let headerText = 'Monitor - Site - Events';
    if (site.edit) {
      headerText = 'Monitor - Site: ' + site.edit.site_id + ' - Events';

      if (allowAdd) {
          addBtn = <button type="button"
                          className="btn btn-success visible"
                          onClick={this.onAddClickNew}>New Event</button>;
      }

      searchform = <SimpleSearchWClear changeHandler={this.onSearchInput}
                          displayText="Search Events"
                          searchValue={searchTxt}
                          submitHandler={this.onSearchSubmit}
                          searching={searching}
                          clearHandler={this.onSearchClear}
                          searchDisable={searchDisable}
                          keydownHandler={this.onkeydownHandler} />;
    }

    let datatable = null;
    let pagination = null;
    let cmodal = null
    let list = (events.list && events.list.length > 0) ? events.list : null;
    if (search && search.found) list = search.found;
    if (list) {
      const allowEdit = permissionCheck(auth.permissions, 'portal.events.edit');
      const allowDelete = permissionCheck(auth.permissions, 'portal.events.delete');
      const eventlist = (search && search.found) ? search.found : events.list;
      const tableInfoObj = prepTableInfo(eventlist);
      const rowCount = events.tableRows ? events.tableRows : this.state.tableRows;
      const totalpages = calcTotalPages(eventlist.length, rowCount);
      const deactivateHandler = this.onDeactivateClick;
      let currPage = events.page ? events.page : 1;
      if (currPage > totalpages) currPage = totalpages;

      let pageParams = {
          rowCount: rowCount
        , dataPage: currPage
        , pages: totalpages
        , handler: this.onPageClick
        , selectcount: true
        , numselectHandler: this.onPaginationCountChange
        , hasBtn: true
      };
      const tableDisplayParams = {
          name: 'events'
        , hideFields: ['event_id', 'all_day', 'iteration_number', 'iteration_day', 'recurrence', 'ends', 'ends_count', 'ends_date', 'created_id', 'modified_id']
        , allowOptions: {
              edit: { allowed: allowEdit, handler: this.onEditClick }
            , delete: { allowed: allowDelete, handler: deactivateHandler }
            , select: { allowed: false, handler: null }
          }
        , configLookups: ['active']
        , pagination: {
              rowCount: pageParams.rowCount
            , dataPage: pageParams.dataPage
          }
        , sorting: {
              columns: ['start', 'end', 'title', 'created', 'modified', 'location']
            , handler: this.onSortClick
            , activeSort: (events.sort) ? events.sort.field : null
            , direction: (events.sort) ? events.sort.direction : null
          }
        , columnHiding: {
              xs: ['created']
            , sm: ['modified']
            , md: ['description', 'iteration']
          }
      };
      datatable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} />;
      pagination = <Pagination data={tableInfoObj.data} pageParams={pageParams} />;
    }

    if (events.processing) processIndShow = events.processing;
    if (site.processing) processIndShow = site.processing;
    if (!searchDisable) searchDisable = processIndShow;

    if (this.state.showConfirm) {
        let confirmOpts = confirmOptions();
        confirmOpts.show = true;
        confirmOpts.title = 'Confirm Deactivate';
        confirmOpts.question = 'Are you sure you want to deactivate: ' + this.state.deleting.title;
        confirmOpts.handlers.proceed = this.onConfirmDeactivate;
        confirmOpts.handlers.cancel = this.onConfirmCancel;
        confirmOpts.proceed.title = 'Deactivate';
        confirmOpts.proceed.className = 'btn-warning';
        cmodal = <ConfirmModal options={confirmOpts} />;
    }

    return(
      <div id="events">
        <NavigationBar header={headerText} />
        <div className="container-fluid">
          <SiteTabs active="events" />
          <SiteData site={site.edit} more={false} showSiteIp={showSiteIp} siteList={sites.list} showList={this.state.showSiteList} clickHandler={this.onSiteSelect} displayHandler={this.onSiteDisplay} closeHandler={this.onSiteDisplayClose} />
          <ProcessIndicator show={processIndShow} />
          <div className="container">
            {searchform}
            {alert.message &&
              <div className={`alert ${alert.type}`}>{alert.message}</div>
            }
          </div>
          <div className="row">
            <div className="col-sm">{addBtn}</div>
            <div className="col-sm paginationWBtn2 row justify-content-end mr-1">{pagination}</div>
          </div>
          {datatable}
          {cmodal}
        </div>
      </div>
    );
  }

  onSiteSelect(evt) {
    let siteIdx = evt.currentTarget.attributes['data-idx'].value;
    this.setState({ showSiteList: false });
    const { monitor, getSite, alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    let selectedsite = monitor.sites.list[siteIdx];
    getSite(monitor, selectedsite);
  }

  onSiteDisplay() {
    this.setState({ showSiteList: true });
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) this.props.clearAlert();
  }

  onSiteDisplayClose() {
    this.setState({ showSiteList: false });
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) this.props.clearAlert();
  }

  onAddClickNew() {
    const { alert, clearAlert, monitor, sendErr, select } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    const { site } = monitor;
    if (!site.edit) {
      sendErr('A site must be selected to add an event');
      return;
    }
    select(monitor, null);
  }

  onSortClick(evt) {
    let field = evt.currentTarget.attributes['data-field'].value;
    const { monitor, sort, alert, clearAlert } = this.props;
    sort(monitor, field);
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onSearchInput(evt) {
    const { value } = evt.target;
    const { monitor, searchInput, alert, clearAlert } = this.props;
    searchInput(monitor, value);
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onSearchSubmit(evt) {
    const { monitor, search, alert, clearAlert } = this.props;
    search(monitor);
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onSearchClear(evt) {
    const { monitor, searchClear, alert, clearAlert } = this.props;
    searchClear(monitor);
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onDeactivateClick(evt) {
    evt.stopPropagation();
    var idx = parseInt(evt.currentTarget.attributes['data-index'].value);
    const { monitor, sendErr, alert, clearAlert } = this.props;
    const { events } = monitor;
    const { search } = events;
    let list = events.list;
    if (search && search.found) list = search.found;
    let obj = null;
    if (idx >= 0) {
      obj = list[idx];
    }
    if (!obj) {
      sendErr('Item data not found');
      return;
    }
    this.setState({ showConfirm: true, deleting: obj });
     if (!isObjectEmpty(alert)) clearAlert();
  }
  onConfirmDeactivate() {
    const { auth, monitor, deactivate } = this.props;
    const { deleting } = this.state;
    if (!deleting) return;
    deactivate(monitor, deleting, auth);
    this.setState({ showConfirm: false, deleting: null });
  }
  onConfirmCancel() {
    this.setState({ showConfirm: false, deleting: null });
  }

  onEditClick(evt) {
    var idx = parseInt(evt.currentTarget.attributes['data-index'].value);
    const { monitor, auth, select, newSelect, sendErr, alert, clearAlert } = this.props;
    const { events } = monitor;
    const { search } = events;
    let list = events.list;
    if (search && search.found) list = search.found;
    let obj = null;
    if (idx >= 0) {
      obj = list[idx];
    }
    if (!obj) {
      sendErr('Item data not found');
      return;
    }
    select(monitor, obj);
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onPageClick(evt) {
    const pagenum = parseInt(evt.currentTarget.attributes['data-pagenumber'].value);
    const { monitor, page, alert, clearAlert } = this.props;
    page(monitor, pagenum);
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onPaginationCountChange(evt) {
    const { value } = evt.target;
    this.setState({ tableRows: value });
  }

  onkeydownHandler(evt) {
      if (evt.keyCode === 13) {
          evt.preventDefault();
          evt.stopPropagation();
          this.onSearchSubmit();
      }
  }
}

function mapStateToProps(state) {
  const alert = state.alert;
  const auth = state.authentication.user;
  const monitor = state.monitor;
  return { alert, auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    unauthorized() { dispatch(alertActions.error('You are not authorized for that page.')); }
  , getSiteList(monitor) { dispatch(sitesActions.list(monitor)); }
  , clearAlert() { dispatch(alertActions.clear()); }
  , getSite(monitor, site) { dispatch(siteActions.get(monitor, site, false)); }
  , sendErr(msg) { dispatch(alertActions.error(msg)); }
  , getEvents(monitor) { dispatch(eventActions.get(monitor)); }
  , sort(monitor, field) { dispatch(eventActions.sort(monitor, field)); }
  , searchInput(monitor, value) { dispatch(eventActions.searchInput(monitor, value)); }
  , search(monitor) { dispatch(eventActions.search(monitor)); }
  , searchClear(monitor) { dispatch(eventActions.searchClear(monitor)); }
  , select(monitor, obj) { dispatch(eventActions.select(monitor, obj)); }
  , page(monitor, page) { dispatch(eventActions.page(monitor, page)); }
  , deactivate(monitor, obj, auth) { dispatch(eventActions.deactivate(monitor, obj, auth)); }
});

const connectedEventsMain = connect(mapStateToProps, mapDispatchToProps)(EventsMain);
export { connectedEventsMain as EventsMain };
