import { adminSiteConstants } from '../_constants';

export function site(state, action) {
  switch(action.type) {
    case adminSiteConstants.SITE_INPUT:
      return {
        ...state,
        site: action.obj
      };
    case adminSiteConstants.SITE_CLEAR:
      return {
        ...state,
        site: action.obj
      }
    case adminSiteConstants.SITE_SEARCH_REQUEST:
      return {
        ...state,
        site: action.obj
      }
    case adminSiteConstants.SITE_SEARCH_SUCCESS:
      return {
        ...state,
        site: action.obj
      }
    case adminSiteConstants.SITE_SEARCH_FAILURE:
      return {
        ...state,
        site: action.obj
      }
    case adminSiteConstants.SITE_USER_REQUEST:
      return {
        ...state,
        site: action.obj
      };
    case adminSiteConstants.SITE_USER_SUCCESS:
      return {
        ...state,
        site: action.obj
      };
    case adminSiteConstants.SITE_USER_FAILURE:
      return {
        ...state,
        site: action.obj
      };
    default:
      return state;
  }
}
