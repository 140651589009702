import React from 'react';

import { WciModal } from '../wcimodal';

export const LoginHelperModal = props => (
        <WciModal show={props.show} className={props.className}>
            <div className={props.placementClass}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">What kind of help do you need?</h5>
                        <button type="button"
                                className="close"
                                onClick={props.close}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className="row justify-content-around">
                                <button type="button" className="btn btn-warning" onClick={props.forgotUN}>Forgot User Name</button>
                                <button type="button" className="btn btn-warning" onClick={props.resetPW}>Reset Password</button>
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button"
                              className="btn btn-secondary"
                              onClick={props.close}>Cancel</button>
                    </div>
                </div>
            </div>
        </WciModal>
);
