import { getApiUrl } from '../_helpers';

export const profileService = {
    get
  , update
};

function get(group, user_id) {
  const url = getApiUrl() + 'profile/' + group + '/' + user_id.toString();

  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'include'
    })
    .then(response => {
      if (response.status === 200) {
        resolve(response.json().then(data => data));
      }
      else {
        if (response.status === 409) {
          reject(response.statusText);
        }
        else {
          reject('Get not successful because: ' + response.status + ' - ' + response.statusText);
        }
      }
    })
    .catch(response => { reject('Get not successful because of error: ' + response.status + ' - ' + response.statusText); });
  });
}

function update(group, data) {
  const url = getApiUrl() + 'profile/' + group + '/update';

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response.json().then(data => data));
        }
        else {
          if (response.status === 409) {
            reject(response.statusText);
          }
          else {
            reject('Registration not successful because: ' + response.status + ' - ' + response.statusText);
          }
        }
      })
      .catch(response => { reject('Update not successful because of error: ' + response.status + ' - ' + response.statusText); });
  });
}
