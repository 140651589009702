import React from "react";
import { connect } from "react-redux";

import {
  alertActions,
  siteActions,
  textTokensActions,
  sitesActions,
} from "../../../_actions";
import { permissionCheck, history, isObjectEmpty } from "../../../_helpers";

import { NavigationBar } from "../../navigation";
import { ProcessIndicator } from "../../processIndicator";

import { SiteData } from "../siteData";
import { SiteTabs } from "../siteTabs";
import TextTokensList from "./TokenList";
import { textTokenConstants } from "../../../_constants/monitor.site.texttokens.constants";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism.css"; //Example style, you can use another

require("../../../styles/site");

class TextTokensMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized: false,
      showSiteIp: false,
      showSiteList: false,
      tokenList: [],
      editor: {
        editing: null,
      },
    };

    this.onAdd = this.onAdd.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onTokenSave = this.onTokenSave.bind(this);
    this.onNewTokenCancel = this.onNewTokenCancel.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onReset = this.onReset.bind(this);
  }

  componentDidMount() {
    const { auth, unauthorized, monitor, getSiteList } = this.props;
    if (auth) {
      const hasPermission = permissionCheck(
        auth.permissions,
        "portal.texttokens"
      );
      const showSiteIp = permissionCheck(auth.permissions, "portal.sites.link"); // Permission for site link display
      if (hasPermission) {
        this.setState({ authorized: true, sowSiteIp: showSiteIp });
        const { sites, site } = monitor;
        if(site.edit){
          this.props.getTokens(monitor);
        }
        if (!sites.list) {
          getSiteList(monitor);
        }
      }
    } else {
      unauthorized();
      history.push("/");
    }
  }

  componentDidUpdate(prevProps) {
    const { auth, unauthorized, monitor, getSiteList, alert, clearAlert } =
      this.props;
    const { authorized } = this.state;
    const { sites } = monitor;
    if (auth && !authorized) {
      const hasPermission = permissionCheck(
        auth.permissions,
        "portal.textokens"
      );
      if (hasPermission) {
        this.setState({ authorized: true });
        this.props.getTokens(monitor);
        if (!sites.list) {
          getSiteList(monitor);
        }
      } else {
        unauthorized();
        history.push("/");
      }
    }
    if (!isObjectEmpty(alert)) window.setTimeout(clearAlert, 5000);
  }
  onEdit(tokenId) {
    this.setState({ ...this.state, editor: { editing: tokenId } });
  }

  onEditorChange(code, tokenId) {
    this.props.updateToken({ token: tokenId, value: code });
  }

  render() {
    const { auth, monitor, alert} = this.props;
    if (!auth) return null;

    const { showSiteIp, showSiteList } = this.state;
    const { sites, site} = monitor;
    let headerText = "Monitor - Site - STB Text Tokens";
    let textTokensList = monitor.texttokens.list;
    let isAdding = false;
    for (let item of textTokensList) {
      if (item.mode === "new") isAdding = true;
    }
    let editing = this.state.editor.editing;
    let editorLabel =  null;
    let editorValue = '';
    if (editing !== null) {
      let token_being_edited = monitor.texttokens.list.find((item) => {
        return item.token === editing;
      })
      if (token_being_edited){
        editorLabel = token_being_edited.label;
        editorValue = token_being_edited.value;
      } 
    }
    
    return (
      <div id="texttokens">
        <NavigationBar header={headerText} />
        <div className="container-fluid">
          <SiteTabs active="texttokens" />
          <SiteData
            site={site.edit}
            more={false}
            showSiteIp={showSiteIp}
            siteList={sites.list}
            showList={showSiteList}
            clickHandler={this.onSiteSelect}
            displayHandler={this.onSiteDisplay}
            closeHandler={this.onSiteDisplayClose}
          />
          {alert.message && (
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          )}
          {site.edit ? (
            <div className="container-fluid mt-4" style={{ display: "flex" }}>
            <div className="text-token-page-container" style={{ flexGrow: 1 }}>
              <div className="text-token-list-container">
                <TextTokensList
                  onValueChange={this.onValueChange}
                  onEdit={this.onEdit.bind(this)}
                  onSave={this.onTokenSave}
                  onDelete={this.onDelete}
                  onCancel={this.onNewTokenCancel}
                  list={textTokensList}
                />
                <div className="text-token-button-row">
                  <button
                    type="button"
                    className="btn btn-primary left-margin-10"
                    onClick={this.onAdd}
                    disabled={monitor.texttokens.isRequesting}
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary left-margin-10"
                    onClick={this.onSave}
                    disabled={monitor.texttokens.isRequesting || isAdding}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary left-margin-10"
                    onClick={this.onReset}
                    disabled={monitor.texttokens.isRequesting}
                  >
                    Reset
                  </button>
                </div>
                <ProcessIndicator show={monitor.texttokens.isRequesting} />
              </div>
              <div className="text-token-editor-container">
                {editorLabel}
                <Editor
                  value={editorValue}
                  disabled={editing === null}
                  onValueChange={(code) => {
                    this.onEditorChange(code, editing);
                  }}
                  highlight={(code) => highlight(code, languages.html)}
                  padding={10}
                  style={{
                    fontFamily: '"Consolas", "Fira Code", "monospace"',
                    fontSize: 12,
                    border: "1px solid #ccc",
                  }}
                />
              </div>
            </div>
          </div>
          ):null}
        </div>
      </div>
    );
  }

  onValueChange(event) {
    this.props.updateToken({
      token: event.target.id,
      value: event.target.value,
    });
  }

  onReset(event) {
    this.props.getTokens(this.props.monitor);
  }

  onAdd(event) {
    for (let item of this.props.monitor.texttokens.list) {
      if (item.token === "newtoken") return; //already in add mode
    }

    this.props.addToken({
      token: "newtoken",
      label: "",
      value: "",
      appZone: null,
      descriptionn:null,
      sequence:null,
      group: "",
      mode: "new"
    });
  }

  onDelete(token) {
    this.props.deleteToken(token);
  }

  onTokenSave(newLabel) {
    if(newLabel === ''){
      this.onNewTokenCancel();
      return;
    }
    this.props.saveNewToken(newLabel);
  }

  onSave(event){
    let {site, texttokens} = this.props.monitor;
    //take out ui control related fields
    let list = texttokens.list.map((item, index)=>{
      let newItem = {...item, sequence: index};
      delete newItem.mode;
      return newItem
    })
    this.props.saveTextTokens(site.edit.site_id, list);
  }

  onNewTokenCancel(event){
    this.props.cancelNewToken()
  }

}

function mapStateToProps(state) {
  const alert = state.alert;
  const auth = state.authentication.user;
  const monitor = state.monitor;
  return { alert, auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
  unauthorized() {
    dispatch(alertActions.error("You are not authorized for that page."));
  },
  getSiteList(monitor) {
    dispatch(sitesActions.list(monitor));
  },
  clearAlert() {
    dispatch(alertActions.clear());
  },
  getSite(monitor, site) {
    dispatch(siteActions.get(monitor, site, false));
  },
  getTokens(monitor) {
    dispatch(textTokensActions.get(monitor));
  },
  saveTextTokens(site_id, textTokenList) {
    dispatch(textTokensActions.save(site_id, textTokenList));
  },
  addToken(payload) {
    dispatch({
      type: textTokenConstants.TEXT_TOKEN_ADD_TOKEN,
      payload: payload,
    });
  },
  cancelNewToken(payload) {
    dispatch({
      type: textTokenConstants.TEXT_TOKEN_CANCEL_TOKEN,
    });
  },
  saveNewToken(payload) {
    dispatch({
      type: textTokenConstants.TEXT_TOKEN_SAVE_TOKEN,
      payload: payload,
    });
  },
  updateToken(payload) {
    dispatch({
      type: textTokenConstants.TEXT_TOKEN_UPDATE_TOKEN,
      payload: payload,
    });
  },
  deleteToken(payload) {
    dispatch({
      type: textTokenConstants.TEXT_TOKEN_DELETE_TOKEN,
      payload: payload,
    });
  },
  //    , getParamStbs(monitor, paramid) { dispatch(paramActions.getStbs(monitor, paramid)); }
  //    , clearParamStbs(monitor) { dispatch(paramActions.clearStbs(monitor)); }
});

const connectedTextTokensMain = connect(
  mapStateToProps,
  mapDispatchToProps
)(TextTokensMain);
export { connectedTextTokensMain as TextTokensMain };
