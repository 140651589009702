import React from 'react';
import { config, stbUp, stbOn, stbOff, checkedIn, jsonToCSV, jsonToExcel, hasHtmlCode, convertHtmlCode, stbDownText } from '../_helpers';
import { Pagination } from './pagination';

const specialAdditions = ['room_number'];

export const DataTable = props => {
  var classesToApply = "table table-sm table-hover";
  if (props.data.length > 4) {
    classesToApply += ' table-striped';
  }
  let hdr = null;
  let pagination = null;
  let exportCSVbtn = null;
  let exportExcelbtn = null;
  let topClasses = 'row justify-content-end mr-1';
  if (props.displayParams.export) {
    topClasses = 'row justify-content-between ml-1 mr-1';
    if (props.displayParams.export.csv) {
      exportCSVbtn = <button type="button" onClick={() => {jsonToCSV(props.header, props.data, props.displayParams.export.name)}} className="btn btn-primary d-none d-lg-inline-block">Export as CSV</button>;
    }
    if (props.displayParams.export.excel) {
      exportExcelbtn = <button type="button" onClick={() => {jsonToExcel(props.header, props.data, props.displayParams.export.name, 'STBs')}} className="btn btn-primary siteexportexcel d-none d-lg-inline-block">Export to Excel</button>;
    }
  }
  if (props.pagination) {
    pagination = <Pagination data={props.data} pageParams={props.pagination} />;
  }
  if (props.displayParams.name && props.displayParams.name === 'site') {
    hdr = generateSiteHeaderColumns(props.header, props.displayParams);
  }
  else {
    hdr = generateHeaderColumns(props.header, props.displayParams);
  }
  let info = generateTableData(props.data, props.displayParams);
  return (
    <div>
      <div className={topClasses}>
        {exportCSVbtn}{exportExcelbtn}{pagination}
      </div>
      <table className={classesToApply}>
        <thead>
          <tr>{hdr}</tr>
        </thead>
        <tbody>{info}</tbody>
      </table>
    </div>
  );
};

function generateHeaderColumns(header, displayParams) {
  var hdrs = header.map(function(hdr, idx) {
    let sizingClasses = columnHiding(hdr.fieldkey, displayParams);
    if (displayParams.hideFields.indexOf(hdr.fieldkey) < 0) {
      // Not hidden
      if (displayParams.sorting && displayParams.sorting.columns.indexOf(hdr.fieldkey) >= 0) {
        // A sort column
        if (hdr.fieldkey === displayParams.sorting.activeSort) {
          // Active sort column
          let direction = displayParams.sorting.direction;
          return (<th key={'th' + idx} data-field={hdr.fieldkey} className={'thf thsort' + sizingClasses} onClick={displayParams.sorting.handler}>{hdr.fieldText}<i className={direction === 'asc' ? 'up ml-1' : 'down ml-1'}></i></th>);
        }
        else {
          // Not the active sort column
          return (<th key={'th' + idx} data-field={hdr.fieldkey} className={'thf thsort' + sizingClasses} onClick={displayParams.sorting.handler}>{hdr.fieldText}<i style={{display:'none'}}></i></th>);
        }
      }
      else {
        // Not a sort column
        return (<th key={'th' + idx} data-field={hdr.fieldkey} className={'thf' + sizingClasses}>{hdr.fieldText}</th>);
      }
    }
  });
//  if (displayParams.allowOptions.edit.allowed) {
//    hdrs.splice(0,0, (<th key={'thedit'} data-field={'edit'}></th>));
//  }
  if (displayParams.allowOptions.select.allowed) {
    hdrs.splice(0,0, (<th key={'thselect'} data-field={'select'}></th>));
  }
  if (displayParams.allowOptions.delete.allowed) {
    hdrs.push(<th key={'thdelete'} data-field={'delete'}></th>);
  }
  return hdrs;
}
function generateSiteHeaderColumns(header, displayParams) {
  var hdrs = [];
  hdrs.push(<th key={'thstatus'} data-field={'status'}></th>);
  hdrs.push(<th key={'thcheckin'} data-field={'checkin'}></th>);
  hdrs.push(<th key={'threboot'} data-field={'reboot'}></th>);
  for (let i = 0, j = header.length; i < j; i++) {
    if (displayParams.hideFields.indexOf(header[i].fieldkey) < 0) {
      // Not hidden
      let sizingClasses = columnHiding(header[i].fieldkey, displayParams);
      if (displayParams.sorting && displayParams.sorting.columns.indexOf(header[i].fieldkey) >= 0) {
        // A sort column
        if (header[i].fieldkey === displayParams.sorting.activeSort) {
          // Active sort column
          let direction = displayParams.sorting.direction;
          hdrs.push(<th key={'th' + i} data-field={header[i].fieldkey} className={'thf thsort' + sizingClasses} onClick={displayParams.sorting.handler}>{header[i].fieldText}<i className={direction === 'asc' ? 'up ml-1' : 'down ml-1'}></i></th>);
        }
        else {
          // Not the active sort column
          hdrs.push(<th key={'th' + i} data-field={header[i].fieldkey} className={'thf thsort' + sizingClasses} onClick={displayParams.sorting.handler}>{header[i].fieldText}<i style={{display:'none'}}></i></th>);
        }
      }
      else {
        // Not a sort column
        hdrs.push(<th key={'th' + i} data-field={header[i].fieldkey} className={'thf' + sizingClasses}>{header[i].fieldText}</th>);
      }
    }
  }
  hdrs.push(<th key={'thdelete'} data-field={'delete'}></th>);
  return hdrs;
}

function generateTableData(data, displayParams) {
  let rows = [];
  if (displayParams.pagination) {
    if (!displayParams.pagination.rowCount && !displayParams.pagination.dataPage) {
      rows = mapData(data, displayParams);
    }
    else {
      let startRow = displayParams.pagination.rowCount === 0 ? 0 : displayParams.pagination.rowCount * (displayParams.pagination.dataPage - 1);
      let endRow = startRow + displayParams.pagination.rowCount - 1;
      if (endRow >= data.length) endRow = data.length - 1;
      let pageData = [];
      for (let i = startRow, j = endRow; i <= j; i++) {
        pageData.push(data[i]);
      }
      rows = mapData(pageData, displayParams);
    }
  }
  else {
    rows = mapData(data, displayParams);
  }
  return rows;
}

function mapData(data, displayParams) {
  var rows = data.map(function(item, idx) {
    if (displayParams.pagination && displayParams.pagination.dataPage > 1) {
      idx += (displayParams.pagination.dataPage - 1) * displayParams.pagination.rowCount;
    }
    let cells = null;
    if (displayParams.name && displayParams.name === 'site') {
      cells = generateSiteDataCells(item, idx, displayParams);
    }
    else {
      cells = generateDataCells(item, idx, displayParams);
    }
    let clickHandler = displayParams.allowOptions.edit.handler;
    return(<tr key={'tr' + idx} onClick={clickHandler} data-index={idx}>{cells}</tr>);
  });
  return rows;
}

function generateDataCells(item, idx, displayParams) {
  var cells = [];
//  if (displayParams.allowOptions.edit.allowed) {
//    cells.push(<td key={'td' + idx + 'edit'} data-index={idx} onClick={displayParams.allowOptions.edit.handler}><span className="fas fa-edit" data-toggle="tooltip" title="Edit"></span></td>);
//  }
  if (displayParams.allowOptions.select.allowed) {
    if (!item.selected) {
      cells.push(<td key={'td' + idx + 'select'}><input type="checkbox" data-index={idx} onClick={displayParams.allowOptions.select.handler} /></td>);
    }
    else {
      cells.push(<td key={'td' + idx + 'select'}><input type="checkbox" data-index={idx} defaultChecked onClick={displayParams.allowOptions.select.handler} /></td>);
    }
  }
  if (displayParams.linkfields && displayParams.linkfields.path)
  {
    let str=item['Path'];
    let folder='documentation/';
    var path= str.substring(str.indexOf(folder)+folder.length);
  }
  var keys = Object.keys(item);
  for (let key in keys) {
    let field = keys[key];
    let addcell = true;
    if (displayParams.hideFields.indexOf(field) >= 0) {
      addcell = false;
    }
    if (addcell) {
      let cell;
      //Check if the desrciption/category field exists for documentation tab and if it is null show a text box/select for it
      if (displayParams.optField  && displayParams.optField.permission==true && item[field] === null) {
       if(displayParams.optField.descriptionField.indexOf(field) >= 0)
       {
          cell = <td key={'td' + idx + key}><input type="textbox" style={{width:"100px"}}></input><button type="button" class="btn desc" onClick={displayParams.optField.handler}>Add</button> </td>;
       }
       if(displayParams.optField.categoryField.indexOf(field) >= 0)
       {
        let cat=displayParams.optField.categories;
        cell = <td key={'td' + idx + key}>
            
            <select value={displayParams.optField.selectedCategory} onChange={displayParams.optField.handleChange}>
                {cat.map(fbb =>
                  <option key={fbb.Id} value={fbb.Category}>{fbb.Category}</option>
                )};
            </select>

          <button type="button" class="btn desc" onClick={displayParams.optField.handler}>Add</button> 
          </td>;
       }
      }
      else if (displayParams.linkfields && displayParams.linkfields.allowed) {
        if(displayParams.linkfields.isLink==true && displayParams.linkfields.list.indexOf(field) >= 0) //check if field file name is a link for documentation tab and then create that link
        {
          cell = <td key={'td' + idx + key}><a target="_blank" download={item[field]} href={'/documentation/'  +path}>{item[field]}</a></td>;
          
        }
        else if (displayParams.linkfields.list.indexOf(field) >= 0) {
          let linkIdx = displayParams.linkfields.list.indexOf(field);
          let linkData = item[displayParams.linkfields.link[linkIdx]];
          cell = <td key={'td' + idx + key}><a target="_blank" href={'http://' + linkData + '/'}>{item[field]}</a></td>;
        }
        else {
          cell = gentd(idx, key, item , field, displayParams);
        }
      }
      else {
        cell = gentd(idx, key, item, field, displayParams);
      }
      cells.push(cell);
    }
  }
  if (displayParams.allowOptions.delete.allowed) {
    cells.push(<td key={'td' + idx + 'del'} data-index={idx} onClick={displayParams.allowOptions.delete.handler}><span className="fas fa-minus-circle" data-toggle="tooltip" title="Deactivate" style={{zIndex:"5"}}></span></td>);
  }
  if (displayParams.allowOptions.check && displayParams.allowOptions.check.allowed) {
  cells.push(<td key={'td' + idx + 'check'}><input type="checkbox" data-index={idx} onClick={displayParams.allowOptions.check.handler} /></td>);
  }
  return cells;
}
function generateSiteDataCells(item, idx, displayParams) {
  let cells = [];
  let compareto = displayParams.wci ? config.stbCommTime.wci : config.stbCommTime.hotel;
  let on = stbOn(item);
  let off = stbOff(item);
  let chkIn = checkedIn(item);

  let statusImg = '';
  let statusTooltip=''
  if (on) {
    statusImg = '/images/' + config.images.on;
    statusTooltip= "On"
  }
  else if (off) {
    statusImg = '/images/' + config.images.off;
    statusTooltip= 'Off'
  }
  else {
    statusImg = '/images/' + config.images.down;
    statusTooltip= stbDownText(item);
    if (statusTooltip === 'Network Disconnected') {
      statusImg = '/images/' + config.images.unstable;
    }
  }
  cells.push(<td key={'td' + idx + 'status'}><img src={statusImg} className="status-image"  data-toggle="tooltip" data-placement="right" title={statusTooltip} /></td>);

  let chkinjImgTag = null;
  if (chkIn) {
    chkinjImgTag = <img src={'/images/' + config.images.chkin} className="status-image" />
  }
  else if (chkIn === false) {
    chkinjImgTag = <img src={'/images/' + config.images.chkout} className="status-image" />
  }
  cells.push(<td key={'td' + idx + 'pms'}>{chkinjImgTag}</td>);

  if (displayParams.allowOptions.reboot && displayParams.allowOptions.reboot.allowed) {
    cells.push(<td key={'td' + idx + 'reboot'} data-index={idx} onClick={displayParams.allowOptions.reboot.handler}><span className="fas fa-redo" data-toggle="tooltip" title="Reboot"></span></td>);
  }

  var keys = Object.keys(item);
  for (let key in keys) {
    let field = keys[key];
    let addcell = true;
    if (displayParams.hideFields.indexOf(field) >= 0) {
      addcell = false;
    }
    if (addcell) {
      let cell;
      if (displayParams.linkfields && displayParams.linkfields.allowed) {
        if (displayParams.linkfields.list.indexOf(field) >= 0) {
          let linkIdx = displayParams.linkfields.list.indexOf(field);
          let linkData = item[displayParams.linkfields.link[linkIdx]];
          cell = <td key={'td' + idx + key}><a target="_blank" href={'http://' + linkData + '/'}>{item[field]}</a></td>;
        }
        else {
          cell = gentd(idx, key, item , field, displayParams);
        }
      }
      else {
        cell = gentd(idx, key, item, field, displayParams);
      }
      cells.push(cell);
    }
  }

  if (displayParams.allowOptions.delete.allowed) {
    cells.push(<td key={'td' + idx + 'del'} data-index={idx} onClick={displayParams.allowOptions.delete.handler}><span className="fas fa-minus-circle" data-toggle="tooltip" title="Deactivate"></span></td>);
  }

  return cells;
}

function gentd(idx, key, item, field, displayParams) {
  let cell = null;
  let fieldVal = getFieldValue(item, field, displayParams);
  if (hasHtmlCode(fieldVal)) {
    fieldVal = convertHtmlCode(fieldVal);
  }
  let sizingClasses = columnHiding(field, displayParams);
  let docClass= displayParams.name=="documentation"?' docDesc':'';// add class for documentation field description
  let alertClass= conditionalFormat(field,item);
  if (specialAdditions.indexOf(field) >= 0) {
    let saidx = specialAdditions.indexOf(field);
    switch(saidx) {
      case 0:
        if (displayParams.allowOptions.roomNum.allowed) {
          cell = <td key={'td' + idx + key} data-index={idx} onClick={displayParams.allowOptions.roomNum.handler} className={'tdf' + sizingClasses}><span className="fas fa-pencil-alt" data-toggle="tooltip" title="Change Room Number"></span>&nbsp;{fieldVal}</td>;
        }
        else {
          cell = <td key={'td' + idx + key} className={'tdf' + sizingClasses}>{fieldVal}</td>;
        }
        break;
    }
  }
  else {
    cell = <td key={'td' + idx + key} className={'tdf' + sizingClasses +alertClass +docClass}>{fieldVal}</td>;
  }
  return cell;
}

function columnHiding(key, displayParams) {
  if (!displayParams.columnHiding) return '';
  if (displayParams.columnHiding.xs && displayParams.columnHiding.xs.indexOf(key) >= 0) {
    // drop off for x-small screen
    return ' d-none d-sm-table-cell';
  }
  if (displayParams.columnHiding.sm && displayParams.columnHiding.sm.indexOf(key) >= 0) {
    // drop off for small screen
    return ' d-none d-md-table-cell';
  }
  if (displayParams.columnHiding.md && displayParams.columnHiding.md.indexOf(key) >= 0) {
    // drop off for medium screen
    return ' d-none d-lg-table-cell';
  }
  return '';
}

function getFieldValue(item, field, displayParams) {
  if (!displayParams.configLookups) return item[field];
  if (displayParams.configLookups.indexOf(field) >= 0) {
    return config[displayParams.name][field][item[field]];
  }
  else {
    return item[field];
  }
  
}

//If the housekept past time is >24 hours it sets the field font to red color 
function conditionalFormat(field, item)
  {
    if(field=='Last Serviced')
    {
      if(item['Alert']==true) return ' alertClass';
      else return '';
    }
  
    return '';
  }