export const adminUserConstants = {
    USER_INPUT: 'USER_INPUT'
  , USER_SEARCH_REQUEST: 'USER_SEARCH_REQUEST'
  , USER_SEARCH_SUCCESS: 'USER_SEARCH_SUCCESS'
  , USER_SEARCH_FAILURE: 'USER_SEARCH_FAILURE'

  , USER_DELETE_REQUEST: 'USER_DELETE_REQUEST'
  , USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS'
  , USER_DELETE_FAILURE: 'USER_DELETE_FAILURE'

  , USER_ADD_REQUEST: 'USER_ADD_REQUEST'
  , USER_ADD_SUCCESS: 'USER_ADD_SUCCESS'
  , USER_ADD_FAILURE: 'USER_ADD_FAILURE'

  , USER_GET_REQUEST: 'USER_GET_REQUEST'
  , USER_GET_SUCCESS: 'USER_GET_SUCCESS'
  , USER_GET_FAILURE: 'USER_GET_FAILURE'
  , USER_SITE_SUCCESS: 'USER_SITE_SUCCESS'
  , USER_ROLES_SUCCESS: 'USER_ROLES_SUCCESS'

  , USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST'
  , USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS'
  , USER_UPDATE_FAILURE: 'USER_UPDATE_FAILURE'

  , USER_AVAILABLEROLES_REQUEST: 'USER_AVAILABLEROLES_REQUEST'
  , USER_AVAILABLEROLES_SUCCESS: 'USER_AVAILABLEROLES_SUCCESS'
  , USER_AVAILABLEROLES_FAILURE: 'USER_AVAILABLEROLES_FAILURE'
  , USER_AVAILABLEROLES_CANCEL: 'USER_AVAILABLEROLES_CANCEL'
  , USER_AVAILABLEROLES_PAGE: 'USER_AVAILABLEROLES_PAGE'
  , USER_ADDROLE_REQUEST: 'USER_ADDROLE_REQUEST'
  , USER_ADDROLE_SUCCESS: 'USER_ADDROLE_SUCCESS'
  , USER_ADDROLE_FAILURE: 'USER_ADDROLE_FAILURE'
  , USER_DELETEROLE_REQUEST: 'USER_DELETEROLE_REQUEST'
  , USER_DELETEROLE_SUCCESS: 'USER_DELETEROLE_SUCCESS'
  , USER_DELETEROLE_FAILURE: 'USER_DELETEROLE_FAILURE'

  , USER_GET_AVAILABLESITES_REQUEST: 'USER_GET_AVAILABLESITES_REQUEST'
  , USER_GET_AVAILABLESITES_SUCCESS: 'USER_GET_AVAILABLESITES_SUCCESS'
  , USER_GET_AVAILABLESITES_FAILURE: 'USER_GET_AVAILABLESITES_FAILURE'
  , USER_ADDSITE_CANCEL: 'USER_ADDSITE_CANCEL'
  , USER_SITESEARCH_CLEAR: 'USER_SITESEARCH_CLEAR'
  , USER_SITESEARCH_REQUEST: 'USER_SITESEARCH_REQUEST'
  , USER_SITESEARCH_SUCCESS: 'USER_SITESEARCH_SUCCESS'
  , USER_SITESEARCH_FAILURE: 'USER_SITESEARCH_FAILURE'
  , USER_SITESUBMIT_REQUEST: 'USER_SITESUBMIT_REQUEST'
  , USER_SITESUBMIT_SUCCESS: 'USER_SITESUBMIT_SUCCESS'
  , USER_SITESUBMIT_FAILURE: 'USER_SITESUBMIT_FAILURE'
  , USER_DELETESITE_REQUEST: 'USER_DELETESITE_REQUEST'
  , USER_DELETESITE_SUCCESS: 'USER_DELETESITE_SUCCESS'
  , USER_DELETESITE_FAILURE: 'USER_DELETESITE_FAILURE'

  , USER_EDIT_CANCEL: 'USER_EDIT_CANCEL'

  , USER_ROLESEARCH_REQUEST: 'USER_ROLESEARCH_REQUEST'
  , USER_ROLESEARCH_SUCCESS: 'USER_ROLESEARCH_SUCCESS'
  , USER_ROLESEARCH_FAILURE: 'USER_ROLESEARCH_FAILURE'
};
