module.exports = {
    createChartArray
  , createChartArrayFromArray
};

function createChartArray(obj, x, y) {
  let keys = Object.keys(obj);
  let aChData = [];
  aChData.push([x, y]);
  for (let key in keys) {
    let entry = [keys[key], Math.floor(obj[keys[key]])];
    aChData.push(entry);
  }
  return aChData;
}

function createChartArrayFromArray(arr, x, y) {
  let aChData = [];
  aChData.push([x, y]);
  for (let i in arr) {
    let entry = [arr[i][0], Math.floor(arr[i][1])];
    aChData.push(entry);
  }
  return aChData;
}
