import { trackingConstants } from '../_constants';

export function tracking(state = {}, action) {
  switch(action.type) {
    case trackingConstants.TRACK_CONNECTION:
      return action.obj;
    case trackingConstants.TRACK_LOGON:
      return action.obj;
    default:
      return state;
  }
}
