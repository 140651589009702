import React from 'react';
import { connect } from 'react-redux';
import { permissionCheck, navConfig, history } from '../../_helpers';
import { alertActions, navActions } from '../../_actions';

const SiteTabs = props => {
  const { auth, site, dispatch } = props;
  let location = history.location.pathname;
  const clickHandler = tabOnClick.bind(null, dispatch, location);
  let plinks = navConfig.pageLinks['portal'];
  let links = null;
  links = plinks.map(function(link, idx) {
    let permission = 'portal.' + link.name;
    if (permissionCheck(auth.permissions, permission)) {
      if (link.name === 'sites') return null;
      let active = link.path === location ? 'active' : '';
      return <li key={'tbmonitor' + idx} className="nav-item"><div className={`nav-link ${active}`} data-path={link.path} onClick={clickHandler}>{link.title}</div></li>;
    }
  });

  return (
    <ul className="nav nav-tabs">{links}</ul>
  );
};

function mapStateToProps(state) {
  const auth = state.authentication.user;
  const site = state.site
  return { auth, site };
}

const connectedSiteTabs = connect(mapStateToProps)(SiteTabs);
export { connectedSiteTabs as SiteTabs };

function tabOnClick(dispatch, location, e) {
  let targetPath = e.currentTarget.attributes['data-path'].value;
  if (targetPath === location) return;
  dispatch(alertActions.clear());
  dispatch(navActions.navigate(targetPath));
}
