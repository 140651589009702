import { getApiUrl, standardPost, standardGet } from '../_helpers';

export const streamingService = {
    chanlist,
    chanedit
};

function chanlist(siteId) {
    var url = getApiUrl() + 'site/streaming/listchannels/' + siteId;
    return new Promise((resolve, reject) => {
        standardGet(url)
        .then((r) => { resolve(r); })
        .catch((e) => { reject(e); });
    });
}

function chanedit(data) {
    var url = getApiUrl() + 'site/streaming/editchannel';
    return new Promise((resolve, reject) => {
        standardPost(url, data)
        .then((r) => { resolve(r); })
        .catch((e) => { reject(e); });
    });
}
