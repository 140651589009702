import { siteConstants, siteUserConstants } from '../_constants';

export function site(state = {}, action) {
  switch(action.type) {
    case siteConstants.SITE_CLEAR:
    case siteConstants.SITE_GET_REQUEST:
    case siteConstants.SITE_GET_SUCCESS:
    case siteConstants.SITE_GET_FAILURE:
    case siteConstants.SITE_STB_ONSTATE:
    case siteConstants.SITE_STB_CHECKINSTATE:
    case siteConstants.SITE_CHART_SEARCH_REQUEST:
    case siteConstants.SITE_CHART_SEARCH_SUCCESS:
    case siteConstants.SITE_CHART_SEARCH_FAILURE:
    case siteConstants.SITE_SEARCH_INPUT:
    case siteConstants.SITE_SEARCH_CLEAR:
    case siteConstants.SITE_SEARCH_REQUEST:
    case siteConstants.SITE_SEARCH_SUCCESS:
    case siteConstants.SITE_SEARCH_FAILURE:
    case siteConstants.SITE_PAGE_CHANGE:
    case siteConstants.SITE_TABLE_ROWS:
    case siteConstants.SITE_SORT_REQUEST:
    case siteConstants.SITE_SORT_SUCCESS:
    case siteConstants.SITE_STB_DEACTIVATE_REQUEST:
    case siteConstants.SITE_STB_DEACTIVATE_SUCCESS:
    case siteConstants.SITE_STB_DEACTIVATE_FAILURE:
    case siteConstants.SITE_STB_REBOOT_REQUEST:
    case siteConstants.SITE_STB_REBOOT_SUCCESS:
    case siteConstants.SITE_STB_REBOOT_FAILURE:
    case siteConstants.SITE_ROOM_NUMBER_CHANGE_REQUEST:
    case siteConstants.SITE_ROOM_NUMBER_CHANGE_CANCEL:
    case siteConstants.SITE_ROOM_NUMBER_CHANGE_PUBLIC:
    case siteConstants.SITE_ROOM_NUMBER_CHANGE_INPUT:
    case siteConstants.SITE_ROOM_NUMBER_CHANGE_LOCATION:
    case siteConstants.SITE_ROOM_NUMBER_CHANGE_LOCATIONNUM:
    case siteConstants.SITE_ROOM_NUMBER_CHANGE_SAVE_REQUEST:
    case siteConstants.SITE_ROOM_NUMBER_CHANGE_SAVE_SUCCESS:
    case siteConstants.SITE_ROOM_NUMBER_CHANGE_SAVE_FAILURE:
    case siteConstants.SITE_STB_SETSTATECODES:
    case siteConstants.SITE_SCRIPT_EXECUTE_REQUEST:
    case siteConstants.SITE_SCRIPT_EXECUTE_SUCCESS:
    case siteConstants.SITE_SCRIPT_EXECUTE_FAILURE:
    case siteUserConstants.SITE_USER_GET_REQUEST:
    case siteUserConstants.SITE_USER_GET_SUCCESS:
    case siteUserConstants.SITE_USER_GET_FAILURE:
    case siteUserConstants.SITE_USER_PAGE_CHANGE:
    case siteUserConstants.SITE_USER_DELETE_REQUEST:
    case siteUserConstants.SITE_USER_DELETE_SUCCESS:
    case siteUserConstants.SITE_USER_DELETE_FAILURE:
        return {
            ...state,
            site: action.obj
        };
    default:
        return state;
  }
}
