import { statsConstants } from '../_constants';
import { alertActions } from './';
import { statsService } from '../_services';

export const statsActions = {
    general
  , resv
  , network
  , clear
};

function general(monitor, from, to) {
  return dispatch => {
    const { stats, site } = monitor;
    const newstats = {
      ...stats,
      processing: true
    };
    dispatch(request(newstats));

    statsService.general(site.edit.site_id, from, to)
      .then(
        result => {
          const nextstats = {
            ...stats,
            data: result
          };
          dispatch(success(nextstats));
        },
        error => {
          dispatch(failure(stats));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(obj) { return { type: statsConstants.STATS_GENERAL_REQUEST, obj }; }
  function success(obj) { return { type: statsConstants.STATS_GENERAL_SUCCESS, obj }; }
  function failure(obj) { return { type: statsConstants.STATS_GENERAL_FAILURE, obj }; }
}

function resv(monitor, on) {
  return dispatch => {
    const { stats, site } = monitor;
    const reqstats = {
      ...stats,
      processing: true
    };
    dispatch(request(reqstats));

    statsService.resv(site.edit.site_id, on)
      .then(
        result => {
          const nextstats = {
            ...stats,
            resv: result
          };
          dispatch(success(nextstats));
        },
        error => {
          dispatch(failure(stats));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: statsConstants.STATS_RESV_REQUEST, obj }; }
  function success(obj) { return { type: statsConstants.STATS_RESV_SUCCESS, obj }; }
  function failure(obj) { return { type: statsConstants.STATS_RESV_FAILURE, obj }; }
}

function network(monitor, interval) {
  return dispatch => {
    const { stats, site } = monitor;
    const newstats = {
      ...stats,
      processing: true
    };
    dispatch(request(newstats));

    let nextstats = {};
    let network = {day: null, week: null, month: null};
    statsService.network(site.edit.site_id, 'day')
      .then(
        result => {
//          let newnetwork = {
//            ...network,
//            day: result
//          };
          network.day = result;
          nextstats = {
            ...stats,
            network: network
          };
          dispatch(success(nextstats));
        },
        error => {
          dispatch(failure(nextstats));
          dispatch(alertActions.error(error));
        }
      );

    statsService.network(site.edit.site_id, 'week')
      .then(
        result => {
//          let newnetwork = {
//            ...network,
//            week: result
//          };
          network.week = result;
          nextstats = {
            ...stats,
            network: network
          };
          dispatch(success(nextstats));
        },
        error => {
          dispatch(failure(nextstats));
          dispatch(alertActions.error(error));
        }
      );

    statsService.network(site.edit.site_id, 'month')
      .then(
        result => {
          //let newnetwork = {
          //  ...network,
          //  month: result
          //};
          network.month = result;
          nextstats = {
            ...stats,
            network: network
          };
          dispatch(success(nextstats));
        },
        error => {
          dispatch(failure(nextstats));
          dispatch(alertActions.error(error));
        }
      );


//    statsService.network(site.edit.site_id, interval)
//      .then(
//        result => {
//          switch (intrvl) {
//            case 'day':
//              dispatch(success(result));
//              break;
//            case 'week':
//              dispatch(successWeek(result));
//              break;
//            case 'month':
//              dispatch(successMonth(result));
//              break;
//            default:
//              dispatch(success(result));
//              break;
//          }
//        },
//        error => {
//          dispatch(failure());
//          dispatch(alertActions.error(error));
//        }
//      );
  };

  function request(obj) { return { type: statsConstants.STATS_NETWORK_REQUEST, obj }; }
  function success(obj) { return { type: statsConstants.STATS_NETWORK_SUCCESS, obj }; }
//  function successWeek(img) { return { type: statsConstants.STATS_NETWORKWEEK_SUCCESS, img }; }
//  function successMonth(img) { return { type: statsConstants.STATS_NETWORKMONTH_SUCCESS, img }; }
  function failure(obj) { return { type: statsConstants.STATS_NETWORK_FAILURE, obj }; }
}

function clear() {
  return dispatch => {
    dispatch(request({}));
  };
  function request(obj) { return { type: statsConstants.STATS_CLEAR, obj }; }
}
