import { getApiUrl } from '../_helpers';

export const adminService = {
    search
  , list
  , add
  , get
  , getextend
  , update
  , delete: _delete
  , command
};

function search(category, criteria, limit = 0) {
  var url = getApiUrl() + 'admin/' + category + '/search';
  if (limit > 0) url += '/' + limit;

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(criteria),
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
    .then(response => {
      if (response.status === 200) {
        resolve(response.json().then(data => data));
      }
      else {
        reject(response.message);
      }
    })
    .catch(response => { reject(response.message) });
  });
}

function list(category, limit = 0) {
  var url = getApiUrl() + 'admin/' + category + '/list';
  if (limit > 0) url += '/' + limit;

  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response.json().then(data => data));
        }
        else {
          reject(response.message);
        }
      })
      .catch(response => {
        reject(response.message);
      });
  });
}

function add(category, data) {
  var url = getApiUrl() + 'admin/' + category + '/add';

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
    .then(response => {
      if (response.status === 200) {
        resolve(response.json().then(data => data.ids));
      }
      else {
        reject(response.message);
      }
    })
    .catch(response => {
      reject(response.message)
    });
  });
}

function get(category, id) {
  var url = getApiUrl() + 'admin/' + category + '/' + id.toString();

  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response.json().then(data => data));
        }
        else {
          reject(response.message);
        }
      })
      .catch(response => {
        reject(response.message);
      });
  });
}

function getextend(category, id, extension) {
  let url = getApiUrl() + 'admin/' + category + '/' + id.toString() + '/' + extension;

  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response.json().then(data => data));
        }
        else {
          reject(response.message);
        }
      })
      .catch(response => {
        reject(response.message);
      });
  });
}

function update(category, params) {
  var url = getApiUrl() + 'admin/' + category + '/update';
  var data = JSON.stringify(params);

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
    .then(response => {
      response.json().then(data => {
        if (response.status === 200) {
          resolve(data);
        }
        else {
          reject(data);
        }
      });
    })
    .catch(response => {
      reject(response.message);
    });
  });
}

function _delete(category, id) {
  var url = getApiUrl() + 'admin/' + category + '/' + id;

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'DELETE',
      credentials: 'include'
    })
    .then(response => {
      if (response.status === 200) {
        response.json().then(data => {
          if (data.count == 1 && data.status === 'success') {
            resolve(data.count);
          }
          else {
            reject('An error occurred during the delete.');
          }
        });
      }
      else {
        reject('Delete Failed');
      }
    })
    .catch(response => {
      reject('Delete Failed');
    });
  });
}

function command(category, command, data) {
  var url = getApiUrl() + 'admin/' + category + '/' + command;

  // The data must be formatted before calling the command procedure.
  // Formatted, but not stringified.
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
      .then(response => {
        response.json().then(data => {
          if (response.status === 200) {
            resolve(data);
          }
          else {
            reject(data);
          }
        });
      })
      .catch(response => {
        reject(response.message);
      });
  });
}
