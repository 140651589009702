import { streamingConstants } from '../_constants';

export function chan(state, action) {
  switch(action.type) {
    case streamingConstants.CHAN_CLEAR:
      return {
        ...state,
        chan: {}
      }
    case streamingConstants.CHAN_LIST_REQUEST:
    case streamingConstants.CHAN_LIST_SUCCESS:
    case streamingConstants.CHAN_LIST_FAILURE:
    case streamingConstants.CHAN_EDIT_REQUEST:
    case streamingConstants.CHAN_EDIT_SUCCESS:
    case streamingConstants.CHAN_EDIT_FAILURE:
    case streamingConstants.CHAN_ADD_REQUEST:
    case streamingConstants.CHAN_EDIT_CLEAR_REQUEST:
    case streamingConstants.CHAN_DEACTIVATE_REQUEST:
    case streamingConstants.CHAN_DEACTIVATE_CANCEL:
      return {
        ...state,
        chan: action.obj
      };
    default:
      return state;
  }
}
