export const appParameterConstants = {
      APPPARAM_CLEAR: 'APPPARAM_CLEAR'
    , APPPARAM_GET_REQUEST: 'APPPARAM_GET_REQUEST'
    , APPPARAM_GET_SUCCESS: 'APPPARAM_GET_SUCCESS'
    , APPPARAM_GET_FAILURE: 'APPPARAM_GET_FAILURE'
    , APPPARAM_ADD_REQUEST: 'APPPARAM_ADD_REQUEST'
    , APPPARAM_ADD_CANCEL: 'APPPARAM_ADD_CANCEL'
    , APPPARAM_ADD_SELECT: 'APPPARAM_ADD_SELECT'
    , APPPARAM_EDIT_REQUEST: 'APPPARAM_EDIT_REQUEST'
    , APPPARAM_EDIT_CANCEL: 'APPPARAM_EDIT_CANCEL'
    , APPPARAM_CHANGE_SUCCESS: 'APPPARAM_CHANGE_SUCCESS'
    , APPPARAM_STB_GET_REQUEST: 'APPPARAM_STB_GET_REQUEST'
    , APPPARAM_STB_GET_SUCCESS: 'APPPARAM_STB_GET_SUCCESS'
    , APPPARAM_STB_GET_FAILURE: 'APPPARAM_STB_GET_FAILURE'
    , APPPARAM_DEACTIVATE_REQUEST: 'APPPARAM_DEACTIVATE_REQUEST'
    , APPPARAM_DEACTIVATE_CANCEL: 'APPPARAM_DEACTIVATE_CANCEL'
    , APPPARAM_DEACTIVATE_PROCEED_REQUEST: 'APPPARAM_DEACTIVATE_PROCEED_REQUEST'
    , APPPARAM_DEACTIVATE_PROCEED_SUCCESS: 'APPPARAM_DEACTIVATE_PROCEED_SUCCESS'
    , APPPARAM_DEACTIVATE_PROCEED_FAILURE: 'APPPARAM_DEACTIVATE_PROCEED_FAILURE'
    , APPPARAM_RESET_SUCCESS: 'APPPARAM_RESET_SUCCESS'
    , APPPARAM_SAVE_REQUEST: 'APPPARAM_SAVE_REQUEST'
    , APPPARAM_SAVE_SUCCESS: 'APPPARAM_SAVE_SUCCESS'
    , APPPARAM_SAVE_FAILURE: 'APPPARAM_SAVE_FAILURE'

    , APPPARAM_STB_CLEAR: 'APPPARAM_STB_CLEAR'
};