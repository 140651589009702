import { combineReducers } from 'redux';

import { alert } from './alert.reducer';
import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { administration } from './admin.reducer';
import { monitor } from './monitor.reducer';
import { nav } from './navigation.reducer';
import { profile } from './profile.reducer';
import { tracking } from './tracking.reducer';

const rootReducer = combineReducers({
    alert
  , authentication
  , registration
  , administration
  , monitor
  , nav
  , profile
  , tracking
});

export default rootReducer;
