export const documentationConstants = {

    DOCUMENTATION_GET_REQUEST: 'DOCUMENTATION_GET_REQUEST'
  , DOCUMENTATION_GET_SUCCESS: 'DOCUMENTATION_GET_SUCCESS'
  , DOCUMENTATION_GET_FAILURE: 'DOCUMENTATION_GET_FAILURE'

  , DOCUMENTATION_SORT_REQUEST: 'DOCUMENTATION_SORT_REQUEST'
  , DOCUMENTATION_SORT_SUCCESS: 'DOCUMENTATION_SORT_SUCCESS'

  , DOCUMENTATION_SEARCH_INPUT: 'DOCUMENTATION_SEARCH_INPUT'
  , DOCUMENTATION_SEARCH_REQUEST: 'DOCUMENTATION_SEARCH_REQUEST'
  , DOCUMENTATION_SEARCH_SUCCESS: 'DOCUMENTATION_SEARCH_SUCCESS'
  , DOCUMENTATION_SEARCH_CLEAR: 'DOCUMENTATION_SEARCH_CLEAR'

  , DOCUMENTATION_PAGE_CHANGE: 'DOCUMENTATION_PAGE_CHANGE'
  
  ,DOCUMENTATION_ADD_REQUEST: 'DOCUMENTATION_ADD_REQUEST'
  ,DOCUMENTATION_ADD_SUCCESS: 'DOCUMENTATION_ADD_SUCCESS'
  ,DOCUMENTATION_ADD_FAILURE: 'DOCUMENTATION_ADD_FAILURE'

  , DOCUMENTATION_CATEGORIES_REQUEST: 'DOCUMENTATION_CATEGORIES_REQUEST'
  , DOCUMENTATION_CATEGORIES_SUCCESS: 'DOCUMENTATION_CATEGORIES_SUCCESS'
  , DOCUMENTATION_CATEGORIES_FAILURE: 'DOCUMENTATION_CATEGORIES_FAILURE'

};
