import { get } from 'jquery';
import React from 'react';
import { config, stbUp, stbOn, checkedIn, jsonToCSV, jsonToExcel } from '../_helpers';
import { Pagination } from './pagination';

const specialAdditions = ['room_number'];

export const DataGrid= props => {
  
      let topClasses = 'row justify-content-end mr-1';
      let pagination = null;
 //  let filetype= null;
 if (props.pagination) {
      pagination = <Pagination data={props.data} pageParams={props.pagination} />;
    }
   
   let  grid= generateGrid(props.data, props.displayParams);

    return (
      <div>
        <div className={topClasses}>
            {pagination}
        </div>
        <div className="rowGrid"> 
         {grid}

        </div>
      </div>
    
      );
};

function getImageSrc(filetype)
{
    switch(filetype){
        case 'pdf':return "/images/iconfinder_pdf.png";
              break;
        case 'xls':
        case 'xlsx':
        case 'csv':
        case 'xlsm': return '/images/iconfinder_Excel.png';
              break;
        case 'doc':
        case 'DOCX':
        case 'docx': return '/images/iconfinder_Word.png';
             break;
        case 'jpg':
        case 'png':
        case 'svg':
        case 'gif': return '/images/iconfinder_Image.png';
              break;
        default: return '';
              break;

    }
}


function generateGrid(data, displayParams)
{
      let rows = [];
  if (displayParams.pagination) {
    if (!displayParams.pagination.rowCount && !displayParams.pagination.dataPage) {
      rows = mapData(data, displayParams);
    }
    else {
      let startRow = displayParams.pagination.rowCount === 0 ? 0 : displayParams.pagination.rowCount * (displayParams.pagination.dataPage - 1);
      let endRow = startRow + displayParams.pagination.rowCount - 1;
      if (endRow >= data.length) endRow = data.length - 1;
      let pageData = [];
      for (let i = startRow, j = endRow; i <= j; i++) {
        pageData.push(data[i]);
      }
      rows = mapData(pageData, displayParams);
    }
  }
  else {
    rows = mapData(data, displayParams);
  }
  return rows;
}


function mapData(data, displayParams) {
      var grid = data.map(function(value, idx) {
        if (displayParams.pagination && displayParams.pagination.dataPage > 1) {
          idx += (displayParams.pagination.dataPage - 1) * displayParams.pagination.rowCount;
        }

        let filetype=value['File Name'].split('.').pop();
        let str=value['Path'];
        let folder='documentation/';
        var path= str.substring(str.indexOf(folder)+folder.length);
              return (

                    <div className="columnGrid" key={idx}>
                        <img src={getImageSrc(filetype)} />
                        <p><a target="_blank" download={value['File Name']} href={'http://wciwvdev:85/documentation/'  +path}>{value['File Name']}</a></p>
                    </div>          
                    );
      });
      return grid;
    }