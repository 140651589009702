import React from 'react';
import { connect } from 'react-redux';
import { permissionCheck, navConfig, history } from '../_helpers';
import { navActions } from '../_actions';
import { Icon } from './icon';

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.linkClickHandler = this.linkClickHandler.bind(this);
  }

  render() {
    const { auth } = this.props;
    let location = history.location.pathname;
    let homeActive = location === '/' ? 'active' : '';
    let links = null;
    if (auth) {
      links = <NavLinks permissions={auth ? auth.permissions : null} handler={this.linkClickHandler} active={location} />;
    }
    let hdr = null;
    if (this.props.header) {
      hdr = <NavHeader headerText={this.props.header} />
    }
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light justify-content-between">
          <div className={`nav-item nav-link ${homeActive}`} data-path="/" onClick={this.linkClickHandler}><Icon /></div>
          <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarlinks" aria-controls="navbarlinks" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div id="navbarlinks" className="collapse navbar-collapse">
            {links}
          </div>
          <span className="nav-item nav-link" onClick={this.linkClickHandler} data-path="/" data-toggle="tooltip" title="Home"><i className="fas fa-home"></i></span>
          <span className="nav-item nav-link" onClick={this.linkClickHandler} data-path="/contact" data-toggle="tooltip" title="Contact"><i className="fas fa-phone-square"></i></span>
          <span className="nav-item nav-link" onClick={this.linkClickHandler} data-path="/user/profile" data-toggle="tooltip" title="Profile"><i className="fas fa-user"></i></span>
          <span className="nav-item nav-link mr-sm-2" onClick={this.linkClickHandler} data-path="/login" data-toggle="tooltip" title="Sign Out"><i className="fas fa-sign-out-alt"></i></span>
        </nav>
        {hdr}
      </div>
    );
  }

  linkClickHandler(e) {
    let targetPath = e.currentTarget.attributes['data-path'].value;
    if (targetPath === '#') return;
    this.props.navigate(targetPath);
  }
};

function mapStateToProps(state) {
  const auth = state.authentication.user;
  return { auth };
}

const mapDispatchToProps = (dispatch) => ({
  navigate(path) { dispatch(navActions.navigate(path)); }
});

const connectedNavigationBar = connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
export { connectedNavigationBar as NavigationBar };


const NavLinks = props => {
  const { active, permissions } = props;
  let groupLinks = [];
  let profileActive = active === '/profile' ? 'active' : '';
  if (permissions) {
    let linkgroup = navConfig.accessLinks;
    for (let i = 0, j = linkgroup.length; i < j; i++) {
      let glink = linkgroup[i];
      if (glink.name === 'common') {
        let thisId = 'ddMenu' + glink.title;
        groupLinks.push(<CommonGroup key={glink.name} linkGroup={glink.name} id={thisId} title={glink.title} handler={props.handler} active={active} />);
      }
      if (permissionCheck(permissions, glink.name)) {
        let thisId = 'ddMenu' + glink.title;
        groupLinks.push(<LinkGroup key={glink.name} permissions={permissions} linkGroup={glink.name} id={thisId} title={glink.title} handler={props.handler} active={active} />);
      }
    }
  }

  return (
    <div className="navbar-nav">
      {groupLinks}
    </div>
  );
};

const LinkGroup = props => {
  let plinks = navConfig.pageLinks[props.linkGroup];
  let links = null;
  let ddactive = '';
  if (props.active.indexOf(props.linkGroup) >= 0) {
    ddactive = 'active';
  }
  links = plinks.map(function(link, idx) {
    let permission = props.linkGroup + '.' + link.name;
    if (permissionCheck(props.permissions, permission)) {
      let active = link.path === props.active ? 'active' : '';
      return <div key={'lg' + props.linkGroup + idx} className={`dropdown-item nav-link ${active}`} data-path={link.path} onClick={props.handler}>{link.title}</div>;
    }
  });
  return (
    <div className="btn-group">
      <a className={`nav-item dropdown nav-link dropdown-toggle ${ddactive}`} href="#" id={props.id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{props.title}</a>
      <div className="dropdown-menu" aria-labelledby={props.id}>{links}</div>
    </div>
  );
};


const CommonGroup = props => {
  let plinks = navConfig.pageLinks[props.linkGroup];
  let links = null;
  let ddactive = '';
  if (props.active.indexOf('admin') < 0 && props.active.indexOf('portal') < 0) {
    ddactive = 'active';
  }
  links = plinks.map(function(link, idx) {
    let active = link.path === props.active ? 'active' : '';
    return <div key={'lg' + props.linkGroup + idx} className={`dropdown-item nav-link ${active}`} data-path={link.path} onClick={props.handler}>{link.title}</div>;
  });
  return (
    <div className="btn-group d-none d-lg-inline">
      <a className={`nav-item dropdown nav-link dropdown-toggle ${ddactive}`} href="#" id={props.id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{props.title}</a>
      <div className="dropdown-menu" aria-labelledby={props.id}>{links}</div>
    </div>
  );
};

const NavHeader = props => <div className="navHeader">{props.headerText}</div>;
