import React from "react";

import { WciModal } from '../../../wcimodal';

export const IterationView = props => {
    const inputs = props.inputs;
    let btnDailyClass = 'btn-light';
    let btnWeeklyClass = 'btn-light';
    let btnMonthlyClass = 'btn-light';

    const iteration = inputs && inputs.iteration ? inputs.iteration.toLowerCase() : '';

    switch (iteration) {
        case 'daily':
            btnDailyClass = 'btn-primary';
            break;
        case 'weekly':
            btnWeeklyClass = 'btn-primary';
            break;
        case 'monthly':
            btnMonthlyClass = 'btn-primary';
            break;
        default:
            break;
    }
    return (
        <WciModal show={true} className="baseModal">
            <div className="eventsIterationModal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Repeating Iteration</h5>                    
                        <button type="button"
                            className="close"
                            onClick={props.onclose}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <p>Select How Often This Event Repeats</p>
                        <div className="btn-group-vertical mt-4" role="group" aria-label="Repeat">
                            <button type="button" className={"btn " + btnDailyClass} name="daily" onClick={props.onselect}>Daily</button>
                            <button type="button" className={"btn mt-1 " + btnWeeklyClass} name="weekly" onClick={props.onselect}>Weekly</button>
                            <button type="button" className={"btn mt-1 " + btnMonthlyClass} name="monthly" onClick={props.onselect}>Monthly</button>
                        </div>
                    </div>
                </div>
            </div>
        </WciModal>
    );
};