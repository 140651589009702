import { adminPermissionConstants } from '../_constants';

export function permission(state = {}, action) {
  switch(action.type) {
    case adminPermissionConstants.PERMISSION_INPUT:
    case adminPermissionConstants.PERMISSION_SEARCH_REQUEST:
    case adminPermissionConstants.PERMISSION_SEARCH_SUCCESS:
    case adminPermissionConstants.PERMISSION_SEARCH_FAILURE:
    case adminPermissionConstants.PERMISSION_PAGE_CHANGE:
    case adminPermissionConstants.PERMISSION_PAGE_ROWS:
    case adminPermissionConstants.PERMISSION_ADD_REQUEST:
    case adminPermissionConstants.PERMISSION_ADD_VALIDITY:
    case adminPermissionConstants.PERMISSION_ADD_SUCCESS:
    case adminPermissionConstants.PERMISSION_ADD_FAILURE:
    case adminPermissionConstants.PERMISSION_GET_REQUEST:
    case adminPermissionConstants.PERMISSION_GET_SUCCESS:
    case adminPermissionConstants.PERMISSION_GET_FAILURE:
    case adminPermissionConstants.PERMISSION_UPDATE_REQUEST:
    case adminPermissionConstants.PERMISSION_UPDATE_VALIDITY:
    case adminPermissionConstants.PERMISSION_UPDATE_SUCCESS:
    case adminPermissionConstants.PERMISSION_UPDATE_FAILURE:
    case adminPermissionConstants.PERMISSION_DELETE_REQUEST:
    case adminPermissionConstants.PERMISSION_DELETE_SUCCESS:
    case adminPermissionConstants.PERMISSION_DELETE_FAILURE:
    case adminPermissionConstants.PERMISSION_SORT_REQUEST:
    case adminPermissionConstants.PERMISSION_SORT_SUCCESS:
      return {
        ...state,
        permission: action.obj
      };
    default:
      return state;
  }
}
