import React from 'react';
import { connect } from 'react-redux';

import { alertActions, sitesActions, siteActions, msgActions } from '../../../_actions';
import { permissionCheck, numericsorter } from '../../../_helpers';

import { NavigationBar } from '../../navigation';
import { ProcessIndicator } from '../../processIndicator';

import { SiteData } from '../siteData';
import { SiteTabs } from '../siteTabs';

import { Rooms } from './rooms';
import { MessageGroups } from './groups';
import { AddControls } from './addControls';
import { SelectedRooms } from './selected';
import { Priority } from './priority';
import { Missive } from './missive';
import { GroupManager } from './groupManager';
import { Response } from './response';
import { Expire } from './expire';
import { MsgType } from './type';
import { MsgReview } from './review';
import { MsgReviewDetail } from './reviewDetail';

require('../../../styles/site');

class Messaging extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        showSiteList: false
      , hasChange: false
    };
    this.onSiteDisplay = this.onSiteDisplay.bind(this);
    this.onSiteDisplayClose = this.onSiteDisplayClose.bind(this);
    this.onSiteSelect = this.onSiteSelect.bind(this);
  }

  componentDidMount() {
    const { auth, monitor, getSiteList, genRoomList } = this.props;
    if (!monitor.sites.list) {
      getSiteList(monitor);
    }
    else {
      if (monitor.site.stbs) {
        genRoomList(monitor);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { monitor, genRoomList } = this.props;
    if (monitor.site.edit && !monitor.msg.list && !monitor.msg.processing) {
      genRoomList(monitor);
    }
  }

  render() {
    const { alert, auth, monitor } = this.props;
    if (!auth) return null;
    const { sites, site, msg } = monitor;
    let showSiteIp = permissionCheck(auth.permissions, 'portal.sites.link'); // Permission to determine if site link displayed
    let grpmgr = null;
    let reviewBtn = null;
    let review = null;
    let priority = null;
    let msgtype = null;
    let response = null;
    let expire = null;
    let missive = null;

    let headerText = 'Monitor - Site - Message';
    let processIndShow = false;
    if (sites.processing) processIndShow = true;
    if (site.processing) processIndShow = true;
    if (msg.processing) processIndShow = true;

    if (alert.message) {
      window.setTimeout(this.props.clearAlert, 7000);
    }

    if (msg) {
      if (msg.group && msg.group.manage) {
        grpmgr = <GroupManager />;
      }
      if (msg.review) {
        if (msg.review.show === 'list') {
          review = <MsgReview />;
        }
        else if (msg.review.show === 'detail') {
          review = <MsgReviewDetail />;
        }
      }
    }

    if (site.edit) {
      headerText = 'Monitor - Site: ' + site.edit.site_id + ' - Message';
      reviewBtn = <p><button type="button" className="btn btn-secondary" onClick={() => { this.props.onReviewClick(monitor); }} disabled={processIndShow}>Review Active Messages</button></p>;
      priority = <Priority changeHandler={(e) => { this.props.onPriorityChange(monitor, e); }} priority={msg.priority ? msg.priority : null} disable={processIndShow} />;
      msgtype = <MsgType changeHandler={(e) => { this.props.onMsgTypeChange(monitor, e); }} msgtype={msg.type ? msg.type : null} disable={processIndShow} />;
      response = <Response changeHandler={() => { this.props.onRequireResponse(monitor); }} response={msg.response} inputHandler={(e) => { this.props.onRequiredInput(monitor, e); }} disable={processIndShow} />;
      expire = <Expire changeHandler={(e) => { this.props.onExpireChange(monitor, e); }} inputHandler={(e) => { this.props.onExpireInput(monitor, e); }} expire={msg.expire} disable={processIndShow} />
      missive = <Missive changeHandler={(e) => { this.props.onMessageChange(monitor, e); }} sendHandler={() => { this.props.onMessageSend(monitor, auth); }} missive={msg.missive} cssClass="missiveContainer" disable={processIndShow} />;
    }

    return(
      <div id="message">
        <NavigationBar header={headerText} />
        <div className="container-fluid">
          <SiteTabs active="message" />
          <SiteData site={site.edit} more={false} showSiteIp={showSiteIp} siteList={sites.list} showList={this.state.showSiteList} clickHandler={this.onSiteSelect} displayHandler={this.onSiteDisplay} closeHandler={this.onSiteDisplayClose} />
          <ProcessIndicator show={processIndShow} />
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
          <div className="d-inline-block">
            <Rooms cssClass="roomlistContainer" disable={processIndShow} />
          </div>
          <div className="d-inline-block">
            <MessageGroups cssClass="groupListContainer" disable={processIndShow} />
            <AddControls cssClass="addcontrolsContainer" disable={processIndShow} />
            {reviewBtn}
          </div>
          <div className="d-inline-block">
            <SelectedRooms cssClass="roomlistContainer-sm" disable={processIndShow} />
          </div>
          <div className="d-inline-block">
            {priority}
            {msgtype}
            {response}
            {expire}
            {missive}
          </div>
        </div>
        {grpmgr}
        {review}
      </div>
    );
  }

  onSiteSelect(e) {
    this.setState({ showSiteList: false });
    const { monitor, getSite } = this.props;
    let siteIdx = e.currentTarget.attributes['data-idx'].value;
    let selectedsite = monitor.sites.list[siteIdx];
    getSite(monitor, selectedsite);
  }
  onSiteDisplay() {
    this.props.clearAlert();
    this.setState({ showSiteList: true });
  }
  onSiteDisplayClose() {
    this.setState({ showSiteList: false });
  }
}

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const monitor = state.monitor
  return { alert, auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    getSiteList(monitor) { dispatch(sitesActions.list(monitor)); }
  , genRoomList(monitor) { dispatch(msgActions.genroomlist(monitor)); }
  , clearAlert() { dispatch(alertActions.clear()); }
  , getSite(monitor, site) { dispatch(siteActions.get(monitor, site, false)); }
  , onPriorityChange(monitor, e) { dispatch(msgActions.priority(monitor, e)); }
  , onMsgTypeChange(monitor, e) { dispatch(msgActions.msgtype(monitor, e)); }
  , onRequireResponse(monitor) { dispatch(msgActions.response(monitor)); }
  , onRequiredInput(monitor, e) { dispatch(msgActions.responseInput(monitor, e)); }
  , onExpireChange(monitor, e) { dispatch(msgActions.expire(monitor, e)); }
  , onExpireInput(monitor, e) { dispatch(msgActions.expireInput(monitor, e)); }
  , onMessageChange(monitor, e) { dispatch(msgActions.message(monitor, e)); }
  , onMessageSend(monitor, auth) { dispatch(msgActions.send(monitor, auth)); }
  , onReviewClick(monitor) { dispatch(msgActions.review(monitor)); }
});


const connectedMessaging = connect(mapStateToProps, mapDispatchToProps)(Messaging);
export { connectedMessaging as Messaging };
