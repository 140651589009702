import React from 'react';

import Charts from '../../charts';

import { createChartArray, createChartArrayFromArray } from '../../../_helpers';

export const UsageChart = props => {
  const { data, sort, limit, limitStart, limitEnd, xval, yval, title } = props;
  let usage = data;
  let entries = null;
  let limited = null;
  let pieData = null;
  if (sort) {
    entries = Object.entries(data);
    entries.sort(function(a, b){
      return b[1] - a[1];
    });
    usage = entries;
  }
  if (limit) {
    let limited = entries ? entries : Object.entries(data);
    usage = limited.slice(limitStart, limitEnd);
  }

  if (sort || limit) {
    pieData = createChartArrayFromArray(usage, xval, yval);
  }
  else {
    pieData = createChartArray(usage, xval, yval);
  }
  let ch = {
      type: 'pie'
    , data: pieData
    , options:{
          "title": title
        , "pieHole": 0.3
        , "is3D": false
        , "pieSliceText": "value"
        , height:'300px'
        , width:'400px'
      }
  };
  return (
    <div className="statchart">
      <Charts data={ch} />
    </div>
  );
};
