import React from 'react';
import { connect } from 'react-redux';

import { paramActions } from '../../../../_actions';

const UrgentMsgParam = props => {
    const { auth, monitor } = props;
    const { appparam } = monitor;
    const { edit } = appparam;
    
    if (!edit) return null;

    let options = [];
    for (let i = 0, j = 3; i < j; i++) {
        switch(i) {
            case 0:
                options.push(<option value='none'>Select a value</option>);
                break;
            case 1:
                if (edit.app_param_value === 'true') {
                    options.push(<option value='true' selected>True</option>);
                }
                else {
                    options.push(<option value='true'>True</option>);
                }
                break;
            case 2:
                if (edit.app_param_value === 'false') {
                    options.push(<option value='false' selected>False</option>);
                }
                else {
                    options.push(<option value='false'>False</option>);
                }
                break;
        }
    }

    const checked = parseInt(edit.active) === 1 ? true : false;

    const submitHandler = evt => { props.onSubmit(auth, monitor, evt); };
    const changeHandler = evt => { props.onChange(monitor, evt); };
    const cancelHandler = evt => { props.onCancel(monitor, evt); };
    const resetHandler = evt => { props.onReset(monitor, evt); };

    const disable = appparam.processing ? appparam.processing : false;

    return(
        <div className='container-fluid'>
            <div className='text-center'>Editing Turn TV on for Urgent Message</div>
            <div className='text-center mt-4'>{edit.app_param_id}</div>
            <div className='jumbotron'>
                <form onSubmit={submitHandler}>
                    <div className="form-group form-check">
                            <input type="checkbox"
                                id="active"
                                name="active"
                                className="form-check-input"
                                checked={checked}
                                onChange={changeHandler} />
                            <label htmlFor="active" className="form-check-label">Active</label>
                    </div>
                    <div className='form-group row'>
                        <label for="app_param_value" className='col-sm-1 text-right'>Options:</label>
                        <select className='form-control col-sm-5' 
                            id='app_param_value'
                            name='app_param_value'
                            selectedIndex={!edit.app_param_value ? 0 : edit.app_param_value === "true" ? 1 : 2}
                            onChange={changeHandler}
                        >{options}</select>
                    </div>
                    <div className="form-group">
                            <button type="submit" className="btn btn-primary" disabled={disable}>
                            <span>Submit</span>
                            {appparam.updating &&
                                <div className="d-inline fa-2x left-margin-10"><i className="fas fa-spinner fa-pulse"></i></div>
                            }
                        </button>
                        <button type="button" className="btn btn-secondary left-margin-10" disabled={disable} onClick={resetHandler}>Reset Form</button>
                        <button type="button" className="btn btn-secondary left-margin-10" disabled={disable} onClick={cancelHandler}>Cancel Edit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    const monitor = state.monitor;
    const auth = state.authentication.user;
    return { alert, auth, monitor };
}

const mapDispatchToProps = dispatch => ({
      onSubmit(auth, monitor, evt) { evt.preventDefault(); dispatch(paramActions.setAppParam(auth, monitor)); }
    , onChange(monitor, evt) { dispatch(paramActions.editParam(monitor, evt)); }
    , onCancel(monitor, evt) { dispatch(paramActions.cancelEdit(monitor)); }
    , onReset(monitor, evt) { dispatch(paramActions.resetEdit(monitor)); }
});

const connectedUrgentMsgParam = connect(mapStateToProps, mapDispatchToProps)(UrgentMsgParam);
export { connectedUrgentMsgParam as UrgentMsgParam };
