import React from 'react';

export class WciModal extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.show != prevProps.show) {
            this.setState({ show: this.props.show });
        }
    }

    render() {
        if (!this.props.show) return null;

        return (
            <div className={this.props.className}>
                {this.props.children}
            </div>
        );
    }
};
