import React from 'react';

export const UserDataEdit = props => {
  const { user, validity, disable } = props;
  return (
    <div className="jumbotron">
      <div className="container">
        <form onSubmit={props.submitHandler}>
          <div className="form-group row">
            <label htmlFor="user_name" className="col-sm-2 col-form-label text-right">User Name:</label>
            <input type="text"
              id="user_name"
              name="user_name"
              className={'col-sm-2 form-control' + (validity ? validity.user_name ? '' : ' is-invalid' : '')}
              placeholder="User Name"
              onChange={props.changeHandler}
              value={user.user_name} />
            {validity && !validity.valid && !user.user_name &&
              <div className='invalid-feedback'>User Name is required</div>
            }
            {validity && !validity.valid && user.user_name.length < 4 &&
              <div className='invalid-feedback'>User Name needs to be longer than 3 characters</div>
            }
            <label htmlFor="first_name" className="col-sm-2 col-form-label text-right">First Name:</label>
            <input type="text"
              id="first_name"
              name="first_name"
              className={'col-sm-2 form-control' + (validity ? validity.first_name ? '' : ' is-invalid' : '')}
              placeholder="First Name"
              onChange={props.changeHandler}
              value={user.first_name} />
            {validity && !validity.valid && !user.first_name &&
              <div className='invalid-feedback'>First Name is required</div>
            }
            <label htmlFor="last_name" className="col-sm-2 col-form-label text-right">Last Name:</label>
            <input type="text"
              id="last_name"
              name="last_name"
              className={'col-sm-2 form-control' + (validity ? validity.last_name ? '' : ' is-invalid' : '')}
              placeholder="Last Name"
              onChange={props.changeHandler}
              value={user.last_name} />
            {validity && !validity.valid && !user.last_name &&
              <div className='invalid-feedback'>Last Name is required</div>
            }
          </div>
          <div className="form-group row">
            <label htmlFor="phone" className="col-sm-2 col-form-label text-right">Phone:</label>
            <input type="text"
              id="phone"
              name="phone"
              className={'col-sm-2 form-control' + (validity ? validity.phone ? '' : ' is-invalid' : '')}
              placeholder="Phone"
              onChange={props.changeHandler}
              value={user.phone} />
            {validity && !validity.valid && !user.phone &&
              <div className='invalid-feedback'>Phone number is required</div>
            }
            {validity && !validity.valid && !validity.phone &&
              <div className='invalid-feedback'>Phone number needs to have at least 10 numeric characters</div>
            }
            <label htmlFor="email" className="col-sm-2 col-form-label text-right">Email:</label>
            <input type="text"
              id="email"
              name="email"
              className={'col-sm-6 form-control' + (validity ? validity.email ? '' : ' is-invalid' : '')}
              placeholder="Email"
              onChange={props.changeHandler}
              value={user.email} />
            {validity && !validity.valid && !user.email &&
              <div className='invalid-feedback'>Email is required</div>
            }
          </div>
          {props.genPWHandler &&
            <div className="form-group row">
              <label htmlFor="password" className="col-sm-2 col-form-label text-right">Password:</label>
              <input type="text"
                id="password"
                name="password"
                className={'col-sm-2 form-control' + (validity ? validity.password ? '' : ' is-invalid' : '')}
                placeholder="Not Generated"
                readOnly
                value={user.password} />
              <button type="button" onClick={props.genPWHandler} className="btn btn-secondary" disabled={disable}>Generate Temp. Password</button>
              {validity && !validity.valid && !user.password &&
                <div className='invalid-feedback'>Password is required</div>
              }
              {validity && !validity.valid && user.password && user.password.length < 8 &&
                <div className='invalid-feedback'>Password length needs to be greater than 8 characters</div>
              }
            </div>
          }
          {props.allowPWChange &&
            <div className="form-group row">
                <label htmlFor="password" className="col-sm-2 col-form-label text-right">Password:</label>
                <input type="password"
                  id="password"
                  name="password"
                  className={'col-sm-2 form-control' + (validity ? validity.password ? '' : ' is-invalid' : '')}
                  placeholder="New Password"
                  onChange={props.changeHandler}
                  value={user.password} defaultValue="" />
                {validity && !validity.valid && !user.password &&
                  <div className='invalid-feedback'>Password is required</div>
                }
                {validity && !validity.valid && user.password && user.password.length < 8 &&
                  <div className='invalid-feedback'>Password length needs to be greater than 8 characters</div>
                }
                <label htmlFor="password" className="col-sm-2 col-form-label text-right">Verify Password:</label>
                <input type="password"
                  id="verifypassword"
                  name="verifypassword"
                  className={'col-sm-2 form-control' + (validity ? validity.password ? '' : ' is-invalid' : '')}
                  placeholder="Verify Password"
                  onChange={props.changeHandler}
                  value={user.verifypassword} defaultValue="" />
                {validity && !validity.valid && !user.verifypassword &&
                  <div className='invalid-feedback'>Verify Password is required</div>
                }
                {validity && !validity.valid && user.verifypassword && user.verifypassword.length < 8 &&
                  <div className='invalid-feedback'>Password length needs to be greater than 8 characters</div>
                }
                {validity && !validity.valid && !user.passwordmatch &&
                  <div className='invalid-feedback'>Password and Verify Password do not match</div>
                }
              <button type="button" className="btn btn-secondary left-margin-10" disabled={disable} onClick={props.pwChangeCancelHandler}>Cancel</button>
            </div>
          }
          <div className="form-group">
            <button type="submit" className="btn btn-primary" disabled={disable}>
              <span>Submit</span>
              {user.updating &&
                <div className="d-inline fa-2x left-margin-10"><i className="fas fa-spinner fa-pulse"></i></div>
              }
            </button>
            {props.pwresetHandler &&
              <button type="button" className="btn btn-secondary left-margin-10" disabled={disable} onClick={props.pwresetHandler}>Change Password</button>
            }
            {props.cancelHandler &&
              <button type="button" className="btn btn-secondary left-margin-10" disabled={disable} onClick={props.cancelHandler}>Cancel</button>
            }
          </div>
        </form>
      </div>
    </div>
  );
};
