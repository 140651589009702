import { documentationService } from '../_services';
import { alertActions } from '.';
import { formatForMySqlWTime, textsorter, listFilter, history,formatHHMMSS } from '../_helpers';
import { documentationConstants } from '../_constants/monitor.documentation.constants';

const constants = {
  searchFields: ['File Name','Category']
};

export const documentationActions = {
    add
  , get
  , sort
  , searchInput
  , search
  , searchClear
  , page
  , categories
};

function add(monitor, values, auth) {
  return dispatch => {
    const { documentation, site } = monitor;
    const reqdocumentation= {
      ...documentation,
      processing: true
    };
    dispatch(request(reqdocumentation));

      const data = {
          Category: values[0].category==""?null:values[0].category
        , FileName: values[0].file
        , Description: values[0].desc==""?null:values[0].desc
       
      };
      const ds = JSON.stringify(data);
      documentationService.add(ds)
        .then(
          result => {
            dispatch(alertActions.success('Field successfully saved.'));
            const list = result;
            const { search, ...nosearch } = documentation; // remove any search results to display all
            const nextdocumentation = {
              ...nosearch,
                list: list
            };
            dispatch(success(nextdocumentation));
          },
          error => {
            dispatch(failure(documentation));
            dispatch(alertActions.error(error));
          }
        );
    

  };
  function request(obj) { return { type: documentationConstants.DOCUMENTATION_ADD_REQUEST, obj }; }
  function success(obj) { return { type: documentationConstants.DOCUMENTATION_ADD_SUCCESS, obj }; }
  function failure(obj) { return { type: documentationConstants.DOCUMENTATION_ADD_FAILURE, obj }; }
}

  function get(monitor) {
    return dispatch => {
      const { documentation, site } = monitor;
      const reqdocumentation = {
        ...documentation,
        processing: true
      };
      dispatch(request(reqdocumentation));

      documentationService.list()
        .then(
          items => {
            const list = items.list;
            const categories= items.categories;
            const sort = { field: 'start', direction: 'asc' };
            const nextDocumentation = {
              ...documentation,
                list: list
              , categories:categories
              , sort: sort
            };
            dispatch(success(nextDocumentation));
          },
          error => {
            dispatch(failure(documentation));
            dispatch(alertActions.error(error));
          }
        );
    };
    function request(obj) { return { type: documentationConstants.DOCUMENTATION_GET_REQUEST, obj }; }
    function success(obj) { return { type: documentationConstants.DOCUMENTATION_GET_SUCCESS, obj }; }
    function failure(obj) { return { type: documentationConstants.DOCUMENTATION_GET_FAILURE, obj }; }
  }
  function categories(monitor)
  {
    return dispatch => {
      const { documentation, site } = monitor;
      const reqdocCategories = {
        ...documentation,
        processing: true
      };
      dispatch(request(reqdocCategories));
  
      const gd = {
        site_id: site.edit.site_id
      };
      const gds = JSON.stringify(gd);
      documentationService.categories(gds)
        .then(
          items => {
            const list = items;
            const sort = { field: 'start', direction: 'asc' };
            const nextDocumentation = {
              ...documentation,
                categories: list
              , sort: sort
            };
            dispatch(success(nextDocumentation));
          },
          error => {
            dispatch(failure(documentation));
            dispatch(alertActions.error(error));
          }
        );
    };
    function request(obj) { return { type: documentationConstants.DOCUMENTATION_CATEGORIES_REQUEST, obj }; }
    function success(obj) { return { type: documentationConstants.DOCUMENTATION_CATEGORIES_SUCCESS, obj }; }
    function failure(obj) { return { type: documentationConstants.DOCUMENTATION_CATEGORIES_FAILURE, obj }; }
  }
  function sort(monitor, field) {
    return dispatch => {
      const { documentation } = monitor;
      const reqdocumentation= {
        ...documentation,
        processing: true
      };
      dispatch(request(reqdocumentation));
  
      let newlist = [];
      for (let i = 0, j = documentation.list.length; i < j; i++) {
        newlist.push(documentation.list[i]);
      }
  
      const { sort } = documentation;
      let direction = 'asc';
      if (sort) {
        if (field === sort.field) {
          if (sort.direction === 'asc') { direction = 'desc'; }
        }
      }
      textsorter(newlist, field, direction);
      const nextsort = { field: field, direction: direction };
      const nextDocumentation = {
        ...documentation,
          list: newlist
        , sort: nextsort
      };
  
      if (documentation.search) {
        const { search } = documentation;
        if (search.found && search.found.length > 1) {
          const { found } = search;
          let newfound = [];
          for(let a = 0, b = found.length; a < b; a++) {
            newfound.push(found[a]);
          }
          textsorter(newfound, field, direction);
          const nextsearch = {
            ...search,
            found: newfound
          };
          nextDocumentation.search = nextsearch;
        }
      }
      dispatch(success(nextDocumentation));
    };
    function request(obj) { return { type: documentationConstants.DOCUMENTATION_SORT_REQUEST, obj }; }
    function success(obj) { return { type: documentationConstants.DOCUMENTATION_SORT_SUCCESS, obj }; }
  }

  function searchInput(monitor, value) {
    return dispatch => {
      const { documentation } = monitor;
      const { search } = documentation;
      const  nextsearch = {
        ...search,
        input: value
      };
      const nextDocumentation = {
        ...documentation,
        search: nextsearch
      };
      dispatch(request(nextDocumentation));
    };
    function request(obj) { return { type: documentationConstants.DOCUMENTATION_SEARCH_INPUT, obj }; }
  }

  function search(monitor) {
    return dispatch => {
      const { documentation } = monitor;
      const { search } = documentation;
      if (!search || !search.input) {
        dispatch(alertActions.error('Need some input to search'));
        return;
      }
      const reqdocumentation = {
        ...documentation,
        processing: true
      };
      dispatch(request(reqdocumentation));
  
      let found = [];
      for (let i = 0, j = constants.searchFields.length; i < j; i++) {
        const field = constants.searchFields[i];
        let doit = true;
        if ((field === 'start' || field === 'end') && isNaN(search.input)) {
          doit = false;
        }
        if (doit) {
          let matches = listFilter(documentation.list, [field], [search.input]);
          if (matches.length > 0) {
            if (found.length === 0) { found = matches; }
            else {
              for(let a = 0, b = matches.length; a < b; a++) {
                const mitem = matches[a];
                let exists = false;
                for (let c = 0, d = found.length; c < d; c++) {
                  const fitem = found[c];
                  if(mitem.event_id === fitem.event_id) {
                    exists = true;
                    break;
                  }
                }
                if(!exists) {
                  found.push(mitem);
                }
              }
            }
          }
        }
      }
      dispatch(alertActions.info('Found ' + found.length + ' documentation'));
      if (found.length === 0) {
        const { found, ...notfound } = search;
        const nofounddocumentation = {
          ...documentation,
          search: notfound
        };
        dispatch(success(nofounddocumentation));
      }
      else {
        const nextsearch = {
          ...search,
          found: found
        };
        const founddocumentation= {
          ...documentation,
          search: nextsearch
        };
        dispatch(success(founddocumentation));
      }
    };
    function request(obj) { return { type: documentationConstants.DOCUMENTATION_SEARCH_REQUEST, obj }; }
    function success(obj) { return { type: documentationConstants.DOCUMENTATION_SEARCH_SUCCESS, obj }; }
  }
  

  function searchClear(monitor) {
    return dispatch => {
      const { documentation } = monitor;
      const { search, ...nosearch } = documentation;
      dispatch(request(nosearch));
    };
    function request(obj) { return { type: documentationConstants.DOCUMENTATION_SEARCH_CLEAR, obj }; }
  }

  function page(monitor, page) {
    return dispatch => {
      const { documentation } = monitor;
      const reqdocumentation = {
        ...documentation,
        page: page
      };
      dispatch(request(reqdocumentation));
    };
    function request(obj) { return { type: documentationConstants.DOCUMENTATION_PAGE_CHANGE, obj }; }
  }

 
  function hasSearchResult(roomserviceObj) {
    if (!roomserviceObj.search) return false;
    if (!roomserviceObj.search.found) return false;
    return true;
  }
  
  function removeDeletedFromSearchResults(found, deleted) {
    let newfound = [];
    for(let i = 0, j = found.length; i < j; i++) {
      if (found[i].event_id !== deleted.event_id) {
        newfound.push(found[i]);
      }
    }
    if (newfound.length > 0) return newfound;
    else return null;
  }
  
  