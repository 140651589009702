import React from 'react';
import { connect } from 'react-redux';

import { paramActions } from '../../../_actions';
import { prepTableInfo } from '../../../_helpers';

import { DataTable } from '../../dataTable';

const StbLevelTable = props => {
    const { monitor } = props;
    const { appparam } = monitor;

    let tableInfoObj = null;
    let dataTable = null;

    if (appparam.stbs && appparam.stbs.length > 0) {
        tableInfoObj = prepTableInfo(appparam.stbs);
        if (tableInfoObj) {
            const rowClickHandler = (evt) => { props.select(monitor, evt); };

            const tableDisplayParams = {
                name: 'stbparams'
              , hideFields: ['created', 'created_id']
              , allowOptions: {
                    edit: { allowed: true, handler: rowClickHandler }
                  , delete: { allowed: false, handler: null }
                  , select: { allowed: false, handler: null }
                }
            };
            dataTable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} />;    
        }
    }

    return (
        <div id="stbleveltable">{dataTable}</div>
    );
};

function mapStateToProps(state) {
    const monitor = state.monitor;
    return { monitor };
}

const mapDispatchToProps = (dispatch) => ({
      select(monitor, evt) { dispatch(paramActions.setStbParam(monitor, evt)); }
});

const connectedStbLevelTable = connect(mapStateToProps, mapDispatchToProps)(StbLevelTable);
export { connectedStbLevelTable as StbLevelTable };
