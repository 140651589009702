import { getApiUrl, standardPost } from '../_helpers';

export const textTokenServices = {
    gettexttokens,
    savetexttokens
};

function gettexttokens(data) {
    // gets app level data from site
    const url = getApiUrl() + 'site/texttokens/get';
    return new Promise((resolve, reject) => {
        standardPost(url, data)
        .then((r) => { 
            resolve(r);
         })
        .catch((e) => {
            reject(e); 
        });
    });
}

function savetexttokens(data) {
    // save data to site
    const url = getApiUrl() + 'site/texttokens/tokensave';
    return new Promise((resolve, reject) => {
        standardPost(url, data)
        .then(r => { 
            resolve(r); 
        })
        .catch(e => { 
            reject(e); 
        });
    });
}
