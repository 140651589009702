import React from 'react';
import { connect } from 'react-redux';

import { alertActions, sitesActions, siteActions, statsActions } from '../../../_actions';
import { permissionCheck, isObjectEmpty, formatForMySql, history, convertSeconds, standardizeValues } from '../../../_helpers';

import { ProcessIndicator } from '../../processIndicator';
import { NavigationBar } from '../../navigation';

import { SiteData } from '../siteData';
import { SiteTabs } from '../siteTabs';

import { UsageChart } from './statchart';
import { StatTable, RoomsTotalTable } from './stattable';
import { RoomStat } from './roomstat';
import { ReservationHeadlines } from './headlines';

class ReservationStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        showSiteList: false
      , on_date: ''
      , showAppTable: true
      , showChanTable: true
      , showPageTable: true
      , showRoomTotalsTable: true
      , showcharts: true
      , showtables: true
      , drilldown: false
      , drilldownroom: null
      , drilldowntype: null
    };

    this.onSiteDisplay = this.onSiteDisplay.bind(this);
    this.onSiteSelect = this.onSiteSelect.bind(this);
    this.onSiteDisplayClose = this.onSiteDisplayClose.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.ToggleCharts = this.ToggleCharts.bind(this);
    this.ToggleTables = this.ToggleTables.bind(this);
    this.toggleSpecificTable = this.toggleSpecificTable.bind(this);
    this.roomDrillDown = this.roomDrillDown.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    const { auth, unauthorized, getSiteList, getStats, monitor } = this.props;
    if (auth) {
      const hasPermission = permissionCheck(auth.permissions, 'portal.resv');
      if (hasPermission) {
        if (!monitor.sites.list) {
          getSiteList(monitor);
        }
      }
      else {
        unauthorized();
        history.push('/');
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { auth, unauthorized, monitor, getSiteList, getStats, alert, clearAlert } = this.props;
    const { authorized } = this.state;
    const { sites, stats, events } = monitor;
    if (auth && !authorized) {
      const hasPermission = permissionCheck(auth.permissions, 'portal.resv');
      if (hasPermission) {
        this.setState({ authorized: true });
        if (!sites.list) {
          getSiteList(monitor);
        }
      }
      else {
        unauthorized();
        history.push('/');
      }
    }
    if (!isObjectEmpty(alert)) window.setTimeout(clearAlert, 5000);
  }

  render() {
    const { alert, auth, monitor } = this.props;
    if (!auth) return null;
    const { sites, site, stats } = monitor;
    const { on_date, appUsageChart, chanUsageChart, totalUsageChart, pageDwellChart, showcharts, showtables } = this.state;
    let headerText = 'Monitor - Site - Reservation Stats';
    let processing = false;
    let appUsage = null;
    let chanUsage = null;
    let conUsage = null;
    let totalschart = null;
    let chartsBtnText = 'Show Charts';
    let chartsBtn = null;
    let tablesBtnText = 'Show Tables';
    let tableBtn = null;
    let appTable = null;
    let chanTable = null;
    let conTable = null;
    let roomtotalTable = null;
    let roomdrilldown = null;
    let headlines = null;

    if (!processing && sites.processing) processing = true;
    if (!processing && site.processing) processing = true;
    if (!processing && stats.processing) processing = true;

    if (stats.resv) {
      const { occupancy, rooms, apps, chans, cons, totals, ads } = stats.resv;
      const rmtotals = genRoomsTotalsData(rooms);
      headlines = <ReservationHeadlines rooms={rooms} total={totals} occupancy={occupancy} addata={ads} />;
      if (showcharts) {
        const chapps = standardizeValues(apps, null, false, 0);
        const chchans = standardizeValues(chans, null, false, 0);
        const chcons = standardizeValues(cons, null, false, 0);
        const totalschartdata = {'Applications': convertSeconds(totals.app, false, 0), 'Channels': convertSeconds(totals.chan, false, 0)};

        appUsage = <UsageChart data={chapps} sort={false} limit={false} limitStart={null} limitEnd={null} xval="app" yval="hours" title="App Usage - Hours" />
        chanUsage = <UsageChart data={chchans} sort={true} limit={true} limitStart={0} limitEnd={10} xval="chan" yval="hours" title="Top 10 Channels - Hours" />
        totalschart = <UsageChart data={totalschartdata} sort={false} limit={false}  limitStart={null} limitEnd={null} xval="total" yval="hours" title="Total Usage - Hours" />
        conUsage = <UsageChart data={chcons} sort={true} limit={false} limitStart={null} limitEnd={null} xval="con" yval="hours" title="Total Page Time - Hours" />
        chartsBtnText = 'Hide Charts';
      }
      if (showtables){
        const { showAppTable, showChanTable, showPageTable, showRoomTotalsTable, drilldown, drilldownroom, drilldowntype } = this.state;
        const tbapps = standardizeValues(apps, null, false, 3);
        const tbchans = standardizeValues(chans, null, false, 3);
        const tbcons = standardizeValues(cons, null, false, 3);

        appTable = <StatTable data={tbapps} header={['Application', 'Total Time']} site={site.edit.site_id} dte={on_date} tname='Apps' toggleHandler={this.toggleSpecificTable} show={showAppTable} cssclass='table-narrow' sort={true} />;
        chanTable = <StatTable data={tbchans} header={['Channel', 'Total Time']} site={site.edit.site_id} dte={on_date} tname='Chans' toggleHandler={this.toggleSpecificTable} show={showChanTable} cssclass='table-narrow' sort={true} />;
        conTable = <StatTable data={tbcons} header={['Page', 'Total Time']} site={site.edit.site_id} dte={on_date} tname='Pages' toggleHandler={this.toggleSpecificTable} show={showPageTable} cssclass='table-narrow' sort={true} />;
        roomtotalTable = <RoomsTotalTable tbldata={rmtotals} header={['Room', 'STBs', 'Dwell', 'App', 'Chan', 'Page']} site={site.edit.site_id} dte={on_date} tname='RoomTotals' toggleHandler={this.toggleSpecificTable} show={showRoomTotalsTable} drilldownHandler={this.roomDrillDown} cssclass='table-lessnarrow' sort={true} />;
        tablesBtnText = 'Hide All Tables';

        if (drilldown) {
          let ddobj = null;
          for (let i = 0, j = rooms.length; i < j; i++) {
            const room = rooms[i];
            if (room.room === drilldownroom) {
              ddobj = room;
              break;
            }
          }
          if (ddobj) {
            let tname = '';
            let thdr = [];
            let ddata = [];
            for (let i = 0, j = ddobj.stbs.length; i < j; i++) {
              ddata.push(ddobj.stbs[i]);
            }
            switch(drilldowntype) {
              case 'apps':
                tname = 'Application';
                thdr = ['Application', 'Hours'];
                break;
              case 'chans':
                tname = 'Channel';
                thdr = ['Channel', 'Hours'];
                break;
              case 'cons':
                tname = 'Page';
                thdr = ['Page', 'Hours'];
                break;
            }
            roomdrilldown = <RoomStat close={this.closeModal} room={ddobj.room} data={ddata} ddtype={drilldowntype} header={thdr} tname={tname} site={site.edit.site_id} dte={on_date} />;
          }
        }
      }
      chartsBtn=<button type="button" className="btn btn-success visible" onClick={this.ToggleCharts}>{chartsBtnText}</button>;
      tableBtn=<button type="button" className="btn btn-success visible ml-2" onClick={this.ToggleTables}>{tablesBtnText}</button>;
    }

    if (site.edit) {
      headerText = 'Monitor - Site: ' + site.edit.site_id + ' - Reservation Stats';
    }

//    <ProcessIndicator show={processing} />
    return(
      <div>
        <NavigationBar header={headerText} />
        <div className="container-fluid">
          <SiteTabs active="stats" />
          <SiteData site={site.edit} more={false} siteList={sites.list} showList={this.state.showSiteList} clickHandler={this.onSiteSelect} displayHandler={this.onSiteDisplay} closeHandler={this.onSiteDisplayClose} />
          <div className="row justify-content-md-center">
            <div className="col-6">
              <div className="input-group">
                <label htmlFor="on_date">Reservation Date:&nbsp;
                  <input type="date" id="on_date" name="on" value={on_date} onChange={this.onDateChange} />
                </label>
                <div className="input-group-append" onClick={this.onSubmit} style={{height:"30px"}}>
                  <span className="input-group-text" data-toggle="tooltip" data-placement="top" title="Submit">
                    <span className="fas fa-search"></span>
                    {processing &&
                      <div className="d-inline fa-2x ml-2"><i className="fas fa-spinner fa-pulse"></i></div>
                    }
                  </span>
                </div>
              </div>
            </div>
            {chartsBtn}
            {tableBtn}
          </div>
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
          {headlines}
        </div>
        <div id="charts">
          {appUsage}
          {chanUsage}
          {totalschart}
          {conUsage}
        </div>
        <div id="tables">
          {appTable}
          {chanTable}
          {conTable}
          {roomtotalTable}
        </div>
        {roomdrilldown}
      </div>
    );
  }

  onSiteDisplay() {
    this.props.clearAlert();
    this.setState({ showSiteList: true });
  }

  onSiteDisplayClose() {
    this.setState({ showSiteList: false });
  }

  onSiteSelect(e) {
    this.setState({ showSiteList: false });
    const { monitor, alert, clearAlert, getSite } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    let siteIdx = e.currentTarget.attributes['data-idx'].value;
    let selectedsite = monitor.sites.list[siteIdx];
    getSite(monitor, selectedsite);
  }

  onDateChange(evt) {
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) this.props.clearAlert();
    const { id, value } = evt.target;
    this.setState({ [id]: value });
  }

  onSubmit(evt) {
    const { monitor, alert, clearAlert, sendAlert, getStats } = this.props;
    const { on_date } = this.state;
    if (!isObjectEmpty(alert)) clearAlert();
    const { site, stats } = monitor;
    let msg = null;
    if (!site.edit) msg = 'Please select a site';
    if (on_date.length === 0) {
      if (msg) msg += ' and ';
      else msg = '';
      msg += 'Please select a date';
    }

    if (msg) {
      sendAlert(msg);
      return;
    }

    getStats(monitor, on_date);
  }

  ToggleCharts() {
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    this.setState({ showcharts: !this.state.showcharts });
  }

  ToggleTables() {
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    this.setState({ showtables: !this.state.showtables });
  }

  toggleSpecificTable(name) {
    switch(name) {
      case 'Apps':
        this.setState({ showAppTable: !this.state.showAppTable });
        break;
      case 'Chans':
        this.setState({ showChanTable: !this.state.showChanTable });
        break;
      case 'Pages':
        this.setState({ showPageTable: !this.state.showPageTable });
        break;
    }
  }

  roomDrillDown(evt) {
    let room = evt.currentTarget.attributes['data-room'].value;
    let dtype = evt.currentTarget.attributes['data-type'].value;
    this.setState({ drilldown: true, drilldownroom: room, drilldowntype: dtype });
  }

  closeModal() {
    this.setState({ drilldown: false, drilldownroom: null, drilldowntype: null });
  }
};

function mapStateToProps(state) {
  const auth = state.authentication.user;
  const alert = state.alert;
  const monitor = state.monitor;
  return { alert, auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    unauthorized() { dispatch(alertActions.error('You are not authorized for that page.')); }
  , getSiteList(monitor) { dispatch(sitesActions.list(monitor)); }
  , clearAlert() { dispatch(alertActions.clear()); }
  , sendAlert(msg) { dispatch(alertActions.error(msg)); }
  , getSite(monitor, site) { dispatch(siteActions.get(monitor, site, false)); }
  , getStats(monitor, on) { dispatch(statsActions.resv(monitor, on)); }
});

const connectedReservationStats = connect(mapStateToProps, mapDispatchToProps)(ReservationStats);
export { connectedReservationStats as ReservationStats };

function genRoomsTotalsData(rooms) {
  let rmtotals = [];
  for (let a = 0, b = rooms.length; a < b; a++) {
    let rm = rooms[a];
    let totes = { room: rm.room, stbs: rm.stbs.length, app: 0, chan: 0, con: 0, dwell: 0 };
    for (let c = 0, d = rm.stbs.length; c < d; c++) {
      const stb = rm.stbs[c];
      totes.app += stb.total.app;
      totes.chan += stb.total.chan;
      totes.con += stb.total.con;
      totes.dwell += stb.total.dwell;
    }
    rmtotals.push(totes);
  }
  return rmtotals;
}
