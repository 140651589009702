import React from 'react';
import { connect } from 'react-redux';

class StreamingChannelData extends React.Component {
  render() {
    const { monitor } = this.props;

    const { chan } = monitor;
    let count = 0;
    let activecnt = 0;

    if (chan.list) {
      count = chan.list.length;

      for (let i = 0, j = count; i < j; i++) {
        let obj = chan.list[i];
        if (parseInt(obj.active) === 1) activecnt++;
      }
    }

    return (
      <div id="chandata" className="row justify-content-md-center">
        <span>Total Channel Count: {count}</span>
        <span className="ml-3">Active Count: {activecnt}</span>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const monitor = state.monitor;
  return { monitor };
}

const connectedStreamingChannelData = connect(mapStateToProps)(StreamingChannelData);
export { connectedStreamingChannelData as StreamingChannelData };
