import React from 'react';
import { connect } from 'react-redux';
import { WciModal } from '../../wcimodal';

import { msgActions } from '../../../_actions';
import { config, prepTableInfo, calcTotalPages } from '../../../_helpers';

import { DataTable } from '../../dataTable';

const MsgReviewDetail = props => {
  const { alert, monitor } = props;
  const { msg } = monitor;
  const { review } = msg;
  const { selected } = review;

  if (!selected) props.close(monitor);
  const smsg = review.list[selected.index];

  let tableInfoObj = null;
  let dataTable = null;

  if (selected.msgdata) {
    let hideResponse = smsg.response_option.toUpperCase() === 'NO' ? true : false;
    let hiddenFields = ['message_id'];
    if (hideResponse) {
      hiddenFields.push('response');
      hiddenFields.push('response_time');
    }
    tableInfoObj = prepTableInfo(selected.msgdata);

    let totalpages = calcTotalPages(selected.msgdata.length, 10);
    let currPage = (selected.page) ? selected.page : 1;
    if (currPage > totalpages) currPage = totalpages;

    let pageParams = {
        rowCount: 10
      , dataPage: currPage
      , pages: totalpages
      , handler: (e) => { props.page(monitor, e); }
      , hasBtn: false
    };
    if (tableInfoObj) {
      const tableDisplayParams = {
          name: 'messageDetail'
        , hideFields: hiddenFields
        , allowOptions: {
              edit: { allowed: false, handler: null }
            , delete: { allowed: false, handler: null }
            , select: { allowed: false, handler: null }
          }
        , configLookups: ['priority', 'type']
        , pagination: {
              rowCount: pageParams.rowCount
            , dataPage: pageParams.dataPage
          }
        , sorting: {
              columns: ['room', 'viewed', 'viewed_time', 'response', 'response_time']
            , handler: (e) => { props.sort(monitor, e); }
            , activeSort: (selected.sort) ? selected.sort.field : null
            , direction: (selected.sort) ? selected.sort.direction : null
          }
      };
      dataTable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} pagination={pageParams} />;
    }
  }

  return (
    <WciModal show={true} className="baseModal">
      <div className="reviewMsgModal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Review Message Detail</h5>
            <button type="button"
                    className="close"
                    onClick={() => { props.close(monitor); }}>&times;</button>
          </div>
          <div className="modal-body">
            {alert.message &&
              <div className={`alert ${alert.type}`}>{alert.message}</div>
            }
            <div className="grpMgrSection">
              <p>Message: <span className="msgReviewContent">{smsg.content}</span></p>
              <div>
                <div className="msgReviewGenDiv">Type: {config.messages.type[smsg.type]}</div>
                <div className="msgReviewGenDiv">Priority: {config.messages.priority[smsg.priority]}</div>
                <div className="msgReviewGenDiv">Expiration: {smsg.expiration ? smsg.expiration : 'None'}</div>
                <div className="msgReviewGenDiv">Response: {smsg.response_option}</div>
              </div>
            </div>
            <div className="grpMgrSection">
              {dataTable}
            </div>
          </div>
        </div>
      </div>
    </WciModal>
  );
};

function mapStateToProps(state) {
  const alert = state.alert;
  const monitor = state.monitor;
  return { alert, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    close(monitor) { dispatch(msgActions.reviewDetailClose(monitor)); }
  , page(monitor, e) { dispatch(msgActions.reviewDetailPage(monitor, e)); }
  , sort(monitor, e) { dispatch(msgActions.reviewDetailSort(monitor, e)); }
});

const connectedMsgReviewDetail = connect(mapStateToProps, mapDispatchToProps)(MsgReviewDetail);
export { connectedMsgReviewDetail as MsgReviewDetail };
