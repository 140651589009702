import { fileConstants } from '../_constants';
import { fileService } from '../_services';

const uploadTypes = ['events'];

export const fileActions = {
  upload
};

function upload(monitor, file, contenttype) {
  return dispatch => {
    const { upload, site } = monitor;
    const requpload = {
      ...upload,
      processing: true
    };
    dispatch(request(requpload));

    let ct = uploadTypes[contenttype];
    let fd = new FormData();
    fd.append('uploadfile', file, file.name);
    fd.append('content', ct);
    fd.append('site', site.edit.site_id);
    fileService.upload(fd)
      .then(
        result => {},
        error => {}
      );
  };
  function request(obj) { return { type: fileConstants.FILE_UPLOAD_REQUEST, obj }; }
  function success(obj) { return { type: fileConstants.FILE_UPLOAD_SUCCESS, obj }; }
  function failure(obj) { return { type: fileConstants.FILE_UPLOAD_FAILURE, obj }; }
}
