export const msgConstants = {
    MSG_CLEAR: 'MSG_CLEAR'
  , MSG_GENROOMLIST_REQUEST: 'MSG_GENROOMLIST_REQUEST'
  , MSG_GENROOMLIST_SUCCESS: 'MSG_GENROOMLIST_SUCCESS'
  , MSG_SORT_REQUEST: 'MSG_SORT_REQUEST'
  , MSG_SORT_SUCCESS: 'MSG_SORT_SUCCESS'
  , MSG_FILTER_REQUEST: 'MSG_FILTER_REQUEST'
  , MSG_FILTER_SUCCESS: 'MSG_FILTER_SUCCESS'
  , MSG_ROOM_SELECT: 'MSG_ROOM_SELECT'
  , MSG_ROOM_REMOVE: 'MSG_ROOM_REMOVE'

  , MSG_GROUP_CREATEGROUP: 'MSG_GROUP_CREATEGROUP'
  , MSG_GROUP_CANCEL: 'MSG_GROUP_CANCEL'
  , MSG_GROUP_INPUT: 'MSG_GROUP_INPUT'
  , MSG_GROUP_SUBMIT_REQUEST: 'MSG_GROUP_SUBMIT_REQUEST'
  , MSG_GROUP_SUBMIT_SUCCESS: 'MSG_GROUP_SUBMIT_SUCCESS'
  , MSG_GROUP_SUBMIT_FAILURE: 'MSG_GROUP_SUBMIT_FAILURE'
  , MSG_GROUP_SELECT_REQUEST: 'MSG_GROUP_SELECT_REQUEST'
  , MSG_GROUP_SELECT_SUCCESS: 'MSG_GROUP_SELECT_SUCCESS'
  , MSG_GROUP_SELECT_FAILURE: 'MSG_GROUP_SELECT_FAILURE'
  , MSG_GROUP_MANAGE: 'MSG_GROUP_MANAGE'
  , MSG_GROUP_MANAGE_CANCEL: 'MSG_GROUP_MANAGE_CANCEL'
  , MSG_GROUP_MANAGE_SELECT: 'MSG_GROUP_MANAGE_SELECT'
  , MSG_GROUP_MANAGE_DELETE: 'MSG_GROUP_MANAGE_DELETE'
  , MSG_GROUP_MANAGE_DELETE_REQUEST: 'MSG_GROUP_MANAGE_DELETE_REQUEST'
  , MSG_GROUP_MANAGE_DELETE_SUCCESS: 'MSG_GROUP_MANAGE_DELETE_SUCCESS'
  , MSG_GROUP_MANAGE_DELETE_FAILURE: 'MSG_GROUP_MANAGE_DELETE_FAILURE'
  , MSG_GROUP_MANAGE_DELETE_CANCEL: 'MSG_GROUP_MANAGE_DELETE_CANCEL'

  , MSG_SELECT_ALL: 'MSG_SELECT_ALL'
  , MSG_SELECT_CHECKEDIN: 'MSG_SELECT_CHECKEDIN'
  , MSG_REMOVE_ALL: 'MSG_REMOVE_ALL'
  , MSG_RESET: 'MSG_RESET'
  , MSG_PRIORITY: 'MSG_PRIORITY'
  , MSG_TYPE: 'MSG_TYPE'
  , MSG_RESPONSE: 'MSG_RESPONSE'
  , MSG_RESPONSE_INPUT: 'MSG_RESPONSE_INPUT'
  , MSG_EXPIRE: 'MSG_EXPIRE'
  , MSG_EXPIRE_INPUT: 'MSG_EXPIRE_INPUT'
  , MSG_MESSAGE: 'MSG_MESSAGE'

  , MSG_SEND_REQUEST: 'MSG_SEND_REQUEST'
  , MSG_SEND_SUCCESS: 'MSG_SEND_SUCCESS'
  , MSG_SEND_FAILURE: 'MSG_SEND_FAILURE'

  , MSG_REVIEW_REQUEST: 'MSG_REVIEW_REQUEST'
  , MSG_REVIEW_SUCCESS: 'MSG_REVIEW_SUCCESS'
  , MSG_REVIEW_FAILURE: 'MSG_REVIEW_FAILURE'
  , MSG_REVIEW_CLOSE: 'MSG_REVIEW_CLOSE'
  , MSG_REVIEW_PAGE: 'MSG_REVIEW_PAGE'
  , MSG_REVIEW_SORT: 'MSG_REVIEW_SORT'
  , MSG_REVIEW_DEACTIVATE: 'MSG_REVIEW_DEACTIVATE'
  , MSG_REVIEW_DEACTIVATE_CANCEL: 'MSG_REVIEW_DEACTIVATE_CANCEL'
  , MSG_REVIEW_DEACTIVATE_REQUEST: 'MSG_REVIEW_DEACTIVATE_REQUEST'
  , MSG_REVIEW_DEACTIVATE_SUCCESS: 'MSG_REVIEW_DEACTIVATE_SUCCESS'
  , MSG_REVIEW_DEACTIVATE_FAILURE: 'MSG_REVIEW_DEACTIVATE_FAILURE'
  , MSG_REVIEW_SELECT_REQUEST: 'MSG_REVIEW_SELECT_REQUEST'
  , MSG_REVIEW_SELECT_SUCCESS: 'MSG_REVIEW_SELECT_SUCCESS'
  , MSG_REVIEW_SELECT_FAILURE: 'MSG_REVIEW_SELECT_FAILURE'
  , MSG_REVIEW_DETAIL_CLOSE: 'MSG_REVIEW_DETAIL_CLOSE'
  , MSG_REVIEW_DETAIL_PAGE: 'MSG_REVIEW_DETAIL_PAGE'
  , MSG_REVIEW_DETAIL_SORT: 'MSG_REVIEW_DETAIL_SORT'
};
