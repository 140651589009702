import React from 'react';
import { connect } from 'react-redux';

import { msgActions } from '../../../_actions';

const AddControls = props => {
  const { auth, monitor } = props;
  if (!auth) return null;
  const { site } = monitor;
  if (!site.stbs) return null;

  return (
    <div className={props.cssClass}>
      <p className="addControl" onClick={!props.disable ? () => { props.addall(monitor); } : null} data-toggle="tooltip" data-placement="top" title="Add All Rooms">&#187;</p>
      <p className="addControlText" onClick={!props.disable ? () => { props.addchkin(monitor); } : null}>All Checked In</p>
      <p className="addControl" onClick={!props.disable ? () => { props.removeall(monitor); } : null} data-toggle="tooltip" data-placement="top" title="Remove All Rooms">&#171;</p>
      <p><button type="button" className="btn btn-warning" onClick={() => { props.reset(monitor); }} disabled={props.disable}>Reset</button></p>
    </div>
  );
};

function mapStateToProps(state) {
  const auth = state.authentication.user;
  const monitor = state.monitor
  return { auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    addall(monitor) { dispatch(msgActions.addAll(monitor)); }
  , addchkin(monitor) { dispatch(msgActions.addchkin(monitor)); }
  , removeall(monitor) { dispatch(msgActions.removeAll(monitor)); }
  , reset(monitor) { dispatch(msgActions.reset(monitor)); }
});

const connectedAddControls = connect(mapStateToProps, mapDispatchToProps)(AddControls);
export { connectedAddControls as AddControls };
