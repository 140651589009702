import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { FooterContact, Icon, ProcessIndicator } from '../_components';
import { EmailModal, UserNameModal, LoginHelperModal } from '../_components/modals';

import { alertActions, userActions } from '../_actions';

import { isObjectEmpty } from '../_helpers';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.props.clearAlert();
        this.props.logout();
        this.state = {
            user_name: '',
            password: '',
            remember: false,
            validity: null,
            viewPw: false,
            loginHelperModal: false,
            emailModal: false,
            usernameModal: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isValid = this.isValid.bind(this);
        this.viewPw = this.viewPw.bind(this);
        this.viewLoginHelp = this.viewLoginHelp.bind(this);
        this.closeLoginHelperModal = this.closeLoginHelperModal.bind(this);
        this.forgotUserName = this.forgotUserName.bind(this);
        this.closeEmailModal = this.closeEmailModal.bind(this);
        this.submitEmail = this.submitEmail.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.closeUserNameModal = this.closeUserNameModal.bind(this);
        this.submitPwReset = this.submitPwReset.bind(this);
    }

    componentWillUnmount() {
        this.props.clearAlert();
    }

    render() {
        const { loggingIn, auth, alert } = this.props;
        const { user_name, password, remember, validity, viewPw } = this.state;
        const working = auth.submitted || loggingIn;

        let pwInput = (<input type="password"
                               className={'form-control' + (validity ? validity.password ? ' is-valid' : ' is-invalid': '')}
                               name="password"
                               value={password}
                               onChange={this.handleChange}
                               placeholder="Password"
                               autoComplete="current-password" disabled={working} />);
        if (viewPw) {
            pwInput = (<input type="text"
                             className={'form-control' + (validity ? validity.password ? ' is-valid' : ' is-invalid' : '')}
                             name="password"
                             value={password}
                             onChange={this.handleChange}
                             placeholder="Password"
                             autoComplete="current-password" disabled={working} />);
        }

        return (
            <div>
                <div style={{textAlign: "center"}}><Icon /></div>
                <div className="jumbotron">
                    <ProcessIndicator show={working} />
                    <div className="container">
                        <div className="row">
                            <div className="col col-md-offset-3">
                                <h3>Sign in to WorldVue</h3>
                                <form name="loginForm" onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <input type="text"
                                            className={'form-control' + (validity ? validity.user_name ? ' is-valid' : ' is-invalid' : '')}
                                            name="user_name"
                                            value={user_name}
                                            onChange={this.handleChange}
                                            placeholder="User Name"
                                            autoFocus={true}
                                            autoComplete="user-name" disabled={working} />
                                        {loggingIn && !user_name &&
                                            <div className="invalid-feedback">User name is required</div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        {pwInput}
                                        {loggingIn && !password &&
                                            <div className="invalid-feedback">Password is required</div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <button className="btn btn-primary mr-1" disabled={working}>
                                            <span>Sign in</span>
                                            {loggingIn &&
                                                <div className="d-inline fa-2x ml-2"><i className="fas fa-spinner fa-pulse"></i></div>
                                            }
                                        </button>
                                        <Link to="/register" className="btn btn-link ml-4">Register</Link>
                                        <button className="btn btn-link ml-4" role="link" onClick={this.viewLoginHelp} disabled={working}>Login Help</button>
                                    </div>
                                </form>
                                {alert.message &&
                                    <div className={`alert ${alert.type}`}>{alert.message}</div>
                                }
                            </div>
                            <div className="col">
                                <h3>&nbsp;</h3>
                                <div className="mt-4">&nbsp;</div>
                                <div className="mt-3">
                                    <span onClick={this.viewPw} data-bs-toggle="tooltip" title="Show Password"><i className="fas fa-eye" disabled={working}></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relativefooter">
                        <FooterContact />
                    </div>
                </div>
                <LoginHelperModal show={this.state.loginHelperModal} className="baseModal" placementClass="emailModalSize" close={this.closeLoginHelperModal} forgotUN={this.forgotUserName} resetPW={this.resetPassword} />
                <EmailModal show={this.state.emailModal} className="baseModal" placementClass="emailModalSize" close={this.closeEmailModal} proceed={this.submitEmail} />
                <UserNameModal show={this.state.usernameModal} className="baseModal" placementClass="emailModalSize" close={this.closeUserNameModal} proceed={this.submitPwReset} />
            </div>
        );
    }

    handleChange(evt) {
        this.props.clearAlert();
        const { name, value } = evt.target;
        this.setState({ [name]: value });
    }

    handleSubmit(evt) {
        evt.preventDefault();

        const { user_name, password } = this.state;
        const { login, tracking } = this.props;
        if (this.isValid()) {
            login(user_name, password, tracking);
        }
    }

    isValid() {
        var validity = {
            user_name: true,
            password: true
        };
        var valid = true;
        if (!this.state.user_name) {
            validity.user_name = false;
            valid = false;
        }
        if (!this.state.password) {
            validity.password = false;
            valid = false;
        }
        this.setState({ validity: validity });
        return valid;
    }

    viewPw() {
        this.setState({ viewPw: !this.state.viewPw });
    }

    viewLoginHelp(evt) {
        evt.preventDefault();
        this.setState({ loginHelperModal: true });
    }
    closeLoginHelperModal() {
        this.setState({ loginHelperModal: false });
    }

    forgotUserName(evt) {
        evt.preventDefault();
        this.setState({ loginHelperModal: false });
        this.setState({ emailModal: true });
    }

    closeEmailModal() {
        this.setState({ emailModal: false });
    }

    submitEmail(evt, r) {
        evt.preventDefault();
        this.setState({ emailModal: false });
        const { auth, sendUserName } = this.props;
        sendUserName(auth, r);
    }

    resetPassword(evt) {
        evt.preventDefault();
        this.setState({ loginHelperModal: false });
        this.setState({ usernameModal: true });
    }

    closeUserNameModal() {
        this.setState({ usernameModal: false });
    }

    submitPwReset(evt, r) {
        evt.preventDefault();
        this.setState({ usernameModal: false });
        const { auth, resetPw, alert, clearAlert } = this.props;
        if (!isObjectEmpty(alert)) clearAlert();
        resetPw(auth, r);
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    const { alert } = state;
    const auth = state.authentication;
    const tracking = state.tracking;
    return {
        loggingIn,
        alert,
        auth,
        tracking
    };
}

const mapDispatchToProps = (dispatch) => ({
      clearAlert() { dispatch(alertActions.clear()); }
    , logout() { dispatch(userActions.logout()); }
    , login(user_name, password, tracking) { dispatch(userActions.login(user_name, password, tracking)); }
    , sendUserName(auth, email) { dispatch(userActions.sendUserName(auth, email)); }
    , resetPw(auth, username) { dispatch(userActions.resetPw(auth, username)); }
});

const connectedLoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
export { connectedLoginPage as LoginPage };
