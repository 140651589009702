import React from 'react';
import { connect } from 'react-redux';

import { alertActions, adminRoleActions } from '../../../_actions';
import { history, isObjectEmpty } from '../../../_helpers';

import { NavigationBar } from '../../navigation';

class RoleAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        name: ''
      , description: ''
      ,
    };

    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { auth } = this.props;
    if (!auth) history.push('/admin/roles');
  }

  render() {
    const { alert, admin } = this.props;
    const { role } = admin;
    const { name, description } = this.state;
    const readonly = role.adding ? role.adding : false;
    const validity = role.validity ? role.validity : null;
    const btnText = role.refresh ? 'Exit' : 'Cancel';
    const exit = <button type="button" className="btn btn-secondary left-margin-10" disabled={role.adding} onClick={this.onCancel}>{btnText}</button>;

    return (
      <div>
        <NavigationBar header="Admin - Roles - Add" />
        <div className="jumbotron">
          <div className="container">
            <div className="row justify-content-md-center">
              <h5>New Role</h5>
            </div>
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <input type="text"
                      className={'form-control' + (validity ? validity.name ? ' is-valid' : ' is-invalid' : ' is-valid')}
                      name="name"
                      onChange={this.onChange}
                      value={name}
                      readOnly={readonly}
                      placeholder="Name" />
                {validity && !validity.valid && name.length === 0 &&
                  <div className='invalid-feedback'>Name is required</div>
                }
              </div>
              <div className="form-group">
                <input type="text"
                      className={'form-control' + (validity ? validity.description ? ' is-valid' : ' is-invalid' : ' is-valid')}
                      name="description"
                      onChange={this.onChange}
                      value={description}
                      readOnly={readonly}
                      placeholder="Description" />
                {validity && !validity.valid && description.length === 0 &&
                  <div className='invalid-feedback'>Description is required</div>
                }
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary" disabled={role.adding}>
                  <span>Submit</span>
                  {role.adding &&
                    <div className="d-inline fa-2x left-margin-10"><i className="fas fa-spinner fa-pulse"></i></div>
                  }
                </button>
                {exit}
              </div>
            </form>
          </div>
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
        </div>
      </div>
    );
  }

  onChange(evt) {
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    const { name, value } = evt.target;
    this.setState({ [name]: value });
  }

  onCancel() {
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    history.push('/admin/roles');
  }

  onSubmit(evt) {
    evt.preventDefault();
    const { alert, admin, clearAlert, save } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    const { name, description } = this.state;
    const params = {
        name: name
      , description: description
    };
    save(admin, params);
  }
}

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const admin = state.administration
  return { alert, auth, admin };
}

const mapDispatchToProps = (dispatch) => ({
    clearAlert() { dispatch(alertActions.clear()); }
  , save(admin, params) { dispatch(adminRoleActions.add(admin, params)); }
});

const connectedRoleAdd = connect(mapStateToProps, mapDispatchToProps)(RoleAdd);
export { connectedRoleAdd as RoleAdd };
