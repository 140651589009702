import React from 'react';
import { connect } from 'react-redux';

import { alertActions, sitesActions, siteActions } from '../../../_actions';
import { permissionCheck, prepTableInfo, calcTotalPages, isObjectEmpty, history } from '../../../_helpers';

import { NavigationBar } from '../../navigation';
import { ComplexSiteSearch } from '../../search';
import { DataTable } from '../../dataTable';
import { ProcessIndicator } from '../../processIndicator';

class Sites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        authenticated: false
      , searchParams: {
            site_id: ''
          , their_id: ''
          , name: ''
          , site_ip_address: ''
        }
      , tableitemcount: 10
      , dataPage: 1
    };

    this.onPageClick = this.onPageClick.bind(this);
    this.onPaginationCountChange = this.onPaginationCountChange.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onSortColumnClick = this.onSortColumnClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputClear = this.onInputClear.bind(this);
  }

  componentDidMount() {
    const { auth, monitor, list } = this.props;
    if (auth) {
      if (permissionCheck(auth.permissions, 'portal.sites')) {
        this.setState({ authenticated: true });
        if (!monitor.sites.list) {
          list(monitor, auth);
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { auth, alert, monitor, list } = this.props;
    if (auth) {
      if (!this.state.authenticated) {
        if (permissionCheck(auth.permissions, 'portal.sites')) {
          this.setState({ authenticated: true });
        }
        else {
          dispatch(alertActions.error('Access has not been granted for that page.'));
          history.push('/');
        }
      }
    }
    if (this.state.authenticated) {
      if (isObjectEmpty(alert)) {
        if (!monitor.sites.list && !monitor.sites.processing) {
          list(monitor);
        }
      }
      else {
        return;
      }
    }
    if (!isObjectEmpty(alert)) {
      window.setTimeout(this.props.clearAlert, 7000);
    }
  }

  render() {
    const { alert, auth, monitor } = this.props;
    if (!auth) return null;
    if (!auth.permissions) return null;
    const { sites } = monitor;
    const processing = sites.processing ? sites.processing : false;
    const wci = permissionCheck(this.props.auth.permissions, 'portal.site.wci'); // WCI employee? Determine how to calculate down STB's. At 15 minutes or 120 minutes.

    const siteSearch = { searchParams: this.state.searchParams, searching: processing };

    let dataTable = null;
    if (sites.list && sites.list.length > 0) {
      const linkAllowed = permissionCheck(auth.permissions, 'portal.sites.link');
      const isdemo = permissionCheck(auth.permissions, 'portal.demo');
      const tableList = sites.found ? sites.found : sites.list;
      const tableInfoObj = prepTableInfo(tableList, isdemo);
      const showIpAddress = linkAllowed ? '' : 'ip_address';

      let totalpages = calcTotalPages(tableList.length, this.state.tableitemcount);

      const pageParams = {
          rowCount: this.state.tableitemcount
        , dataPage: this.state.dataPage
        , pages: totalpages
        , handler: this.onPageClick
        , selectcount: true
        , numselectHandler: this.onPaginationCountChange
        , hasBtn: false
      };
      const tableDisplayParams = {
          wci: wci
        , hideFields: ['address2', 'created', 'modified', showIpAddress]
        , allowOptions: {
              edit: { allowed: true, handler: this.onEditClick }
            , delete: { allowed: false, handler: null }
            , select: { allowed: false, handler: null }
          }
        , pagination: {
              rowCount: pageParams.rowCount
            , dataPage: pageParams.dataPage
          }
        , linkfields: {
              allowed: linkAllowed
            , list: ['site_id']
            , link: ['site_ip_address']
          }
        , sorting: {
              columns: ['site_id', 'their_id', 'name', 'box_down', 'box_tot']
            , handler: this.onSortColumnClick
            , activeSort: (sites.sort) ? sites.sort.field : null
            , direction: (sites.sort) ? sites.sort.direction : null
          }
        , columnHiding: {
              xs: ['box_on', 'box_tot']
            , sm: ['modify_time']
          }
      };
      dataTable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} pagination={pageParams} />;
    }

    return(
      <div>
        <NavigationBar header="Monitor - Sites" />
        <div className="container-fluid">
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
          <ProcessIndicator show={processing} />
          <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#searchCriteria" aria-expanded="false" aria-controls="searchCriteria">
            Toggle Search
          </button>
          <div className="collapse" id="searchCriteria">
            <ComplexSiteSearch site={siteSearch} submitHandler={this.onSubmit} changeHandler={this.onInputChange} clearHandler={this.onInputClear} searchDisable={false} />
          </div>
          {dataTable}
        </div>
      </div>
    );
  }

  onPageClick(evt) {
    const page = parseInt(evt.currentTarget.attributes['data-pagenumber'].value);
    const { dataPage } = this.state;
    if (page === dataPage) return;
    this.setState({ dataPage: page });
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onPaginationCountChange(evt) {
    const { value } = evt.target;
    this.setState({tableitemcount: parseInt(value)});
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onEditClick(evt) {
    const { alert, monitor, auth, select, clearAlert } = this.props;
    const { sites } = monitor;
    if (!isObjectEmpty(alert)) clearAlert();
    let idx = parseInt(evt.currentTarget.attributes['data-index'].value);
    let obj = null;
    if (idx >= 0) {
      if (sites.found) {
        obj = sites.found[idx];
      }
      else {
        obj = sites.list[idx];
      }
    }
    if (!obj) {
      dispatch(alertActions.error('Selected site not found'));
      return;
    }
    select(monitor, obj, auth);
  }

  onSortColumnClick(evt) {
    const field = evt.currentTarget.attributes['data-field'].value;
    const { alert, monitor, sort, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    sort(monitor, field);
  }

  onInputChange(evt) {
    const { name, value } = evt.target;
    const { alert, clearAlert } = this.props;
    const { searchParams } = this.state;
    const newsp = {
      ...searchParams,
      [name]: value
    };
    this.setState({ searchParams: newsp });
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onInputClear() {
    const cleared = {
        site_id: ''
      , their_id: ''
      , name: ''
      , site_ip_address: ''
    };
    this.setState({ searchParams: cleared });
    const { monitor, clearSearch, alert, clearAlert } = this.props;
    clearSearch(monitor);
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onSubmit(evt) {
    evt.preventDefault();
    const { alert, monitor, search, clearAlert } = this.props;
    search(monitor, this.state.searchParams);
    if (!isObjectEmpty(alert)) clearAlert();
  }
}

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const monitor = state.monitor;
  return { alert, auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    list(monitor, auth) { dispatch(sitesActions.list(monitor, auth)); }
  , clearAlert() { dispatch(alertActions.clear()); }
  , sort(monitor, field) { dispatch(sitesActions.sort(monitor, field)); }
  , search(monitor, params) { dispatch(sitesActions.search(monitor, params)); }
  , clearSearch(monitor) { dispatch(sitesActions.searchClear(monitor)); }
  , select(monitor, site, auth) { dispatch(siteActions.get(monitor, site, true, auth)); }
});

const connectedSites = connect(mapStateToProps, mapDispatchToProps)(Sites);
export { connectedSites as Sites };
