import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';

let middleware = null;
if (process.env.NODE_ENV !== 'production') {
  middleware = applyMiddleware(
      thunkMiddleware,
      createLogger()
  );
}
else {
  middleware = applyMiddleware(
      thunkMiddleware
  );
}

export const store = createStore(
    rootReducer,
    middleware
);
