// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statchart {
  display: inline-block;
  width: 400px;
}
.networkImg {
  height: 350px;
  margin-bottom: 60px;
}
.networkImg img {
  height: 350px;
}
.networkImgHdr {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.headlines-section {
  margin: 20px 150px;
}
.headline-occupancy {
  font-size: 24px;
  font-weight: bold;
}
.headline-disclaimer {
  font-size: 14px;
  font-weight: normal;
}
.headline-strong {
  font-size: 20px;
  font-weight: bold;
}
.stattbltitle {
  position: relative;
  right: 220px;
  top: 7px;
  font-size: 1.2em;
}
`, "",{"version":3,"sources":["webpack://./src/styles/stats.less"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,YAAA;AACF;AACA;EACE,aAAA;EACA,mBAAA;AACF;AACA;EACE,aAAA;AACF;AACA;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;AACF;AACA;EACE,kBAAA;AACF;AACA;EACE,eAAA;EACA,iBAAA;AACF;AACA;EACE,eAAA;EACA,mBAAA;AACF;AACA;EACE,eAAA;EACA,iBAAA;AACF;AACA;EACE,kBAAA;EACA,YAAA;EACA,QAAA;EACA,gBAAA;AACF","sourcesContent":[".statchart {\n  display:inline-block;\n  width:400px;\n}\n.networkImg {\n  height: 350px;\n  margin-bottom: 60px;\n}\n.networkImg img {\n  height: 350px;\n}\n.networkImgHdr {\n  font-size: 20px;\n  font-weight: 600;\n  text-align: center;\n}\n.headlines-section {\n  margin: 20px 150px;\n}\n.headline-occupancy {\n  font-size: 24px;\n  font-weight: bold;\n}\n.headline-disclaimer {\n  font-size: 14px;\n  font-weight: normal;\n}\n.headline-strong {\n  font-size: 20px;\n  font-weight: bold;\n}\n.stattbltitle {\n  position: relative;\n  right:220px;\n  top:7px;\n  font-size: 1.2em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
