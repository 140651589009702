import React from 'react';
import { UserSiteList } from './list';

import { hasHtmlCode, convertHtmlCode } from '../../_helpers';

export const SiteData = props => {
  return (<DataView {...props} />);
};

const DataView = props => {
  let userSiteList = null;
  let sd = null;
  let sdm = null;
  if(props.siteList) {
    userSiteList = <UserSiteList show={props.showList}
                                list={props.siteList}
                                showSiteIp={props.showSiteIp}
                                clickHandler={props.clickHandler}
                                displayHandler={props.displayHandler}
                                closeHandler={props.closeHandler} />;
  }
  if (props.site) {
    sd = [];
    let width = window.outerWidth;
    if (width >= 720) {
      sd.push(<SDLabel key="sd0" labelId="their_id" labelVal="Their Id:" />);
      sd.push(<SDInput key="sd1" inputId="their_id" inputVal={props.site.their_id ? props.site.their_id : ''} inputClass="col-sm-2 form-control-plaintext" />);
      sd.push(<SDLabel key="sd2" labelId="name" labelVal="Name:" />);
      sd.push(<SDInput key="sd3" inputId="name" inputVal={props.site.name} inputClass="col-sm-4 form-control-plaintext" />);
      if (props.more) {
        sd.push(<button key="sd4" type="button" className="btn btn-info" data-toggle="collapse" data-target="#moreinfo" aria-expanded="false" aria-controls="moreinfo">Toggle More</button>);
        sdm = <SDMore showSiteIp={props.showSiteIp} site={props.site} />;
      }
    }
    else {
      sd.push(<SDInput key="sd0" inputId="name" inputVal={props.site.name} inputClass="col-10 form-control-plaintext text-center ml-4" />);
    }
  }

  return (
    <div>
      <div className="row">
        {userSiteList}
        {sd}
      </div>
      {sdm}
    </div>
  );
}


const SDLabel = props => (
  <label htmlFor={props.labelId} className="col-sm-2 col-form-label text-right">{props.labelVal}</label>
);

const SDInput = props => (
  <input type="text"
    id={props.inputId}
    className={props.inputClass}
    readOnly
    value={hasHtmlCode(props.inputVal) ? convertHtmlCode(props.inputVal) : props.inputVal} />
);

const SDMore = props => (
  <div className="collapse" id="moreinfo">
    <div className="row ml-4">
      <label htmlFor="ip_address" className="col-sm-2 col-form-label text-right">IP Address:</label>
      <input type="text"
        id="ip_address"
        readOnly
        className="col-sm-2 form-control-plaintext"
        value={props.showSiteIp ? props.site.site_ip_address : ''} />
      <label htmlFor="modify_time" className="col-sm-2 col-form-label text-right ml-1">Last Modified:</label>
      <input type="text"
        id="modify_time"
        className="col-sm-3 form-control-plaintext"
        readOnly
        value={props.site.modify_time ? props.site.modify_time : ''} />
    </div>
  </div>
);
