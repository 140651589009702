import React from 'react';
import { connect } from 'react-redux';

import { alertActions, adminPermissionActions } from '../../../_actions';
import { permissionCheck, isObjectEmpty, prepTableInfo, calcTotalPages, config, history, confirmOptions } from '../../../_helpers';

import { NavigationBar } from '../../navigation';
import { SimpleSearch } from '../../search';
import { DataTable } from '../../dataTable';
import { ConfirmModal } from '../../confirm';
import { Pagination } from '../../pagination';

class PermissionAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        authorized: false
      , rowcount: config.itemCountOptions[0]
      , confirm: false
      , deleteobj: null
    };

    this.onSearchInput = this.onSearchInput.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.onPageClick = this.onPageClick.bind(this);
    this.onPaginationCountChange = this.onPaginationCountChange.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeleteProceed = this.onDeleteProceed.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
    this.onSortClick = this.onSortClick.bind(this);
    this.onkeydownHandler = this.onkeydownHandler.bind(this);
  }

  componentDidMount() {
    const { auth, unauthorized, admin } = this.props;
    if (auth) {
      const hasPermission = permissionCheck(auth.permissions, 'admin.permission');
      if (hasPermission) {
        this.setState({ authorized: true });
        if (admin.permission.refresh) {
          this.onSearchSubmit();
        }
      }
      else {
        unauthorized();
        history.push('/');
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { auth, unauthorized, alert, clearAlert, admin } = this.props;
    const { authorized } = this.state;
    if (auth && !authorized) {
      const hasPermission = permissionCheck(auth.permissions, 'admin.permission');
      if (hasPermission) {
        this.setState({ authorized: true });
      }
      else {
        unauthorized();
        history.push('/');
      }
    }
    if (!isObjectEmpty(alert)) window.setTimeout(clearAlert, 5000);
    if (admin.permission.refresh && !prevProps.admin.permission.refresh) {
      this.onSearchSubmit();
    }
  }

  render() {
    const { alert, auth, admin } = this.props;
    if (!auth) return null;
    const { authorized, rowcount } = this.state;
    if (!authorized) return null;

    const { permission } = admin;
    const allowEdit = permissionCheck(auth.permissions, 'admin.permission.edit');
    const allowDel = permissionCheck(auth.permissions, 'admin.permission.delete');
    const searchval = permission.searchValue ? permission.searchValue : '';

    let processing = permission.processing ? permission.processing : false;
    let dataTable = null;
    let addBtn = null;
    let pagination = null;

    if(permission.items && permission.items.length > 0) {
      const rowCount = permission.tableRows ? permission.tableRows : rowcount;
      const tableInfoObj = prepTableInfo(permission.items);
      const totalpages = calcTotalPages(permission.items.length, rowCount);

      let currPage = (permission.dataPage) ? permission.dataPage : 1;
      if (currPage > totalpages) currPage = totalpages; // could happen on a table row count change

      const pageParams = {
          rowCount: rowCount
        , dataPage: currPage
        , pages: totalpages
        , handler: this.onPageClick
        , selectcount: true
        , numselectHandler: this.onPaginationCountChange
        , hasBtn: true
      };
      const tableDisplayParams = {
          hideFields: ['permission_id', 'created', 'created_id', 'created_user_name', 'modified', 'modified_id', 'modified_user_name']
        , allowOptions: {
              edit: { allowed: allowEdit, handler: (allowEdit) ? this.onEditClick : null }
            , delete: { allowed: allowDel, handler: (allowDel) ? this.onDeleteClick : null }
            , select: { allowed: false, handler: null }
          }
        , sorting: {
              columns: ['name']
            , handler: this.onSortClick
            , activeSort: (permission.sort) ?permission.sort.field : null
            , direction: (permission.sort) ? permission.sort.direction : null
          }
        , pagination: {
              rowCount: pageParams.rowCount
            , dataPage: pageParams.dataPage
          }
      };
      dataTable = <DataTable data={tableInfoObj.data} header={tableInfoObj.header} displayParams={tableDisplayParams} />;
      pagination = <Pagination data={tableInfoObj.data} pageParams={pageParams} />;
    }

    let confirmOpts = new confirmOptions();
    if (allowEdit) {
      addBtn = <button type="button"
                        className={(permission.items) ? 'btn btn-success visible' : 'btn btn-success invisible'}
                        onClick={this.onAddClick}>+ Add</button>
      if (this.state.confirm && this.state.deleteobj) {
        confirmOpts.show = true;
        confirmOpts.title = 'Confirm Delete';
        confirmOpts.question = 'Are you sure you want to delete the Permission: ' + this.state.deleteobj.name;
        confirmOpts.handlers.proceed = this.onDeleteProceed;
        confirmOpts.handlers.cancel = this.onDeleteCancel;
        confirmOpts.proceed.title = 'Delete';
        confirmOpts.proceed.className = 'btn-danger';
      }
    }

    return (
      <div>
        <NavigationBar header="Admin - Permissions" />
        <div className="container">
          <SimpleSearch submitHandler={this.onSearchSubmit}
                        changeHandler={this.onSearchInput}
                        searchValue={searchval}
                        searching={processing}
                        searchDisable={processing}
                        keydownHandler={this.onkeydownHandler} />
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
        </div>
        <div className="container">
          <ConfirmModal options={confirmOpts} />
          <div className="row">
            <div className="col-sm">{addBtn}</div>
            <div className="col-sm paginationWBtn2 row justify-content-end mr-1">{pagination}</div>
          </div>
          {dataTable}
        </div>
      </div>
    );
  }

  onSearchInput(evt) {
    const { value } = evt.target;
    const { admin, input, alert, clearAlert } = this.props;
    const { permission } = admin;
    const newpermission = {
      ...permission,
      searchValue: value
    };
    input(newpermission);
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onSearchSubmit(evt) {
    if(evt) evt.preventDefault();
    const { alert, clearAlert, admin, search } = this.props;
    if (admin.permission.processing) return;
    if(!isObjectEmpty(alert)) clearAlert();
    search(admin);
  }

  onPageClick(evt) {
    const newpage = parseInt(evt.currentTarget.attributes['data-pagenumber'].value);
    const { alert, clearAlert, admin, page } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    page(admin, newpage);
  }

  onPaginationCountChange(evt) {
    const { value } = evt.target;
    const { alert, clearAlert, admin, rows } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    rows(admin, value);
  }

  onAddClick(evt) {
    const { alert, clearAlert } = this.props;
    if (!isObjectEmpty(alert)) clearAlert();
    history.push('/admin/permissions/add');
  }

  onEditClick(evt) {
    var idx = parseInt(evt.currentTarget.attributes['data-index'].value);
    const { alert, clearAlert, admin, edit } = this.props;
    const { permission } = admin;
    const { items } = permission;
    const edititem = items[idx];
    if (!isObjectEmpty(alert)) clearAlert();
    edit(admin, edititem);
  }

  onDeleteClick(evt) {
    evt.stopPropagation();
    const idx = parseInt(evt.currentTarget.attributes['data-index'].value);
    const { admin, alert, clearAlert, sendMsg } = this.props;
    const { permission } = admin;
    if (!isObjectEmpty(alert)) clearAlert();
    let obj = null;
    if (idx >= 0) {
      obj = permission.items[idx];
    }
    if (!obj) {
      sendMsg('Selected item not found');
      return;
    }
    this.setState({ confirm: true, deleteobj: obj });
  }

  onDeleteProceed() {
    const { admin, remove, alert, clearAlert } = this.props;
    const { deleteobj } = this.state;
    if (!isObjectEmpty(alert)) clearAlert();
    remove(admin, deleteobj);
    this.setState({ confirm: false, deleteobj: null });
  }

  onDeleteCancel() {
    this.setState({ confirm: false, deleteobj: null });
  }

  onSortClick(evt) {
    let field = evt.currentTarget.attributes['data-field'].value;
    const { admin, sort, alert, clearAlert } = this.props;
    sort(admin, field);
    if (!isObjectEmpty(alert)) clearAlert();
  }

  onkeydownHandler(evt) {
      if (evt.keyCode === 13) {
          evt.preventDefault();
          evt.stopPropagation();
          this.onSearchSubmit();
      }
  }
}

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const admin = state.administration
  return { alert, auth, admin };
}

const mapDispatchToProps = (dispatch) => ({
    unauthorized() { dispatch(alertActions.error('You are not authorized for that page')); }
  , clearAlert() { dispatch(alertActions.clear()); }
  , sendMsg(msg) { dispatch(alertActions.error(msg)); }
  , input(obj) { dispatch(adminPermissionActions.input(obj)); }
  , search(admin) { dispatch(adminPermissionActions.search(admin)); }
  , page(admin, newpage) { dispatch(adminPermissionActions.pageChange(admin, newpage)); }
  , rows(admin, count) { dispatch(adminPermissionActions.rowCount(admin, count)); }
  , edit(admin, obj) { dispatch(adminPermissionActions.edit(admin, obj)); }
  , remove(admin, obj) { dispatch(adminPermissionActions.delete(admin, obj)); }
  , sort(admin, field) { dispatch(adminPermissionActions.sort(admin, field)); }
});

const connectedPermissionAdmin = connect(mapStateToProps, mapDispatchToProps)(PermissionAdmin);
export { connectedPermissionAdmin as PermissionAdmin };
