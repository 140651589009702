module.exports = {
    textsorter
  , numericsorter
}

function textsorter(list, field, direction) {
  if (direction === 'asc') {
    list.sort(function(a, b) {
      let aVal = (a[field]) ? a[field].toLowerCase() : '';
      let bVal = (b[field]) ? b[field].toLowerCase() : '';
      if (aVal < bVal) {
        return -1;
      }
      if (aVal > bVal) {
        return 1;
      }
      return 0;
    });
  }
  else {
    list.sort(function(a, b) {
      let aVal = (a[field]) ? a[field].toLowerCase() : '';
      let bVal = (b[field]) ? b[field].toLowerCase() : '';
      if (aVal < bVal) {
        return 1;
      }
      if (aVal > bVal) {
        return -1;
      }
      return 0;
    });
  }
}

function numericsorter(list, field, direction) {
  if (direction === 'asc') {
    list.sort(function(a, b) {
      let aVal = a[field];
      let bVal = b[field];
      return aVal - bVal;
    });
  }
  else {
    list.sort(function(a, b) {
      let aVal = a[field];
      let bVal = b[field];
      return bVal - aVal;
    });
  }
}
