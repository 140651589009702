import { msgConstants } from '../_constants';
import { numericsorter, stbReservationState, roomCleaner, addDays, setToMidnight, formatMMDDYYHH, formatMMDDYYHHNN, formatForMySqlWTime, config, textsorter } from '../_helpers';
import { alertActions } from './';
import { msgService } from '../_services';

export const msgActions = {
    clear
  , genroomlist
  , selectorSort
  , filter
  , selectRoom
  , selectedSort
  , deselectRoom
  , createGroupDisplay
  , createGroupCancel
  , createGroupInput
  , createGroupSubmit
  , groupSelect
  , addAll
  , addchkin
  , removeAll
  , reset
  , managegroups
  , groupManageCancel
  , groupManageSelect
  , groupManageDelete
  , groupManageDeleteConfirm
  , groupManageDeleteConfirmCancel
  , priority
  , msgtype
  , response
  , responseInput
  , expire
  , expireInput
  , message
  , send
  , review
  , reviewClose
  , reviewPage
  , reviewSort
  , reviewDeactivate
  , reviewDeactivateCancel
  , reviewDeactiveConfirm
  , reviewSelect
  , reviewDetailClose
  , reviewDetailPage
  , reviewDetailSort
};

function clear() {
  return dispatch => {
    dispatch(request({}));
  };
  function request(obj) { return { type: msgConstants.MSG_CLEAR, obj }; }
}

function genroomlist(monitor) {
  return dispatch => {
    const { msg, site } = monitor;
    const newmsg = {
      ...msg,
      processing: true
    };
    dispatch(request(newmsg));

    let rooms = roomCleaner(site.stbs);
    numericsorter(rooms, 'room_number', 'asc');
    let nextmsg = {
      ...msg,
        list: rooms
      , sort: {selector: {direction: 'asc'}, selected: {}}
    };
    msgService.grouplist(site.edit.site_id)
      .then(
        items => {
          nextmsg.group = { list: items };
          dispatch(success(nextmsg));
        },
        error => {
          dispatch(success(nextmsg));
          dispatch(alertActions.error('Could not get the groups.'));
        }
      );
  };
  function request(obj) { return { type: msgConstants.MSG_GENROOMLIST_REQUEST, obj }; }
  function success(obj) { return { type: msgConstants.MSG_GENROOMLIST_SUCCESS, obj }; }
}

function selectorSort(monitor) {
  return dispatch => {
    const { msg } = monitor;
    const newmsg = {
      ...msg,
      processing: true
    };
    dispatch(request(newmsg));

    let direction = 'asc';
    const { sort } = msg;
    const { selector } = sort;
    if (selector.direction === 'asc') direction = 'desc';
    numericsorter(msg.list, 'room_number', direction);
    if (msg.filter) {
      numericsorter(msg.filter, 'room_number', direction);
    }

    const newselector = {
      ...selector,
      direction: direction
    };
    const newsort = {
      ...sort,
      selector: newselector
    };
    const nextmsg = {
      ...msg,
      sort: newsort
    };
    dispatch(success(nextmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_SORT_REQUEST, obj }; }
  function success(obj) { return { type: msgConstants.MSG_SORT_SUCCESS, obj }; }
}

function filter(monitor) {
  return dispatch => {
    const { msg } = monitor;
    const newmsg = {
      ...msg,
      processing: true
    }
    dispatch(request(newmsg));

    const { filter, ...nofilter } = msg;
    if (msg.filter) {
      dispatch(success(nofilter));
      return;
    }
    let result = stbReservationState(msg.list, 'in');
    if (result.length > 0) {
      nofilter.filter = result;
    }
    dispatch(success(nofilter));
  };
  function request(obj) { return { type: msgConstants.MSG_FILTER_REQUEST, obj }; }
  function success(obj) { return { type: msgConstants.MSG_FILTER_SUCCESS, obj }; }
}

function selectRoom(monitor, idx) {
  return dispatch => {
    const { msg } = monitor;
    const list = msg.filter ? msg.filter : msg.list;
    const stb = list[idx];
    let { rooms } = msg;
    if (!rooms) rooms = [];
    rooms.push(stb);
    const newmsg = {
      ...msg,
      rooms: rooms
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_ROOM_SELECT, obj }; }
}

function selectedSort(monitor) {
  return dispatch => {
    const { msg } = monitor;
    const newmsg = {
      ...msg,
      processing: true
    };
    dispatch(request(newmsg));

    let direction = 'asc';
    const { sort } = msg;
    const { selected } = sort;
    if (selected.direction && selected.direction === 'asc') direction = 'desc';
    numericsorter(msg.rooms, 'room_number', direction);

    const newselected = {
      ...selected,
      direction: direction
    };
    const newsort = {
      ...sort,
      selected: newselected
    };
    const nextmsg = {
      ...msg,
      sort: newsort
    };
    dispatch(success(nextmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_SORT_REQUEST, obj }; }
  function success(obj) { return { type: msgConstants.MSG_SORT_SUCCESS, obj }; }
}

function deselectRoom(monitor, idx) {
  return dispatch => {
    const { msg } = monitor;
    let newrooms = [];
    for (let i = 0, j = msg.rooms.length; i < j; i++) {
      if (i !== idx) {
        newrooms.push(msg.rooms[i]);
      }
    }
    const newmsg = {
      ...msg,
      rooms: newrooms
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_ROOM_REMOVE, obj }; }
}

function createGroupDisplay(monitor) {
  return dispatch => {
    const { msg } = monitor;
    const { group } = msg;

    let newmsg = {};
    if (!group.selectedIdx >= 0) {
      newmsg = {
        ...msg,
        createGroup: true
      };
    }
    else {
      const grp = group.list[group.selectedIdx];
      const newgrp = {
        ...group,
        name: grp.name
      };
      newmsg = {
        ...msg,
          createGroup: true
        , group: newgrp
      };
    }
    dispatch(display(newmsg));
  };
  function display(obj) { return { type: msgConstants.MSG_GROUP_CREATEGROUP, obj }; }
}

function createGroupCancel(monitor) {
  return dispatch => {
    const { msg } = monitor;
    const { group } = msg;
    const { name, ...nocreate } = group;
    const newmsg = {
      ...msg,
      group: nocreate
    };
    const { createGroup, ...notcreategroup } = newmsg;
    dispatch(request(notcreategroup));
  };
  function request(obj) { return { type: msgConstants.MSG_GROUP_CANCEL, obj }; }
}

function createGroupInput(monitor, e) {
  return dispatch => {
    const { msg } = monitor;
    const { group } = msg;
    const { value } = e.target;
    const newgroup = {
      ...group,
      name: value
    };
    const newmsg = {
      ...msg,
      group: newgroup
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_GROUP_INPUT, obj }; }
}

function createGroupSubmit(monitor) {
  return dispatch => {
    const { msg, site } = monitor;
    const newmsg = {
      ...msg,
      processing: true
    };
    dispatch(request(newmsg));

    const { group } = msg;

    const { name, ...nocreategroup } = group;
    const { processing, createGroup, ...nocreate } = newmsg;

    let chosen = false;
    if (group.list.length > 0 && group.selectedIdx) {
      let grp = group.list[group.selectedIdx];
      if (grp) chosen = true;
    }

    if(chosen) {
      // update code
      let updateRooms = [];
      for (let k = 0, l = msg.rooms.length; k < l; k++) {
        updateRooms.push(msg.rooms[k].room_number);
      }
      let udr = {
          group_id: group.list[group.selectedIdx].group_id
        , rooms: updateRooms
      };
      let udrs = JSON.stringify(udr);
      msgService.assigngrouprooms(udrs)
        .then(
          result => {
            const { groupName, createGroup, ...nogroupname } = msg;
            dispatch(success(nogroupname));
            dispatch(alertActions.success('Group Changed'));
          },
          error => {
            dispatch(failure(msg));
            dispatch(alertActions.error(error));
          }
        );
    }
    else {
      // insert code
      if (!group.name) {
        dispatch(alertActions.error('Please enter a group name.'));
        dispatch(failure(msg));
        return;
      }

      let exists = false;
      for (let i = 0, j = group.list.length; i < j; i++) {
        let grp = group.list[i];
        if (grp.name === group.name) {
          exists = true;
          break;
        }
      }

      if (exists) {
        const msgexistserr = {
          ...msg,
          group: nocreategroup
        };
        dispatch(failure(msgexistserr));
        dispatch(alertActions.error('That group name already exists.'));
        return;
      }

      let data = {
          site_id: site.edit.site_id
        , name: group.name
      };
      let d = JSON.stringify(data);
      msgService.groupcreate(d)
        .then(
          result => {
            let groupId = result.result.ids[0];
            let rooms = [];
            for (let i = 0, j = msg.rooms.length; i < j; i++) {
              rooms.push(msg.rooms[i].room_number);
            }
            let dr = {
                group_id: groupId
              , rooms: rooms
            };
            let drs = JSON.stringify(dr);
            msgService.assigngrouprooms(drs)
              .then(
                result => {
                  const { list, ...emptygroup } = nocreategroup;
                  let newlist = [];
                  for (let i in list) {
                    newlist.push(list[i]);
                  }
                  newlist.push({ group_id: groupId, name: group.name });
                  const insertsuccessgroup = {
                    ...emptygroup,
                      list: newlist
                    , selectedIdx: newlist.length - 1
                  };
                  const nextmsg = {
                    ...nocreate,
                    group: insertsuccessgroup
                  };
                  dispatch(success(nextmsg));
                  dispatch(alertActions.success('Group Created'));
                },
                error => {
                  let msginsertroomserr = {
                    ...nocreate,
                    group: nocreategroup
                  };
                  dispatch(failure(msginsertroomserr));
                  dispatch(alertActions.error(error));
                }
              );
          },
          error => {
            let msginserterr = {
              ...nocreate,
              group: nocreategroup
            };
            dispatch(failure(msginserterr));
            dispatch(alertActions.error(error));
          }
        );
    }
  };

  function request(obj) { return { type: msgConstants.MSG_GROUP_SUBMIT_REQUEST, obj }; }
  function success(obj) { return { type: msgConstants.MSG_GROUP_SUBMIT_SUCCESS, obj }; }
  function failure(obj) { return { type: msgConstants.MSG_GROUP_SUBMIT_FAILURE, obj }; }
}

function groupSelect(monitor, e) {
  return dispatch => {
    const { msg } = monitor;
    const { group, sort } = msg;
    const gid = e.currentTarget.options[e.currentTarget.selectedIndex].value;
    const newmsg = {
      ...msg,
      processing: true
    };
    dispatch(request(newmsg));

    if (gid === 'none') {
      const { list, ...restofgroup } = group;
      const newgrp = {
        list: list
      };
      const newsort = {
        ...sort,
        selected: {}
      };
      const { rooms, ...norooms } = msg;
      const nextmsg = {
        ...norooms,
          sort: newsort
        , group: newgrp
      };
      dispatch(success(nextmsg));
    }
    else {
      const idx = parseInt(e.currentTarget.options[e.currentTarget.selectedIndex].attributes['data-index-value'].value);
      const grp = group.list[idx];
      if (!grp) {
        dispatch(alertActions.error('There was an error getting that group'));
        return;
      }
      msgService.getgrouprooms(grp.group_id)
        .then(
          result => {
            let rooms = [];
            let list = msg.list;
            for (let i = 0, j = result.length; i < j; i++) {
              let rm  = result[i];
              for (let a = 0, b = list.length; a < b; a++) {
                let l = list[a];
                if (l.room_number === rm.pms_room_number) {
                  rooms.push(l);
                  break;
                }
              }
            }
            const newgrp = {
              ...group,
              selectedIdx: idx
            };
            const nextmsg = {
              ...msg,
                rooms: rooms
              , group: newgrp
            };
            dispatch(success(nextmsg));
          },
          error => {
            dispatch(failure(msg));
            dispatch(alertActions.error(error));
          }
        );
    }
  };
  function request(obj) { return { type: msgConstants.MSG_GROUP_SELECT_REQUEST, obj }; }
  function success(obj) { return { type: msgConstants.MSG_GROUP_SELECT_SUCCESS, obj }; }
  function failure(obj) { return { type: msgConstants.MSG_GROUP_SELECT_FAILURE, obj }; }
}

function addAll(monitor) {
  return dispatch => {
    const { msg } = monitor;
    let newmsg = {
      ...msg,
      rooms: msg.list
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_SELECT_ALL, obj }; }
}

function addchkin(monitor) {
  return dispatch => {
    const { msg } = monitor;
    let result = stbReservationState(msg.list, 'in');
    if (!result || result.length === 0) {
      dispatch(alertActions.info('No rooms checked in.'));
      return;
    }

    const newmsg = {
      ...msg,
      rooms: result
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_SELECT_CHECKEDIN, obj }; }
}

function removeAll(monitor) {
  return dispatch => {
    const { msg } = monitor;
    const { rooms, sort, ...norooms } = msg;
    const newsort = {
      ...sort,
      selected: {}
    };
    const newmsg = {
      ...norooms,
      sort: newsort
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_REMOVE_ALL, obj }; }
}

function reset(monitor) {
  return dispatch => {
    const { msg } = monitor;
    const { group, sort } = msg;
    const newsort = {selector: {direction: 'asc'}, selected: {}};
    const { list, ...ete } = group;
    const newgrp = {list: list};
    if (sort.selector.direction === 'desc') {
      numericsorter(msg.list, 'room_number', 'asc');
    }
    const newmsg = {
        group: newgrp
      , sort: newsort
      , list: msg.list
    };

    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_RESET, obj }; }
}

function managegroups(monitor) {
  return dispatch => {
    const { msg } = monitor;
    const { group } = msg;
    const newgrp = {
      ...group,
      manage: {}
    };
    const newmsg = {
      ...msg,
      group: newgrp
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_GROUP_MANAGE, obj }; }
}

function groupManageCancel(monitor) {
  return dispatch => {
    const { msg } = monitor;
    const { group } = msg;
    const { manage, ...notmanage } = group;
    const newmsg = {
      ...msg,
      group: notmanage
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_GROUP_MANAGE_CANCEL, obj }; }
}

function groupManageSelect(monitor, e) {
  return dispatch => {
    const { msg } = monitor;
    const { group } = msg;
    let newgrp = {};
    let newmsg = {};

    const gid = e.currentTarget.options[e.currentTarget.selectedIndex].value;

    if (gid === 'none') {
      newgrp = {
        ...group,
        manage: {}
      };
    }
    else {
      const idx = parseInt(e.currentTarget.options[e.currentTarget.selectedIndex].attributes['data-index-value'].value);
      const grp = group.list[idx];
      const newmanage = {
          edit: grp
        , selectedIdx: idx
      };
      newgrp = {
        ...group,
        manage: newmanage
      };
    }

    newmsg = {
      ...msg,
      group: newgrp
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_GROUP_MANAGE_SELECT, obj }; }
}

function groupManageDelete(monitor) {
  return dispatch => {
    const { msg } = monitor;
    const { group } = msg;
    const { manage } = group;

    const newmanage = {
      ...manage,
      deleting: true
    };
    const newgrp = {
      ...group,
      manage: newmanage
    };
    const newmsg = {
      ...msg,
      group: newgrp
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_GROUP_MANAGE_DELETE, obj }; }
}

function groupManageDeleteConfirmCancel(monitor) {
  return dispatch => {
    const { msg } = monitor;
    const { group } = msg;
    const { manage } = group;

    const { deleting, ...nodelete } = manage;
    const newgrp = {
      ...group,
      manage: nodelete
    };
    const newmsg = {
      ...msg,
      group: newgrp
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_GROUP_MANAGE_DELETE_CANCEL, obj }; }
}

function groupManageDeleteConfirm(monitor) {
  return dispatch => {
    const { msg } = monitor;
    const { group } = msg;
    const { manage } = group;
    const { deleting, ...notdeleting } = manage;
    const newgroup = {
      ...group,
        deleting: true
      , manage: notdeleting
    };
    const newmsg = {
      ...msg,
      group: newgroup
    };
    dispatch(request(newmsg));

    const { edit, selectedIdx, ...managecleared } = notdeleting;
    msgService.deactivateGroup(edit.group_id)
      .then(
        result => {
          let list = [];
          for (let i = 0, j = group.list.length; i < j; i++) {
            if (i !== selectedIdx) list.push(group.list[i]);
          }
          const newgrp = {
            ...group,
              manage: managecleared
            , list: list
          };
          const nextmsg = {
            ...msg,
            group: newgrp
          };
          dispatch(success(nextmsg));
        },
        error => {
          dispatch(failure(msg));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: msgConstants.MSG_GROUP_MANAGE_DELETE_REQUEST, obj }; }
  function success(obj) { return { type: msgConstants.MSG_GROUP_MANAGE_DELETE_SUCCESS, obj }; }
  function failure(obj) { return { type: msgConstants.MSG_GROUP_MANAGE_DELETE_FAILURE, obj }; }
}

function priority(monitor, e) {
  return dispatch => {
    const { msg } = monitor;
    const { value } = e.target;

    const newmsg = {
      ...msg,
      priority: value
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_PRIORITY, obj }; }
}

function msgtype(monitor, e) {
  return dispatch => {
    const { msg } = monitor;
    const { value } = e.target;

    const newmsg = {
      ...msg,
      type: value
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_TYPE, obj }; }
}

function response(monitor) {
  return dispatch => {
    const { msg } = monitor;
    const { response } = msg;

    if (!response) {
      const response = { input0: null, input1: null };
      const newmsg = {
        ...msg,
        response: response
      };
      dispatch(request(newmsg));
    }
    else {
      const { response, ...noresponse } = msg;
      dispatch(request(noresponse));
    }
  };
  function request(obj) { return { type: msgConstants.MSG_RESPONSE, obj }; }
}

function responseInput(monitor, e) {
  return dispatch => {
    const { msg } = monitor;
    const { response } = msg;
    const { name, value } = e.target;

    const newresponse = {
      ...response,
      [name]: value
    };
    const newmsg = {
      ...msg,
      response: newresponse
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_RESPONSE_INPUT, obj }; }
}

function expire(monitor, e) {
  return dispatch => {
    const { msg } = monitor;
    const { expire } = msg;
    const { value } = e.target;

    let selection = parseInt(value);
    let expDate = calcExpirationDateTime(selection);
    const newexpire = {
        selection: selection
      , expiration: expDate
    };
    const newmsg = {
      ...msg,
      expire: newexpire
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_EXPIRE, obj }; }
}

function expireInput(monitor, e) {
  return dispatch => {
    const { msg } = monitor;
    const { expire } = msg;
    const { value } = e.target;

    if (value.length === 0) {
        const { expire, ...expirermvd } = msg;
        dispatch(request(expirermvd));
        return;
    }
    let expDate = formatMMDDYYHHNN(new Date(value));
    const newexpire = {
      ...expire,
      expiration: expDate
    };
    const newmsg = {
      ...msg,
      expire: newexpire
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_EXPIRE_INPUT, obj }; }
}

function message(monitor, e) {
  return dispatch => {
    const { msg } = monitor;
    const { value } = e.target;
    const newmsg = {
      ...msg,
      missive: value
    }
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_MESSAGE, obj }; }
}

function send(monitor, user) {
  return dispatch => {
    const { msg, site } = monitor;
    const newmsg = {
      ...msg,
      processing: true
    };
    dispatch(request(newmsg));

    if (!msg.rooms || msg.rooms.length === 0) {
      dispatch(alertActions.error('Please select recipient rooms.'));
      dispatch(failure(msg));
      return;
    }
    if (!msg.missive || msg.missive.length === 0) {
      dispatch(alertActions.error('Please type the message.'));
      dispatch(failure(msg));
      return;
    }
    if (msg.response && (msg.response.input0 === null || msg.response.input0.length === 0)) {
      dispatch(alertActions.error('A response is requested, so at least one response value is required. If only one value is desired it must be in textbox with the red star'));
      dispatch(failure(msg));
      return;
    }
    if (msg.missive.indexOf("'") > 0) {
      dispatch(alertActions.error('Quotation marks and contractions are not allowed at this time. Please remove them.'));
      dispatch(failure(msg));
      return;
    }

    let sendData = {
        site_id: site.edit.site_id
      , content: msg.missive
      , priority: 2
      , type: 1
      , responseOptions: null
      , expiration: ''
      , createdId: user.user_id
      , rooms: null
      , roomCount: msg.rooms.length
    };

    if (msg.priority) sendData.priority = parseInt(msg.priority);
    if (msg.type) sendData.type = msg.type;

    if (msg.expire) {
      sendData.expiration = formatForMySqlWTime(new Date(msg.expire.expiration));
    }

    if (msg.response) {
      let response = '';
      if (msg.response.input0) response += msg.response.input0;
      if (msg.response.input1) response += '|' + msg.response.input1;
      sendData.responseOptions = response;
    }

    let roomlist = '';
    for (let i = 0, j = msg.rooms.length; i < j; i++) {
      if (i > 0) {
        roomlist += ',' + msg.rooms[i].room_number;
      }
      else {
        roomlist += msg.rooms[i].room_number;
      }
    }
    sendData.rooms = roomlist;

    let sd = JSON.stringify(sendData);

    msgService.send(sd)
      .then(
        result => {
          const resetmsg = {
              group: {list: msg.group.list}
            , list: msg.list
            , sort: {
                  selected: {}
                , selector: msg.sort.selector
              }
          };
          dispatch(success(resetmsg));
          dispatch(alertActions.success('Message Sent'));
        },
        error => {
          dispatch(failure(msg));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(obj) { return { type: msgConstants.MSG_SEND_REQUEST, obj }; }
  function success(obj) { return { type: msgConstants.MSG_SEND_SUCCESS, obj }; }
  function failure(obj) { return { type: msgConstants.MSG_SEND_FAILURE, obj }; }
}

function review(monitor) {
  return dispatch => {
    const { msg, site } = monitor;
    const newmsg = {
      ...msg,
      processing: true
    };
    dispatch(request(newmsg));

    let reviewReqData = {
      site_id: site.edit.site_id
    };
    let rrd = JSON.stringify(reviewReqData);
    msgService.getAllActiveFromSite(rrd)
      .then(
        result => {
          let review = { show:'list', list: result };
          let nextmsg = {
            ...msg,
            review: review
          };
          dispatch(success(nextmsg));
        },
        error => {
          dispatch(failure(msg));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(obj) { return { type: msgConstants.MSG_REVIEW_REQUEST, obj }; }
  function success(obj) { return { type: msgConstants.MSG_REVIEW_SUCCESS, obj }; }
  function failure(obj) { return { type: msgConstants.MSG_REVIEW_FAILURE, obj }; }
}

function reviewClose(monitor) {
  return dispatch => {
    const { msg } = monitor;
    const { review, ...noreview } = msg;
    dispatch(request(noreview));
  };
  function request(obj) { return { type: msgConstants.MSG_REVIEW_CLOSE, obj }; }
}

function reviewPage(monitor, e) {
  return dispatch => {
    const { msg } = monitor;
    const { review } = msg;
    let page = parseInt(e.currentTarget.attributes['data-pagenumber'].value);
    const newreview = {
      ...review,
      page: page
    };
    const newmsg = {
      ...msg,
      review: newreview
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_REVIEW_PAGE, obj }; }
}

function reviewSort(monitor, e) {
  return dispatch => {
    const { msg } = monitor;
    const { review } = msg;
    const { sort } = review;

    const field = e.currentTarget.attributes['data-field'].value;
    const isNumeric = config.numericColumns.message.indexOf(field) >= 0 ? true : false;
    let direction = 'asc';

    let newlist = [];
    for (let i = 0, j = review.list.length; i < j; i++) {
      newlist.push(review.list[i]);
    }

    if (sort) {
      if (field === sort.field) {
        if (sort.direction === 'asc') direction = 'desc';
      }
    }

    if (isNumeric) {
      numericsorter(newlist, field, direction);
    }
    else {
      textsorter(newlist, field, direction);
    }

    const newsort = { field: field, direction: direction };
    const newreview = {
      ...review,
        list: newlist
      , sort: newsort
    };
    const newmsg = {
      ...msg,
      review: newreview
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_REVIEW_SORT, obj }; }
}

function reviewDeactivate(monitor, e) {
  return dispatch => {
    const { msg } = monitor;
    const { review } = msg;
    var idx = parseInt(e.currentTarget.attributes['data-index'].value);
    const newreview = {
      ...review,
      deactivate: {index: idx}
    };
    const newmsg = {
      ...msg,
      review: newreview
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_REVIEW_DEACTIVATE, obj }; }
}

function reviewDeactivateCancel(monitor) {
  return dispatch => {
    const { msg } = monitor;
    const { review } = msg;
    const { deactivate, ...notdeactivate } = review;
    const newmsg = {
      ...msg,
      review: notdeactivate
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_REVIEW_DEACTIVATE_CANCEL, obj }; }
}

function reviewDeactiveConfirm(monitor) {
  return dispatch => {
    const { msg, site } = monitor;
    const { review } = msg;
    const { deactivate, ...notdeactivate } = review;

    const newreview = {
      ...notdeactivate,
      processing: true
    };
    const newmsg = {
      ...msg,
        review: newreview
    };
    dispatch(request(newmsg));

    const workingmsg = review.list[deactivate.index];
    const data = {
        site_id:  site.edit.site_id
      , message_id: workingmsg.message_id
    };
    const sdata = JSON.stringify(data);
    msgService.deactivateMessageFromSite(sdata)
      .then(
        result => {
          let newlist = [];
          for (let i = 0, j = review.list.length; i < j; i++) {
            if (i !== deactivate.index) {
              newlist.push(review.list[i]);
            }
          }
          const nextreview = {
            ...notdeactivate,
            list: newlist
          };
          const nextmsg = {
            ...msg,
            review: nextreview
          };
          dispatch(success(nextmsg));
        },
        error => {
          dispatch(failure(msg));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: msgConstants.MSG_REVIEW_DEACTIVATE_REQUEST, obj }; }
  function success(obj) { return { type: msgConstants.MSG_REVIEW_DEACTIVATE_SUCCESS, obj }; }
  function failure(obj) { return { type: msgConstants.MSG_REVIEW_DEACTIVATE_FAILURE, obj }; }
}

function reviewSelect(monitor, e) {
  return dispatch => {
    const { msg, site } = monitor;
    const { review } = msg;

    const newreview = {
      ...review,
      processing: true
    };
    const newmsg = {
      ...msg,
      review: newreview
    };
    dispatch(request(newmsg));

    const idx = parseInt(e.currentTarget.attributes['data-index'].value);
    const item = review.list[idx];
    let reqdata = {
        site_id: site.edit.site_id
      , message_id: item.message_id
    };
    let rd = JSON.stringify(reqdata);
    msgService.getMessageDetailsFromSite(rd)
      .then(
        result => {
          const selected = { index: idx, msgdata: result, sort: {field: 'room', direction: 'asc'} };
          const nextreview = {
            ...review,
              show: 'detail'
            , selected: selected
          };
          const nextmsg = {
            ...msg,
            review: nextreview
          };
          dispatch(success(nextmsg));
        },
        error => {
          dispatch(failure(msg));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(obj) { return { type: msgConstants.MSG_REVIEW_SELECT_REQUEST, obj }; }
  function success(obj) { return { type: msgConstants.MSG_REVIEW_SELECT_SUCCESS, obj }; }
  function failure(obj) { return { type: msgConstants.MSG_REVIEW_SELECT_FAILURE, obj }; }
}

function reviewDetailClose(monitor) {
  return dispatch => {
    const { msg } = monitor;
    const { review } = msg;
    const { selected, ...notselected } = review;
    notselected.show = 'list';
    const newmsg = {
      ...msg,
      review: notselected
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_REVIEW_DETAIL_CLOSE, obj }; }
}

function reviewDetailPage(monitor, e) {
  return dispatch => {
    const { msg } = monitor;
    const { review } = msg;
    const { selected } = review;
    const page = parseInt(e.currentTarget.attributes['data-pagenumber'].value);

    // make sure there's really a page change needed.
    if (!selected.page && page === 1) return;
    if (selected.page && page === selected.page) return;

    const newselected = {
      ...selected,
      page: page
    };
    const newreview = {
      ...review,
      selected: newselected
    };
    const newmsg = {
      ...msg,
      review: newreview
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_REVIEW_DETAIL_PAGE, obj }; }
}

function reviewDetailSort(monitor, e) {
  return dispatch => {
    const { msg } = monitor;
    const { review } = msg;
    const { selected } = review;
    const { sort } = selected;

    if (review.list <= 1) return;
    const field = e.currentTarget.attributes['data-field'].value;
    const isNumeric = config.numericColumns.msgdetail.indexOf(field) >= 0 ? true : false;

    let direction = 'asc';
    if (sort) {
      if (field === sort.field) {
        if (sort.direction === 'asc') direction = 'desc';
      }
    }

    if (isNumeric) {
      numericsorter(selected.msgdata, field, direction);
    }
    else {
      textsorter(selected.msgdata, field, direction);
    }
    const newsort = { field: field, direction: direction };
    const newselected = {
      ...selected,
      sort: newsort
    };
    const newreview = {
      ...review,
      selected: newselected
    };
    const newmsg = {
      ...msg,
      review: newreview
    };
    dispatch(request(newmsg));
  };
  function request(obj) { return { type: msgConstants.MSG_REVIEW_DETAIL_SORT, obj }}
}



/* PRIVATE METHODS */
function calcExpirationDateTime(value) {
  switch(value) {
    case 1:
      return null;
    case 2:
      return calcEOD();
    case 3:
      let dt2 = addDays(new Date(), 1);
      dt2 = formatMMDDYYHH(dt2);
      return dt2;
    case 4:
      return formatMMDDYYHHNN(new Date());;
    default:
      return formatMMDDYYHH(new Date());
  }
}

function calcEOD() {
  let dtadd = addDays(new Date(), 1);
  let dtmid = setToMidnight(dtadd);
  return dtmid;
}
