import React from 'react';

import { config } from '../../../_helpers';

export const MsgType = props => {
  let type1 = true;
  let type2 = false;
  if (props.msgtype === "2") {
    type1 = false;
    type2 = true;
  }

  return (
    <div className="priorityContainer">
      <div className="baseAreaHdr">Message Type</div>
        <div>
          <div className="radioOpts"><input type="radio" id="rdomsgtype1" name="msgtype" value="1" onChange={(e) => { props.changeHandler(e); }} checked={type1} disabled={props.disable} /><label htmlFor="rdomsgtype1" className="rdoLabel">&nbsp;{config.messages.type[1]}</label></div>
          <div className="radioOpts"><input type="radio" id="rdomsgtype2" name="msgtype" value="2" onChange={(e) => { props.changeHandler(e); }} checked={type2} disabled={props.disable} /><label htmlFor="rdomsgtype2" className="rdoLabel">&nbsp;{config.messages.type[2]}</label></div>
        </div>
    </div>
  );
};
