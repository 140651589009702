import { getApiUrl, standardPost } from '../_helpers';

export const userService = {
    init
  , login
  , logout
  , register
  , resetTempPW
  , verifypassword
  , verifyUserName
  , verifyEmail
  , sendUserName
  , resetForgottenPw
};

function init() {
  const url = getApiUrl() + 'init';
  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'include'
    })
    .then(response => {
      if (response.status === 200) {
        response.json().then(data => {
          resolve(data);
        });
      }
      else {
        reject({logged_in: false});
      }
    })
    .catch(response => { reject(response.message); });
  });
}

function login(username, password) {
  const url = getApiUrl() + 'login';
  const data = JSON.stringify({username: username, password: password});
  return new Promise((resolve, reject) => {
      standardPost(url, data)
      .then((r) => { resolve(r); })
      .catch((e) => { reject(e); });
  });
}

function logout() {
  const url = getApiUrl() + 'logout';

  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'include'
    })
    .then(response => {
      if (response.status === 200) {
        resolve(true);
      }
      else {
        reject('Error during logout');
      }
    })
    .catch(response => { reject(response.message); });
  });
}

function register(data) {
  const url = getApiUrl() + 'register';
  return new Promise((resolve, reject) => {
      standardPost(url, data)
      .then((r) => { resolve(r); })
      .catch((e) => { reject(e); });
  });
}

function resetTempPW(data) {
  const url = getApiUrl() + 'resetpw';
  return new Promise((resolve, reject) => {
      standardPost(url, data)
      .then((r) => { resolve(r); })
      .catch((e) => { reject(e); });
  });
}

function verifypassword(data) {
  const url = getApiUrl() + 'verifypw';
  return new Promise((resolve, reject) => {
      standardPost(url, data)
      .then((r) => { resolve(r); })
      .catch((e) => { reject(e); });
  });
}

function verifyUserName(data) {
  const url = getApiUrl() + 'verifyusername';
  return new Promise((resolve, reject) => {
      standardPost(url, data)
      .then((r) => { resolve(r); })
      .catch((e) => { reject(e); });
  });
}

function verifyEmail(data) {
    const url = getApiUrl() + 'verifyemail';
    return new Promise((resolve, reject) => {
        standardPost(url, data)
        .then((r) => { resolve(r); })
        .catch((e) => { reject(e); });
    });
}

function sendUserName(data) {
    const url = getApiUrl() + 'sendUserName';
    return new Promise((resolve, reject) => {
        standardPost(url, data)
        .then((r) => { resolve(r); })
        .catch((e) => { reject(e); });
    });
}

function resetForgottenPw(data) {
    const url = getApiUrl() + 'resetforgottenpw';
    return new Promise((resolve,reject) => {
        standardPost(url, data)
        .then((r) => { resolve(r); })
        .catch((e) => { reject(e); });
    });
}
