// Takes json data and creates an object with extra information to generate a table
module.exports = {
    prepTableInfo
  , calcTotalPages
  , prepColumnHeaders
};

function calcTotalPages(itemCount, rowCount) {
  if (itemCount === 0) return 0;
  if (rowCount < 1) return 1;
  let fullPages = itemCount / rowCount;
  let remainderRows = itemCount % rowCount;
  if (remainderRows > 0) {
    fullPages = Math.floor(fullPages) + 1;
  }
  return fullPages;
}

function prepTableInfo(items, isdemo = false) {
  if (!items) return null;
  if (items.length === 0) return null;
  var keys = Object.keys(items[0]);
  let colHdrs = prepColumnHeaders(keys);
  if (isdemo) {
    if (keytest(keys, 'site_id')) {
      if (keytest(keys, 'name')) {
        hidecolumndata(items, 'name');
      }
      if (keytest(keys, 'app_version')) {
        hidecolumndata(items, 'app_version');
      }
    }
  }
  const tinfo = {data:items, header:colHdrs};
  return tinfo;
}

function prepColumnHeaders(keys) {
  var hdrlist = [];
  for(var key in keys) {
    let isKey = false;
    let txt = keys[key];
    let atxt = txt.split('_');
    let fieldText = '';
    for (let i = 0, j = atxt.length; i < j; i++) {
      if (i > 0) fieldText += ' ';
      switch(atxt[i]) {
        case 'ptc':
        case 'cpu':
          fieldText += atxt[i].toUpperCase();
          break;
        default:
          fieldText += atxt[i].charAt(0).toUpperCase() + atxt[i].slice(1);
      }
    }
    let hdrinfo = {
        fieldkey: txt
      , fieldText: fieldText
      , isKey: isKey
    };
    hdrlist.push(hdrinfo);
  }
  return hdrlist;
}

function keytest(keys, key) {
  if (keys.includes(key)) return true;
  return false;
}

function hidecolumndata(items, key) {
  for (let i = 0, j = items.length; i < j; i++) {
    items[i][key] = ' ';
  }
}


