import React from 'react';
import { connect } from 'react-redux';

import { streamingActions } from '../../../_actions';
import { history } from '../../../_helpers';

class StreamingChannelEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = { inputs: null };

    this.onInputChange = this.onInputChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { monitor } = this.props;
    const { chan } = monitor;
    if (chan.edit) {
      const d = {
          logical_channel: chan.edit.logical_channel
        , menu_text: chan.edit.menu_text
        , access_code: chan.edit.access_code
        , active: chan.edit.active
        , description: chan.edit.description
      };
      this.setState({ inputs: d });
    }
    else {
      history.push('/portal/streaming');
    }
  }

  render() {
    const { monitor } = this.props;
    const { chan } = monitor;

    const { inputs } = this.state;
    if (!inputs) return null;

    const changeHandler = this.onInputChange;
    const isActive = parseInt(inputs.active) === 1 ? true : false;

    let processing = chan.processing ? chan.processing: false;
    let validity = chan.validity ? chan.validity : null;

    return(
      <div className="container">
        <div className="row justify-content-md-center mt-3 ts-2">Edit Streaming Channel</div>
          <form onSubmit={this.onSubmit} className="mt-2">
            <div className="form-group row">
              <label htmlFor="logical_channel" className="col-sm-2 col-form-label text-right">Logical Channel:</label>
              <input type="number"
                id="logical_channel"
                name="logical_channel"
                readOnly
                className="col-sm-6 form-control"
                value={inputs && inputs.logical_channel ? inputs.logical_channel : ''} />
            </div>
            <div className="form-group row">
              <label htmlFor="menu_text" className="col-sm-2 col-form-label text-right">Menu Text:</label>
              <input type="text"
                id="menu_text"
                name="menu_text"
                className={'col-sm-6 form-control' + (validity ? validity.menu_text ? '' : ' is-invalid' : '')}
                placeholder="Menu Text"
                onChange={changeHandler}
                disabled={processing}
                value={inputs && inputs.menu_text ? inputs.menu_text : ''} />
              {validity && !validity.valid && (!inputs || !inputs.menu_text) &&
                <div className='offset-sm-1 invalid-feedback'>Menu Text is required</div>
              }
            </div>
            <div className="form-group row">
              <label htmlFor="access_code" className="col-sm-2 col-form-label text-right">Access Code:</label>
              <input type="number"
                id="access_code"
                name="access_code"
                className={'col-sm-6 form-control' + (validity ? validity.access_code ? '' : ' is-invalid' : '')}
                placeholder="Access Code between 4 to 6 digits"
                onChange={changeHandler}
                disabled={processing}
                value={inputs && inputs.access_code ? inputs.access_code : ''} />
              {validity && !validity.valid && (!inputs || !inputs.access_code) &&
                <div className='offset-sm-1 invalid-feedback'>Access Code is required</div>
              }
              {validity && !validity.valid && inputs && inputs.access_code && inputs.access_code.length < 4 &&
                <div className='offset-sm-2 invalid-feedback'>Access code must be at least 4 digits</div>
              }
              {validity && !validity.valid && inputs && inputs.access_code && inputs.access_code.length > 6 &&
                <div className='offset-sm-2 invalid-feedback'>Access code can be no more than 6 digits</div>
              }
              {validity && !validity.valid && inputs && isNaN(inputs.access_code) &&
                <div className='offset-sm-2 invalid-feedback'>Access code must be numeric</div>
              }
              {inputs && inputs.access_code && inputs.access_code < 0 && 
                  <div className='offset-sm-1 feedback'>Access code is not required</div>
              }
            </div>
            <div className="form-group row">
                <label htmlFor="active" className="col-sm-2 col-form-label text-right">Active:</label>
                <input type="checkbox" id="active" name="active" checked={isActive} onChange={changeHandler} className="mt-2" />
            </div>
            <div className="form-group row">
                <label htmlFor="description" className="col-sm-2 col-form-label text-right">Description:</label>
                <input type="text"
                    id="description"
                    name="description"
                    className={'col-sm-6 form-control' + (validity ? validity.description ? '' : ' is-invalid' : '')}
                    placeholder="Channel Description"
                    onChange={changeHandler}
                    disabled={processing}
                    maxLength="100"
                    value={inputs && inputs.description ? inputs.description : ''} />
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary" disabled={processing}>
                <span>Submit</span>
                {processing &&
                  <div className="d-inline fa-2x left-margin-10"><i className="fas fa-spinner fa-pulse"></i></div>
                }
              </button>
              <button type="button" className="btn btn-secondary left-margin-10" disabled={processing} onClick={this.onCancel}>Cancel</button>
            </div>
          </form>
      </div>
    );
  }

  onInputChange(evt) {
    const { name, value } = evt.target;
    const { inputs } = this.state;
    let inputval = null;
    if (name === 'active') {
      inputval = evt.target.checked ? 1 : 0;
    }
    else {
      inputval = value;
    }
    const newinputs = {
      ...inputs,
      [name]: inputval
    };
    this.setState({ inputs: newinputs });
  }

  onCancel() {
    const { monitor, cancel } = this.props;
    cancel(monitor);
    history.push('/portal/streaming');
  }

  onSubmit(evt) {
    evt.preventDefault();
    const { auth, monitor, save } = this.props;
    const { inputs } = this.state;
    save(monitor, inputs, auth);
    history.push('/portal/streaming');
  }
}

function mapStateToProps(state) {
  const auth = state.authentication.user;
  const monitor = state.monitor;
  return { auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
    save(monitor, inputs, auth) { dispatch(streamingActions.channeledit(monitor, inputs, auth)); }
  , cancel(monitor) { dispatch(streamingActions.chaneditclear(monitor)); }
});

const connectedStreamingChannelEdit = connect(mapStateToProps, mapDispatchToProps)(StreamingChannelEdit);
export { connectedStreamingChannelEdit as StreamingChannelEdit };
