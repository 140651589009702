import { streamingConstants } from '../_constants';
import { alertActions } from '../_actions';
import { streamingService } from '../_services';
import { history } from '../_helpers';

export const streamingActions = {
    clear
  , channellist
  , channeledit
  , chanadd
  , chanedit
  , chaneditclear
  , chanDeactivate
  , chanDeactivatecancel
//  , chanDevativateConfirm
};

function clear() {
  return dispatch => {
    dispatch(request());
  };
  function request() { return { type: streamingConstants.CHAN_CLEAR }; }
}

function channellist(monitor) {
  return dispatch => {
    const { site, chan } = monitor;
    const { saved, ...notsaved } = chan
    const reqchan = {
      ...notsaved,
      processing: true
    };
    dispatch(request(reqchan));

    streamingService.chanlist(site.edit.site_id)
      .then(
        items => {
          const nextchan = {
            list: items
          };
          dispatch(success(nextchan));
        },
        error => {
          dispatch(failure(notsaved));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(obj) { return { type: streamingConstants.CHAN_LIST_REQUEST, obj }; }
  function success(obj) { return { type: streamingConstants.CHAN_LIST_SUCCESS, obj }; }
  function failure(obj) { return { type: streamingConstants.CHAN_LIST_FAILURE, obj }; }
}

function channeledit(monitor, values, auth) {
  return dispatch => {
    const { site, chan } = monitor;
    const { saved, ...notsaved } = chan
    const reqchan = {
      ...notsaved,
      processing: true
    };
    dispatch(request(reqchan));

    const validation = isValid(values);
    if (validation.valid) {
      const d = {
          logical_channel: values.logical_channel
        , menu_text: values.menu_text
        , access_code: values.access_code
        , edit_id: parseInt(auth.user_id)
        , active: values.active
        , description: values.description
        , site_id: site.edit.site_id
      };
      const ds = JSON.stringify(d);
      streamingService.chanedit(ds)
        .then(
          result => {
            dispatch(alertActions.success('Channel successfully saved.'));
            const { edit, deactivate, ...doneedit } = notsaved;
            const nextchan = {
                ...doneedit
              , list: result
              , saved: true
            };
            dispatch(success(nextchan));
          },
          error => {
            dispatch(failure(notsaved));
            dispatch(alertActions.error(error));
          }
        );
    }
    else {
      const invalidchan = {
        ...chan,
        validity: validation
      };
      dispatch(failure(invalidchan));
    }
  };

  function request(obj) { return { type: streamingConstants.CHAN_EDIT_REQUEST, obj }; }
  function success(obj) { return { type: streamingConstants.CHAN_EDIT_SUCCESS, obj }; }
  function failure(obj) { return { type: streamingConstants.CHAN_EDIT_FAILURE, obj }; }
}

function chanadd(monitor, obj) {
  return dispatch => {
    const { chan } = monitor;
    const { saved, ...notsaved } = chan;
    dispatch(request(notsaved));
    history.push('/portal/streaming/chanadd');
  };
  function request(obj) { return { type: streamingConstants.CHAN_ADD_REQUEST, obj }; }
}

function chanedit(monitor, editobj) {
  return dispatch => {
    const { chan } = monitor;
    const { saved, deactivate, ...notsaved } = chan;
    const reqchan = {
      ...notsaved,
      edit: editobj
    };
    dispatch(request(reqchan));
    history.push('/portal/streaming/chanedit');
  };
  function request(obj) { return { type: streamingConstants.CHAN_EDIT_REQUEST, obj }; }
}

function chaneditclear(monitor) {
  return dispatch => {
    const { chan } = monitor;
    const { saved, edit, ...notsaved } = chan;
    dispatch(request(notsaved));
  };
  function request(obj) { return { type: streamingConstants.CHAN_EDIT_CLEAR_REQUEST, obj }; }
}

function chanDeactivate(monitor, obj) {
  return dispatch => {
    const { chan } = monitor;
    const reqchan = {
      ...chan,
      deactivate: obj
    };
    dispatch(request(reqchan));
  };
  function request(obj) { return { type: streamingConstants.CHAN_DEACTIVATE_REQUEST, obj }; }
}

function chanDeactivatecancel(monitor) {
  return dispatch => {
    const { chan } = monitor;
    const { deactivate, ...nodel } = chan;
    dispatch(request(nodel));
  };
  function request(obj) { return { type: streamingConstants.CHAN_DEACTIVATE_CANCEL, obj }; }
}





// PRIVATE METHODS
function isValid(inputs) {
    let validity = {
        valid: true
        , logical_channel: true
        , menu_text: true
        , access_code: true
        , description: true
    };
    if (!inputs) {
        validity.valid = false;
        validity.logical_channel = false;
        validity.menu_text = false;
        validity.access_code = false;
        return validity;
    }
    if (!inputs.logical_channel || inputs.logical_channel.length === 0) {
        validity.logical_channel = false;
        validity.valid = false;
    }
    if (inputs.logical_channel && inputs.logical_channel.length > 2) {
        validity.logical_channel = false;
        validity.valid = false;
    }
    if (isNaN(inputs.logical_channel)) {
        validity.logical_channel = false;
        validity.valid = false;
    }
    if (!inputs.menu_text || inputs.menu_text.length === 0) {
        validity.menu_text = false;
        validity.valid = false;
    }
    if (isNaN(parseInt(inputs.access_code))) {
        validity.access_code = false;
        validity.valid = false;
    }
    else {
        if (parseInt(inputs.access_code) < 0) {
            // this is valid. But, I have to check here for < zero (no code required) so a 0000 code will not fail.
        }
        else {
            if (inputs.access_code.length > 0 && inputs.access_code.length < 4) {
                validity.access_code = false;
                validity.valid = false;
            }
            if (inputs.access_code.length > 6) {
                validity.access_code = false;
                validity.valid = false;
            }
        }
    }
    return validity;
}
