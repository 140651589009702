import React from 'react';
import { WciModal } from '../../wcimodal';

export const AvailableRoles = props => (
  <WciModal show={props.show} className="baseModal">
    <div className="availableRoleModal">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{props.title}</h5>
          <button type="button"
                  className="close"
                  onClick={props.cancel}>&times;</button>
        </div>
        <div className="modal-body">
            <div>{props.search}</div>
            <div>{props.table}</div>
        </div>
        <div className="modal-footer">
          <button type="button"
                  className="btn btn-secondary"
                  onClick={props.cancel}>Cancel</button>
        </div>
      </div>
    </div>
  </WciModal>
);
