import { profileConstants } from '../_constants';

export function profile(state={}, action) {
  switch(action.type) {
    case profileConstants.CLEAR:
      return {};
    case profileConstants.INPUT:
      return action.obj;
    case profileConstants.GET_REQUEST:
      return action.obj;
    case profileConstants.GET_SUCCESS:
      return action.obj;
    case profileConstants.GET_FAILURE:
      return {};
    case profileConstants.UPDATE_REQUEST:
      return action.obj;
    case profileConstants.UPDATE_FAILURE:
      return action.obj;
    case profileConstants.UPDATE_SUCCESS:
      return action.obj;
    default:
      return state;
  }
}
