import React from 'react';
import { connect } from 'react-redux';

import { alertActions, adminUserActions } from '../../../_actions';
import { config, history, permissionCheck, generateTemporaryPassword } from '../../../_helpers';

import { NavigationBar } from '../../navigation';

class UserAdminAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        params: config.userAddParams
      , added: false
    };

    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onGenPW = this.onGenPW.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  render() {
    const {alert, auth, admin} = this.props;
    if (!auth) return null;
    if (!admin.user) return null;

    const { user } = admin;
    const { validity } = user;
    let allowEdit = permissionCheck(auth.permissions, 'admin.user.edit');

    let form = null;
    if (allowEdit) {
      const processing = user.processing ? user.processing : false;
      const btnText = this.state.added ? 'Exit' : 'Cancel';
      let exit = <button type="button" className="btn btn-secondary left-margin-10" disabled={processing} onClick={this.onCancel}>{btnText}</button>;
      form = <AddForm item={this.state.params} updating={processing} exit={exit} changeHandler={this.onChange} onGenPWHandler={this.onGenPW} submitHandler={this.onSubmit} validity={validity} />;
    }

    return (
      <div>
        <NavigationBar header="Admin - Users - Add" />
        <div className="jumbotron">
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
          <div className="container">
            {form}
          </div>
        </div>
      </div>
    );
  }

  onChange(e) {
    let { name, value } = e.target;
    if (e.target.className.indexOf('form-check-input') >= 0) {
      value = e.target.checked;
      name = 'ldap_user';
    }
    const { params } = this.state;
    const { clearAlert } = this.props;
    const newparams = {
      ...params,
      [name]: value
    };
    this.setState({ params: newparams });
    clearAlert();
  }

  onCancel() {
    const { clearAlert } = this.props;
    clearAlert();
    history.push('/admin/users');
  }

  onGenPW() {
    const pw = generateTemporaryPassword();
    const { params } = this.state;
    const { clearAlert } = this.props;
    const newparams = {
      ...params,
      password: pw
    };
    this.setState({ params: newparams });
    clearAlert();
  }

  onSubmit(e) {
    e.preventDefault();
    const { params } = this.state;
    const { admin, save } = this.props;
    this.setState({ added: true });
    save(admin, params);
  }
};

function mapStateToProps(state) {
  const alert = state.alert
  const auth = state.authentication.user;
  const admin = state.administration
  return { alert, auth, admin };
}

const mapDispatchToProps = (dispatch) => ({
    clearAlert() { dispatch(alertActions.clear()); }
  , save(admin, params) { dispatch(adminUserActions.add(admin, params)); }
});

const connectedUserAdminAdd = connect(mapStateToProps, mapDispatchToProps)(UserAdminAdd);
export { connectedUserAdminAdd as UserAdminAdd };

const AddForm = props => {
  const submitHandler = props.submitHandler;
  const changeHandler = props.changeHandler;
  const onGenPWHandler = props.onGenPWHandler;
  const validity = props.validity;
  const params = props.item;
  return(
    <form onSubmit={submitHandler}>
      <div className="form-group row">
        <div className="col-3">
          <input type="text"
            name="first_name"
            className={'form-control' + (validity ? validity.first_name ? '' : ' is-invalid' : '')}
            placeholder="First Name"
            onChange={changeHandler}
            value={params.first_name} />
          {validity && !validity.valid && !params.first_name &&
            <div className='invalid-feedback'>First name is required</div>
          }
        </div>
        <div className="col-3">
          <input type="text"
            name="last_name"
            className={'form-control' + (validity ? validity.last_name ? '' : ' is-invalid' : '')}
            placeholder="Last Name"
            onChange={changeHandler}
            value={params.last_name} />
          {validity && !validity.valid && !params.last_name &&
            <div className='invalid-feedback'>Last name is required</div>
          }
        </div>
        <div className="col-3">
          <input type="text"
            name="phone"
            className={'form-control' + (validity ? validity.phone ? '' : ' is-invalid' : '')}
            placeholder="Phone"
            onChange={changeHandler}
            value={params.phone} />
          {validity && !validity.valid && !params.ldap_user && !params.phone &&
            <div className='invalid-feedback'>Phone is required for external users</div>
          }
          {validity && !validity.valid && params.phone && params.phone.length < 10 &&
            <div className='invalid-feedback'>Phone has to be at least 10 digits</div>
          }
        </div>
      </div>
      <div className="form-group row">
        <div className="col-3">
          <input type="text"
            name="user_name"
            className={'form-control' + (validity ? validity.user_name ? '' : ' is-invalid' : '')}
            placeholder="User Name"
            onChange={changeHandler}
            value={params.user_name} />
          {validity && !validity.valid && !params.user_name &&
            <div className='invalid-feedback'>User Name is required</div>
          }
          {validity && !validity.valid && params.user_name && params.user_name.length < 3 &&
            <div className="invalid-feedback">User name has to be at least 3 characters</div>
          }
        </div>
        <div className="col-6">
          <input type="email"
            name="email"
            className={'form-control' + (validity ? validity.email ? '' : ' is-invalid' : '')}
            placeholder="Email"
            onChange={changeHandler}
            value={params.email} />
          {validity && !validity.valid && !params.email &&
            <div className='invalid-feedback'>Email is required</div>
          }
        </div>
      </div>
      <div className="form-group row">
        <div className="col-3">
          <div className="form-check">
            <input type="checkbox"
              className="form-check-input"
              id="ldapuser"
              name="ldap_user"
              onChange={changeHandler}
              checked={params.ldap_user} />
            <label className="form-check-label" htmlFor="ldapuser">LDAP User</label>
          </div>
        </div>
        <div className="col-3">
          <input type="text"
            name="password"
            className={'form-control' + (validity ? validity.password ? '' : ' is-invalid' : '')}
            placeholder="Password"
            onChange={changeHandler}
            value={params.password} />
          {validity && !validity.valid && !params.password &&
            <div className='invalid-feedback'>Password is required</div>
          }
          {validity && !validity.valid && params.password && params.password.length < 8 &&
            <div className='invalid-feedback'>Password length needs to greater than 8 characters</div>
          }
        </div>
        <button type="button" onClick={onGenPWHandler} className="btn btn-secondary" disabled={props.updating}>Generate Temp. Password</button>
      </div>
      <div className="form-group">
        <button type="submit" className="btn btn-primary" disabled={props.updating}>
          <span>Submit</span>
          {props.updating &&
            <div className="d-inline fa-2x left-margin-10"><i className="fas fa-spinner fa-pulse"></i></div>
          }
        </button>
        {props.exit}
      </div>
    </form>
  );
};
