import React from 'react';

export const Missive = props => {
  let message = '';
  let disabled = true;

  if (props.missive) {
    disabled = props.disable ? props.disable : false;
    message = props.missive;
  }

  return (
    <div className={props.cssClass}>
      <div className="baseAreaHdr">Type The Message</div>
      <textarea className="missiveText" rows="5" onChange={(e) => { props.changeHandler(e); }} value={message} disabled={props.disable}></textarea>
      <button type="button" onClick={() => { props.sendHandler(); }} className="btn btn-primary" style={{marginTop:"3px"}} disabled={disabled}>Send Message</button>
    </div>
  );
};
