export const eventsConstants = { 
    EVENTS_GET_REQUEST: 'EVENTS_GET_REQUEST'
  , EVENTS_GET_SUCCESS: 'EVENTS_GET_SUCCESS'
  , EVENTS_GET_FAILURE: 'EVENTS_GET_FAILURE'

  , EVENTS_SORT_REQUEST: 'EVENTS_SORT_REQUEST'
  , EVENTS_SORT_SUCCESS: 'EVENTS_SORT_SUCCESS'

  , EVENTS_SEARCH_INPUT: 'EVENTS_SEARCH_INPUT'
  , EVENTS_SEARCH_REQUEST: 'EVENTS_SEARCH_REQUEST'
  , EVENTS_SEARCH_SUCCESS: 'EVENTS_SEARCH_SUCCESS'
  , EVENTS_SEARCH_CLEAR: 'EVENTS_SEARCH_CLEAR'

  , EVENTS_DEACTIVATE_REQUEST: 'EVENTS_DEACTIVATE_REQUEST'
  , EVENTS_DEACTIVATE_SUCCESS: 'EVENTS_DEACTIVATE_SUCCESS'
  , EVENTS_DEACTIVATE_FAILURE: 'EVENTS_DEACTIVATE_FAILURE'
  , EVENTS_SAVE_REQUEST: 'EVENTS_SAVE_REQUEST'
  , EVENTS_SAVE_SUCCESS: 'EVENTS_SAVE_SUCCESS'
  , EVENTS_SAVE_FAILURE: 'EVENTS_SAVE_FAILURE'

  , EVENTS_EDIT_SELECT: 'EVENTS_EDIT_SELECT'
  , EVENTS_PAGE_CHANGE: 'EVENTS_PAGE_CHANGE'
  , EVENTS_CLEAR: 'EVENTS_CLEAR'
};
