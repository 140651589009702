module.exports = {
    addDays
  , formatForMySql
  , formatForMySqlWTime
  , formatForDTInput
  , formatYYMMDDHHNNSS
  , formatMMDDYYHHNN
  , formatMMDDYYHH
  , formatYYYYMMDD
  , formatHHMMSS
  , setToMidnight
}


/* DATE MATH */
function addDays(dte, days) {
  let d = new Date(dte);
  d.setDate(dte.getDate() + days);
  return d;
}
/* END DATE MATH */


/* YYMMDD FORMATS */
function formatForMySql(dte) {
  let y = dte.getFullYear().toString();
  let m = (dte.getMonth() + 1).toString();
  let d = dte.getDate().toString();
  m = !m[1] ? '0' + m : m;
  d = !d[1] ? '0' + d : d;
  return y + '-' + m + '-' + d;
}
function formatForMySqlWTime(dte) {
  let y = dte.getFullYear().toString();
  let m = (dte.getMonth() + 1).toString();
  let d = dte.getDate().toString();
  let h = dte.getHours().toString();
  let n = dte.getMinutes().toString();
  m = !m[1] ? '0' + m : m;
  d = !d[1] ? '0' + d : d;
  h = !h[1] ? '0' + h : h;
  n = !n[1] ? '0' + n : n;
  return y + '-' + m + '-' + d + ' ' + h + ':' + n;
}

function formatForDTInput(dte) {
  let y = dte.getFullYear().toString();
  let m = (dte.getMonth() + 1).toString();
  let d = dte.getDate().toString();
  let h = dte.getHours().toString();
  let n = dte.getMinutes().toString();
  m = !m[1] ? '0' + m : m;
  d = !d[1] ? '0' + d : d;
  h = !h[1] ? '0' + h : h;
  n = !n[1] ? '0' + n : n;
  return y + '-' + m + '-' + d + 'T' + h + ':' + n;
}

function formatYYMMDDHHNNSS(dte) {
  let y = dte.getFullYear().toString();
  let m = (dte.getMonth() + 1).toString();
  let d = dte.getDate().toString();
  let h = dte.getHours().toString();
  let n = dte.getMinutes().toString();
  let s = dte.getSeconds().toString();
  m = !m[1] ? '0' + m : m;
  d = !d[1] ? '0' + d : d;
  h = !h[1] ? '0' + h : h;
  n = !n[1] ? '0' + n : n;
  s = !s[1] ? '0' + s : s;
  return y + '-' + m + '-' + d + ' ' + h + ':' + n + ':' + s;
}

function formatYYYYMMDD(dte) {
  let y = dte.getFullYear().toString();
  let m = (dte.getMonth() + 1).toString();
  let d = dte.getDate().toString();
  m = !m[1] ? '0' + m : m;
  d = !d[1] ? '0' + d : d;
  return y + '-' + m + '-' + d;
}
/* END YYMMDD FORMATS */


/* MMDDYY FORMATS */
function formatMMDDYYHHNN(dte) {
  let y = dte.getFullYear().toString();
  let m = (dte.getMonth() + 1).toString();
  let d = dte.getDate().toString();
  let h = dte.getHours().toString();
  let n = dte.getMinutes().toString();
  m = !m[1] ? '0' + m : m;
  d = !d[1] ? '0' + d : d;
  h = !h[1] ? '0' + h : h;
  n = !n[1] ? '0' + n : n;
  return m + '-' + d + '-' + y + ' ' + h + ':' + n;
}

function formatMMDDYYHH(dte) {
  let y = dte.getFullYear().toString();
  let m = (dte.getMonth() + 1).toString();
  let d = dte.getDate().toString();
  let h = dte.getHours().toString();
  let n = dte.getMinutes();
  m = !m[1] ? '0' + m : m;
  d = !d[1] ? '0' + d : d;
  h = !h[1] ? '0' + h : h;
  if (n > 45) {
    h = parseInt(h);
    ++h;
    h = h.toString();
  }
  return m + '-' + d + '-' + y + ' ' + h + ':00';
}

function setToMidnight(dte) {
  let y = dte.getFullYear().toString();
  let m = (dte.getMonth() + 1).toString();
  let d = dte.getDate().toString();
  m = !m[1] ? '0' + m : m;
  d = !d[1] ? '0' + d : d;
  return m + '-' + d + '-' + y + ' 00:00';
}
/* END MMDDYY FORMATS */


/* TIME FORMATS */
function formatHHMMSS(dte) {
  let h = dte.getHours().toString();
  let n = dte.getMinutes().toString();
  let s = dte.getSeconds().toString();
  h = !h[1] ? '0' + h : h;
  n = !n[1] ? '0' + n : n;
  s = !s[1] ? '0' + s : s;
  return h + ':' + n + ':' + s;
}
/* END TIME FORMATS */
