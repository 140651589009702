import { adminService, userService } from '../_services';

const isUserNameDuplicate = (username) => {
    return new Promise((resolve, reject) => {
        const d  = {username: username};
        const ds = JSON.stringify(d);

        // This will return the user_id if found
        userService.verifyUserName(ds)
        .then(
            result => {
                if (isNaN(result)) { resolve(); }
                else { reject('User Name Exists'); }
            },
            error => {
                // If error 'Could not find user name' is returned there is no duplication.
                if (error.indexOf('Could not find') >= 0) { resolve(true); }
                else { reject(error); }
            }
        );
    });  
};

const isEmailDuplicate = (email) => {
    return new Promise((resolve, reject) => {
        const d  = {email: email};
        const ds = JSON.stringify(d);

        userService.verifyEmail(ds)
        .then(
            result => {
                if (result === 'false') { resolve(); }
                else { reject('Email Exists'); }
            },
            error => { reject(error); }
        );
    });
};



export {
    phoneValidator
  , usernameValidator
  , marshaCodeValidator
  , userParamValidator
  , isUserNameDuplicate
  , isEmailDuplicate
};

function phoneValidator(phonenum, required) {
  if (phonenum.length === 0 && required) return false;
  const punctRE = /[()-.]/g;
  const spaceRE = /\s/g;
  let cleanphonenum = phonenum.replace(punctRE, '').replace(spaceRE, '');
  if (cleanphonenum.length < 10) {
    return false;
  }
  if (isNaN(cleanphonenum)) { // only numeric characters
    return false;
  }
  else { // passes numeric test because e is a numeric symbol, but not a valid value here.
    if (cleanphonenum.indexOf('e') >= 0) {
      return false;
    }
  }
  return true;
}

function usernameValidator(username) {
  if (username.length === 0) return false; // this is required, always
  if (username.length < 3) return false; // at least 3 characters
  return true;
}

function marshaCodeValidator(code, required) {
  if (code.length === 0 && required) return false; // required field.
  if (code.length < 3) return false; // at least 3 characters as a sanity check
  return true;
}

function userParamValidator(params, resetPW = false) {
  let validity = {
      valid: true
    , user_name: true
    , password: true
    , email: true
    , first_name: true
    , last_name: true
    , phone: true
  };
  if (!usernameValidator(params.user_name)) {
    validity.user_name = false;
    validity.valid = false;
  }
  if (resetPW) { // not present for update, only when it is an Add or password reset.
    if (params.password.length === 0) {
      validity.password = false;
      validity.valid = false;
    }
    else if (params.password.length < 8) {
      validity.password = false;
      validity.valid = false;
    }
  }
  if (params.email.length === 0) {
    validity.email = false;
    validity.valid = false;
  }
  if (params.first_name.length === 0) {
    validity.first_name = false;
    validity.valid = false;
  }
  if (params.last_name.length === 0) {
    validity.last_name = false;
    validity.valid = false;
  }
  if (!params.ldap_user) {
    if (params.phone.length === 0) {
      validity.phone = false;
      validity.valid = false;
    }
  }
  if (!phoneValidator(params.phone, true)) {
    validity.phone = false;
    validity.valid = false;
  }
  return validity;
}
