import React from 'react';
import { connect } from 'react-redux';

import { alertActions, siteActions, paramActions, sitesActions } from '../../../_actions';
import { permissionCheck, history, isObjectEmpty } from '../../../_helpers';

import { NavigationBar } from '../../navigation';
import { ProcessIndicator } from '../../processIndicator';

import { SiteData } from '../siteData';
import { SiteTabs } from '../siteTabs';

import { ParamList } from './list';
import { AppLevelTable } from './applevel';
import { StbLevelTable } from './stblevel';
import { ParamEditor } from './edit';

require('../../../styles/site');

class StbParamsMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
              authorized: false
            , showSiteIp: false
            , showSiteList: false
        };

        this.onSiteSelect = this.onSiteSelect.bind(this);
        this.onSiteDisplay = this.onSiteDisplay.bind(this);
        this.onSiteDisplayClose = this.onSiteDisplayClose.bind(this);
    }

    componentDidMount() {
        const { auth, unauthorized, monitor, getSiteList, getParams } = this.props;
        if (auth) {
            const hasPermission = permissionCheck(auth.permissions, 'portal.stbparams');
            const showSiteIp = permissionCheck(auth.permissions, 'portal.sites.link'); // Permission for site link display
            if (hasPermission) {
                this.setState({ authorized: true, sowSiteIp: showSiteIp });
                getParams(monitor);
                const { sites } = monitor;
                if (!sites.list) {
                    getSiteList(monitor);
                }
             }
         }
        else {
            unauthorized();
            history.push('/');
        }
    }

    componentDidUpdate(prevProps) {
        const { auth, unauthorized, monitor, getSiteList, alert, clearAlert } = this.props;
        const { authorized } = this.state;
        const { sites } = monitor;
        if (auth && !authorized) {
            const hasPermission = permissionCheck(auth.permissions, 'portal.stbparams');
            if (hasPermission) {
                this.setState({ authorized: true });
                getParams(monitor);
                if (!sites.list) {
                    getSiteList(monitor);
                }
            }
            else {
                unauthorized();
                history.push('/');
            }
        }
        if (!isObjectEmpty(alert)) window.setTimeout(clearAlert, 5000);
    }

    render() {
        const { auth, monitor, alert, addParam } = this.props;
        if (!auth) return null;

        const { showSiteIp, showSiteList } = this.state;
        const { sites, site, appparam } = monitor;

        const addHandler = (monitor) => { addParam(monitor); };

        let headerText = 'Monitor - Site - STB Parameters';
        let processIndShow = appparam.processing ? appparam.processing : false;
        let editor = null;
        let paramlist = null;

        if (sites.list) {
            if (site.edit) {
                headerText = `Monitor - Site: ${site.edit.site_id} - STB Parameters`;
            }
        }

        if (appparam.edit) {
            editor = <ParamEditor />;
        }

        if (appparam.add) {
            paramlist = <ParamList />;
        }

        return(
            <div id="stbparams">
                <NavigationBar header={headerText} />
                <ProcessIndicator show={processIndShow} />
                <div className="container-fluid">
                    <SiteTabs active="stbparams" />
                    <SiteData site={site.edit} more={false} showSiteIp={showSiteIp} siteList={sites.list} showList={showSiteList} clickHandler={this.onSiteSelect} displayHandler={this.onSiteDisplay} closeHandler={this.onSiteDisplayClose} />
                    {alert.message &&
                        <div className={`alert ${alert.type}`}>{alert.message}</div>
                    }
                    <div className='container-fluid mt-4'>
                        <div className='row'>
                            <button type="button"
                            className="btn btn-success visible"
                            onClick={addHandler}>New Parameter</button>
                        </div>
                        <div className='row'>
                            <div className='col mt-2'>
                                <AppLevelTable />
                            </div>
                            <div className='col mt-2'>
                                <StbLevelTable />
                            </div>
                        </div>
                    </div>
                    <div>{editor}</div>
                </div>
                {paramlist}
            </div>
        );
    }

    onSiteSelect(evt) {
        const siteIdx = evt.currentTarget.attributes['data-idx'].value;
        this.setState({ showSiteList: false });
        const { monitor, getSite, alert, clearAlert } = this.props;
        if(!isObjectEmpty(alert)) clearAlert();
        let selectedsite = monitor.sites.list[siteIdx];
        getSite(monitor, selectedsite);
    }

    onSiteDisplay() {
        this.setState({ showSiteList: true });
        const { alert, clearAlert } = this.props;
        if(!isObjectEmpty(alert)) clearAlert();
    }

    onSiteDisplayClose() {
        this.setState({ showSiteList: false });
        const { alert, clearAlert } = this.props;
        if(!isObjectEmpty(alert)) clearAlert();
    }
}

function mapStateToProps(state) {
    const alert = state.alert;
    const auth = state.authentication.user;
    const monitor = state.monitor;
    return { alert, auth, monitor };
}

const mapDispatchToProps = (dispatch) => ({
      unauthorized() { dispatch(alertActions.error('You are not authorized for that page.')); }
    , getSiteList(monitor) { dispatch(sitesActions.list(monitor)); }
    , clearAlert() { dispatch(alertActions.clear()); }
    , getSite(monitor, site) { dispatch(siteActions.get(monitor, site, false)); }
    , getParams(monitor) { dispatch(paramActions.get(monitor)); }
    , addParam(monitor) {dispatch(paramActions.add(monitor)); }
//    , getParamStbs(monitor, paramid) { dispatch(paramActions.getStbs(monitor, paramid)); }
//    , clearParamStbs(monitor) { dispatch(paramActions.clearStbs(monitor)); }
});

const connectedStbParamsMain = connect(mapStateToProps, mapDispatchToProps)(StbParamsMain);
export { connectedStbParamsMain as StbParamsMain };