import React from 'react';

import { addDays, formatMMDDYYHH, formatForDTInput } from '../../../_helpers';

export const Expire = props => {
  let expiretime = addDays(new Date(), 1); // 24 hour calculation
  expiretime = formatMMDDYYHH(expiretime);

  let expiration = formatForDTInput(new Date()); // placeholder in input box
  const mininput = expiration;
  let currExpiration = 'None';

  let expire1 = false;
  let expire2 = false;
  let expire3 = false;
  let expire4 = false;

  if (props.expire) {
    switch(props.expire.selection) {
      case 1:
        expire1 = true;
        break;
      case 2:
        expire2 = true;
        break;
      case 3:
        expire3 = true;
        break;
      case 4:
        expire4 = true;
        expiration = formatForDTInput(new Date(props.expire.expiration));
        break;
      default:
       expire1 = true;
    }
    currExpiration = props.expire.expiration ? props.expire.expiration : currExpiration;
  }
  else { expire1 = true; }

  return(
    <div className="expireContainer">
      <div className="baseAreaHdr">Expire - Current Expiration: {currExpiration.toString()}</div>
        <div>
          <div>
            <input type="radio" id="rdoexpire1" name="expire" value="1" onChange={(e) => { props.changeHandler(e); }} checked={expire1} className="radioOpts" disabled={props.disable} /><label htmlFor="rdoexpire1" className="rdoLabel">None</label>
          </div>
          <div>
            <input type="radio" id="rdoexpire2" name="expire" value="2" onChange={(e) => { props.changeHandler(e); }} checked={expire2} className="radioOpts" disabled={props.disable} /><label htmlFor="rdoexpire2" className="rdoLabel">End of Day</label>
          </div>
          <div>
            <input type="radio" id="rdoexpire3" name="expire" value="3" onChange={(e) => { props.changeHandler(e); }} checked={expire3} className="radioOpts" disabled={props.disable} /><label htmlFor="rdoexpire3" className="rdoLabel">24 Hours</label>
            <span className="expiretime">{expiretime}</span>
          </div>
          <div>
            <input type="radio" id="rdoexpire4" name="expire" value="4" onChange={(e) => { props.changeHandler(e); }} checked={expire4} className="radioOpts" disabled={props.disable} /><label htmlFor="rdoexpire4" className="rdoLabel">Specific Time</label>
            <input type="datetime-local" style={{marginLeft:"1em", fontSize:".7em"}} value={expiration} disabled={!expire4 || props.disable} onChange={(e) => { props.inputHandler(e); }} min={mininput} />
          </div>
        </div>
    </div>
  );
};
