import { adminRoleConstants } from '../_constants';

export function role(state = {}, action) {
  switch(action.type) {
    case adminRoleConstants.ROLE_SEARCH_REQUEST:
    case adminRoleConstants.ROLE_SEARCH_SUCCESS:
    case adminRoleConstants.ROLE_SEARCH_FAILURE:
    case adminRoleConstants.ROLE_ADD_REQUEST:
    case adminRoleConstants.ROLE_ADD_SUCCESS:
    case adminRoleConstants.ROLE_ADD_FAILURE:
    case adminRoleConstants.ROLE_DELETE_REQUEST:
    case adminRoleConstants.ROLE_DELETE_SUCCESS:
    case adminRoleConstants.ROLE_DELETE_FAILURE:
    case adminRoleConstants.ROLE_EDIT_REQUEST:
    case adminRoleConstants.ROLE_EDIT_FAILURE:
    case adminRoleConstants.ROLE_UPDATE_REQUEST:
    case adminRoleConstants.ROLE_UPDATE_SUCCESS:
    case adminRoleConstants.ROLE_UPDATE_FAILURE:
    case adminRoleConstants.ROLE_PERMISSION_SEARCH_REQUEST:
    case adminRoleConstants.ROLE_PERMISSION_SEARCH_SUCCESS:
    case adminRoleConstants.ROLE_PERMISSION_SEARCH_FAILURE:
    case adminRoleConstants.ROLE_PERMISSION_ADD_SELECT:
    case adminRoleConstants.ROLE_PERMISSION_UPDATE_REQUEST:
    case adminRoleConstants.ROLE_PERMISSION_UPDATE_SUCCESS:
    case adminRoleConstants.ROLE_PERMISSION_UPDATE_FAILURE:
    case adminRoleConstants.ROLE_SORT_REQUEST:
    case adminRoleConstants.ROLE_UPDATE_CANCEL:
        return {
            ...state,
            role: action.obj
        };
    default:
        return state;
  }
}
