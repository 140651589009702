export const adminPermissionConstants = {
    PERMISSION_INPUT: 'PERMISSION_INPUT'
  , PERMISSION_SEARCH_REQUEST: 'PERMISSION_SEARCH_REQUEST'
  , PERMISSION_SEARCH_SUCCESS: 'PERMISSION_SEARCH_SUCCESS'
  , PERMISSION_SEARCH_FAILURE: 'PERMISSION_SEARCH_FAILURE'

  , PERMISSION_PAGE_CHANGE: 'PERMISSION_PAGE_CHANGE'
  , PERMISSION_PAGE_ROWS: 'PERMISSION_PAGE_ROWS'

  , PERMISSION_SORT_REQUEST: 'PERMISSION_SORT_REQUEST'
  , PERMISSION_SORT_SUCCESS: 'PERMISSION_SORT_SUCCESS'

  , PERMISSION_ADD_REQUEST: 'PERMISSION_ADD_REQUEST'
  , PERMISSION_ADD_VALIDITY: 'PERMISSION_ADD_VALIDITY'
  , PERMISSION_ADD_SUCCESS: 'PERMISSION_ADD_SUCCESS'
  , PERMISSION_ADD_FAILURE: 'PERMISSION_ADD_FAILURE'

  , PERMISSION_GET_REQUEST: 'PERMISSION_GET_REQUEST'
  , PERMISSION_GET_SUCCESS: 'PERMISSION_GET_SUCCESS'
  , PERMISSION_GET_FAILURE: 'PERMISSION_GET_FAILURE'

  , PERMISSION_UPDATE_REQUEST: 'PERMISSION_UPDATE_REQUEST'
  , PERMISSION_UPDATE_VALIDITY: 'PERMISSION_UPDATE_VALIDITY'
  , PERMISSION_UPDATE_SUCCESS: 'PERMISSION_UPDATE_SUCCESS'
  , PERMISSION_UPDATE_FAILURE: 'PERMISSION_UPDATE_FAILURE'

  , PERMISSION_DELETE_REQUEST: 'PERMISSION_DELETE_REQUEST'
  , PERMISSION_DELETE_SUCCESS: 'PERMISSION_DELETE_SUCCESS'
  , PERMISSION_DELETE_FAILURE: 'PERMISSION_DELETE_FAILURE'
};
